import { registerRandomEventTrigger } from "../../../events/autorandom/RandomEventTrigger";
import { buildEventMessage } from "../../../events/EventMessageBuilder";
import { addOccurrenceToQueue, triggerEvent } from "../../../events/Events";
import { createGameEvent } from "../../../events/SimpleGameEvent";
import {
  hasFlag,
  hasGlobalFlag,
  setFlag,
  setGlobalFlag,
  setGlobalFlagForNextRun,
} from "../../../Flags";
import {
  EXPLORATION_INITIAL_STATE,
  PlayerContextState,
} from "../../../PlayerContext";
import { addContextListener } from "../../../PlayerContextListeners";
import { getResourceAmount, grantResource, Resource } from "../../../Resources";
import { Storyline, Storylines } from "../../../storylines/Storylines";
import { registerTimeTickListener } from "../../../timetick/TimeTick";
import { getSecondsPlayed } from "../../../timetick/TotalTimePlayed";
import { addUICommand } from "../../../UICommands";
import SchoolBasementB2 from "../../dungeons/schoolbasement/SchoolBasementB2";
import SchoolBasementB3 from "../../dungeons/schoolbasement/SchoolBasementB3";
import {
  getCurrentDungeonFloor,
  getExplorationStatus,
  standardEnemyAttackEffect,
  startCombat,
} from "../../Exploration";
import { ExplorationStatus } from "../../ExplorationStatus";
import { BattlerAction, BattlerStats, Enemy } from "../Enemy";

class Mimic extends Enemy {
  getId(): string {
    return "mimic";
  }

  getName(): string {
    return "Mimic";
  }

  getPicture() {
    return require("./mimic.png");
  }

  getBaseStats(): BattlerStats {
    return {
      maxHP: 1200,
      defense: 40,
      attack: 48,
      attackDelay: 2.2,
      dodgeChance: 0,
      critChance: 0,
    };
  }

  getNextAction(state: PlayerContextState): BattlerAction {
    return {
      name: "Attack",
      delay: this.getAttackDelay(state),
      transform: (state: PlayerContextState) =>
        standardEnemyAttackEffect(state, 1.0, this.getAttack(state)),
    };
  }

  getCoinsAwarded(state: PlayerContextState): number {
    return 1000000;
  }

  getMonstiumAwarded(state: PlayerContextState): number {
    return 99;
  }

  getItemsAwarded() {
    return [];
  }

  isBoss(): boolean {
    return true;
  }

  onDeath(
    state: PlayerContextState,
    isFromCreatures: boolean,
  ): PlayerContextState {
    state = super.onDeath(state, isFromCreatures);

    const mimicEventOccurrence = Object.values(state.eventOccurrences).find(
      (eventOccurrence) => eventOccurrence.eventId == "mimic_event_1",
    );
    if (mimicEventOccurrence) {
      state.eventOccurrences[
        mimicEventOccurrence.eventOccurrenceId
      ].currentMessageId = "mimic_event_2";
      state.eventOccurrences[mimicEventOccurrence.eventOccurrenceId].timestamp =
        Date.now();
      state = addOccurrenceToQueue(mimicEventOccurrence.eventOccurrenceId)(
        state,
      );
    }
    return setGlobalFlagForNextRun("mimic_defeated")(state);
  }
}

const mimic = new Mimic();

const mimicEventMessage = buildEventMessage(
  "mimic_event_1",
  "(Storyline) The Mimic",
)
  .setSimpleDescription(
    `As you explore through the school basement, you find a peculiar treasure chest. You can see teeth coming out of the opening. It's a mimic!
  
You know mimics are very dangerous monsters, but you are aware that they also hold amazing treasures inside...`,
  )
  .addOption("Fight the Mimic", undefined, (state) => {
    if (!state.exploration) {
      state.exploration = EXPLORATION_INITIAL_STATE;
    }
    state.exploration.currentDungeonFloorId = SchoolBasementB2.getId();
    state = addUICommand(state, "goToExploration");
    state = addUICommand(state, "closeAllMenus");
    state = addUICommand(state, "closeEventOverlay");
    state = startCombat(mimic)(state);
    return state;
  })
  .addOption("Maybe later")
  .build();

const postMimicFightMessage = buildEventMessage(
  "mimic_event_2",
  "(Storyline) The Mimic",
)
  .setSimpleDescription(
    `You defeat the mimic with your overwhelming strength! You inspect its corpse and manage to loot a vast amount of coins!
  
However, before you can finish, the mimic suddenly jumps back and flees at an amazing speed. Your eyes can barely follow where it went! You decide pursuing is fruitless...

Maybe you will encounter it some other time?`,
  )
  .build();

const mimicEvent1 = createGameEvent(
  "mimic_event_1",
  mimicEventMessage,
  setFlag("mimic_event_1_triggered"),
);

registerRandomEventTrigger(
  mimicEvent1,
  20,
  (state) =>
    !hasFlag(state, "mimic_event_1_triggered") &&
    getExplorationStatus(state) != ExplorationStatus.None &&
    (getCurrentDungeonFloor(state).getId() == SchoolBasementB2.getId() ||
      getCurrentDungeonFloor(state).getId() == SchoolBasementB3.getId()) &&
    !hasGlobalFlag(state, "mimic_defeated"),
);

const finalMimicMessage = buildEventMessage(
  "mimic_event_3",
  "(Storyline) The Mimic",
)
  .setSimpleDescription(
    `You enter the new campus building, only to find a toothed treasure chest right in the middle of the main hall! As you prepare to run or fight, the chest... starts speaking!
  
"M-M-Massssster", it says, with a hideous voice. "You are sssssstrong... pleassssse don't hurt me again. I can help you..." Then, it spits out a small amount of coins.

"I will make coinssssss... for you..."

It's odd, but the mimic doesn't seem to be aggressive. You cautiously pick up the coins and nod to the mimic. A treasure chest that creates coins... this might be helpful!

**You have completed "The Mimic" storyline! You will permanently gain +5:coins:/sec. This will persist even after restarting the game!**`,
  )
  .build();

const mimicEvent2 = createGameEvent(
  "mimic_event_2",
  finalMimicMessage,
  setGlobalFlag("mimic_event_finished"),
);

addContextListener("mimic_event_2", (oldState, newState) => {
  if (
    !hasGlobalFlag(newState, "mimic_event_finished") &&
    hasGlobalFlag(newState, "mimic_defeated") &&
    getResourceAmount(newState, Resource.Mana) > 0 &&
    getSecondsPlayed(newState) > 5.0
  ) {
    return triggerEvent(mimicEvent2);
  }
});

Storylines.register(
  new Storyline(
    "The Mimic",
    (state) => hasGlobalFlag(state, "mimic_event_finished"),
    (state) => hasGlobalFlag(state, "mimic_event_finished"),
    "There's a chance of this happening randomly if you are exploring the School Basement B2 or B3...",
    "+5:coins:/sec",
    (state) => true,
  ),
);

registerTimeTickListener("MimicGoldListener", (state, timeElapsedSec) => {
  if (hasGlobalFlag(state, "mimic_event_finished")) {
    const coinsGranted = timeElapsedSec * 5;
    return grantResource(Resource.Coins, coinsGranted)(state);
  }

  return state;
});

export default mimic;
