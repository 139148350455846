import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { StyleSheet, View } from "react-native";
import { Button, Input } from "react-native-elements";
import Demo from "../../backend/Demo";
import { PlayerContext } from "../../backend/PlayerContext";
import { loadBase64Data, saveDataUnsafe } from "../../backend/SaveData";
import { getCurrentVersionTimestamp } from "../../utils/Versioning";
import { Text } from "./Text";

export default function LoadGameContainer() {
  const playerContext = useContext(PlayerContext);
  const playerContextRef = useRef(playerContext);
  const [loadMessage, setLoadMessage] = useState("");
  const [loadSaveContents, setLoadSaveContents] = useState("");
  const importSavefile = useCallback(async () => {
    if (loadSaveContents === "") {
      setLoadMessage(
        "Please paste your save in the text field above this message first!",
      );
      return;
    }
    try {
      const deserializedData = await loadBase64Data(loadSaveContents);
      if (deserializedData != null) {
        // Only in the demo version, fail loading saves coming from the full version.
        const isDemo = Demo.isDemo();
        if (isDemo && deserializedData.global.isFullVersionSave) {
          setLoadMessage(
            "Failed to load save. This save data belongs to the full version of the game, and cannot be used in the demo version.",
          );
          return;
        }

        // Only in the full version, fail loading saves coming from the full version
        // if they come from a future version.
        const currentAppVersionTimestamp =
          getCurrentVersionTimestamp() || +Infinity;
        if (
          currentAppVersionTimestamp <
            deserializedData.global.appVersionTimestamp &&
          !isDemo &&
          deserializedData.global.isFullVersionSave
        ) {
          setLoadMessage(
            "Failed to load save. This save data was made with a more recent version of the game. Please update your game and try again. If no update is available, please try restarting the game or your device several times and loading the save again.",
          );
          return;
        }
        playerContextRef.current.overwrite((_oldState) => {
          return deserializedData;
        });
        await saveDataUnsafe(deserializedData);
        setLoadMessage("Load successful!");
      } else {
        setLoadMessage(
          "Failed to load save. Make sure you have copied the full text!",
        );
      }
    } catch (e) {
      setLoadMessage(
        "Failed to load save. Make sure you have copied the full text!",
      );
    }
  }, [loadSaveContents]);
  useEffect(() => setLoadMessage(""), [loadSaveContents]);

  return (
    <View>
      <Input
        multiline={true}
        onChangeText={setLoadSaveContents}
        value={loadSaveContents}
        inputContainerStyle={styles.inputContainer}
      />
      {loadMessage != "" && (
        <View style={styles.loadMessageContainer}>
          <Text style={{ color: "red" }}>{loadMessage}</Text>
        </View>
      )}
      <Button onPress={importSavefile} title="Import savefile" />
    </View>
  );
}

const styles = StyleSheet.create({
  inputContainer: {
    borderWidth: 1,
    marginTop: 16,
  },
  loadMessageContainer: {
    marginBottom: 8,
  },
});
