import { useContext, useMemo, useRef } from "react";
import { StyleSheet, View } from "react-native";
import { Button, Icon } from "react-native-elements";
import { PlayerContext, PlayerContextValue } from "../../backend/PlayerContext";
import {
  getFavoriteSpells,
  removeFromFavoriteSpells,
} from "../../backend/spells/Spells";
import CategoryContainer from "../utility/CategoryContainer";
import { SpellView } from "../utility/SpellView";

function FavoriteSpellsContainerInside(props: {
  playerContext: PlayerContextValue;
}) {
  const favoriteSpells = getFavoriteSpells(props.playerContext);
  if (favoriteSpells.length === 0) {
    return null;
  }
  const playerContextRef = useRef(props.playerContext);
  return (
    <View>
      <CategoryContainer title="Favorites">
        {favoriteSpells.map((spell) => (
          <View style={styles.spellViewContainer} key={spell.getName()}>
            <SpellView key={spell.getName()} spell={spell} isMinimal={true} />
            <Button
              icon={<Icon name="close" size={24} color="white" />}
              containerStyle={styles.deleteButton}
              style={styles.deleteButtonInner}
              iconContainerStyle={styles.deleteButtonInner}
              buttonStyle={styles.deleteButtonInner}
              hitSlop={{ top: 8, bottom: 8, left: 8, right: 8 }}
              onPress={() =>
                playerContextRef.current.apply((state) =>
                  removeFromFavoriteSpells(state, spell),
                )
              }
            />
          </View>
        ))}
      </CategoryContainer>
    </View>
  );
}

export function FavoriteSpellsContainer(props: { isVisible?: boolean }) {
  const playerContext = useContext(PlayerContext);

  return useMemo(
    () => (
      <FavoriteSpellsContainerInside playerContext={playerContext} {...props} />
    ),
    [props.isVisible !== false ? playerContext : false],
  );
}

const styles = StyleSheet.create({
  spellViewContainer: {
    paddingTop: 8,
    flexDirection: "row",
    alignItems: "center",
  },
  deleteButton: {
    marginLeft: 8,
  },
  deleteButtonInner: {
    paddingHorizontal: 0,
    paddingVertical: 0,
    height: 32,
    width: 32,
    borderRadius: 16,
  },
});
