import mem from "mem";
import { formatNumber } from "../utils/FormattingUtils";
import { Action } from "./actions/Action";
import { getActionByName } from "./actions/Actions";
import { ConsumableItem } from "./items/consumable/ConsumableItem";
import { getAmountOfItem } from "./items/Inventory";
import { Item, ItemOccurrence } from "./items/Item";
import { getItemById } from "./items/Items";
import { PlayerContextState, PlayerContextTransform } from "./PlayerContext";

export interface QuickbarItemData {
  type: "item";
  occurrence: ItemOccurrence;
}

export interface QuickbarActionData {
  type: "action";
  name: string;
}

export type QuickbarData = QuickbarItemData | QuickbarActionData;

export interface QuickbarAction {
  getDisplayName(state: PlayerContextState): string;
  isEnabled(state: PlayerContextState): boolean;
  getTransform(state: PlayerContextState): PlayerContextTransform;
  toQuickbarData(): QuickbarData;
}

class QuickbarItem {
  data: QuickbarItemData;

  constructor(data: QuickbarItemData) {
    this.data = data;
  }

  getItem(): Item {
    const itemOccurrence = this.data.occurrence;
    const item = getItemById(itemOccurrence.itemId);
    return item;
  }

  getDisplayName(state: PlayerContextState): string {
    const item = this.getItem();
    const name = item.getName(this.data.occurrence.params);
    const amount = getAmountOfItem(this.data.occurrence, state);
    let cooldown = 0;
    if (
      item
        .getTags(state, this.data.occurrence.params)
        .findIndex((item) => item.tag == "Consumable") >= 0
    ) {
      cooldown = (item as ConsumableItem).getCooldownLeftSec(state);
    }
    let displayName = name;
    if (amount > 1) {
      displayName += ` (${amount})`;
    }
    if (cooldown > 0) {
      displayName += ` (${formatNumber(cooldown, { showDecimals: true })} sec)`;
    }
    return displayName;
  }

  isEnabled(state: PlayerContextState): boolean {
    return (
      this.getItem().getPrimaryAction(state, this.data.occurrence.params)
        ?.isEnabled || false
    );
  }

  getTransform(state: PlayerContextState): PlayerContextTransform {
    return (
      this.getItem().getPrimaryAction(state, this.data.occurrence.params)
        ?.transform || ((state) => state)
    );
  }

  toQuickbarData(): QuickbarData {
    return this.data;
  }
}

const doActionMemoized = mem((action: Action) => {
  return action.doAction.bind(action, {
    isAutomatic: false,
  });
});

class QuickbarActionImpl {
  data: QuickbarActionData;

  constructor(data: QuickbarActionData) {
    this.data = data;
  }

  getAction(): Action {
    return getActionByName(this.data.name);
  }

  getDisplayName(state: PlayerContextState): string {
    return this.getAction().getDisplayName(state);
  }

  isEnabled(state: PlayerContextState): boolean {
    return this.getAction().isEnabled(state);
  }

  getTransform(state: PlayerContextState): PlayerContextTransform {
    return doActionMemoized(this.getAction());
  }

  toQuickbarData(): QuickbarData {
    return this.data;
  }
}

export function getQuickbarActions(
  state: PlayerContextState,
): QuickbarAction[] {
  return state.quickbar.map((quickbarData) => {
    if (quickbarData.type == "item") {
      return new QuickbarItem(quickbarData);
    } else {
      return new QuickbarActionImpl(quickbarData);
    }
  });
}

export function addActionToQuickbar(
  state: PlayerContextState,
  action: Action,
): PlayerContextState {
  return addToQuickbar(state, {
    type: "action",
    name: action.getName(),
  });
}

export function addItemToQuickbar(
  state: PlayerContextState,
  itemOccurrence: ItemOccurrence,
): PlayerContextState {
  return addToQuickbar(state, {
    type: "item",
    occurrence: itemOccurrence,
  });
}

export function addToQuickbar(
  state: PlayerContextState,
  data: QuickbarData,
): PlayerContextState {
  if (isInQuickbar(state, data)) {
    return state;
  }
  if (isQuickbarFull(state)) {
    return state;
  }
  state.quickbar.push(data);
  return state;
}

export function removeFromQuickbar(
  state: PlayerContextState,
  data: QuickbarData,
): PlayerContextState {
  const stringifiedData = JSON.stringify(data);
  const index = state.quickbar.findIndex(
    (element) => JSON.stringify(element) == stringifiedData,
  );
  if (index < 0) {
    return state;
  }
  state.quickbar.splice(index, 1);
  return state;
}

export function isQuickbarFull(state: PlayerContextState): boolean {
  return state.quickbar.length >= 10;
}

export function isInQuickbar(
  state: PlayerContextState,
  data: QuickbarData,
): boolean {
  const stringifiedData = JSON.stringify(data);
  return (
    state.quickbar.findIndex(
      (element) => JSON.stringify(element) == stringifiedData,
    ) >= 0
  );
}
