import { PlayerContextState } from "../../../PlayerContext";
import { EquipmentSlot } from "../../Equipment";
import { ItemParams } from "../../Item";
import { EquippableItem } from "../EquippableItem";

class HandAxe extends EquippableItem {
  getId(): string {
    return "handAxe";
  }

  getPicture() {
    return require("./handAxe.png");
  }

  getBaseName(): string {
    return "Hand Axe";
  }

  getDescription(): string | undefined {
    return "An axe. Slow, but very damaging.";
  }

  getAttackBonus(state: PlayerContextState, params: ItemParams): number {
    return 50;
  }

  getAttackDelayBonusPct(
    state: PlayerContextState,
    params: ItemParams,
  ): number {
    return 50;
  }

  getSlot(): EquipmentSlot {
    return EquipmentSlot.Hand;
  }

  getSalePrice(): number {
    return 1500;
  }

  getCraftingSchoolLevelRequirements() {
    return { Conjuration: 6 };
  }

  getCraftingMaterialsBase() {
    return {
      resources: {
        Iron: 150,
        Wood: 300,
        Temperature: 600,
      },
      items: {},
    };
  }
}

export default new HandAxe();
