import React, { useContext } from "react";
import { View } from "react-native";
import ProgressBar from "react-native-progress/Bar";
import { PlayerContext } from "../../backend/PlayerContext";
import {
  getExplorationStartTime,
  getExplorationTimeRequirementSec,
} from "../../backend/exploration/Exploration";
import { getSecondsPlayed } from "../../backend/timetick/TotalTimePlayed";

export function ExplorationProgressDisplay() {
  const playerContext = useContext(PlayerContext);
  const currentTime = getSecondsPlayed(playerContext);
  const actionStartTime = getExplorationStartTime(playerContext);
  const actionTotalTime = getExplorationTimeRequirementSec(playerContext);
  const progress = (currentTime - actionStartTime) / actionTotalTime;
  return (
    <View>
      <ProgressBar progress={progress} useNativeDriver={true} />
    </View>
  );
}
