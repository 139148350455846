import { registerAction } from "./Actions";
import GatherIron from "./campus/GatherIron";
import GatherMana from "./campus/GatherMana";
import GatherWood from "./campus/GatherWood";
import HireResearcher from "./campus/HireResearcher";
import UseTimePieces from "./campus/UseTimePieces";

export function loadActionsDemo() {
  registerAction(GatherMana);
  registerAction(GatherWood);
  registerAction(GatherIron);
  registerAction(HireResearcher);
  registerAction(UseTimePieces);
}
