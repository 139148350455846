import {
  registerTransformation,
  TransformationType,
} from "../../calculation/Calculation";
import { AttackTarget } from "../../exploration/AttackTarget";
import { CombatStat } from "../../exploration/CombatStats";
import { PlayerContextState } from "../../PlayerContext";
import { Upgrade } from "../Upgrade";
import healthBoost from "./HealthBoost";

class InnateRegeneration extends Upgrade {
  getName(): string {
    return "Innate Regeneration";
  }

  getMonstiumCost(): number {
    return 1800;
  }

  getDisplayDescription(state: PlayerContextState): string {
    return "Alter your body so that it's slightly more effective at healing itself.";
  }

  getDisplayEffect(state: PlayerContextState): string {
    return "+0.1:hpRegen:";
  }

  getUpgradeRequirements() {
    return [healthBoost];
  }
}

const innateRegeneration = new InnateRegeneration();

registerTransformation(
  [[AttackTarget.Player, CombatStat.HPRegen]],
  "InnateRegeneration",
  TransformationType.Addition,
  (state) => (innateRegeneration.isPurchased(state) ? 0.1 : 0),
);

export default innateRegeneration;
