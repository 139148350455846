import React, { useContext, useRef, useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { PlayerContext } from "../backend/PlayerContext";
import { Card, Button } from "react-native-elements";
import {
  getFreeResearchers,
  allocateResearchersToSchool,
  calculateResearcherCap,
  getBaseResearchRate,
} from "../backend/research/Researchers";
import { Text } from "./utility/Text";
import { formatNumber } from "../utils/FormattingUtils";
import ActionsContainer from "./utility/ActionsContainer";
import { PlusMinusAllocator } from "./utility/PlusMinusAllocator";
import {
  getUnlockedSchools,
  getUnlockedElements,
} from "../backend/spells/ElementsAndSchools";

export function Research() {
  const playerContext = useContext(PlayerContext);
  const playerContextRef = useRef(playerContext);
  playerContextRef.current = playerContext;

  return useMemo(() => {
    const elements = getUnlockedElements(playerContext);
    const schools = getUnlockedSchools(playerContext);
    const freeResearchers = getFreeResearchers(playerContext);
    const hasFreeResearchers = freeResearchers > 0;

    return (
      <View>
        <Card>
          <Card.Title>Schools</Card.Title>
          {schools.map((school) => {
            const current =
              playerContext.research.researcherAllocation.schools?.[school] ||
              0;
            return (
              <View
                key={school}
                style={[styles.row, styles.singleSchoolElementContainer]}
              >
                <Text>
                  {school}
                  {playerContext.primarySchool == school ? " (Primary)" : ""}:
                </Text>
                <View style={styles.row}>
                  <Text>{current}</Text>
                  <View style={styles.plusMinusAllocatorContainer}>
                    <PlusMinusAllocator
                      disableMinus={current === 0}
                      disablePlus={!hasFreeResearchers}
                      onPressMinus={() =>
                        playerContextRef.current.apply(
                          allocateResearchersToSchool(school, -1),
                        )
                      }
                      onPressPlus={() =>
                        playerContextRef.current.apply(
                          allocateResearchersToSchool(school, 1),
                        )
                      }
                    />
                  </View>
                </View>
              </View>
            );
          })}
        </Card>
        <View style={[styles.row, styles.totalResearchersContainer]}>
          <Text>Researchers available: </Text>
          <Text>
            {formatNumber(freeResearchers)}/
            {formatNumber(playerContext.research.totalResearchers)}
          </Text>
        </View>
        <View style={[styles.row, styles.researcherCapacityContainer]}>
          <Text>Researcher capacity: </Text>
          <Text>
            {formatNumber(playerContext.research.totalResearchers)}/
            {formatNumber(calculateResearcherCap(playerContext))}
          </Text>
        </View>
        <View style={[styles.row, styles.researcherCapacityContainer]}>
          <Text>Exp per researcher: </Text>
          <Text>
            {formatNumber(getBaseResearchRate(playerContext), {
              showDecimals: true,
            })}{" "}
            / sec
          </Text>
        </View>
        <View>
          <ActionsContainer area="Research" />
        </View>
      </View>
    );
  }, [
    JSON.stringify(playerContext.research),
    getBaseResearchRate(playerContext),
  ]);
}

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  plusMinusAllocatorContainer: {
    marginLeft: 16,
  },
  totalResearchersContainer: {
    marginTop: 16,
    paddingHorizontal: 16,
  },
  researcherCapacityContainer: {
    marginTop: 4,
    paddingHorizontal: 16,
  },
  singleSchoolElementContainer: {
    marginVertical: 4,
  },
});
