import { SimpleLineIcons } from "@expo/vector-icons";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  ActivityIndicator,
  Animated,
  Platform,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from "react-native";
import {
  Badge,
  createTheme,
  CreateThemeOptions,
  Header,
  Icon,
  Text,
  ThemeContext,
  ThemeProvider,
  useTheme,
} from "react-native-elements";
import { SafeAreaProvider } from "react-native-safe-area-context";
import AudioManager from "./audio/AudioManager";
import Demo from "./backend/Demo";
import { getEventMessageById } from "./backend/events/Events";
import {
  PlayerContext,
  PlayerContextState,
  PlayerContextTransform,
  PLAYER_INITIAL_STATE,
} from "./backend/PlayerContext";
import {
  addContextListener,
  applyAllTransforms,
} from "./backend/PlayerContextListeners";
import { loadData, saveData } from "./backend/SaveData";
import { getSecondsPlayed } from "./backend/timetick/TotalTimePlayed";
import { getUICommands } from "./backend/UICommands";
import About from "./components/About";
import { Autocast } from "./components/Autocast";
import Campus from "./components/Campus";
import { Creatures } from "./components/Creatures";
import Debug from "./components/Debug";
import Ending from "./components/Ending";
import { EventList } from "./components/events/EventList";
import { EventMenu } from "./components/events/EventMenu";
import { EventOverlay } from "./components/events/EventOverlay";
import Exploration from "./components/Exploration";
import Help from "./components/Help";
import Intro from "./components/Intro";
import Inventory from "./components/Inventory";
import GameTickProcessor from "./components/main/GameTickProcessor";
import ManaBar from "./components/main/ManaBar";
import { Menu, MenuContents } from "./components/main/Menu";
import Options from "./components/Options";
import { Research } from "./components/Research";
import Retirement from "./components/Retirement";
import { SpellMenu, SpellMenuContents } from "./components/spells/SpellMenu";
import Stats from "./components/Stats";
import Strengthening from "./components/Strengthening";
import Upgrades from "./components/Upgrades";
import { BottomResourceViewer } from "./components/utility/BottomResourceViewer";
import { OrientationLockManager } from "./utils/OrientationLockManager";
import { getCurrentVersionTimestamp } from "./utils/Versioning";

AudioManager.load();

const primaryColorsByElement: Record<string, any> = {
  Fire: { colors: { primary: "#c30" } },
  Earth: { colors: { primary: "#740" } },
  Water: { colors: { primary: "#08f" } },
  Air: { colors: { primary: "#0b4" } },
  Nature: { colors: { primary: "#5b0" } },
  Lightning: { colors: { primary: "#80f" } },
  Darkness: { colors: { primary: "#444" } },
};

function overrideShouldComponentUpdate(
  this: any,
  nextProps: any,
  nextState: any,
) {
  return (
    this.props.theme != nextProps.theme ||
    JSON.stringify(this.state) != JSON.stringify(nextState)
  );
}

const memoizedThemes: Record<string, any> = {};
const memoizedTheme = (key: any, theme: any) => {
  const stringified = JSON.stringify(key);
  if (!memoizedThemes[stringified]) {
    memoizedThemes[stringified] = theme;
  }
  return memoizedThemes[stringified];
};

function overrideRender(this: any) {
  const value = memoizedTheme(this.state.theme, {
    theme: this.state.theme,
    updateTheme: this.updateTheme,
    replaceTheme: this.replaceTheme,
  });
  return (
    <ThemeContext.Provider value={value}>
      {this.props.children}
    </ThemeContext.Provider>
  );
}
ThemeProvider.prototype.shouldComponentUpdate = overrideShouldComponentUpdate;
ThemeProvider.prototype.render = overrideRender;

function ThemeUpdater() {
  const theme = useTheme();
  const playerContext = useContext(PlayerContext);

  const fullTheme = useMemo((): CreateThemeOptions => {
    return {
      lightColors:
        primaryColorsByElement?.[playerContext.primaryElement || ""]?.colors,
      mode: "light",
    };
  }, [primaryColorsByElement, playerContext.primaryElement]);

  useEffect(() => {
    theme.replaceTheme(fullTheme);
  }, [fullTheme]);

  return null;
}

function MainAppContents(props: { setPlayerState: any }) {
  const { height, width } = useWindowDimensions();
  const playerContext = useContext(PlayerContext);

  const [menuShown, setMenuShown] = useState(false);
  const [rightMenuShown, setRightMenuShown] = useState("none");

  const [section, setSection] = useState("Campus");

  const processUICommand = useCallback((uiCommand: string) => {
    if (uiCommand == "goToExploration") {
      setSection("Exploration");
    } else if (uiCommand == "goToEnding") {
      setSection("Ending");
    } else if (uiCommand == "closeAllMenus") {
      setMenuShown(false);
      setRightMenuShown("none");
    }
  }, []);

  const processUICommands = useCallback((state: PlayerContextState) => {
    const uiCommands = getUICommands(state);
    uiCommands.forEach(processUICommand);
    state.uiCommands = uiCommands.filter(
      (uiCommand) =>
        uiCommand != "goToExploration" &&
        uiCommand != "goToEnding" &&
        uiCommand != "closeAllMenus",
    );
    return state;
  }, []);

  useEffect(() => {
    addContextListener("appUiCommand", (oldState, newState) => {
      const uiCommands = getUICommands(newState);
      if (uiCommands.length > 0) {
        return (state) => processUICommands(state);
      }
    });
  }, []);

  const onMenuPress = useCallback(() => {
    setMenuShown(!menuShown);
    setRightMenuShown("none");
  }, [menuShown]);
  const onEventMenuPress = useCallback(() => {
    if (rightMenuShown !== "event") {
      setRightMenuShown("event");
    } else {
      setRightMenuShown("none");
    }
    setMenuShown(false);
  }, [rightMenuShown]);
  const onSpellMenuPress = useCallback(() => {
    if (rightMenuShown !== "spell") {
      setRightMenuShown("spell");
    } else {
      setRightMenuShown("none");
    }
    setMenuShown(false);
  }, [rightMenuShown]);
  const onNavigationPress = useCallback((section: string) => {
    setSection(section);
    setMenuShown(false);
  }, []);

  const ongoingEventCount = Object.values(
    playerContext.eventOccurrences,
  ).filter((event) => {
    return (
      event.currentMessageId &&
      !getEventMessageById(event.currentMessageId).isTerminal() &&
      getEventMessageById(event.currentMessageId).isActionable(
        playerContext,
        event.params,
      )
    );
  }).length;

  const hasFourColumns = width >= 1250;
  const hasTwoColumnsOrMore = width >= 700;

  const showBottomResourceBar =
    Platform.OS !== "web" && !hasTwoColumnsOrMore && section == "Campus";

  const centerComponent = useMemo(
    () => (
      <View style={styles.centerText}>
        <Text style={{ color: "#fff" }}>{section}</Text>
      </View>
    ),
    [section],
  );

  const bottomOpacityAnim = useRef(
    new Animated.Value(showBottomResourceBar ? 0.5 : 0),
  ).current;

  useEffect(() => {
    if (showBottomResourceBar) {
      Animated.timing(bottomOpacityAnim, {
        toValue: 1.0,
        duration: 150,
        useNativeDriver: true,
      }).start();
    } else {
      Animated.timing(bottomOpacityAnim, {
        toValue: 0,
        duration: 150,
        useNativeDriver: true,
      }).start();
    }
  }, [showBottomResourceBar]);

  const mainSection = useMemo(
    () =>
      section === "Exploration" ? (
        <View style={styles.mainContainer}>
          <Exploration />
        </View>
      ) : (
        <ScrollView
          style={styles.mainContainer}
          contentContainerStyle={styles.container}
        >
          {section === "Campus" && <Campus />}
          {section === "Research" && <Research />}
          {section === "Apprentices" && <Autocast />}
          {section === "Inventory" && <Inventory />}
          {section === "Upgrades" && <Upgrades />}
          {section === "Stats" && <Stats />}
          {section === "Creatures" && <Creatures />}
          {section === "Strengthening" && <Strengthening />}
          {section === "Retirement" && <Retirement />}
          {section === "Ending" && <Ending />}
          {section === "Options" && <Options />}
          {section === "About" && <About />}
          {section === "Help" && <Help />}
          {section === "Debug" && <Debug />}
        </ScrollView>
      ),
    [section],
  );

  const theme = useTheme();

  return useMemo(() => {
    if (hasTwoColumnsOrMore) {
      return (
        <View style={styles.maxContainer}>
          <GameTickProcessor setPlayerState={props.setPlayerState} />
          <ThemeUpdater />
          <Header
            containerStyle={styles.headerContainer}
            statusBarProps={{
              translucent: true,
              backgroundColor: "transparent",
              barStyle: "light-content",
            }}
            leftComponent={undefined}
            centerComponent={centerComponent}
            rightComponent={
              !hasFourColumns ? (
                <View style={styles.rightHeaderContainer}>
                  <TouchableOpacity
                    style={styles.rightHeaderIcon}
                    onPressIn={onSpellMenuPress}
                    hitSlop={{ top: 20, left: 20, bottom: 20, right: 20 }}
                  >
                    <SimpleLineIcons name="magic-wand" size={24} color="#fff" />
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={styles.rightHeaderIcon}
                    onPressIn={onEventMenuPress}
                    hitSlop={{ top: 20, left: 10, bottom: 20, right: 20 }}
                  >
                    <Icon name="notifications" color="#fff" />
                    {ongoingEventCount > 0 && (
                      <Badge
                        value={ongoingEventCount}
                        status="error"
                        containerStyle={styles.notificationsBadge}
                      />
                    )}
                  </TouchableOpacity>
                </View>
              ) : undefined
            }
          />
          <SafeAreaView style={styles.safeAreaContainer}>
            <EventOverlay />
            <View style={styles.biggerContainer}>
              <View
                style={[
                  styles.horizontalContainer,
                  Platform.OS === "web" && hasFourColumns
                    ? { height: height - 66 }
                    : Platform.OS === "web"
                    ? { height: height - 76 }
                    : { flex: 1 },
                ]}
              >
                <View style={styles.webMenuContainer}>
                  <MenuContents
                    visible={true}
                    onNavigationPress={onNavigationPress}
                  />
                </View>
                {mainSection}
                {hasFourColumns ? (
                  <View style={styles.webRightMenuContainer}>
                    <SpellMenuContents visible={true} />
                  </View>
                ) : (
                  <SpellMenu
                    visible={rightMenuShown === "spell"}
                    onBackgroundPress={onSpellMenuPress}
                  />
                )}
                {hasFourColumns ? (
                  <View style={styles.webRightMenuContainer}>
                    <ScrollView>
                      <EventList />
                    </ScrollView>
                  </View>
                ) : (
                  <EventMenu
                    visible={rightMenuShown === "event"}
                    onBackgroundPress={onEventMenuPress}
                  />
                )}
              </View>

              <ManaBar />
            </View>
          </SafeAreaView>
        </View>
      );
    }
    return (
      <View style={styles.maxContainer}>
        <GameTickProcessor setPlayerState={props.setPlayerState} />
        <ThemeUpdater />
        <Header
          containerStyle={styles.headerContainer}
          statusBarProps={{
            translucent: true,
            backgroundColor: "transparent",
            barStyle: "light-content",
          }}
          leftComponent={
            <TouchableOpacity
              onPressIn={onMenuPress}
              hitSlop={{ top: 20, left: 20, bottom: 20, right: 20 }}
            >
              <Icon name="menu" color="#fff" />
            </TouchableOpacity>
          }
          centerComponent={centerComponent}
          rightComponent={
            <View style={styles.rightHeaderContainer}>
              <TouchableOpacity
                style={styles.rightHeaderIcon}
                onPressIn={onSpellMenuPress}
                hitSlop={{ top: 20, left: 20, bottom: 20, right: 20 }}
              >
                <SimpleLineIcons name="magic-wand" size={24} color="#fff" />
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.rightHeaderIcon}
                onPressIn={onEventMenuPress}
                hitSlop={{ top: 20, left: 10, bottom: 20, right: 20 }}
              >
                <Icon name="notifications" color="#fff" />
                {ongoingEventCount > 0 && (
                  <Badge
                    value={ongoingEventCount}
                    status="error"
                    containerStyle={styles.notificationsBadge}
                  />
                )}
              </TouchableOpacity>
            </View>
          }
        />
        <SafeAreaView style={styles.safeAreaContainer}>
          <EventOverlay />
          <View style={styles.biggerContainer}>
            <View
              style={[
                Platform.OS === "web" ? { height: height - 76 } : { flex: 1 },
                styles.mainContainerPhone,
              ]}
            >
              <View style={{ flex: 1 }}>
                {mainSection}
                <SpellMenu
                  visible={rightMenuShown === "spell"}
                  onBackgroundPress={onSpellMenuPress}
                />
                <EventMenu
                  visible={rightMenuShown === "event"}
                  onBackgroundPress={onEventMenuPress}
                />
              </View>
              {showBottomResourceBar && (
                <Animated.View
                  style={[
                    {
                      opacity: bottomOpacityAnim,
                      borderTopWidth: 1.0,
                      borderTopColor: theme.theme.colors.divider,
                    },
                  ]}
                >
                  <BottomResourceViewer />
                </Animated.View>
              )}
              <Menu
                visible={menuShown}
                onBackgroundPress={onMenuPress}
                onNavigationPress={onNavigationPress}
              />
            </View>

            <ManaBar />
          </View>
        </SafeAreaView>
      </View>
    );
  }, [
    props.setPlayerState,
    onMenuPress,
    onSpellMenuPress,
    onEventMenuPress,
    section,
    mainSection,
    ongoingEventCount,
    menuShown,
    rightMenuShown,
    onNavigationPress,
    hasTwoColumnsOrMore,
    hasFourColumns,
    height,
    bottomOpacityAnim,
  ]);
}

export default function App() {
  const [playerState, setPlayerState] = useState(PLAYER_INITIAL_STATE);

  const [loading, setLoading] = useState(true);

  const playerStateRef = useRef(playerState);
  playerStateRef.current = playerState;

  const apply = useCallback(
    (transform: PlayerContextTransform) => {
      const newState = applyAllTransforms(playerStateRef.current, transform);
      newState.global.appVersionTimestamp = getCurrentVersionTimestamp() || 0;
      newState.global.isFullVersionSave = !Demo.isDemo();

      setPlayerState(newState);
    },
    [playerStateRef, setPlayerState],
  );

  const overwrite = useCallback(
    (transform: PlayerContextTransform) => {
      setPlayerState(transform(playerStateRef.current));
    },
    [playerStateRef, setPlayerState],
  );

  const playerContextValue = useMemo(() => {
    return {
      ...playerState,
      apply: apply,
      overwrite: overwrite,
    };
  }, [playerState, apply, overwrite]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const loadedState = await loadData();
        setPlayerState(loadedState);
        setLoading(false);
      } catch (error) {
        console.error("Error loading data");
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const storeData = async () => {
      try {
        await saveData(playerState);
      } catch (error) {
        console.error("Error saving data", error);
      }
    };
    storeData();
  }, [Math.floor(getSecondsPlayed(playerState) / 5.0)]);

  const theme = useMemo(() => {
    return createTheme({
      lightColors:
        primaryColorsByElement?.[playerContextValue.primaryElement || ""]
          ?.colors,
      mode: "light",
    });
  }, [primaryColorsByElement, playerContextValue.primaryElement]);

  if (loading) {
    return (
      <SafeAreaProvider>
        <OrientationLockManager />
        <SafeAreaView style={[styles.safeAreaContainer]}>
          <ActivityIndicator size="large" />
        </SafeAreaView>
      </SafeAreaProvider>
    );
  }

  if (
    playerContextValue.primaryElement == undefined ||
    playerContextValue.primarySchool == undefined
  ) {
    // Send to intro - do not have the GameTickProcessor or any menu
    return (
      <SafeAreaProvider>
        <PlayerContext.Provider value={playerContextValue}>
          <Header
            containerStyle={styles.headerContainer}
            statusBarProps={{
              translucent: true,
              backgroundColor: "transparent",
              barStyle: "light-content",
            }}
            centerComponent={{
              text: "Welcome to Magic Research",
              style: { color: "#fff" },
            }}
          />
          <OrientationLockManager />
          <SafeAreaView style={styles.safeAreaContainer}>
            <View style={styles.biggerContainer}>
              <View style={styles.introContainer}>
                <ScrollView contentContainerStyle={styles.container}>
                  <Intro onSelect={() => {}} />
                </ScrollView>
              </View>
            </View>
          </SafeAreaView>
        </PlayerContext.Provider>
      </SafeAreaProvider>
    );
  }

  return (
    <SafeAreaProvider>
      <OrientationLockManager />
      <PlayerContext.Provider value={playerContextValue}>
        <ThemeProvider theme={theme}>
          <MainAppContents setPlayerState={setPlayerState} />
        </ThemeProvider>
      </PlayerContext.Provider>
    </SafeAreaProvider>
  );
}

/*export default function Test() {
  return (
    <Markdown debugPrintTree>
      Focus your thoughts :close: and gather mana. And don't forget to lock the
      door!
    </Markdown>
  );
}*/

const headerPadding =
  Platform.OS === "android" ? 16 : Platform.OS === "web" ? 8 : 0;

const styles = StyleSheet.create({
  headerContainer: {
    paddingTop: headerPadding,
  },
  rightHeaderContainer: {
    flexDirection: "row",
  },
  rightHeaderIcon: {
    marginLeft: 20,
  },
  safeAreaContainer: {
    flex: 1,
    justifyContent: "center",
  },
  centerAligned: {
    alignItems: "center",
  },
  maxContainer: {
    flex: 1,
    backgroundColor: "#fff",
  },
  biggerContainer: {
    flex: 1,
    backgroundColor: "#fff",
  },
  mainContainerPhone: {
    overflow: "hidden",
  },
  introContainer: {
    alignItems: "center",
  },
  horizontalContainer: {
    flexDirection: "row",
    alignItems: "stretch",
    overflow: "hidden",
  },
  mainContainer: {
    flex: 1,
  },
  webMenuContainer: {
    width: 284,
    paddingRight: 8,
  },
  webRightMenuContainer: {
    width: 300,
    paddingLeft: 8,
  },
  notificationsBadge: {
    position: "absolute",
    top: -8,
    right: -8,
  },
  menuContainer: {
    position: "absolute",
  },
  container: {
    padding: 8,
  },
  centerText: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});
