import {
  registerTransformation,
  TransformationType,
} from "../../calculation/Calculation";
import { TransformationTags } from "../../calculation/TransformationTags";
import { PlayerContextState } from "../../PlayerContext";
import { Upgrade } from "../Upgrade";

class LargePouch extends Upgrade {
  getName(): string {
    return "Large Pouch";
  }

  getMonstiumCost(): number {
    return 600;
  }

  getDisplayDescription(state: PlayerContextState): string {
    return "Increases the size of the pouch you use to store items, allowing you to store more items.";
  }

  getDisplayEffect(state: PlayerContextState): string {
    return "+4 Inventory slots";
  }
}

const largePouch = new LargePouch();

registerTransformation(
  [[TransformationTags.InventorySlot]],
  "LargePouch",
  TransformationType.Addition,
  (state) => (largePouch.isPurchased(state) ? 4 : 0),
);

export default largePouch;
