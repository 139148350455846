import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import Crafting from "./inventory/Crafting";
import InventoryItems from "./inventory/InventoryItems";
import { Text } from "./utility/Text";
import { Button, Icon } from "react-native-elements";
import EquippedItems from "./inventory/EquippedItems";

export default function Inventory() {
  const [isCrafting, setIsCrafting] = useState(false);

  if (!isCrafting) {
    return (
      <View style={styles.container}>
        <View style={styles.topRow}>
          <Text h2>Inventory</Text>
          <Button title="Craft Items" onPress={() => setIsCrafting(true)} />
        </View>
        <View style={styles.equippedItemsContainer}>
          <EquippedItems />
        </View>
        <InventoryItems />
      </View>
    );
  } else {
    return (
      <View style={styles.container}>
        <View style={[styles.topRow, styles.row]}>
          <Icon
            name="arrow-back"
            color="#000"
            containerStyle={styles.arrowBack}
            onPress={() => setIsCrafting(false)}
            hitSlop={{ top: 30, left: 30, bottom: 30, right: 30 }}
          />
          <Text h2>Crafting</Text>
        </View>
        <Crafting />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "stretch",
    justifyContent: "flex-start",
    width: "100%",
  },
  topRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
    height: 48,
  },
  arrowBack: {
    marginRight: 8,
  },
  equippedItemsContainer: {
    marginBottom: 16,
  },
  row: {
    justifyContent: "flex-start",
  },
});
