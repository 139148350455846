import React, { useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { Icon } from "react-native-elements";
import { Help } from "../../backend/help/Helps";
import { Markdown } from "./Markdown";
import { Text } from "./Text";

export function HelpContainer(props: {
  help: Help;
  collapsed?: boolean;
  onPressExpand?: () => void;
}) {
  const help = props.help;
  const title = help.title;
  const description = help.description;

  const descriptionContainer = useMemo(() => {
    return (
      <View style={styles.descriptionTextContainer}>
        <Markdown style={styles.descriptionViewer}>{description}</Markdown>
      </View>
    );
  }, [description]);

  return useMemo(() => {
    return (
      <View style={styles.helpContainer} key={title}>
        <View style={styles.topRowContainer}>
          <Text
            h3
            key={title}
            onPress={props.onPressExpand}
            style={[styles.topContainer]}
          >
            {title}
          </Text>
          <Icon
            name={props.collapsed ? "chevron-right" : "chevron-down"}
            type="entypo"
            containerStyle={styles.expandContainer}
            onPress={props.onPressExpand}
          />
        </View>
        {!props.collapsed && <View>{descriptionContainer}</View>}
      </View>
    );
  }, [
    title,
    !props.collapsed && descriptionContainer,
    props.collapsed,
    props.onPressExpand,
  ]);
}

const styles = StyleSheet.create({
  helpContainer: { marginVertical: 8 },
  topRowContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  expandContainer: {
    width: 40,
  },
  descriptionTextContainer: {
    marginBottom: 8,
    marginTop: 8,
    marginHorizontal: 8,
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
  },
  descriptionViewer: { flex: 1, justifyContent: "center" },
  topContainer: {
    flex: 1,
    marginLeft: 8,
  },
  disabledStorylineText: {
    color: "#aaa",
  },
});
