import mem from "mem";
import { formatNumber } from "../../utils/FormattingUtils";
import { ActionEffect } from "../actions/Action";
import { applyTransformations } from "../calculation/Calculation";
import { TransformationTags } from "../calculation/TransformationTags";
import { hasFlag } from "../Flags";
import { PlayerContextState, PlayerContextTransform } from "../PlayerContext";
import { addItemToQuickbar, isInQuickbar, isQuickbarFull } from "../Quickbar";
import { getResourceAmount, grantResource, Resource } from "../Resources";
import {
  grantSchoolExp,
  meetsRequirements,
  School,
} from "../spells/ElementsAndSchools";
import {
  addToInventory,
  calculateInventoryCap,
  getAmountOfItem,
  getInventory,
  getTotalAmountOfItem,
  removeFromInventory,
  removeFromInventoryWithAnyParams,
} from "./Inventory";
import { getItemById, registerItem } from "./Items";

export interface ItemTag {
  tag: string;
}

export interface ItemStack {
  itemOccurrence: ItemOccurrence;
  amount: number;
}

export type ItemParams = Record<string, any>;

export interface ItemOccurrence {
  itemId: string;
  params: ItemParams;
}

export interface ItemAction {
  description: string;
  isEnabled: boolean;
  transform?: PlayerContextTransform;
}

export interface Item {
  getId(): string;
  getPicture(): any;
  getName(params: ItemParams): string;
  getDescription(
    state: PlayerContextState,
    params: ItemParams,
  ): string | undefined;
  getEffect(state: PlayerContextState, params: ItemParams): string | undefined;
  getTags(state: PlayerContextState, params: ItemParams): ItemTag[];
  getPrimaryAction(
    state: PlayerContextState,
    params: ItemParams,
  ): ItemAction | undefined;
  getSaleAction(
    state: PlayerContextState,
    params: ItemParams,
  ): ItemAction | undefined;
  getQuickbarAction(
    state: PlayerContextState,
    params: ItemParams,
  ): ItemAction | undefined;

  getCraftingSchoolLevelRequirements(
    state: PlayerContextState,
  ): Partial<Record<string, number>>;
  getCraftingMaterials(state: PlayerContextState): {
    resources: Partial<Record<Resource, number>>;
    items: Record<string, number>;
  };
  generateCraftedParams(state: PlayerContextState): ItemParams;
  getDefaultParams(): ItemParams;
  isCraftable(state: PlayerContextState): boolean;
  canCraft(state: PlayerContextState): boolean;
  craft(state: PlayerContextState): PlayerContextState;
}

const saleAction = mem(
  (
    sellingUnlocked: boolean,
    id: string,
    params: ItemParams,
    salePrice: number,
    totalAmount: number,
  ) => {
    if (sellingUnlocked) {
      const totalCoins = salePrice * totalAmount;
      return {
        description: `Sell all for ${formatNumber(totalCoins)} Coins`,
        isEnabled: true,
        transform: (state: PlayerContextState) => {
          state = removeFromInventory({ itemId: id, params }, 1e10, state);
          return grantResource(Resource.Coins, totalCoins)(state);
        },
      };
    } else {
      return {
        description: "Discard all",
        isEnabled: true,
        transform: (state: PlayerContextState) => {
          return removeFromInventory({ itemId: id, params }, 1e10, state);
        },
      };
    }
  },
  { cacheKey: JSON.stringify },
);

export abstract class ItemBase implements Item {
  constructor() {
    registerItem(this);
  }

  abstract getId(): string;
  abstract getPicture(): any;
  abstract getBaseName(params: ItemParams): string;
  abstract getDescription(
    state: PlayerContextState,
    params: ItemParams,
  ): string | undefined;
  abstract getEffect(
    state: PlayerContextState,
    params: ItemParams,
  ): string | undefined;
  abstract getSalePrice(state: PlayerContextState, params: ItemParams): number;

  getTags(_state: PlayerContextState, _params: ItemParams): ItemTag[] {
    return [];
  }

  getName(params: ItemParams): string {
    const base = this.getBaseName(params);
    if (params?.itemQuality != null && params?.itemQuality > 0) {
      return `${base} +${formatNumber(params?.itemQuality)}`;
    }
    return `${base}`;
  }

  protected getBaseItemEffects(): Record<string, ActionEffect> {
    return {};
  }

  getItemEffects(
    state: PlayerContextState,
    params: ItemParams,
  ): Record<string, number> {
    const baseEffects = this.getBaseItemEffects();
    const finalEffects: Record<string, number> = {};
    for (let key in baseEffects) {
      const baseEffect = baseEffects[key];
      finalEffects[key] = applyTransformations(
        [
          this.getId(),
          ...this.getTags(state, params).map((itemTag) => itemTag.tag),
          ...(baseEffect.tags ?? []),
          TransformationTags.ItemEffect,
        ],
        state,
        baseEffect.value,
        {
          item: this,
          params,
        },
      );
    }
    return finalEffects;
  }

  getPrimaryAction(
    _state: PlayerContextState,
    _params: ItemParams,
  ): ItemAction | undefined {
    return undefined;
  }

  getSaleAction(
    state: PlayerContextState,
    params: ItemParams,
  ): ItemAction | undefined {
    return saleAction(
      hasFlag(state, "selling_unlocked"),
      this.getId(),
      params,
      this.getSalePrice(state, params),
      getAmountOfItem({ itemId: this.getId(), params }, state),
    );
  }

  getQuickbarAction(
    state: PlayerContextState,
    params: ItemParams,
  ): ItemAction | undefined {
    if (!this.getPrimaryAction(state, params)) {
      return undefined;
    }
    return {
      description: "Add to Quickbar",
      isEnabled:
        !isInQuickbar(state, {
          type: "item",
          occurrence: { itemId: this.getId(), params },
        }) && !isQuickbarFull(state),
      transform: (state: PlayerContextState) => {
        return addItemToQuickbar(state, { itemId: this.getId(), params });
      },
    };
  }

  isCraftable(state: PlayerContextState): boolean {
    const schoolRequirements = this.getCraftingSchoolLevelRequirements(state);
    if (Object.keys(schoolRequirements).length == 0) {
      return false;
    }
    return meetsRequirements(state, schoolRequirements);
  }

  canCraft(state: PlayerContextState): boolean {
    if (!this.isCraftable(state)) {
      return false;
    }

    const materials = this.getCraftingMaterials(state);

    for (let resource in materials.resources) {
      const amount = materials.resources?.[resource as Resource] || 0;
      if (getResourceAmount(state, resource as Resource) < amount) {
        return false;
      }
    }

    for (let itemId in materials.items) {
      const amount = materials.items[itemId];
      if (getTotalAmountOfItem(getItemById(itemId), state) < amount) {
        return false;
      }
    }

    if (getInventory(state).length >= calculateInventoryCap(state)) {
      return false;
    }

    return true;
  }

  getCraftingSchoolLevelRequirements(
    state: PlayerContextState,
  ): Partial<Record<School, number>> {
    return {};
  }

  getCraftingMaterialsBase(state: PlayerContextState): {
    resources: Partial<Record<Resource, number>>;
    items: Record<string, number>;
  } {
    return {
      resources: {},
      items: {},
    };
  }

  getCraftingMaterials(state: PlayerContextState): {
    resources: Partial<Record<Resource, number>>;
    items: Record<string, number>;
  } {
    const base = this.getCraftingMaterialsBase(state);
    let resources: Partial<Record<Resource, number>> = {},
      items: Record<string, number> = {};
    for (let resource in base.resources) {
      const baseAmount = base.resources[resource as Resource] ?? 0;
      resources[resource as Resource] = Math.ceil(
        applyTransformations(
          [
            ...this.getTags(state, {}).map((itemTag) => itemTag.tag),
            ...Object.keys(this.getCraftingSchoolLevelRequirements(state)),
            TransformationTags.ItemCost,
          ],
          state,
          baseAmount,
        ),
      );
    }
    for (let item in base.items) {
      const baseAmount = base.items[item] ?? 0;
      items[item] = Math.ceil(
        applyTransformations(
          [
            ...this.getTags(state, {}).map((itemTag) => itemTag.tag),
            ...Object.keys(this.getCraftingSchoolLevelRequirements(state)),
            TransformationTags.ItemCost,
          ],
          state,
          baseAmount,
        ),
      );
    }
    return {
      resources,
      items,
    };
  }

  generateCraftedParams(state: PlayerContextState): ItemParams {
    const itemQuality = applyTransformations(
      [
        this.getId(),
        TransformationTags.ItemQuality,
        ...this.getTags(state, {}).map((tag) => tag.tag),
        ...Object.keys(this.getCraftingSchoolLevelRequirements(state)),
      ],
      state,
      0,
      { item: this },
    );
    if (itemQuality == 0) {
      return {};
    }
    return { itemQuality };
  }

  getDefaultParams(): ItemParams {
    return {};
  }

  craft(state: PlayerContextState): PlayerContextState {
    if (!this.canCraft(state)) {
      return state;
    }

    const materials = this.getCraftingMaterials(state);
    const params = this.generateCraftedParams(state);
    const schoolRequirements = this.getCraftingSchoolLevelRequirements(state);

    for (let resource in materials.resources) {
      const amount = materials.resources?.[resource as Resource] || 0;
      state = grantResource(resource as Resource, -amount)(state);
    }

    for (let itemId in materials.items) {
      const amount = materials.items[itemId];
      state = removeFromInventoryWithAnyParams(
        getItemById(itemId),
        amount,
        state,
      );
    }

    state = addToInventory({ itemId: this.getId(), params }, 1, state);

    Object.keys(schoolRequirements).forEach((school) => {
      state = grantSchoolExp(
        school as School,
        (schoolRequirements[school as School] || 0) * 3 + 5,
      )(state);
    });

    return state;
  }
}
