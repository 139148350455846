import { PlayerContextState, PlayerContextTransform } from "../PlayerContext";
import { getSecondsPlayed } from "../timetick/TotalTimePlayed";
import { EmptyEventMessage } from "./EmptyEventMessage";
import { EventMessage, EventMessageOption, GameEvent } from "./GameEvent";

const eventMessages: Record<string, EventMessage> = {};

export function registerEventMessage(message: EventMessage) {
  eventMessages[message.getId()] = message;
}

export function eventMessageExists(id: string): boolean {
  return Boolean(eventMessages?.[id]);
}

export function getEventMessageById(id: string): EventMessage {
  return (eventMessages?.[id] || EmptyEventMessage) as EventMessage;
}

export type EventOccurrenceData = {
  eventOccurrenceId: string;
  eventId: string;
  currentMessageId: string | null;
  timestamp: number;
  timestampSecondsPlayed: number;
  params: any;
  messageHistory: string[];
};

const gameEvents: Record<string, GameEvent> = {};

export function registerGameEvent(event: GameEvent) {
  gameEvents[event.getId()] = event;
}

export function getGameEventById(id: string): GameEvent {
  return gameEvents?.[id] as GameEvent;
}

export function getAllGameEvents(): Record<string, GameEvent> {
  return gameEvents;
}

export function getEventOccurrenceById(
  state: PlayerContextState,
  id: string,
): EventOccurrenceData | undefined {
  return state.eventOccurrences?.[id];
}

function executeEventOccurrenceActionImpl(
  occurrenceId: string,
  selectedOption: EventMessageOption,
  state: PlayerContextState,
) {
  const nextMessage = selectedOption.nextMessage;
  if (nextMessage) {
    if (state.eventOccurrences[occurrenceId].messageHistory == null) {
      state.eventOccurrences[occurrenceId].messageHistory = [];
    }
    state.eventOccurrences[occurrenceId].messageHistory.push(
      state.eventOccurrences[occurrenceId].currentMessageId as string,
    );
    state.eventOccurrences[occurrenceId].currentMessageId = nextMessage.getId();

    // Do this to avoid accidentally cleaning up events after they go terminal
    if (nextMessage.isTerminal()) {
      state.eventOccurrences[occurrenceId].timestamp = Date.now();
      state.eventOccurrences[occurrenceId].timestampSecondsPlayed =
        getSecondsPlayed(state);
    }
  }

  if (selectedOption.transform) {
    state = selectedOption.transform(
      state,
      state.eventOccurrences[occurrenceId].params,
    );
  }

  return state;
}

export function executeEventOccurrenceAction(
  this: any,
  occurrenceId: string,
  selectedOption: EventMessageOption,
): PlayerContextTransform {
  return executeEventOccurrenceActionImpl.bind(
    this,
    occurrenceId,
    selectedOption,
  );
}

function addOccurrenceToQueueImpl(
  occurrenceId: string,
  state: PlayerContextState,
) {
  state.eventQueue.push(occurrenceId);
  return state;
}

export function addOccurrenceToQueue(
  this: any,
  occurrenceId: string,
): PlayerContextTransform {
  return addOccurrenceToQueueImpl.bind(this, occurrenceId);
}

function removeOccurrenceFromQueueImpl(
  occurrenceId: string,
  state: PlayerContextState,
) {
  const position = state.eventQueue.lastIndexOf(occurrenceId);
  if (position >= 0) {
    state.eventQueue.splice(position, 1);
  }

  return state;
}

export function removeOccurrenceFromQueue(
  this: any,
  occurrenceId: string,
): PlayerContextTransform {
  return removeOccurrenceFromQueueImpl.bind(this, occurrenceId);
}

function triggerEventImpl(
  event: GameEvent,
  params: any,
  state: PlayerContextState,
) {
  const transform = event.onTrigger();
  if (transform) {
    state = transform(state, params);
  }

  const occurrenceId = event.getId() + Date.now();
  state.eventQueue.unshift(occurrenceId);
  state.eventOccurrences[occurrenceId] = {
    eventOccurrenceId: occurrenceId,
    eventId: event.getId(),
    currentMessageId: event.getMainEventMessage().getId(),
    timestamp: Date.now(),
    timestampSecondsPlayed: getSecondsPlayed(state),
    params,
    messageHistory: [],
  };
  return state;
}

export function triggerEvent(
  this: any,
  event: GameEvent,
  params?: any,
): PlayerContextTransform {
  return triggerEventImpl.bind(this, event, params || {});
}
