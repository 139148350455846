import {
  registerTransformation,
  TransformationType,
} from "../../calculation/Calculation";
import { TransformationTags } from "../../calculation/TransformationTags";
import { PlayerContextState } from "../../PlayerContext";
import { Upgrade } from "../Upgrade";
import researchFair from "./ResearchFair";

class ResearcherFocus extends Upgrade {
  getName(): string {
    return "Researcher Focus";
  }

  getMonstiumCost(): number {
    return 2000;
  }

  getDisplayDescription(state: PlayerContextState): string {
    return "Train your researchers so that they can focus better, to increase their effectivity.";
  }

  getDisplayEffect(state: PlayerContextState): string {
    return "+50% Research speed";
  }

  getUpgradeRequirements() {
    return [researchFair];
  }
}

const researcherFocus = new ResearcherFocus();

registerTransformation(
  [[TransformationTags.Research]],
  "ResearcherFocus",
  TransformationType.Multiplier,
  (state) => (researcherFocus.isPurchased(state) ? 1.5 : 1.0),
);

export default researcherFocus;
