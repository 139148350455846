import React, { useContext } from "react";
import { StyleSheet, View } from "react-native";
import { getTotalAmountOfItem } from "../../backend/items/Inventory";
import { getItemById } from "../../backend/items/Items";
import { PlayerContext } from "../../backend/PlayerContext";
import {
  getResourceAmount,
  getResourceCap,
  getResourceInfo,
  Resource,
} from "../../backend/Resources";
import { formatNumber } from "../../utils/FormattingUtils";
import { GameIcon } from "./icons/GameIcon";
import { Text } from "./Text";

type Props = {
  resources: Partial<Record<Resource, number>>;
  items?: Record<string, number>;
};

function ResourceRow(props: {
  name: string;
  icon: string;
  current: number;
  cost: number;
  cap: number;
}) {
  return (
    <View style={styles.row}>
      <View style={styles.inlineRow}>
        <GameIcon icon={props.icon} style={styles.icon} small />
        <Text>{props.name}: </Text>
      </View>
      <View style={styles.costValue}>
        <Text style={props.current < props.cost && styles.notMet}>
          {formatNumber(props.current, { showDecimals: true })}
        </Text>
        <Text>/</Text>
        <Text style={props.cap < props.cost && styles.notMet}>
          {formatNumber(props.cost)}
        </Text>
      </View>
    </View>
  );
}

const ResourceRowMemo = React.memo(ResourceRow);

export function CostViewer(props: Props) {
  const playerContext = useContext(PlayerContext);
  const resourceKeys = Object.keys(props.resources) as Array<Resource>;
  const rows = resourceKeys.map((resourceName) => {
    const resourceCost = props.resources[resourceName] as number;
    const resourceCap = getResourceCap(playerContext, resourceName);
    const currentAmount = getResourceAmount(playerContext, resourceName);
    const resourceInfo = getResourceInfo(resourceName);
    return (
      <ResourceRowMemo
        name={resourceName}
        icon={resourceInfo.icon}
        current={currentAmount}
        cost={resourceCost}
        cap={resourceCap}
        key={resourceName}
      />
    );
  });
  const itemRows = props.items
    ? Object.keys(props.items).map((itemId) => {
        const itemCost = props?.items?.[itemId] as number;
        const item = getItemById(itemId);
        const itemAmount = getTotalAmountOfItem(item, playerContext);
        return (
          <ResourceRowMemo
            name={item.getName(item.getDefaultParams())}
            icon={item.getId()}
            current={itemAmount}
            cost={itemCost}
            cap={1e10}
            key={itemId}
          />
        );
      })
    : [];
  return (
    <View style={styles.container}>
      {rows}
      {itemRows}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "stretch",
  },
  inlineRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  icon: {
    marginRight: 2,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  costValue: {
    flexDirection: "row",
  },
  notMet: {
    color: "red",
  },
});
