import React, { useContext, useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { Text } from "react-native-elements";
import ProgressBar from "react-native-progress/Bar";
import {
  getCurrentSummonAction,
  getSummonActionProgress,
} from "../../backend/exploration/Exploration";
import { PlayerContext } from "../../backend/PlayerContext";
import { GameIcon } from "../utility/icons/GameIcon";

export function SummonActionDisplay() {
  const playerContext = useContext(PlayerContext);
  const currentSummonAction = getCurrentSummonAction(playerContext);
  const progress = getSummonActionProgress(playerContext);
  return useMemo(() => {
    if (!currentSummonAction) {
      return (
        <View style={styles.row}>
          <GameIcon icon="attack" style={styles.icon} />
          <View style={styles.progressBarContainer}>
            <Text style={styles.idleTextContainer}>-</Text>
            <ProgressBar progress={0} useNativeDriver={true} width={120} />
          </View>
        </View>
      );
    }
    return (
      <View style={styles.row}>
        <GameIcon icon="attack" style={styles.icon} />
        <View style={styles.progressBarContainer}>
          <Text numberOfLines={1} style={styles.markdownContainer}>
            {currentSummonAction.name}
          </Text>
          <ProgressBar progress={progress} useNativeDriver={true} width={120} />
        </View>
      </View>
    );
  }, [currentSummonAction?.name, progress]);
}

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: 156,
  },
  icon: {
    marginRight: 4,
  },
  progressBarContainer: {
    flex: 1,
    height: 32,
  },
  idleTextContainer: {
    height: 18,
  },
  markdownContainer: {
    minHeight: 18,
  },
});
