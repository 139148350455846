import { formatNumber } from "../../../utils/FormattingUtils";
import { ActionEffect } from "../../actions/Action";
import { getBuildingAmount } from "../../buildings/Buildings";
import Furnace from "../../buildings/campus/Furnace";
import {
  registerTransformation,
  TransformationType,
} from "../../calculation/Calculation";
import { TransformationTags } from "../../calculation/TransformationTags";
import { PlayerContextState } from "../../PlayerContext";
import {
  grantTemporaryEffect,
  hasTemporaryEffect,
} from "../../timetick/TemporaryEffects";
import { SpellActionBase } from "../Spell";

class EnchantFurnaces extends SpellActionBase {
  constructor() {
    super();
  }

  isVisible(state: PlayerContextState): boolean {
    return super.isVisible(state) && getBuildingAmount(state, Furnace) > 0;
  }

  getSpellName(): string {
    return "Enchant Furnaces";
  }

  getArea(): string {
    return "Campus";
  }

  getDisplayDescription(): string {
    return "Magically alter your Furnaces to produce (and consume) faster for a limited time.";
  }

  protected getBaseActionEffects(): Record<string, ActionEffect> {
    return {
      magnitude: {
        value: 2.0,
        tags: [TransformationTags.TemporaryEffectMagnitude],
      },
      duration: {
        value: 30,
        tags: [TransformationTags.TemporaryEffectDuration],
      },
    };
  }

  doSpellAction(state: PlayerContextState): PlayerContextState {
    const effects = this.getActionEffects(state);
    return grantTemporaryEffect(
      this.getId(),
      this.getSpellName(),
      effects.duration,
      {},
    )(state);
  }

  getDisplayEffect(state: PlayerContextState): string {
    const effects = this.getActionEffects(state);
    return `${formatNumber(effects.magnitude, {
      showDecimals: true,
    })}x:temperature: production and ${formatNumber(effects.magnitude, {
      showDecimals: true,
    })}x:wood: consumption by Furnaces for ${formatNumber(
      effects.duration,
    )} sec.`;
  }

  getManaCost(): number {
    return 90;
  }

  getSchoolLevelRequirements(): Record<string, number> {
    return {
      Enchantment: 3,
    };
  }
}

const enchantFurnaces = new EnchantFurnaces();

registerTransformation(
  [
    [Furnace.getName(), TransformationTags.Production],
    [Furnace.getName(), TransformationTags.Consumption],
  ],
  "EnchantFurnaces",
  TransformationType.Multiplier,
  (state) =>
    hasTemporaryEffect(state, "Enchant Furnaces")
      ? enchantFurnaces.getActionEffects(state).magnitude
      : 1,
);

export default enchantFurnaces;
