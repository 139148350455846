import { applyTransformations } from "../../calculation/Calculation";
import { TransformationTags } from "../../calculation/TransformationTags";
import { PlayerContextState } from "../../PlayerContext";
import {
  calculateResearcherCap,
  grantNewResearcher,
} from "../../research/Researchers";
import { grantResource, Resource } from "../../Resources";
import { ActionBase, DoActionArgs } from "../Action";

class HireResearcher extends ActionBase {
  constructor() {
    super();
  }

  isVisible(state: PlayerContextState): boolean {
    return true;
  }

  getName(): string {
    return "Hire Researcher";
  }

  getArea(): string {
    return "Research";
  }

  getDisplayDescription(): string {
    return "Hire a new researcher to gain magical experience faster.";
  }

  getDisplayEffect(): string {
    return "+1 Researcher";
  }

  canAfford(state: PlayerContextState): boolean {
    return (
      super.canAfford(state) &&
      calculateResearcherCap(state) > state.research.totalResearchers
    );
  }

  getCost(state: PlayerContextState): {
    resources: Partial<Record<Resource, number>>;
    items: Record<string, number>;
  } {
    const fractionAddedPerResearcher = applyTransformations(
      [...this.getTags(), TransformationTags.CostScale],
      state,
      1.0,
    );
    return {
      resources: {
        Coins:
          Math.pow(
            1.0 + fractionAddedPerResearcher,
            state.research.totalResearchers,
          ) * 250,
      },
      items: {},
    };
  }

  doAction(args: DoActionArgs, state: PlayerContextState): PlayerContextState {
    state = grantResource(
      Resource.Coins,
      -(this.getCost(state).resources.Coins ?? 0),
    )(state);
    return grantNewResearcher(1)(state);
  }
}

export default new HireResearcher();
