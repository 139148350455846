import { Dungeon } from "../Dungeon";
import { DungeonFloor } from "../DungeonFloor";
import { PlayerContextState } from "../../../PlayerContext";
import SchoolBasementB1 from "./SchoolBasementB1";
import SchoolBasementB2 from "./SchoolBasementB2";
import SchoolBasementB3 from "./SchoolBasementB3";

class SchoolBasement extends Dungeon {
  getId(): string {
    return "schoolBasement";
  }

  getName(): string {
    return "School Basement";
  }

  getDungeonFloors(): DungeonFloor[] {
    return [SchoolBasementB1, SchoolBasementB2, SchoolBasementB3];
  }

  isUnlocked(state: PlayerContextState): boolean {
    return true;
  }
}

export default new SchoolBasement();
