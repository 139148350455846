import { formatNumber } from "../../../utils/FormattingUtils";
import { ActionEffect } from "../../actions/Action";
import { TransformationTags } from "../../calculation/TransformationTags";
import { PlayerContextState } from "../../PlayerContext";
import { grantTemporaryEffect } from "../../timetick/TemporaryEffects";
import { ItemParams } from "../Item";
import { ConsumableItem } from "./ConsumableItem";

class VitalityPotion extends ConsumableItem {
  getId(): string {
    return "vitalityPotion";
  }

  getPicture() {
    return require("./vitalityPotion.png");
  }

  getBaseName(): string {
    return "Vitality Potion";
  }

  getDescription(): string | undefined {
    return "A potion that fills you with vigor. Temporarily raises Max:hp:.";
  }

  doConsumableItemEffect(
    state: PlayerContextState,
    params: ItemParams,
  ): PlayerContextState {
    const effects = this.getItemEffects(state, params);
    return grantTemporaryEffect(
      "Vitality Potion",
      "Vitality Potion",
      effects.duration,
      {
        params: { commonBuff: { Player: { MaxHP: effects.magnitude } } },
      },
    )(state);
  }

  protected getBaseItemEffects(): Record<string, ActionEffect> {
    return {
      magnitude: {
        value: 100,
        tags: [TransformationTags.TemporaryEffectMagnitude],
      },
      duration: {
        value: 180,
        tags: [TransformationTags.TemporaryEffectDuration],
      },
    };
  }

  getEffect(state: PlayerContextState, params: ItemParams): string | undefined {
    const effects = this.getItemEffects(state, params);

    return `+${formatNumber(effects.magnitude)} Max:hp: for ${formatNumber(
      effects.duration,
    )} sec`;
  }

  getSalePrice(): number {
    return 300;
  }

  getCraftingSchoolLevelRequirements() {
    return { Alchemy: 5 };
  }

  getCraftingMaterialsBase() {
    return {
      resources: {
        Mana: 700,
        Water: 200,
        Monstium: 50,
      },
      items: { medicinalHerb: 1 },
    };
  }
}

export default new VitalityPotion();
