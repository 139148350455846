import { formatNumber } from "../../utils/FormattingUtils";
import { PlayerContextState } from "../PlayerContext";
import { registerEventMessage } from "./Events";
import {
  EventMessage,
  EventMessageOption,
  EventTransform,
  GameEvent,
} from "./GameEvent";
import { createGameEvent } from "./SimpleGameEvent";

/*
Usage:

const staffEventBuilder = buildEvent("RandomSpoutEvents_1", "Found a staff!");
staffEventBuilder.createMessage()
  .setSimpleDescription("A staff was found on the ground.")
  .addOption(
    "Pick up",
    { transform: grantResource(Resource.Mana, -100) },  
    staffEventBuilder.createMessage()
      .setSimpleDescription(
        "You pick up the staff. It's cursed! You feel your mana getting drained.",
      ),
  )
  .addOption(
    "Destroy",
    staffEventBuilder.createMessage()
      .setSimpleDescription(
        "You destroy the staff. Wonder what it could have been...",
      ),
  );

const staffEvent = staffEventBuilder.build();
*/

export const CONFIRM_OPTION = {
  description: "Close",
};

type EventMessageOptionWithVisibility = {
  option: Omit<EventMessageOption, "isEnabled">;
  isVisible?: (state: PlayerContextState, params?: any) => boolean;
  isEnabled?: (state: PlayerContextState, params?: any) => boolean;
};

function replaceWithParams(text: string, params: any) {
  if (!params) {
    return text;
  }
  Object.keys(params)
    .map((key) => [
      "{" + key + "}",
      isNaN(params[key]) || params[key] === ""
        ? params[key]
        : formatNumber(params[key]),
    ])
    .forEach((replacement) => {
      text = text.replace(replacement[0], replacement[1]);
    });
  return text;
}

export class EventMessageImpl implements EventMessage {
  constructor(
    private id: string,
    private title: string,
    private description: (state: PlayerContextState) => string,
    private options: EventMessageOptionWithVisibility[],
  ) {
    registerEventMessage(this);
  }

  getId(): string {
    return this.id;
  }
  getTitle(params: any): string {
    return replaceWithParams(this.title, params);
  }
  getDescription(state: PlayerContextState, params: any): string {
    return replaceWithParams(this.description(state), params);
  }
  getAllowedOptions(
    state: PlayerContextState,
    params: any,
  ): EventMessageOption[] {
    return this.options
      .filter(
        (option) => option.isVisible == null || option.isVisible(state, params),
      )
      .map((option) => ({
        ...option.option,
        description: replaceWithParams(option.option.description, params),
        isEnabled: option.isEnabled == null || option.isEnabled(state, params),
      }));
  }
  isTerminal(): boolean {
    return this.options.every(
      (option) => !option.option.nextMessage && !option.option.transform,
    );
  }
  isActionable(state: PlayerContextState, params: any): boolean {
    return this.getAllowedOptions(state, params).some(
      (option) => (option.nextMessage || option.transform) && option.isEnabled,
    );
  }
}

class EventMessageBuilder {
  constructor(private id: string, private title: string) {}
  description: (state: PlayerContextState) => string = () => "";
  options: EventMessageOptionWithVisibility[] = [];

  setDescription(description: (state: PlayerContextState) => string) {
    this.description = description;
    return this;
  }

  setSimpleDescription(description: string) {
    this.setDescription((state: PlayerContextState) => description);
    return this;
  }

  addOption(
    description: string,
    nextMessage?: EventMessage,
    transform?: (state: PlayerContextState, params: any) => PlayerContextState,
    isVisible?: (state: PlayerContextState, params: any) => boolean,
    isEnabled?: (state: PlayerContextState, params: any) => boolean,
  ) {
    this.options.push({
      option: {
        description,
        transform,
        nextMessage,
      },
      isVisible,
      isEnabled,
    });
    return this;
  }

  build(): EventMessage {
    return new EventMessageImpl(
      this.id,
      this.title,
      this.description,
      this.options.length > 0 ? this.options : [{ option: CONFIRM_OPTION }],
    );
  }
}

type EventMessageOptionStub = {
  description: string;
  nextMessage?: EventMessageBuilderDeluxe;
  transform?: (state: PlayerContextState, params: any) => PlayerContextState;
  isVisible?: (state: PlayerContextState, params: any) => boolean;
  isEnabled?: (state: PlayerContextState, params: any) => boolean;
};

class EventMessageBuilderDeluxe {
  id: string;
  description: (state: PlayerContextState) => string = () => "";
  options: EventMessageOptionStub[] = [];

  constructor(private eventBuilder: EventBuilder) {
    this.id = this.eventBuilder.newMessageId();
  }

  setDescription(description: (state: PlayerContextState) => string) {
    this.description = description;
    return this;
  }

  setSimpleDescription(description: string) {
    this.setDescription((state: PlayerContextState) => description);
    return this;
  }

  addOption(
    description: string,
    paramsOrNextMessage:
      | {
          transform?: (
            state: PlayerContextState,
            params: any,
          ) => PlayerContextState;
          isVisible?: (state: PlayerContextState, params: any) => boolean;
          isEnabled?: (state: PlayerContextState, params: any) => boolean;
        }
      | EventMessageBuilderDeluxe = {},
    nextMessage?: EventMessageBuilderDeluxe,
  ) {
    if (paramsOrNextMessage instanceof EventMessageBuilderDeluxe) {
      nextMessage = paramsOrNextMessage;
      paramsOrNextMessage = {};
    }

    this.options.push({
      description,
      nextMessage,
      transform: paramsOrNextMessage?.transform,
      isVisible: paramsOrNextMessage?.isVisible,
      isEnabled: paramsOrNextMessage?.isEnabled,
    });
    return this;
  }

  build(): GameEvent {
    return this.eventBuilder.build();
  }
}

class EventBuilder {
  constructor(private id: string, private title: string) {}

  messages: { [messageId: string]: EventMessageBuilderDeluxe } = {};

  private lastMessageId: number = 0;
  private onTrigger?: EventTransform;

  createMessage(): EventMessageBuilderDeluxe {
    const builder = new EventMessageBuilderDeluxe(this);
    this.messages[builder.id] = builder;
    return builder;
  }

  newMessageId(): string {
    return this.id + (this.lastMessageId++).toString();
  }

  setOnTrigger(onTrigger: EventTransform) {
    this.onTrigger = onTrigger;
    return this;
  }

  build(): GameEvent {
    // Start from the first message
    const message = this.buildRecursive(this.messages[this.id + "0"]);
    return createGameEvent(this.id, message, this.onTrigger);
  }

  private buildRecursive(
    messageBuilder: EventMessageBuilderDeluxe,
  ): EventMessage {
    const options = messageBuilder.options.map((optionStub) => {
      const nextMessageBuilder = optionStub?.nextMessage;

      let message = undefined;
      if (nextMessageBuilder != null) {
        message = this.buildRecursive(nextMessageBuilder);
      }
      return {
        option: {
          description: optionStub.description,
          transform: optionStub.transform,
          nextMessage: message,
        },
        isVisible: optionStub.isVisible,
        isEnabled: optionStub.isEnabled,
      };
    });
    return new EventMessageImpl(
      messageBuilder.id,
      this.title,
      messageBuilder.description,
      options.length > 0 ? options : [{ option: CONFIRM_OPTION }],
    );
  }
}

export function buildEvent(id: string, title: string) {
  return new EventBuilder(id, title);
}

export function buildEventMessage(id: string, title: string) {
  return new EventMessageBuilder(id, title);
}
