import { ActionEffect } from "../../actions/Action";
import { TransformationTags } from "../../calculation/TransformationTags";
import { PlayerContextState } from "../../PlayerContext";
import { grantResource, Resource } from "../../Resources";
import { SpellElement } from "../ElementsAndSchools";
import { SpellActionBase } from "../Spell";
import { getStandardRangeEffectText } from "../Utils";

class FlameShow extends SpellActionBase {
  constructor() {
    super();
  }

  getSpellName(): string {
    return "Flame Show";
  }

  getElement(): SpellElement | undefined {
    return SpellElement.Fire;
  }

  getArea(): string {
    return "Campus";
  }

  getDisplayDescription(): string {
    return "Create an impressive entertainment show based on fire.";
  }

  protected getBaseActionEffects(): Record<string, ActionEffect> {
    return {
      average: {
        value: 2400,
        tags: [Resource.Coins, TransformationTags.ActionMagnitude],
      },
      variance: {
        value: 400,
        tags: [Resource.Coins, TransformationTags.ActionVariance],
      },
    };
  }

  getDisplayEffect(state: PlayerContextState): string {
    const effects = this.getActionEffects(state);
    return `+${getStandardRangeEffectText(
      effects.average,
      effects.variance,
    )}:coins:`;
  }

  doSpellAction(state: PlayerContextState): PlayerContextState {
    const effects = this.getActionEffects(state);
    return grantResource(
      Resource.Coins,
      effects.average + (Math.random() - 0.5) * effects.variance,
    )(state);
  }

  getManaCost(): number {
    return 580;
  }

  getSchoolLevelRequirements(): Record<string, number> {
    return {
      Illusion: 4,
    };
  }
}

export default new FlameShow();
