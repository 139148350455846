import { ActionEffect } from "../../actions/Action";
import { TransformationTags } from "../../calculation/TransformationTags";
import { PlayerContextState } from "../../PlayerContext";
import { grantResource, Resource } from "../../Resources";
import { SpellActionBase } from "../Spell";
import { getStandardRangeEffectText } from "../Utils";

class RainbowShow extends SpellActionBase {
  constructor() {
    super();
  }

  getSpellName(): string {
    return "Rainbow Show";
  }

  getArea(): string {
    return "Campus";
  }

  getDisplayDescription(): string {
    return "Create an astounding entertainment show with rainbows.";
  }

  protected getBaseActionEffects(): Record<string, ActionEffect> {
    return {
      average: {
        value: 16000,
        tags: [Resource.Coins, TransformationTags.ActionMagnitude],
      },
      variance: {
        value: 2000,
        tags: [Resource.Coins, TransformationTags.ActionVariance],
      },
    };
  }

  getDisplayEffect(state: PlayerContextState): string {
    const effects = this.getActionEffects(state);
    return `+${getStandardRangeEffectText(
      effects.average,
      effects.variance,
    )}:coins:`;
  }

  doSpellAction(state: PlayerContextState): PlayerContextState {
    const effects = this.getActionEffects(state);
    return grantResource(
      Resource.Coins,
      effects.average + (Math.random() - 0.5) * effects.variance,
    )(state);
  }

  getManaCost(): number {
    return 2450;
  }

  getSchoolLevelRequirements(): Record<string, number> {
    return {
      Illusion: 10,
    };
  }
}

export default new RainbowShow();
