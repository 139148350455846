import { formatNumber } from "../../../utils/FormattingUtils";
import { ActionEffect } from "../../actions/Action";
import { TransformationTags } from "../../calculation/TransformationTags";
import { PlayerContextState } from "../../PlayerContext";
import { grantTemporaryEffect } from "../../timetick/TemporaryEffects";
import { ItemParams } from "../Item";
import { ConsumableItem } from "./ConsumableItem";

class SpeedPotion extends ConsumableItem {
  getId(): string {
    return "speedPotion";
  }

  getPicture() {
    return require("./speedPotion.png");
  }

  getBaseName(): string {
    return "Speed Potion";
  }

  getDescription(): string | undefined {
    return "A potion that makes you attack quicker. Reduces :attackDelay: temporarily.";
  }

  doConsumableItemEffect(
    state: PlayerContextState,
    params: ItemParams,
  ): PlayerContextState {
    const effects = this.getItemEffects(state, params);
    return grantTemporaryEffect(
      "Speed Potion",
      "Speed Potion",
      effects.duration,
      {
        params: {
          commonBuff: {
            Player: { AttackDelayMultiplier: 1 / (1 + effects.magnitude) },
          },
        },
      },
    )(state);
  }

  protected getBaseItemEffects(): Record<string, ActionEffect> {
    return {
      magnitude: {
        value: 0.1764,
        tags: [TransformationTags.TemporaryEffectMagnitude],
      },
      duration: {
        value: 180,
        tags: [TransformationTags.TemporaryEffectDuration],
      },
    };
  }

  getEffect(state: PlayerContextState, params: ItemParams): string | undefined {
    const effects = this.getItemEffects(state, params);

    return `-${formatNumber(
      Math.abs(1 - 1 / (effects.magnitude + 1)) * 100,
    )}%:attackDelay: for ${formatNumber(effects.duration)} sec`;
  }

  getSalePrice(): number {
    return 300;
  }

  getCraftingSchoolLevelRequirements() {
    return { Alchemy: 7 };
  }

  getCraftingMaterialsBase() {
    return {
      resources: {
        Mana: 700,
        Water: 150,
      },
      items: { batWing: 3 },
    };
  }
}

export default new SpeedPotion();
