import { PlayerContextState } from "../../../PlayerContext";
import woolFarmRemake from "../../../upgrades/other/WoolFarmRemake";
import BlackSheep from "../../enemies/wizardsarena/BlackSheep";
import { DungeonFloor, encounterEnemy } from "../DungeonFloor";

class TheSpecialStockade extends DungeonFloor {
  getId(): string {
    return "theSpecialStockade";
  }

  getFloorName(): string {
    return "The Special Stockade";
  }

  getExplorationRequiredTimeSec(): number {
    return 20.0;
  }

  _getExplorationSuccessesForBoss(): number {
    return 0;
  }

  getBoss() {
    return undefined;
  }

  getBreedingLevel(): number {
    return 49;
  }

  isUnlocked(state: PlayerContextState): boolean {
    return woolFarmRemake.isPurchased(state);
  }

  getExplorationPossibilities(state: PlayerContextState) {
    return [
      {
        weight: 100,
        transforms: encounterEnemy(BlackSheep),
      },
    ];
  }

  getNextFloor() {
    return undefined;
  }
}

export default new TheSpecialStockade();
