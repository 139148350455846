import { generateSpeckForState } from "../../../items/other/crystals/Crystals";
import { PlayerContextState } from "../../../PlayerContext";
import { standardEnemyAttackEffect, startExploration } from "../../Exploration";
import { BattlerAction, BattlerStats, Enemy } from "../Enemy";

class RedSlime extends Enemy {
  getId(): string {
    return "redSlime";
  }

  getName(): string {
    return "Red Slime";
  }

  getPicture() {
    return require("./redSlime.png");
  }

  getBaseStats(): BattlerStats {
    return {
      maxHP: 250,
      defense: 6,
      attack: 20,
      attackDelay: 2.25,
      dodgeChance: 0,
      critChance: 0,
    };
  }

  getNextAction(state: PlayerContextState): BattlerAction {
    const runAwayChance = 0.05;
    if (Math.random() < runAwayChance) {
      return {
        name: "Flee",
        delay: this.getAttackDelay(state) * 3,
        transform: startExploration,
      };
    }
    const fastAttackChance = 0.35;
    if (Math.random() < fastAttackChance) {
      return {
        name: "Fast Attack",
        delay: this.getAttackDelay(state) * 0.25,
        transform: (state: PlayerContextState) =>
          standardEnemyAttackEffect(state, 1.0, this.getAttack(state)),
      };
    }
    return {
      name: "Attack",
      delay: this.getAttackDelay(state),
      transform: (state: PlayerContextState) =>
        standardEnemyAttackEffect(state, 1.0, this.getAttack(state)),
    };
  }

  getCoinsAwarded(state: PlayerContextState): number {
    return 160;
  }

  getMonstiumAwarded(state: PlayerContextState): number {
    return 18;
  }

  getItemsAwarded(state: PlayerContextState) {
    return [
      { itemId: "medicinalHerb", chance: 0.3, amount: 1 },
      {
        itemId: generateSpeckForState(state).getId(),
        chance: 0.015,
        amount: 1,
      },
      { itemId: "goldNugget", chance: 0.001, amount: 1 },
    ];
  }
}

export default new RedSlime();
