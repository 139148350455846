import { ExplorationStatus } from "../../exploration/ExplorationStatus";
import { hasFlag } from "../../Flags";
import {
  EXPLORATION_INITIAL_STATE,
  PlayerContextState,
} from "../../PlayerContext";
import { getSecondsPlayed } from "../../timetick/TotalTimePlayed";
import { CombatSpellBase } from "../CombatSpellBase";

class Getaway extends CombatSpellBase {
  getSpellName(): string {
    return "Getaway";
  }

  isVisible(state: PlayerContextState): boolean {
    return super.isVisible(state) && hasFlag(state, "exploration_unlocked");
  }

  getDisplayDescription(): string {
    return "Create an illusion of yourself to trick the enemy.";
  }

  getDisplayEffect(): string {
    return "Immediately exit combat and explore again.";
  }

  getManaCost(): number {
    return 320;
  }

  getSchoolLevelRequirements(): Record<string, number> {
    return {
      Illusion: 6,
    };
  }

  doSpellAction(state: PlayerContextState): PlayerContextState {
    if (!state.exploration) {
      state.exploration = EXPLORATION_INITIAL_STATE;
    }
    state.exploration.explorationStatus = ExplorationStatus.Exploring;
    state.exploration.explorationStartTime = getSecondsPlayed(state);
    return state;
  }
}

export default new Getaway();
