import { getBuildingAmount } from "../../buildings/Buildings";
import Furnace from "../../buildings/campus/Furnace";
import { getSuccessfulExplorationsForFloor } from "../../exploration/Exploration";
import { hasFlag, setFlag } from "../../Flags";
import { addContextListener } from "../../PlayerContextListeners";
import { getResourceAmount, grantResource, Resource } from "../../Resources";
import {
  getUnlockedSchools,
  School,
  unlockSchool,
} from "../../spells/ElementsAndSchools";
import { Storyline, Storylines } from "../../storylines/Storylines";
import { registerRandomEventTrigger } from "../autorandom/RandomEventTrigger";
import { buildEventMessage } from "../EventMessageBuilder";
import { triggerEvent } from "../Events";
import { createGameEvent } from "../SimpleGameEvent";

const message = buildEventMessage(
  "alchemy_lab_1",
  "(Storyline) Cauldron of Potion-Making",
)
  .setSimpleDescription(
    `You find yourself near your furnace. You can feel the heat emanating from it. It evokes memories of a distant past... of when you were learning about magic. You recall a scene where you gathered around a big cauldron...
    
It struck you, then. A *cauldron*! Why didn't you think about this before? You head out and look for your researcher. You find her, and start talking to her about the art of making magical beverages.`,
  )
  .addOption(
    "Continue",
    buildEventMessage("alchemy_lab_2", "(Storyline) Cauldron of Potion-Making")
      .setSimpleDescription(
        `"Magical... beverages? Do you mean potions?", she says. "We definitely can't make those with our crafting station..."
        
She didn't seem to catch on, so you explain that you want to build a cauldron and start investigating the production of powerful potions.

"Oh, of course", she says. She goes back to studying without paying mind to you. It seems like you will need to show her what you mean.`,
      )
      .addOption(
        "Build a cauldron (6500 Stone, 500 Iron, 2000 Temperature)",
        buildEventMessage(
          "alchemy_lab_3",
          "(Storyline) Cauldron of Potion-Making",
        )
          .setSimpleDescription(
            `That was quite some hard work. The cauldron looks sturdy and prepared for some serious brewing. You have a new robust addition to the campus now!
            
Your researcher is still skeptical.

"I can see where this could go", she says, raising her brow. "But there's more we need to do. We need a consistent, strong source of flame and tons of water, to keep this boiling. *Then* we'll be able to try to make potions." She goes back to her research.

You understand this, and you start working towards that goal.`,
          )
          .addOption(
            "Fill the cauldron and start a fire (2000 Water, 6500 Wood)",
            buildEventMessage(
              "alchemy_lab_4",
              "(Storyline) Cauldron of Potion-Making",
            )
              .setSimpleDescription(
                `The sparks of the fire are noisy. You place the cauldron on top of it, and slowly add water in it. It takes a long time, but you eventually finish and wait for the water to boil.
                
The cauldron starts bubbling. Your researcher finally comes and looks at the cauldron. She observes the flame and the steam coming out from the top of the cauldron, then speaks to you.

"This is actually great!", she exclaims. "We are definitely prepared now! We should study the art of making potions. I am sure we could make many useful things!"`,
              )
              .addOption(
                "Continue",
                buildEventMessage(
                  "alchemy_lab_5",
                  "(Storyline) Cauldron of Potion-Making",
                )
                  .setSimpleDescription(
                    `Your researcher seems eager to try it out. You are more reluctant: you remember how difficult it was to figure out the right formula. Still, with enough research, you have high hopes that you will find plenty of uses for it...

Then you remember. Your professor was saying how they had kept trying to turn iron into gold, but despite years of work, they found no success. But maybe you could...?

**You have completed the "Cauldron of Potion-Making" storyline and have permanently unlocked the Alchemy school! You may now do research on Alchemy, which will let you craft ever-more-powerful potions with various effects. This will persist even after restarting the game!**`,
                  )
                  .build(),
              )
              .build(),
            (state) => {
              state = grantResource(Resource.Water, -2000)(state);
              state = grantResource(Resource.Wood, -6500)(state);
              state = unlockSchool(School.Alchemy)(state);
              return state;
            },
            undefined,
            (state) =>
              getResourceAmount(state, Resource.Water) >= 2000 &&
              getResourceAmount(state, Resource.Wood) >= 6500,
          )
          .addOption("Maybe later")
          .build(),
        (state) => {
          state = grantResource(Resource.Stone, -6500)(state);
          state = grantResource(Resource.Iron, -500)(state);
          state = grantResource(Resource.Temperature, -1000)(state);
          return state;
        },
        undefined,
        (state) =>
          getResourceAmount(state, Resource.Stone) >= 6500 &&
          getResourceAmount(state, Resource.Iron) >= 500 &&
          getResourceAmount(state, Resource.Temperature) >= 1000,
      )
      .addOption("Maybe later")
      .build(),
  )
  .build();

const alchemyLabEvent = createGameEvent(
  "alchemy_lab",
  message,
  setFlag("alchemy_lab_triggered"),
);

addContextListener("safeguard_alchemy_lab_trigger", (oldState, state) => {
  if (
    !hasFlag(state, "alchemy_lab_triggered") &&
    getBuildingAmount(state, Furnace) > 0 &&
    !getUnlockedSchools(state).includes(School.Alchemy) &&
    getSuccessfulExplorationsForFloor(state, "schoolBasementB3") > 25
  ) {
    // Safeguard unlock the alchemy lab.
    return triggerEvent(alchemyLabEvent, {});
  }
});

registerRandomEventTrigger(
  alchemyLabEvent,
  60,
  (state) =>
    !hasFlag(state, "alchemy_lab_triggered") &&
    hasFlag(state, "inventory_unlocked") &&
    getBuildingAmount(state, Furnace) > 0 &&
    !getUnlockedSchools(state).includes(School.Alchemy),
);

Storylines.register(
  new Storyline(
    "The Alchemy Lab",
    (state) => getUnlockedSchools(state).includes(School.Alchemy),
    (state) => getUnlockedSchools(state).includes(School.Alchemy),
    "There's a chance of this happening randomly if you have unlocked your inventory and you have at least a Furnace...",
    "Alchemy school unlocked",
    (state) => true,
  ),
);

export default alchemyLabEvent;
