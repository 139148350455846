export function formatNumber(
  num: number,
  options: { showDecimals: boolean } = { showDecimals: false },
): string {
  num = Number(num);
  if (num == Infinity) {
    return "∞";
  }
  if (num >= 1000000000) {
    return formatNumber(num / 1000000000, { showDecimals: true }) + "B";
  }
  if (num >= 1000000) {
    return formatNumber(num / 1000000, { showDecimals: true }) + "M";
  }
  if (num >= 10000) {
    return formatNumber(num / 1000, { showDecimals: true }) + "K";
  }

  if (options.showDecimals && Math.abs(num) < 10 && !Number.isInteger(num)) {
    return num.toFixed(2);
  } else if (
    options.showDecimals &&
    Math.abs(num) < 100 &&
    !Number.isInteger(num)
  ) {
    return num.toFixed(1);
  } else {
    return num.toFixed();
  }
}

export function formatTime(
  secs: number,
  options: { shortForm: boolean; showDecimals: boolean } = {
    shortForm: false,
    showDecimals: false,
  },
): string {
  const { shortForm, showDecimals } = options;
  let unit = shortForm ? "sec" : "second(s)";
  let time = formatNumber(secs, { showDecimals: showDecimals });
  if (secs > 60) {
    unit = shortForm ? "min" : "minute(s)";
    time = formatNumber(secs / 60, { showDecimals: showDecimals });
  }
  if (secs > 60 * 60) {
    unit = shortForm ? "hr" : "hour(s)";
    time = formatNumber(secs / (60 * 60), { showDecimals: showDecimals });
  }
  return `${time} ${unit}`;
}

export function formatTimeLong(duration: moment.Duration) {
  return `${Math.floor(duration.asDays()).toFixed()} days, ${duration
    .hours()
    .toFixed()
    .padStart(2, "0")}:${duration
    .minutes()
    .toFixed()
    .padStart(2, "0")}:${duration.seconds().toFixed().padStart(2, "0")}`;
}
