import React, { useContext, useMemo, useRef } from "react";
import { StyleSheet, View } from "react-native";
import { Button } from "react-native-elements";
import { ItemAction, ItemStack } from "../../backend/items/Item";
import { getItemById } from "../../backend/items/Items";
import { PlayerContext } from "../../backend/PlayerContext";
import { ItemInfo } from "./ItemInfo";

export interface ItemDetailsProps {
  itemStack: ItemStack;
  disableSelling?: boolean;
  showQuickbar?: boolean;
}

function ItemDetailsAction(props: { action: ItemAction }) {
  const action = props.action;
  const playerContext = useContext(PlayerContext);
  const playerContextRef = useRef(playerContext);
  playerContextRef.current = playerContext;
  return useMemo(
    () => (
      <Button
        key={action.description}
        title={action.description}
        disabled={!action.isEnabled}
        containerStyle={styles.actionButtonContainer}
        onPress={() => {
          action.transform && playerContextRef.current.apply(action.transform);
        }}
      />
    ),
    [action.description, action.isEnabled, action.transform, playerContextRef],
  );
}

export function ItemDetails(props: ItemDetailsProps) {
  const playerContext = useContext(PlayerContext);
  const playerContextRef = useRef(playerContext);
  playerContextRef.current = playerContext;

  const { itemId, params } = props.itemStack.itemOccurrence;
  const item = getItemById(itemId);
  const name = item.getName(params);
  const description = item.getDescription(playerContext, params);
  const effect = item.getEffect(playerContext, params);
  const primaryAction = item.getPrimaryAction(playerContext, params);
  const saleAction = props.disableSelling
    ? undefined
    : item.getSaleAction(playerContext, params);
  const quickbarAction = props.showQuickbar
    ? item.getQuickbarAction(playerContext, params)
    : undefined;

  const actions: ItemAction[] = [
    primaryAction,
    saleAction,
    quickbarAction,
  ].filter((action) => action != undefined) as ItemAction[];

  const itemInfo = useMemo(() => {
    return <ItemInfo itemStack={props.itemStack} />;
  }, [JSON.stringify(props.itemStack)]);

  return (
    <View style={styles.itemDetailsContainer}>
      {itemInfo}
      <View style={styles.actionRow}>
        {actions.map((action) => (
          <ItemDetailsAction key={action.description} action={action} />
        ))}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  itemDetailsContainer: {
    marginBottom: 16,
  },
  tagRow: {
    marginVertical: 4,
  },
  actionRow: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 8,
  },
  actionButtonContainer: {
    marginRight: 8,
  },
});
