import { applyTransformations } from "./calculation/Calculation";
import { TransformationTags } from "./calculation/TransformationTags";
import { PlayerContextState, PlayerContextTransform } from "./PlayerContext";

export type ResourceData = {
  current: number;
  cap: number;
};

type ResourceInfo = {
  baseCap: number;
  icon: string;
};

export enum Resource {
  Mana = "Mana",
  Coins = "Coins",
  Stone = "Stone",
  Wood = "Wood",
  Water = "Water",
  Iron = "Iron",
  Temperature = "Temperature",
  Monstium = "Monstium",
  Electricity = "Electricity",
  TimePieces = "Time Pieces",
}

const RESOURCES: Record<Resource, ResourceInfo> = {
  Mana: {
    baseCap: 100,
    icon: "mana",
  },
  Coins: {
    baseCap: 1000,
    icon: "coins",
  },
  Stone: {
    baseCap: 50,
    icon: "stone",
  },
  Wood: {
    baseCap: 50,
    icon: "wood",
  },
  Water: {
    baseCap: 10,
    icon: "water",
  },
  Iron: {
    baseCap: 20,
    icon: "iron",
  },
  Temperature: {
    baseCap: 500,
    icon: "temperature",
  },
  Monstium: {
    baseCap: 200,
    icon: "monstium",
  },
  Electricity: {
    baseCap: 1000,
    icon: "electricity",
  },
  "Time Pieces": {
    baseCap: 30000,
    icon: "timepiece",
  },
};

export function getResourceInfo(resource: Resource): ResourceInfo {
  return RESOURCES[resource];
}

function calculateResourceCap(
  state: PlayerContextState,
  resourceName: Resource,
): number {
  return applyTransformations(
    [resourceName, TransformationTags.Cap],
    state,
    RESOURCES[resourceName].baseCap,
  );
}

export function getResourceCap(
  state: PlayerContextState,
  resourceName: Resource,
): number {
  const resourceData = state.resources[resourceName] as ResourceData;
  return resourceData?.cap || RESOURCES[resourceName].baseCap;
}

export function recalculateCaps(state: PlayerContextState): PlayerContextState {
  const resources = Object.keys(state.resources) as Array<Resource>;
  resources.forEach((resource) => {
    const newCap = calculateResourceCap(state, resource);
    const resourceData = state.resources[resource] as ResourceData;
    resourceData.cap = newCap;
    if (resourceData.current > resourceData.cap) {
      resourceData.current = resourceData.cap;
    }
  });
  return state;
}

export function getResourceAmount(
  state: PlayerContextState,
  resource: Resource,
) {
  return state.resources?.[resource]?.current || 0;
}

function grantResourceImpl(
  name: Resource,
  amount: number,
  state: PlayerContextState,
) {
  if (state.resources?.[name] == null) {
    state.resources[name] = {
      current: 0,
      cap: calculateResourceCap(state, name),
    };
  }
  const resource = state.resources[name] as ResourceData;
  resource.current += amount;
  resource.current = Math.min(resource.current, resource.cap);
  resource.current = Math.max(resource.current, 0);
  return state;
}

export function grantResource(
  this: any,
  name: Resource,
  amount: number,
): PlayerContextTransform {
  // Do it this way to avoid creating extra functions and avoid rerenders
  return grantResourceImpl.bind(this, name, amount);
}
