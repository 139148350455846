import { formatNumber } from "../../../utils/FormattingUtils";
import { ActionEffect } from "../../actions/Action";
import {
  registerTransformation,
  TransformationType,
} from "../../calculation/Calculation";
import { TransformationTags } from "../../calculation/TransformationTags";
import { buildEventMessage } from "../../events/EventMessageBuilder";
import { triggerEvent } from "../../events/Events";
import { createGameEvent } from "../../events/SimpleGameEvent";
import { hasFlag, setFlag } from "../../Flags";
import { PlayerContextState } from "../../PlayerContext";
import { addContextListener } from "../../PlayerContextListeners";
import { getResourceAmount, recalculateCaps, Resource } from "../../Resources";
import { BuildingActionBase } from "../Building";
import { buildingAmountsChanged, getBuildingAmount } from "../Buildings";

class ManaShard extends BuildingActionBase {
  constructor() {
    super();
  }

  isVisible(state: PlayerContextState): boolean {
    return hasFlag(state, "has_gathered_at_least_50_mana");
  }

  getBuildingName(): string {
    return "Mana Shard";
  }

  getArea(): string {
    return "Campus";
  }

  getDisplayDescription(): string {
    return "Increase the amount of mana you can store.";
  }

  protected getBaseActionEffects(): Record<string, ActionEffect> {
    return {
      mana: {
        value: 100,
        tags: [Resource.Mana, TransformationTags.PerBuildingCap],
      },
    };
  }

  getDisplayEffect(state: PlayerContextState): string {
    const effects = this.getActionEffects(state);
    return `+${formatNumber(effects.mana)} Max:mana:`;
  }

  getBaseResourceCost(): Record<string, number> {
    return {
      Coins: 100,
    };
  }

  getBaseResourceScale(): Record<string, number> {
    return {
      Coins: 1.3,
    };
  }
}

const manaShard = new ManaShard();

registerTransformation(
  [[Resource.Mana, TransformationTags.Cap]],
  "ManaShardCap",
  TransformationType.Addition,
  (state) =>
    getBuildingAmount(state, manaShard) *
    manaShard.getActionEffects(state).mana,
);

addContextListener("mana_shard_cap_listener", (oldState, newState) => {
  if (buildingAmountsChanged(oldState, newState, manaShard)) {
    return (state) => {
      return recalculateCaps(state);
    };
  }
});

const message = buildEventMessage(
  "has_gathered_at_least_50_mana",
  "The Mana Shard",
)
  .setSimpleDescription(
    `The mana reserves of the school are building up. Looking at them, you realize they may soon hit their maximum.
    
If only you had a way to store more mana... Then you remember what you had learned as an apprentice, that Mana Shards can increase capacity.
    
We could buy some if we had enough coins... maybe with some Illusion spell?

**You can now build Mana Shards!**`,
  )
  .build();

const event = createGameEvent(
  "has_gathered_at_least_50_mana",
  message,
  setFlag("has_gathered_at_least_50_mana"),
);

addContextListener("mana_shard_unlocked", (oldState, newState) => {
  if (
    !hasFlag(newState, "has_gathered_at_least_50_mana") &&
    getResourceAmount(newState, Resource.Mana) >= 50
  ) {
    return triggerEvent(event);
  }
});

export default manaShard as ManaShard;
