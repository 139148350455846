import { applyTransformations } from "../../../calculation/Calculation";
import { TransformationTags } from "../../../calculation/TransformationTags";
import { PlayerContextState } from "../../../PlayerContext";
import { Resource } from "../../../Resources";
import { EquipmentSlot } from "../../Equipment";
import { ItemParams } from "../../Item";
import { EquippableItem } from "../EquippableItem";

class DarknessWand extends EquippableItem {
  getId(): string {
    return "darknessWand";
  }

  getPicture() {
    return require("./darknessWand.png");
  }

  getBaseName(): string {
    return "Darkness Wand";
  }

  getDescription(): string | undefined {
    return "A wizard's magical wand with the power of Darkness. Very powerful.";
  }

  getAttackBonus(state: PlayerContextState, params: ItemParams): number {
    return 45;
  }

  getManaRegenBonus(state: PlayerContextState, params: ItemParams): number {
    return applyTransformations(
      [this.getId(), Resource.Mana, TransformationTags.Production],
      state,
      32.0,
    );
  }

  getMaxManaBonus(state: PlayerContextState, params: ItemParams): number {
    return 800;
  }

  getSlot(): EquipmentSlot {
    return EquipmentSlot.Hand;
  }

  isCraftable(state: PlayerContextState): boolean {
    return super.isCraftable(state) && state.primaryElement == "Darkness";
  }

  getSalePrice(): number {
    return 1800;
  }

  getCraftingSchoolLevelRequirements() {
    return { Conjuration: 8, Enchantment: 8 };
  }

  getCraftingMaterialsBase() {
    return {
      resources: {
        Wood: 3000,
        Mana: 2500,
      },
      items: {
        darknessSpeck: 1,
      },
    };
  }
}

const darknessWand = new DarknessWand();

export default darknessWand;
