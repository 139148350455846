import { PlayerContextState } from "../../../PlayerContext";
import { BattlerStats, Enemy } from "../Enemy";

class Mouse extends Enemy {
  getId(): string {
    return "mouse";
  }

  getName(): string {
    return "Mouse";
  }

  getPicture() {
    return require("./mouse.png");
  }

  getBaseStats(): BattlerStats {
    return {
      maxHP: 40,
      defense: 2,
      attack: 9,
      attackDelay: 2.0,
      dodgeChance: 0,
      critChance: 0,
    };
  }

  getCoinsAwarded(state: PlayerContextState): number {
    return 45;
  }

  getMonstiumAwarded(state: PlayerContextState): number {
    return 4;
  }

  getItemsAwarded() {
    return [
      { itemId: "medicinalHerb", chance: 0.1, amount: 1 },
      { itemId: "ratTail", chance: 0.25, amount: 2 },
    ];
  }
}

export default new Mouse();
