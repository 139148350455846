import React, { useContext, useState } from "react";
import { StyleSheet, View } from "react-native";
import { Button } from "react-native-elements";
import { PlayerContext } from "../backend/PlayerContext";
import { retire } from "../backend/Retire";
import {
  maximumSchoolLevelProductionMultiplierDx,
  maximumSchoolLevelResearchMultiplier,
} from "../backend/spells/ElementsAndSchools";
import { clone } from "../utils/CoreUtils";
import { formatNumber } from "../utils/FormattingUtils";
import { Markdown } from "./utility/Markdown";
import { Text } from "./utility/Text";

export default function Retirement() {
  const playerContext = useContext(PlayerContext);

  const modifiedContext = retire(clone(playerContext), true);
  const newResearchMultiplier =
    maximumSchoolLevelResearchMultiplier(modifiedContext);
  const newProductionMultiplier =
    maximumSchoolLevelProductionMultiplierDx(modifiedContext);
  const oldResearchMultiplier =
    maximumSchoolLevelResearchMultiplier(playerContext);
  const oldProductionMultiplier =
    maximumSchoolLevelProductionMultiplierDx(playerContext);
  const researchDeltaPct =
    ((newResearchMultiplier - oldResearchMultiplier) * 100.0) /
    oldResearchMultiplier;
  const productionDeltaPct =
    ((newProductionMultiplier - oldProductionMultiplier) * 100.0) /
    oldProductionMultiplier;

  const text = `You have built up enough knowledge that you feel it might be enough. You consider retirement.

If you retire, **you will reset the game.** Everything will revert back to its initial state, except:
* Any unlocked schools or elements will stay unlocked;
* Any bonuses you obtained from "(Storyline)" events will remain;
* You will gain a boost to research, building production and loot based on the maximum level you have ever attained on your primary school (MPL), as well as on the sum of the MPLs you have attained on each school you have unlocked.

When you retire, you will be able to choose a new primary school and element.

**If you retire now, your research multiplier will become ${formatNumber(
    newResearchMultiplier,
    { showDecimals: true },
  )}x (+${formatNumber(researchDeltaPct, {
    showDecimals: true,
  })}%) and your production and loot multiplier will become ${formatNumber(
    newProductionMultiplier,
    { showDecimals: true },
  )}x (+${formatNumber(productionDeltaPct, { showDecimals: true })}%).**

*Do you want to retire?*`;

  const [hasClicked, setHasClicked] = useState(false);

  return (
    <View style={styles.container}>
      <Text h1>Retirement</Text>
      <View style={styles.explanation}>
        <Markdown>{text}</Markdown>
      </View>
      <View style={styles.buttonContainer}>
        {!hasClicked && (
          <Button title="Retire" onPress={() => setHasClicked(true)} />
        )}
        {hasClicked && (
          <Button
            title="Are you sure? Press again to confirm"
            onPress={() => playerContext.apply((state) => retire(state, false))}
          />
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "stretch",
    justifyContent: "flex-start",
    width: "100%",
  },
  explanation: {
    marginTop: 16,
  },
  buttonContainer: {
    marginTop: 16,
  },
});
