import { DungeonFloors, Dungeons } from "./Dungeons";
import TheCorral from "./farming/TheCorral";
import TheMagicalPen from "./farming/TheMagicalPen";
import TheSpecialStockade from "./farming/TheSpecialStockade";
import WoolFarm from "./farming/WoolFarm";
import SchoolBasement from "./schoolbasement/SchoolBasement";
import SchoolBasementB1 from "./schoolbasement/SchoolBasementB1";
import SchoolBasementB2 from "./schoolbasement/SchoolBasementB2";
import SchoolBasementB3 from "./schoolbasement/SchoolBasementB3";

export function loadDungeonsDemo() {
  Dungeons.register(SchoolBasement);
  DungeonFloors.register(SchoolBasementB1);
  DungeonFloors.register(SchoolBasementB2);
  DungeonFloors.register(SchoolBasementB3);
  Dungeons.register(WoolFarm);
  DungeonFloors.register(TheCorral);
  DungeonFloors.register(TheMagicalPen);
  DungeonFloors.register(TheSpecialStockade);
}
