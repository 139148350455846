import moment from "moment";
import React, { useContext, useMemo, useState } from "react";
import { StyleSheet, View } from "react-native";
import { Card, Divider, ListItem } from "react-native-elements";
import { getMarkdownTextForSpell } from "../backend/events/campus/LevelUp";
import { PlayerContext } from "../backend/PlayerContext";
import { SecretsByType, SecretType } from "../backend/secrets/Secrets";
import {
  getMaxPrimarySchoolLevel,
  getSchoolExp,
  getSchoolLevel,
  getTotalExpRequiredForLevel,
  getUnlockedSchools,
  maximumSchoolLevelProductionMultiplierDx,
  maximumSchoolLevelResearchMultiplier,
  School,
} from "../backend/spells/ElementsAndSchools";
import { getVisibleSpellsForSchool } from "../backend/spells/Spells";
import { Storylines } from "../backend/storylines/Storylines";
import { formatNumber, formatTimeLong } from "../utils/FormattingUtils";
import { Markdown } from "./utility/Markdown";
import SecretsContainer from "./utility/SecretsContainer";
import StorylinesContainer from "./utility/StorylinesContainer";
import { Text } from "./utility/Text";

function StatsSingleSchool(props: { school: School }) {
  const school = props.school;
  const playerContext = useContext(PlayerContext);
  const thisLevelSpells = getVisibleSpellsForSchool(playerContext, school);
  const nextLevelSpells = getVisibleSpellsForSchool(playerContext, school, 1);
  const newSpells = nextLevelSpells
    .filter((spell) => !thisLevelSpells.includes(spell))
    .map(getMarkdownTextForSpell);

  const newSpellsText = `Next level, you will learn:
  
  ${newSpells.length > 0 ? newSpells.join("\n") : "*No new spells*"}`;

  return (
    <View>
      <View style={styles.row}>
        <Text>Level:</Text>
        <Text style={styles.valueText}>
          {getSchoolLevel(playerContext, school)}
        </Text>
      </View>
      <View style={styles.row}>
        <Text>To Next Level:</Text>
        <Text style={styles.valueText}>
          {formatNumber(
            getTotalExpRequiredForLevel(
              playerContext,
              getSchoolLevel(playerContext, school) + 1,
              school,
            ) - getSchoolExp(playerContext, school),
          )}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={{ flex: 1 }}>
          Maximum Primary Level in previous runs (MPL):
        </Text>
        <Text style={styles.valueText}>
          {formatNumber(getMaxPrimarySchoolLevel(playerContext, school))}
        </Text>
      </View>
      <View style={styles.insideBlock}>
        <Markdown>{newSpellsText}</Markdown>
      </View>
    </View>
  );
}

function StatsSchoolViewer() {
  const [activeSection, setActiveSection] = useState("");
  const playerContext = useContext(PlayerContext);

  const schools = getUnlockedSchools(playerContext);

  const perSchool = schools.map((school, index) => {
    const title =
      `${school} Lv${getSchoolLevel(playerContext, school)}` +
      (school == playerContext.primarySchool ? " (Primary)" : "");
    return (
      <View key={school}>
        <ListItem
          topDivider
          onPress={() => {
            if (activeSection != school) {
              setActiveSection(school);
            } else {
              setActiveSection("");
            }
          }}
        >
          <ListItem.Content>
            <ListItem.Title>{title}</ListItem.Title>
          </ListItem.Content>
          <ListItem.Chevron
            name={activeSection == school ? "close" : "chevron-right"}
          />
        </ListItem>
        {activeSection == school && (
          <View style={styles.schoolElementContainer}>
            <StatsSingleSchool school={school} />
          </View>
        )}
      </View>
    );
  });

  return (
    <View>
      {perSchool}
      <Divider />
    </View>
  );
}

function StorylineViewer() {
  const playerContext = useContext(PlayerContext);
  const allStorylines = Storylines.getAll();
  const completedStorylines = allStorylines.filter((storyline) =>
    storyline.isCompleted(playerContext),
  );

  return (
    <View>
      <View style={styles.row}>
        <Text>Total Completed Storylines:</Text>
        <Text style={styles.valueText}>
          {completedStorylines.length} / {allStorylines.length}
        </Text>
      </View>
      <View style={styles.row}>
        <Text>Completion Percentage:</Text>
        <Text style={styles.valueText}>
          {formatNumber(
            (100 * completedStorylines.length) / allStorylines.length,
          ) + "%"}
        </Text>
      </View>
      <View style={styles.storylineContainer}>
        <StorylinesContainer />
      </View>
    </View>
  );
}

function StatsOtherViewer() {
  const playerContext = useContext(PlayerContext);
  const totalDuration = moment.duration(
    playerContext.global?.secondsPlayed,
    "seconds",
  );
  const thisRunDuration = moment.duration(
    playerContext.thisRun?.secondsPlayed,
    "seconds",
  );
  const totalRealWorldDuration = moment.duration(
    Date.now() - playerContext.global?.startTimestamp ?? 0,
  );
  const thisRunRealWorldDuration = moment.duration(
    Date.now() - playerContext.thisRun?.startTimestamp ?? 0,
  );

  const researchMultiplier =
    maximumSchoolLevelResearchMultiplier(playerContext);
  const productionMultiplier =
    maximumSchoolLevelProductionMultiplierDx(playerContext);

  return (
    <View>
      <View style={styles.row}>
        <Text style={styles.captionText} numberOfLines={2}>
          In-game time played (total):
        </Text>
        <Text style={[styles.valueText, styles.valueTextLong]}>
          {formatTimeLong(totalDuration)}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.captionText} numberOfLines={2}>
          In-game time played (this run):
        </Text>
        <Text style={[styles.valueText, styles.valueTextLong]}>
          {formatTimeLong(thisRunDuration)}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.captionText} numberOfLines={2}>
          Real world time played (total):
        </Text>
        <Text style={[styles.valueText, styles.valueTextLong]}>
          {formatTimeLong(totalRealWorldDuration)}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.captionText} numberOfLines={2}>
          Real world time played (this run):
        </Text>
        <Text style={[styles.valueText, styles.valueTextLong]}>
          {formatTimeLong(thisRunRealWorldDuration)}
        </Text>
      </View>
      <View style={styles.row}>
        <Text>Total times retired:</Text>
        <Text style={styles.valueText}>
          {playerContext.global.totalTimesResetted}
        </Text>
      </View>
      <View style={styles.row}>
        <Text>Global research multiplier:</Text>
        <Text style={styles.valueText}>
          {formatNumber(researchMultiplier, { showDecimals: true })}x
        </Text>
      </View>
      <View style={styles.row}>
        <Text>Global production multiplier:</Text>
        <Text style={styles.valueText}>
          {formatNumber(productionMultiplier, { showDecimals: true })}x
        </Text>
      </View>
    </View>
  );
}

export default function Stats() {
  const playerContext = useContext(PlayerContext);
  const hasSecrets =
    SecretsByType.Basic.getAll().filter((secret) =>
      secret.isUnlocked(playerContext),
    ).length > 0;

  return useMemo(
    () => (
      <View style={styles.container}>
        <Card>
          <Card.Title>Stats</Card.Title>
          <StatsOtherViewer />
        </Card>
        <Card>
          <Card.Title>Schools</Card.Title>
          <StatsSchoolViewer />
        </Card>
        <Card>
          <Card.Title>Storylines</Card.Title>
          <StorylineViewer />
        </Card>
        {hasSecrets && (
          <Card>
            <Card.Title>Secrets</Card.Title>
            <SecretsContainer secretType={SecretType.Basic} />
          </Card>
        )}
      </View>
    ),
    [hasSecrets],
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "stretch",
    justifyContent: "flex-start",
    width: "100%",
  },
  schoolElementContainer: {
    margin: 8,
    marginBottom: 24,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 4,
  },
  insideBlock: {
    marginTop: 16,
  },
  valueText: {
    minWidth: 60,
    textAlign: "right",
    marginLeft: 8,
  },
  valueTextLong: {
    minWidth: 112,
  },
  storylineContainer: {
    marginTop: 8,
  },
  captionText: {
    flex: 1,
  },
});
