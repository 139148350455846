import React, { useContext, useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { Icon } from "react-native-elements";
import { PlayerContext } from "../../backend/PlayerContext";
import { SecretsByType } from "../../backend/secrets/Secrets";
import { Storyline } from "../../backend/storylines/Storylines";
import { DescriptionViewer, EffectViewer } from "./Miscellaneous";
import { Text } from "./Text";

export function StorylineContainer(props: {
  storyline: Storyline;
  collapsed?: boolean;
  onPressExpand?: () => void;
}) {
  const playerContext = useContext(PlayerContext);
  const storyline = props.storyline;
  const secret = SecretsByType.Storyline.getById(storyline.name);

  const isSecretUnlocked = secret.isUnlocked(playerContext);
  const isCompleted = storyline.isCompleted(playerContext);
  const isBonusActive = storyline.isBonusActive(playerContext);

  const isEnabled = isSecretUnlocked || isCompleted || isBonusActive;

  const descriptionContainer = useMemo(() => {
    let descriptionContents = "???";
    if (isSecretUnlocked) {
      descriptionContents = secret.getDescription(playerContext);
    }
    if (isCompleted) {
      descriptionContents =
        "You have completed this Storyline, but not all of its bonuses are active. Retire to obtain the rest!";
    }
    if (isBonusActive) {
      descriptionContents = storyline.getBonusDescription(playerContext);
    }
    return (
      <View style={styles.descriptionTextContainer}>
        {isBonusActive ? (
          <View style={styles.effectContainer}>
            <Text>Storyline completed!</Text>
            <View style={styles.effectSubcontainer}>
              <Text>Bonus: </Text>
              <EffectViewer style={styles.descriptionViewer}>
                {descriptionContents}
              </EffectViewer>
            </View>
          </View>
        ) : (
          <DescriptionViewer style={styles.descriptionViewer}>
            {descriptionContents}
          </DescriptionViewer>
        )}
      </View>
    );
  }, [
    secret.getDescription(playerContext),
    storyline.getBonusDescription(playerContext),
    isSecretUnlocked,
    isCompleted,
    isBonusActive,
  ]);

  const icon = useMemo(() => {
    let icon = <Icon name="lock" size={20} color="#666" />;
    if (isSecretUnlocked) {
      icon = <Icon name="more-horiz" size={20} color="#666" />;
    }
    if (isCompleted) {
      icon = <Icon name="error-outline" size={20} color="#666" />;
    }
    if (isBonusActive) {
      icon = <Icon name="check" size={20} />;
    }
    return icon;
  }, [isSecretUnlocked, isCompleted, isBonusActive]);

  return useMemo(() => {
    return (
      <View style={styles.storylineContainer} key={storyline.name}>
        <View style={styles.topRowContainer}>
          {icon}
          <Text
            h3
            key={storyline.name}
            onPress={props.onPressExpand}
            style={[
              styles.topContainer,
              !isEnabled && styles.disabledStorylineText,
            ]}
          >
            {isEnabled ? storyline.name : "???"}
          </Text>
          <Icon
            name={props.collapsed ? "chevron-right" : "chevron-down"}
            type="entypo"
            containerStyle={styles.expandContainer}
            onPress={props.onPressExpand}
          />
        </View>
        {!props.collapsed && <View>{descriptionContainer}</View>}
      </View>
    );
  }, [
    storyline.name,
    isEnabled,
    icon,
    !props.collapsed && descriptionContainer,
    props.collapsed,
    props.onPressExpand,
  ]);
}

const styles = StyleSheet.create({
  storylineContainer: { marginVertical: 8 },
  topRowContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  expandContainer: {
    width: 40,
  },
  descriptionTextContainer: {
    marginBottom: 8,
    marginTop: 8,
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
  },
  descriptionViewer: { flex: 1, justifyContent: "center" },
  effectContainer: {
    flex: 1,
  },
  effectSubcontainer: {
    flexDirection: "row",
    alignItems: "flex-start",
    flexWrap: "wrap",
  },
  smallButtonTitle: {
    fontSize: 12,
  },
  topContainer: {
    flex: 1,
    marginLeft: 8,
  },
  disabledStorylineText: {
    color: "#aaa",
  },
});
