// A secret is a piece of information that can be unlocked through some means.

import { hashCode } from "../../utils/CoreUtils";
import { hasGlobalFlag, setGlobalFlag } from "../Flags";
import { DataStore } from "../generic/DataStore";
import { Identifiable } from "../generic/Identifiable";
import { PlayerContextState } from "../PlayerContext";

export enum SecretType {
  Basic = "Basic",
  Storyline = "Storyline",
}

export class Secret implements Identifiable {
  id: string;
  secretType: SecretType;
  getDescription: (state: PlayerContextState) => string;
  isUnlockable: (state: PlayerContextState) => boolean;
  _isCompleted?: (state: PlayerContextState) => boolean;

  constructor(
    id: string,
    secretType: SecretType,
    getDescription: (state: PlayerContextState) => string,
    isUnlockable: (state: PlayerContextState) => boolean,
    isCompleted?: (state: PlayerContextState) => boolean,
  ) {
    (this.id = id), (this.secretType = secretType);
    this.getDescription = getDescription;
    this.isUnlockable = isUnlockable;
    this._isCompleted = isCompleted;
  }

  getId(): string {
    return this.id;
  }

  getType(): SecretType {
    return this.secretType;
  }

  unlock(state: PlayerContextState): PlayerContextState {
    return setGlobalFlag(`secret_${this.secretType}_${this.id}`)(state);
  }

  isUnlocked(state: PlayerContextState): boolean {
    return hasGlobalFlag(state, `secret_${this.secretType}_${this.id}`);
  }

  isCompleted(state: PlayerContextState): boolean {
    if (this._isCompleted != null) {
      return this._isCompleted(state);
    }
    return this.isUnlocked(state);
  }
}

const SecretsByType: Record<SecretType, DataStore<Secret>> = {
  Basic: new DataStore<Secret>(),
  Storyline: new DataStore<Secret>(),
};

export function addSecret(secret: Secret): void {
  SecretsByType[secret.getType()].register(secret);
}

export function getUnlockedSecretsByType(
  state: PlayerContextState,
  secretType: SecretType,
): Secret[] {
  return SecretsByType[secretType]
    .getAll()
    .filter((secret) => secret.isUnlocked(state) || secret.isCompleted(state));
}

export function getUnlockableLockedSecretsByType(
  state: PlayerContextState,
  secretType: SecretType,
): Secret[] {
  return SecretsByType[secretType]
    .getAll()
    .filter(
      (secret) =>
        secret.isUnlockable(state) &&
        !secret.isUnlocked(state) &&
        !secret.isCompleted(state),
    );
}

export function getRandomUnlockableSecret(
  state: PlayerContextState,
  secretType: SecretType,
): Secret | undefined {
  const unlockableSecrets = getUnlockableLockedSecretsByType(state, secretType);
  if (unlockableSecrets.length === 0) {
    return undefined;
  }
  const randomIdx = Math.floor(Math.random() * unlockableSecrets.length);
  const secretToUnlock = unlockableSecrets[randomIdx];
  return secretToUnlock;
}

export function getUnlockableSecretsByType(
  state: PlayerContextState,
  secretType: SecretType,
): Secret[] {
  return SecretsByType[secretType]
    .getAll()
    .filter(
      (secret) =>
        secret.isUnlockable(state) ||
        secret.isUnlocked(state) ||
        secret.isCompleted(state),
    );
}

export function addBasicSecret(
  description: string,
  isUnlockable?: (state: PlayerContextState) => boolean,
) {
  addSecret(
    new Secret(
      hashCode(description).toString(),
      SecretType.Basic,
      (state) => description,
      isUnlockable ?? ((state) => true),
    ),
  );
}

export { SecretsByType };
