import Plugin from "markdown-it-regexp";

const plugin = Plugin(
  // regexp to match
  /\:([a-zA-Z_-]+)\:/,

  // this function will be called when something matches
  (match: any[], utils: any) => {
    const icon = match[1];

    return icon;
  },
);

export default plugin;
