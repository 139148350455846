import memoize from "fast-memoize";
import { AttackTarget } from "../exploration/AttackTarget";
import { CombatStat } from "../exploration/CombatStats";
import { PlayerContextState } from "../PlayerContext";
import { Resource } from "../Resources";
import { School, SpellElement } from "../spells/ElementsAndSchools";
import { TransformationTags } from "./TransformationTags";

export enum TransformationType {
  Addition = "Addition",
  Multiplier = "Multiplier",
  Power = "Power",
  Override = "Override",
}

export type TransformationTag =
  | TransformationTags
  | Resource
  | School
  | SpellElement
  | CombatStat
  | AttackTarget
  | string;

export type Transformation = (
  state: PlayerContextState,
  params: Record<string, any>,
) => number | undefined;

export type TransformationData = {
  id: string;
  transformation: Transformation;
  type: TransformationType;
  tags: TransformationTag[][];
};

const transformationDatabase: Partial<
  Record<TransformationTag, TransformationData[]>
> = {};

export function registerTransformation(
  tags: TransformationTag[][],
  transformationId: string,
  transformationType: TransformationType,
  transformation: Transformation,
) {
  const data = {
    id: transformationId,
    transformation,
    type: transformationType,
    tags,
  };

  const allTags = new Set(tags.flat(2));
  allTags.forEach((tag) => {
    const forTag = transformationDatabase?.[tag] ?? [];
    if (!forTag.some((preExisting) => preExisting.id === transformationId)) {
      forTag.push(data);
      transformationDatabase[tag] = forTag;
    }
  });
}

const getTransformationsThatApply = memoize(
  (tags: TransformationTag[], type: TransformationType) => {
    const allTransformations = tags
      .map((tag) => transformationDatabase?.[tag] ?? [])
      .flat();
    const allTransformationsThatApply = allTransformations.filter((data) =>
      data.tags.some((targetTags) =>
        targetTags.every((targetTag) => tags.includes(targetTag)),
      ),
    );
    const uniqueTransformations = [...new Set(allTransformationsThatApply)];
    const transformationsByType = uniqueTransformations.filter(
      (data) => data.type === type,
    );
    return transformationsByType;
  },
);

export function applyTransformations(
  tags: TransformationTag[],
  state: PlayerContextState,
  startingValue: number,
  params?: Record<string, any>,
) {
  const transformParams = params ?? {};

  // Step 1: apply power transforms (rare)
  const powerTransformations = getTransformationsThatApply(
    tags,
    TransformationType.Power,
  );
  const afterPower = powerTransformations
    .map((data) => data.transformation(state, transformParams))
    .reduce(
      (prev, current) => Math.pow(prev ?? 1.0, current ?? 1.0),
      startingValue,
    );

  // Step 2: apply addition transforms
  const additionTransformations = getTransformationsThatApply(
    tags,
    TransformationType.Addition,
  );
  const afterAddition = additionTransformations
    .map((data) => data.transformation(state, transformParams))
    .reduce((prev, current) => (prev ?? 0) + (current ?? 0), afterPower);

  // Step 3: apply multiplication transforms
  const multiplicationTransformations = getTransformationsThatApply(
    tags,
    TransformationType.Multiplier,
  );
  const afterMultiplication = multiplicationTransformations
    .map((data) => data.transformation(state, transformParams))
    .reduce((prev, current) => (prev ?? 1.0) * (current ?? 1.0), afterAddition);

  // Step 4: finally, apply override transforms
  const overrideTransformations = getTransformationsThatApply(
    tags,
    TransformationType.Override,
  );
  const afterOverride = overrideTransformations
    .map((data) => data.transformation(state, transformParams))
    .reduce((prev, current) => current ?? prev, afterMultiplication);

  return afterOverride as number;
}

export function explainTransformations(
  tags: TransformationTag[],
  state: PlayerContextState,
  startingValue: number,
  params?: Record<string, any>,
): Record<
  TransformationType,
  Array<{ id: string; value: number | undefined }>
> {
  const transformParams = params ?? {};
  const output: Record<
    TransformationType,
    Array<{ id: string; value: number | undefined }>
  > = {
    Power: [],
    Addition: [],
    Multiplier: [],
    Override: [],
  };

  // Step 1: get the transformations that apply
  const allTransformations = tags
    .map((tag) => transformationDatabase?.[tag] ?? [])
    .flat();
  const allTransformationsThatApply = allTransformations.filter((data) =>
    data.tags.some((targetTags) =>
      targetTags.every((targetTag) => tags.includes(targetTag)),
    ),
  );
  const uniqueTransformations = [...new Set(allTransformationsThatApply)];

  // Step 2: apply power transforms (rare)
  const powerTransformations = uniqueTransformations.filter(
    (data) => data.type === TransformationType.Power,
  );
  const afterPower = powerTransformations
    .map((data) => {
      const value = data.transformation(state, transformParams);
      output.Power.push({ id: data.id, value });
      return value;
    })
    .reduce(
      (prev, current) => Math.pow(prev ?? 1.0, current ?? 1.0),
      startingValue,
    );

  // Step 3: apply addition transforms
  const additionTransformations = uniqueTransformations.filter(
    (data) => data.type === TransformationType.Addition,
  );
  const afterAddition = additionTransformations
    .map((data) => {
      const value = data.transformation(state, transformParams);
      output.Addition.push({ id: data.id, value });
      return value;
    })
    .reduce((prev, current) => (prev ?? 0) + (current ?? 0), afterPower);

  // Step 4: apply multiplication transforms
  const multiplicationTransformations = uniqueTransformations.filter(
    (data) => data.type === TransformationType.Multiplier,
  );
  const afterMultiplication = multiplicationTransformations
    .map((data) => {
      const value = data.transformation(state, transformParams);
      output.Multiplier.push({ id: data.id, value });
      return value;
    })
    .reduce((prev, current) => (prev ?? 1.0) * (current ?? 1.0), afterAddition);

  // Step 5: finally, apply override transforms
  const overrideTransformations = uniqueTransformations.filter(
    (data) => data.type === TransformationType.Override,
  );
  const _afterOverride = overrideTransformations
    .map((data) => {
      const value = data.transformation(state, transformParams);
      output.Override.push({ id: data.id, value });
      return value;
    })
    .reduce((prev, current) => current ?? prev, afterMultiplication);

  return output;
}
