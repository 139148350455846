import { formatNumber } from "../../../utils/FormattingUtils";
import { ActionEffect } from "../../actions/Action";
import { hasFlag } from "../../Flags";
import { PlayerContextState } from "../../PlayerContext";
import { grantResource, Resource } from "../../Resources";
import { SpellElement } from "../ElementsAndSchools";
import { SpellActionBase } from "../Spell";

class CreateIronBar extends SpellActionBase {
  constructor() {
    super();
  }

  isVisible(state: PlayerContextState): boolean {
    return super.isVisible(state) && hasFlag(state, "gather_iron_unlocked");
  }

  getSpellName(): string {
    return "Create Iron Bar";
  }

  getElement(): SpellElement | undefined {
    return SpellElement.Earth;
  }

  getArea(): string {
    return "Campus";
  }

  getDisplayDescription(): string {
    return "Materialize an iron bar.";
  }

  protected getBaseActionEffects(): Record<string, ActionEffect> {
    return {
      iron: {
        value: 80,
        tags: [Resource.Iron],
      },
    };
  }

  getDisplayEffect(state: PlayerContextState): string {
    const effects = this.getActionEffects(state);
    return `+${formatNumber(effects.iron)}:iron:`;
  }

  doSpellAction(state: PlayerContextState): PlayerContextState {
    return grantResource(
      Resource.Iron,
      this.getActionEffects(state).iron,
    )(state);
  }

  getManaCost(): number {
    return 320;
  }

  getSchoolLevelRequirements(): Record<string, number> {
    return {
      Conjuration: 7,
    };
  }
}

export default new CreateIronBar();
