import { ActionBase, DoActionArgs } from "../actions/Action";
import { applyTransformations } from "../calculation/Calculation";
import { TransformationTags } from "../calculation/TransformationTags";
import { PlayerContextState } from "../PlayerContext";
import { grantResource, Resource } from "../Resources";
import {
  BuildingData,
  getBuildingAmount,
  getBuildingAmountTurnedOn,
} from "./Buildings";

export interface Building {
  getBuildingName(): string;
  canAfford(state: PlayerContextState): boolean;
  canTurnOff(): boolean;
}

export abstract class BuildingActionBase
  extends ActionBase
  implements Building
{
  abstract getBaseResourceCost(): Record<string, number>;
  abstract getBaseResourceScale(): Record<string, number>;
  abstract getBuildingName(): string;

  constructor() {
    super();
  }

  getName(): string {
    return this.getBuildingName();
  }

  getDisplayName(state: PlayerContextState): string {
    const amount = getBuildingAmount(state, this);
    if (amount > 0) {
      return this.getName() + " (" + getBuildingAmount(state, this) + ")";
    }
    return this.getName();
  }

  getCategory(): string {
    return "Buildings";
  }

  getCost(state: PlayerContextState): {
    resources: Partial<Record<Resource, number>>;
    items: Record<string, number>;
  } {
    return {
      resources: this.getResourceCost(state),
      items: {},
    };
  }

  getResourceCost(state: PlayerContextState): Record<string, number> {
    const currentAmount = getBuildingAmount(state, this);
    const baseCost = this.getBaseResourceCost();
    const scale = this.getBaseResourceScale();
    const finalCost: Record<string, number> = {};
    Object.keys(baseCost).forEach((key) => {
      const baseCostForKey = applyTransformations(
        [...this.getTags(), TransformationTags.Cost, key],
        state,
        baseCost[key],
      );
      const costScaleForKey = applyTransformations(
        [...this.getTags(), TransformationTags.CostScale, key],
        state,
        scale[key] - 1,
      );

      finalCost[key] = Math.ceil(
        baseCostForKey * Math.pow(1 + costScaleForKey, currentAmount),
      );
    });
    return finalCost;
  }

  canTurnOff(): boolean {
    return false;
  }

  doAction(args: DoActionArgs, state: PlayerContextState): PlayerContextState {
    if (!this.canAfford(state)) {
      return state;
    }

    const name = this.getBuildingName();
    const cost = this.getResourceCost(state);
    if (state.buildings?.[name] == null) {
      state.buildings[name] = {
        current: 0,
        turnedOn: 0,
      };
    }
    const buildingData = state.buildings[name] as BuildingData;
    buildingData.current += 1;
    buildingData.turnedOn = getBuildingAmountTurnedOn(state, this) + 1;
    (Object.keys(cost) as Array<Resource>).forEach((resourceName) => {
      state = grantResource(resourceName, -cost[resourceName])(state);
    });
    return state;
  }
}
