import { DungeonFloor } from "./DungeonFloor";
import { PlayerContextState } from "../../PlayerContext";
import { Identifiable } from "../../generic/Identifiable";

export abstract class Dungeon implements Identifiable {
  abstract getId(): string;
  abstract getName(): string;
  abstract getDungeonFloors(): DungeonFloor[];
  abstract isUnlocked(state: PlayerContextState): boolean;
}
