import { Audio } from "expo-av";

const soundMap: Record<string, any> = {
  menuClick: require("./menu/click.wav"),
};

class AudioManager {
  sampleMap: Record<string, Audio.Sound> = {};

  async load(): Promise<void> {
    await Audio.setIsEnabledAsync(true);
    await Promise.all(
      Object.keys(soundMap).map(async (audioId) => {
        const audioSample = soundMap[audioId];
        const audioSound = new Audio.Sound();
        await audioSound.loadAsync(audioSample);
        this.sampleMap[audioId] = audioSound;
      }),
    );
  }

  play(audioId: string) {
    this.sampleMap[audioId].replayAsync();
    this.sampleMap[audioId].replayAsync();
  }
}

export default new AudioManager();
