import { applyTransformations } from "../calculation/Calculation";
import { TransformationTags } from "../calculation/TransformationTags";
import { PlayerContextState, PlayerContextTransform } from "../PlayerContext";
import { OfflineTickListeners } from "./OfflineTickListeners";

type TimeTickListener = (
  state: PlayerContextState,
  timeElapsedSec: number,
) => PlayerContextState;

const listeners: Record<string, TimeTickListener> = {};

export function registerTimeTickListener(
  id: string,
  listener: TimeTickListener,
) {
  listeners[id] = listener;
}

export function processTimeTick(
  this: any,
  timeElapsedSec: number,
): PlayerContextTransform {
  return (state) => {
    const speedMultiplier = applyTransformations(
      [TransformationTags.GameSpeed],
      state,
      1.0,
    );
    const timeElapsedSecAfterTransform = timeElapsedSec * speedMultiplier;
    Object.values(listeners).forEach((listener) => {
      state = listener(state, timeElapsedSecAfterTransform);
    });
    return state;
  };
}

const OFFLINE_TICK_PROCESSING_CONFIG_DEFAULT = {
  secsPerTick: 1.0,
};

let offlineTickProcessingConfig = OFFLINE_TICK_PROCESSING_CONFIG_DEFAULT;

export function setOfflineTickSecsPerTick(secsPerTick: number): void {
  offlineTickProcessingConfig = { secsPerTick: secsPerTick };
}

export async function processOfflineTicks(
  initialState: PlayerContextState,
  secsTotal: number,
  lastProcessedTime: number,
  setPlayerState: (state: PlayerContextState) => void,
): Promise<void> {
  let currentState = initialState;
  currentState.lastProcessedTime = lastProcessedTime;

  OfflineTickListeners.getAll().forEach((listener) => {
    const transform = listener.getListener()(secsTotal);
    if (transform) {
      currentState = transform(currentState);
    }
  });

  setPlayerState(currentState);
}
