import { DataStore } from "../generic/DataStore";
import { PlayerContextState } from "../PlayerContext";

export type Help = {
  title: string;
  description: string;
  descriptionWeb?: string;
  order: number;
  isVisible: (state: PlayerContextState) => boolean;
};

let helpsDatabase: Help[] = [];

const Helps = new DataStore<Help>();

export { Helps };

export function getVisibleHelps(state: PlayerContextState): Help[] {
  return Helps.getAll()
    .filter((help) => help.isVisible(state))
    .sort((a, b) => a.order - b.order);
}
