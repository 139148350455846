import { PlayerContextState } from "../../../PlayerContext";
import { EquipmentSlot } from "../../Equipment";
import { ItemParams } from "../../Item";
import { EquippableItem } from "../EquippableItem";

class Cloak extends EquippableItem {
  getId(): string {
    return "cloak";
  }

  getPicture() {
    return require("./cloak.png");
  }

  getBaseName(): string {
    return "Cloak";
  }

  getDescription(): string | undefined {
    return "A basic cloak made out of cloth.";
  }

  getDefenseBonus(state: PlayerContextState, params: ItemParams): number {
    return 4;
  }

  getSlot(): EquipmentSlot {
    return EquipmentSlot.Accessory;
  }

  getSalePrice(): number {
    return 1200;
  }

  getCraftingSchoolLevelRequirements() {
    return {
      Conjuration: 7,
    };
  }

  getCraftingMaterialsBase() {
    return {
      resources: {},
      items: { cloth: 4 },
    };
  }
}

export default new Cloak();
