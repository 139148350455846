import HireResearcher from "../../actions/campus/HireResearcher";
import {
  registerTransformation,
  TransformationType,
} from "../../calculation/Calculation";
import { TransformationTags } from "../../calculation/TransformationTags";
import { PlayerContextState } from "../../PlayerContext";
import { Upgrade } from "../Upgrade";

class ResearchFair extends Upgrade {
  getName(): string {
    return "Research Fair";
  }

  getMonstiumCost(): number {
    return 600;
  }

  getDisplayDescription(state: PlayerContextState): string {
    return "Host a research fair in campus, to advertise the magical research the school is doing.";
  }

  getDisplayEffect(state: PlayerContextState): string {
    return "Reduces the cost to hire researchers.";
  }
}

const researchFair = new ResearchFair();

registerTransformation(
  [[HireResearcher.getName(), TransformationTags.CostScale]],
  "ResearchFairCostScale",
  TransformationType.Multiplier,
  (state) => (researchFair.isPurchased(state) ? 2.0 / 3.0 : 1.0),
);

export default researchFair;
