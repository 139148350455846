import {
  registerTransformation,
  TransformationType,
} from "../../calculation/Calculation";
import { TransformationTags } from "../../calculation/TransformationTags";
import {
  hasFlag,
  hasGlobalFlag,
  hasGlobalFlagIncludingNextRun,
  setFlag,
  setGlobalFlagForNextRun,
} from "../../Flags";
import { getResourceAmount, grantResource, Resource } from "../../Resources";
import { getSchoolLevel, School } from "../../spells/ElementsAndSchools";
import { Storyline, Storylines } from "../../storylines/Storylines";
import { registerRandomEventTrigger } from "../autorandom/RandomEventTrigger";
import { buildEventMessage } from "../EventMessageBuilder";
import { createGameEvent } from "../SimpleGameEvent";

const message = buildEventMessage(
  "headmasters_staff_1",
  "(Storyline) The Headmaster's Staff",
)
  .setSimpleDescription(
    `The researcher and the apprentice approach you. "We've been thinking", starts saying the researcher. "You are the renowned headmaster of this institution, but you certainly don't look the part. You are missing a staff!", she exclaims. The apprentice nods in agreement.
    
"We can craft now. We should try our best to build an emblematic item for you. You deserve this!", she continues.

"...Actually," interrupts the apprentice, "we cannot craft this on our own. It seems ironic, but you should definitely craft yourself a staff. I-it is painful for me to say, but a headmaster without a staff... is... somewhat uninspiring."`,
  )
  .addOption(
    "Craft a Headmaster's Staff (1500 Mana, 3500 Wood, Requires Conjuration Lv8)",
    buildEventMessage(
      "headmasters_staff_2",
      "(Storyline) The Headmaster's Staff",
    )
      .setSimpleDescription(
        `You put together the staff and infuse it with a lot of mana. The apprentice and researcher watch you in awe. You produce a Headmaster's Staff!

...Or is it? It still looks just like a normal magic staff.

Your researcher cheers you on. "You should enchant it!", she says. "I'm sure it will be great once you do!"`,
      )
      .addOption(
        "Enchant the Headmaster's Staff (1700 Mana, Requires Enchantment Lv9)",
        buildEventMessage(
          "headmasters_staff_3",
          "(Storyline) The Headmaster's Staff",
        )
          .setSimpleDescription(
            `You pool together even more mana. The staff begins to shine! You feel the magical energies starting to roar. The apprentice jumps back as you cast the spell.
            
The researcher keeps cheering. "This is working!", she screams with excitement. "Just a little more!"`,
          )
          .addOption(
            "Enchant the Headmaster's Staff (1900 Mana, Requires Enchantment Lv10)",
            buildEventMessage(
              "headmasters_staff_4",
              "(Storyline) The Headmaster's Staff",
            )
              .setSimpleDescription(
                `This time, you focus deeply. You pool a ton of magical energy, and with one swift movement, you enchant the staff.

A flash of light emanates from the staff for a split second and brightens the room! The staff glows brightly, starts rattling, then it gently begins floating towards you! The researcher and apprentice both look at it, with their mouths wide open.

You grip the staff... and somehow the power vanishes, without a trace?`,
              )
              .addOption(
                "Continue",
                buildEventMessage(
                  "headmasters_staff_5",
                  "(Storyline) The Headmaster's Staff",
                )
                  .setSimpleDescription(
                    `You are confused. You leave the room, without saying anything to the researcher and the apprentice, and go back to your personal chamber. You put away the staff and look at it...
                    
*You are not worthy. You will never be worthy.* The voice resonates through your insides, but you don't know where it's coming from... Somehow, you got the feeling the staff *is* magically enchanted, but why can't you unlock its powers?

You decide to store it. Maybe a successor would be able to use it...

**You have completed "The Headmaster's Staff" storyline! You will permanently be four times more efficient when gathering in future runs (after restarting the game).**`,
                  )
                  .build(),
              )
              .build(),
            (state) => {
              state = grantResource(Resource.Mana, -1900)(state);
              state = setGlobalFlagForNextRun("headmasters_staff_completed")(
                state,
              );
              return state;
            },
            undefined,
            (state) =>
              getResourceAmount(state, Resource.Mana) >= 1900 &&
              getSchoolLevel(state, School.Enchantment) >= 10,
          )
          .addOption("Maybe later")
          .build(),
        (state) => {
          state = grantResource(Resource.Mana, -1700)(state);
          return state;
        },
        undefined,
        (state) =>
          getResourceAmount(state, Resource.Mana) >= 1700 &&
          getSchoolLevel(state, School.Enchantment) >= 9,
      )
      .addOption("Maybe later")
      .build(),
    (state) => {
      state = grantResource(Resource.Mana, -1500)(state);
      state = grantResource(Resource.Wood, -3500)(state);
      return state;
    },
    undefined,
    (state) =>
      getResourceAmount(state, Resource.Mana) >= 1500 &&
      getResourceAmount(state, Resource.Wood) >= 3500 &&
      getSchoolLevel(state, School.Conjuration) >= 8,
  )
  .addOption("Maybe later")
  .build();

const event = createGameEvent(
  "headmasters_staff",
  message,
  setFlag("headmasters_staff_triggered"),
);

registerRandomEventTrigger(
  event,
  20,
  (state) =>
    hasFlag(state, "inventory_unlocked") &&
    !hasFlag(state, "headmasters_staff_triggered") &&
    !hasGlobalFlag(state, "headmasters_staff_completed"),
);

registerTransformation(
  [[TransformationTags.ManualGathering]],
  "HeadmastersStaff",
  TransformationType.Multiplier,
  (state) => (hasGlobalFlag(state, "headmasters_staff_completed") ? 4.0 : 1.0),
);

Storylines.register(
  new Storyline(
    "The Headmaster's Staff",
    (state) =>
      hasGlobalFlagIncludingNextRun(state, "headmasters_staff_completed"),
    (state) => hasGlobalFlag(state, "headmasters_staff_completed"),
    "There's a chance of this happening randomly if you have unlocked your inventory...",
    "Manual gathering 4x more effective",
    (state) => true,
  ),
);

export default event;
