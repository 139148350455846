import { PlayerContextState } from "../../../PlayerContext";
import { EquipmentSlot } from "../../Equipment";
import { ItemParams } from "../../Item";
import { EquippableItem } from "../EquippableItem";

class Glaive extends EquippableItem {
  getId(): string {
    return "glaive";
  }

  getPicture() {
    return require("./glaive.png");
  }

  getBaseName(): string {
    return "Glaive";
  }

  getDescription(): string | undefined {
    return "An effective weapon, similar to a spear, for dealing large amounts of damage.";
  }

  getAttackBonus(state: PlayerContextState, params: ItemParams): number {
    return 50;
  }

  getAttackDelayBonusPct(
    state: PlayerContextState,
    params: ItemParams,
  ): number {
    return -15;
  }

  getSlot(): EquipmentSlot {
    return EquipmentSlot.Hand;
  }

  getSalePrice(): number {
    return 4500;
  }

  getCraftingSchoolLevelRequirements() {
    return { Conjuration: 11 };
  }

  getCraftingMaterialsBase() {
    return {
      resources: {
        Iron: 1500,
        Wood: 4500,
        Temperature: 2400,
      },
      items: {},
    };
  }
}

const glaive = new Glaive();

export default glaive;
