import React, { useCallback, useMemo, useState } from "react";
import { StyleSheet, View } from "react-native";
import { Divider } from "react-native-elements";
import { Storylines } from "../../backend/storylines/Storylines";
import { StorylineContainer } from "./StorylineContainer";

type StorylinesContainerProps = {};

export default function StorylinesContainer(props: StorylinesContainerProps) {
  const [expandedStoryline, setExpandedStoryline] = useState("");
  const setStorylineCallback = useCallback(
    (storylineName: string) => {
      if (expandedStoryline == storylineName) {
        setExpandedStoryline("");
      } else {
        setExpandedStoryline(storylineName);
      }
    },
    [expandedStoryline],
  );

  const storylines = Storylines.getAll();
  const storylineNameString = storylines
    .map((storyline) => storyline.name)
    .join(",");

  const storylineContainers = useMemo(
    () =>
      storylines.map((storyline) => {
        return (
          <StorylineContainer
            key={storyline.name}
            storyline={storyline}
            collapsed={expandedStoryline != storyline.name}
            onPressExpand={setStorylineCallback.bind(null, storyline.name)}
          />
        );
      }),
    [storylineNameString, expandedStoryline, setExpandedStoryline],
  );

  return useMemo(() => {
    if (storylines.length === 0) {
      return null;
    }
    return (
      <View>
        <View key={storylines[0].name}>{storylineContainers[0]}</View>
        {storylineContainers.slice(1).map((storylineContainer, idx) => {
          return (
            <View key={storylines[idx].name}>
              <Divider />
              {storylineContainer}
            </View>
          );
        })}
      </View>
    );
  }, [storylineNameString, storylineContainers]);
}

const styles = StyleSheet.create({});
