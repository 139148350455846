import { getBuildingAmount } from "../../buildings/Buildings";
import ManaSpout from "../../buildings/campus/ManaSpout";
import Vault from "../../buildings/campus/Vault";
import Warehouse from "../../buildings/campus/Warehouse";
import {
  registerTransformation,
  TransformationType,
} from "../../calculation/Calculation";
import { TransformationTags } from "../../calculation/TransformationTags";
import { hasFlag } from "../../Flags";
import { PlayerContextState } from "../../PlayerContext";
import {
  getResourceAmount,
  getResourceCap,
  grantResource,
  Resource,
} from "../../Resources";
import {
  getIncrementalExpRequiredForLevel,
  getSchoolLevel,
  getUnlockedSchools,
  grantSchoolExp,
  School,
} from "../../spells/ElementsAndSchools";
import {
  grantTemporaryEffect,
  hasTemporaryEffect,
} from "../../timetick/TemporaryEffects";
import { registerRandomEventTrigger } from "../autorandom/RandomEventTrigger";
import { buildEvent, buildEventMessage } from "../EventMessageBuilder";
import { createGameEvent } from "../SimpleGameEvent";

export function loadRepeatableCampusEvents() {
  const staffEventBuilder = buildEvent(
    "RandomSpoutEvents_Staff",
    "Found a staff!",
  );
  staffEventBuilder
    .createMessage()
    .setSimpleDescription("A staff was found on the ground.")
    .addOption(
      "Pick up",
      { transform: grantResource(Resource.Mana, -100) },
      staffEventBuilder
        .createMessage()
        .setSimpleDescription(
          "You pick up the staff. It's cursed! You feel your mana getting drained.",
        ),
    )
    .addOption(
      "Destroy",
      staffEventBuilder
        .createMessage()
        .setSimpleDescription(
          "You destroy the staff. Wonder what it could have been...",
        ),
    );

  const staffEvent = staffEventBuilder.build();

  registerRandomEventTrigger(staffEvent, 10);

  // ==============

  const boostedSpoutsEvent = createGameEvent(
    "RandomSpoutEvents_2",
    buildEventMessage("RandomSpoutEvents_2", "The Mana Spouts are Bursting!")
      .setSimpleDescription(
        "It looks like our mana spouts are suddenly bursting with activity and producing Mana twice as fast!",
      )
      .build(),
    grantTemporaryEffect(
      "Mana Spouts bursting with activity!",
      "Mana Spouts bursting with activity!",
      180,
      {},
    ),
  );

  registerRandomEventTrigger(
    boostedSpoutsEvent,
    100,
    (state) => getBuildingAmount(state, ManaSpout) > 0,
  );

  registerTransformation(
    [[ManaSpout.getName(), TransformationTags.Production]],
    "ManaSpoutsBurstingWithActivity",
    TransformationType.Multiplier,
    (state) =>
      hasTemporaryEffect(state, "Mana Spouts bursting with activity!") ? 2 : 1,
  );

  // ==============

  const banditsEvent = createGameEvent(
    "RandomSpoutEvents_3",
    buildEventMessage("RandomSpoutEvents_3", "Bandits!")
      .setSimpleDescription(
        `*Oh no!* It looks like bandits have looted one of your vaults! You notice a significant amount of coins missing. Frustrated, you hope to catch them next time...
        
**You lose {coins}:coins:!**`,
      )
      .build(),
    (state: PlayerContextState, params) => {
      return grantResource(Resource.Coins, -params.coins)(state);
    },
  );

  registerRandomEventTrigger(
    banditsEvent,
    100,
    (state) => getBuildingAmount(state, Vault) > 0,
    (state) => ({
      coins: Math.floor(
        getResourceAmount(state, Resource.Coins) * (0.15 + Math.random() * 0.2),
      ),
    }),
  );

  for (let school in School) {
    const schoolOldBook = createGameEvent(
      "RandomSpoutEvents_8_" + school,
      buildEventMessage(
        "RandomSpoutEvents_8_" + school,
        "Old Book of " + school,
      )
        .setSimpleDescription(
          `One of your researchers seems to have come across a dusty book. The book seems to contain knowledge about ${school}. Thrilled, your researchers get back to work with the new knowledge.
  
  **You gain {exp} ${school} experience!**`,
        )
        .build(),
      (state, params) => grantSchoolExp(school as School, params.exp)(state),
    );

    registerRandomEventTrigger(
      schoolOldBook,
      30,
      (state) =>
        hasFlag(state, "research_unlocked") &&
        getUnlockedSchools(state).includes(school as School),
      (state) => ({
        exp: Math.floor(
          (0.6 + Math.random() * 0.2) *
            getIncrementalExpRequiredForLevel(
              state,
              getSchoolLevel(state, school as School) + 1,
              school as School,
            ) *
            Math.pow(0.96, getSchoolLevel(state, school as School)),
        ),
      }),
    );
  }

  const randomWoodEvent = createGameEvent(
    "RandomSpoutEvents_9",
    buildEventMessage("RandomSpoutEvents_9", "A Pile of Wood")
      .setSimpleDescription(
        `You stumble upon a pile of wood as you walk through campus. You are not sure why it's there, but it seems misplaced. You decide to take it.
        
**You obtain {amount}:wood:!**`,
      )
      .build(),
    (state, params) => grantResource(Resource.Wood, params.amount)(state),
  );

  registerRandomEventTrigger(
    randomWoodEvent,
    100,
    (state) =>
      hasFlag(state, "gather_wood_unlocked") &&
      getBuildingAmount(state, Warehouse) > 0,
    (state) => ({
      amount: Math.floor(
        350 + (getResourceCap(state, Resource.Wood) - 350) * Math.random(),
      ),
    }),
  );

  const randomStoneEvent = createGameEvent(
    "RandomSpoutEvents_10",
    buildEventMessage("RandomSpoutEvents_10", "A Pile of Stone")
      .setSimpleDescription(
        `You find a pile of stone as you walk through campus. You are not sure why it's there, but it seems misplaced. You decide to take it.
        
**You obtain {amount}:stone:!**`,
      )
      .build(),
    (state, params) => grantResource(Resource.Stone, params.amount)(state),
  );

  registerRandomEventTrigger(
    randomStoneEvent,
    100,
    (state) => getBuildingAmount(state, Warehouse) > 0,
    (state) => ({
      amount: Math.floor(
        350 + (getResourceCap(state, Resource.Stone) - 350) * Math.random(),
      ),
    }),
  );

  const travelingMerchantBuysStoneMessage = buildEventMessage(
    "RandomSpoutEvents_11",
    "A traveling merchant appeared",
  )
    .setSimpleDescription(
      `A traveling merchant crosses the campus doors and waves at you.

"Hello!", he exclaims. "I'm interested in buying {stoneCost} stones. I'm willing to pay {coinCost} coins for them. Would you like to sell me some?"`,
    )
    .addOption(
      "Sell an illusion of {stoneCost} stones (150 Mana)",
      buildEventMessage(
        "RandomSpoutEvents_11_1",
        "A traveling merchant appeared",
      )
        .setSimpleDescription(
          `You use your Illusion knowledge to trick the merchant and give him {stoneCost} fake stones. The merchant falls for it!
          
"Here are your coins", he says. "Nice doing business with you!". He walks away afterwards.

He will be in for a surprise later, but you are happy you got the coins!`,
        )
        .build(),
      (state, params) => {
        state = grantResource(Resource.Mana, -150)(state);
        state = grantResource(Resource.Coins, params.coinCost)(state);
        return state;
      },
      (state) => getSchoolLevel(state, School.Illusion) >= 3,
      (state) => getResourceAmount(state, Resource.Mana) >= 150,
    )
    .addOption(
      "Sell {stoneCost} stones",
      buildEventMessage(
        "RandomSpoutEvents_11_2",
        "A traveling merchant appeared",
      )
        .setSimpleDescription(
          `You give the merchant the {stoneCost} stones. He happily takes them.
          
"Here are your coins", he says. "Nice doing business with you!". He walks away afterwards.`,
        )
        .build(),
      (state, params) => {
        state = grantResource(Resource.Stone, -params.stoneCost)(state);
        state = grantResource(Resource.Coins, params.coinCost)(state);
        return state;
      },
      undefined,
      (state, params) =>
        getResourceAmount(state, Resource.Stone) >= params.stoneCost,
    )
    .addOption(
      "Decline",
      buildEventMessage(
        "RandomSpoutEvents_11_3",
        "A traveling merchant appeared",
      )
        .setSimpleDescription(
          "You decline the transaction. These stones seem more valuable to you. The traveling merchant acknowledges you, and decides to leave.",
        )
        .build(),
    )
    .build();

  const travelingMerchantBuysStoneEvent = createGameEvent(
    "RandomSpoutEvents_11",
    travelingMerchantBuysStoneMessage,
  );

  registerRandomEventTrigger(
    travelingMerchantBuysStoneEvent,
    50,
    (state) => getResourceCap(state, Resource.Stone) >= 600,
    (state) => {
      const stoneCost =
        300 +
        Math.floor(
          (getResourceCap(state, Resource.Stone) - 300) * Math.random(),
        );
      const coinCost = Math.floor((stoneCost * 1250) / 300);
      return { stoneCost, coinCost };
    },
  );

  const boostedResearchEvent = createGameEvent(
    "RandomSpoutEvents_12",
    buildEventMessage("RandomSpoutEvents_12", "Your Researchers are Inspired!")
      .setSimpleDescription(
        "Your researchers seem to have a burst of inspiration! They will be studying twice as hard for a short time.",
      )
      .build(),
    grantTemporaryEffect(
      "Your researchers are inspired!",
      "Your researchers are inspired!",
      300,
      {},
    ),
  );

  registerRandomEventTrigger(boostedResearchEvent, 100, (state) =>
    hasFlag(state, "research_unlocked"),
  );

  registerTransformation(
    [[TransformationTags.Research]],
    "ResearcherInspiration",
    TransformationType.Multiplier,
    (state) =>
      hasTemporaryEffect(state, "Your researchers are inspired!") ? 2.0 : 1.0,
  );
}
