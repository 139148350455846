import React, { useContext, useMemo } from "react";
import { PlayerContext } from "../../backend/PlayerContext";
import { View, StyleSheet } from "react-native";
import { Text } from "./Text";
import { formatNumber } from "../../utils/FormattingUtils";
import {
  Resource,
  ResourceData,
  getResourceInfo,
} from "../../backend/Resources";
import { GameIcon } from "./icons/GameIcon";

function ResourceRow(props: {
  resource: Resource;
  current: number;
  cap: number;
}) {
  const resourceInfo = getResourceInfo(props.resource);
  return (
    <View style={styles.row}>
      <View style={styles.inlineRow}>
        <GameIcon icon={resourceInfo.icon} style={styles.icon} small />
        <Text>{props.resource}: </Text>
      </View>
      <Text>
        {formatNumber(props.current, { showDecimals: true })}/
        {formatNumber(props.cap)}
      </Text>
    </View>
  );
}

const ResourceRowMemo = React.memo(ResourceRow);

export function ResourceViewer(props: { isVisible?: boolean }) {
  const playerContext = useContext(PlayerContext);
  const resourceKeys = Object.keys(playerContext.resources) as Array<Resource>;
  const rows = useMemo(
    () =>
      resourceKeys.map((resourceName) => {
        const resource = playerContext.resources[resourceName] as ResourceData;
        return (
          <ResourceRowMemo
            resource={resourceName}
            current={resource.current}
            cap={resource.cap}
            key={resourceName}
          />
        );
      }),
    [props.isVisible !== false ? playerContext : false],
  );
  return <View style={styles.container}>{rows}</View>;
}

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "stretch",
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  inlineRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  icon: {
    marginRight: 2,
  },
});
