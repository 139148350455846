import React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { GameIcon } from "../utility/icons/GameIcon";
import { Badge } from "react-native-elements";

export function ItemTile(props: {
  onPress?: () => void;
  icon?: string;
  amount?: number;
}) {
  const inner = (
    <View style={styles.tile}>
      {props.icon && <GameIcon icon={props.icon} />}
      {props.amount != null && props.amount > 1 && (
        <Badge
          status="primary"
          value={props.amount}
          containerStyle={{ position: "absolute", top: -8, right: -8 }}
        />
      )}
    </View>
  );
  if (!props.onPress) {
    return inner;
  }
  return (
    <TouchableOpacity onPress={() => props.onPress && props.onPress()}>
      {inner}
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  tile: {
    borderColor: "#ddd",
    borderWidth: 0.5,
    height: 48,
    width: 48,
    justifyContent: "center",
    alignItems: "center",
    margin: 8,
  },
});
