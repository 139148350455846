import { hasFlag, setFlag } from "../../Flags";
import { addContextListener } from "../../PlayerContextListeners";
import { getTotalElementAndSchoolExp } from "../../spells/ElementsAndSchools";
import { buildEventMessage } from "../EventMessageBuilder";
import { triggerEvent } from "../Events";
import { createGameEvent } from "../SimpleGameEvent";

const message = buildEventMessage(
  "Autocast_Intro",
  "Casting Spells is Repetitive",
)
  .setSimpleDescription(
    `How many times have you manually cast a spell by now? You have lost count. It certainly is not difficult for you. However, there is real repetition to these casts.
    
You wonder if there might be a way to somehow automate this, as it's starting to feel a bit dull. But thinking about it, you can't figure out any solution...`,
  )
  .build();

const event = createGameEvent(
  "Autocast_Intro",
  message,
  setFlag("autocast_intro_triggered"),
);

addContextListener("autocast_intro_triggered", (oldState, newState) => {
  if (
    !hasFlag(newState, "autocast_intro_triggered") &&
    hasFlag(newState, "research_unlocked") &&
    getTotalElementAndSchoolExp(newState) >= 2200
  ) {
    return triggerEvent(event);
  }
});

export default event;
