import { Picker } from "@react-native-picker/picker";
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { StyleSheet, View } from "react-native";
import { Button } from "react-native-elements";
import {
  allocateApprenticesToSpell,
  calculateApprenticeTotal,
  canAutocast,
  getApprenticeAllocation,
  getAutocastSchoolLevelRequirements,
  getFreeApprentices,
  loadLoadoutFromPosition,
  saveLoadoutToPosition,
} from "../backend/autocast/Autocast";
import { applyTransformations } from "../backend/calculation/Calculation";
import { TransformationTags } from "../backend/calculation/TransformationTags";
import { PlayerContext } from "../backend/PlayerContext";
import {
  getSchoolLevel,
  getUnlockedSchools,
  School,
} from "../backend/spells/ElementsAndSchools";
import { getVisibleSpellsBySchool } from "../backend/spells/Spells";
import { formatNumber } from "../utils/FormattingUtils";
import CategoryContainer from "./utility/CategoryContainer";
import { PlusMinusAllocator } from "./utility/PlusMinusAllocator";
import { Text } from "./utility/Text";

export function Autocast() {
  const playerContext = useContext(PlayerContext);
  const playerContextRef = useRef(playerContext);
  playerContextRef.current = playerContext;

  const loadoutAmount = 4;
  const [slotNumber, setSlotNumber] = useState(
    playerContext?.autocast?.lastLoadoutUsed ?? 0,
  );

  const spellsBySchool = getVisibleSpellsBySchool(playerContext);
  const apprenticeAllocation = getApprenticeAllocation(playerContext);
  const freeApprentices = getFreeApprentices(playerContext);
  const hasFreeApprentices = freeApprentices > 0;

  const spellContainers = useMemo(
    () =>
      Object.keys(spellsBySchool).map((schoolString) => {
        const school = schoolString as School;
        return (
          <CategoryContainer key={school} title={school}>
            {spellsBySchool[school].map((spell) => {
              const spellName = spell.getSpellName();
              const current = apprenticeAllocation?.[spellName] || 0;
              const canAllocate = canAutocast(playerContext, spell);
              let allocator = (
                <View style={styles.row}>
                  <Text>{current}</Text>
                  <View style={styles.plusMinusAllocatorContainer}>
                    <PlusMinusAllocator
                      disableMinus={current === 0}
                      disablePlus={!hasFreeApprentices}
                      onPressMinus={() =>
                        playerContextRef.current.apply(
                          allocateApprenticesToSpell(spell, -1),
                        )
                      }
                      onPressPlus={() =>
                        playerContextRef.current.apply(
                          allocateApprenticesToSpell(spell, 1),
                        )
                      }
                    />
                  </View>
                </View>
              );
              if (!canAllocate) {
                const schoolReqs = getAutocastSchoolLevelRequirements(spell);
                let allReqs = [];
                for (let school in schoolReqs) {
                  if (
                    getSchoolLevel(playerContext, school as School) <
                    schoolReqs[school as School]
                  ) {
                    allReqs.push(`${school} Lv${schoolReqs[school as School]}`);
                  }
                }
                allocator = (
                  <Text style={styles.allocatorText}>
                    Requires: {allReqs.join(", ")}
                  </Text>
                );
              }
              return (
                <View
                  key={spellName}
                  style={[styles.row, styles.spellRowContainer]}
                >
                  <Text>{spellName}:</Text>
                  {allocator}
                </View>
              );
            })}
          </CategoryContainer>
        );
      }),
    [
      JSON.stringify(playerContext.autocast.apprenticeAllocation),
      JSON.stringify(Object.keys(spellsBySchool)),
      JSON.stringify(
        getUnlockedSchools(playerContext).map((school) =>
          getSchoolLevel(playerContext, school),
        ),
      ),
    ],
  );

  const secs = applyTransformations(
    [TransformationTags.AutocastDelay],
    playerContext,
    60.0,
  );

  const toggleApprenticesPaused = useCallback(() => {
    playerContextRef.current.apply((state) => {
      state.autocast.apprenticesPaused = !state.autocast.apprenticesPaused;
      return state;
    });
  }, []);

  const loadoutsSlotPicker = useMemo(() => {
    const items = [];
    for (let i = 0; i < loadoutAmount; i++) {
      items.push(<Picker.Item label={"Slot " + (i + 1)} value={i} key={i} />);
    }
    return (
      <Picker onValueChange={setSlotNumber} selectedValue={slotNumber}>
        {items}
      </Picker>
    );
  }, [loadoutAmount, setSlotNumber, slotNumber]);

  return (
    <View>
      {spellContainers}
      <View style={[styles.row, styles.totalApprenticesContainer]}>
        <Text>Apprentices Available: </Text>
        <Text>
          {formatNumber(freeApprentices)}/
          {formatNumber(calculateApprenticeTotal(playerContext))}
        </Text>
      </View>
      <View style={styles.marginBottom}>
        {playerContext.autocast.apprenticesPaused ? (
          <Button
            title="Unpause Apprentices"
            onPress={toggleApprenticesPaused}
          />
        ) : (
          <Button title="Pause Apprentices" onPress={toggleApprenticesPaused} />
        )}
      </View>
      <View style={[styles.loadoutsRow, styles.marginBottom]}>
        <Text>Loadouts:</Text>
        <View style={styles.loadoutRest}>
          <View style={styles.pickerContainer}>{loadoutsSlotPicker}</View>
          <Button
            containerStyle={styles.loadoutButtons}
            title="Save"
            onPress={() => {
              saveLoadoutToPosition(playerContextRef.current, slotNumber);
            }}
          />
          <Button
            containerStyle={styles.loadoutButtons}
            title="Load"
            onPress={() => {
              loadLoadoutFromPosition(playerContextRef.current, slotNumber);
            }}
          />
        </View>
      </View>
      <Text>
        Apprentices will cast the spell they are assigned to every{" "}
        {formatNumber(secs, { showDecimals: false })} sec, if they can. More
        apprentices will cause the spell to be cast more often.
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  marginBottom: {
    marginBottom: 20,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  plusMinusAllocatorContainer: {
    marginLeft: 16,
  },
  totalApprenticesContainer: {
    marginVertical: 24,
    paddingRight: 8,
  },
  spellRowContainer: {
    marginVertical: 4,
    minHeight: 32,
  },
  allocatorText: {
    marginLeft: 32,
    flex: 1,
    textAlign: "right",
  },
  loadoutsRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  loadoutRest: { flexDirection: "row", alignItems: "center" },
  pickerContainer: {
    width: 128,
    marginLeft: 8,
    backgroundColor: "#eee",
    borderRadius: 10,
  },
  loadoutButtons: {
    marginLeft: 8,
  },
});
