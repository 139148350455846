import { PlayerContextState } from "../../../PlayerContext";
import { addContextListener } from "../../../PlayerContextListeners";
import { grantTemporaryEffect } from "../../../timetick/TemporaryEffects";
import {
  getExplorationStatus,
  standardEnemyAttackEffect,
} from "../../Exploration";
import { ExplorationStatus } from "../../ExplorationStatus";
import { getCurrentEnemy } from "../Enemies";
import { BattlerAction, BattlerStats, Enemy } from "../Enemy";

class BlackSheep extends Enemy {
  getId(): string {
    return "blackSheep";
  }

  getName(): string {
    return "Black Sheep";
  }

  getPicture() {
    return require("./blackSheep.png");
  }

  getBaseStats(): BattlerStats {
    return {
      maxHP: 60000,
      defense: 350,
      attack: 320,
      attackDelay: 1.2,
      dodgeChance: 0.1,
      critChance: 0.02,
    };
  }

  getNextAction(state: PlayerContextState): BattlerAction {
    const doNothingChance = 0.01;
    if (Math.random() < doNothingChance) {
      return {
        name: "Hop Around",
        delay: this.getAttackDelay(state),
        transform: (state: PlayerContextState) => {
          return state;
        },
      };
    }
    const chargeChance = 0.3;
    if (Math.random() < chargeChance) {
      return {
        name: "Charge!",
        delay: this.getAttackDelay(state) * 2,
        transform: (state: PlayerContextState) =>
          standardEnemyAttackEffect(
            state,
            1.2,
            this.getAttack(state) * 3,
            this.getCriticalChance(state),
          ),
      };
    }
    return {
      name: "Attack",
      delay: this.getAttackDelay(state),
      transform: (state: PlayerContextState) =>
        standardEnemyAttackEffect(
          state,
          1.2,
          this.getAttack(state),
          this.getCriticalChance(state),
        ),
    };
  }

  getCoinsAwarded(state: PlayerContextState): number {
    return 99999;
  }

  getMonstiumAwarded(state: PlayerContextState): number {
    return 3500;
  }

  getItemsAwarded(state: PlayerContextState) {
    return [
      {
        itemId: "blackWool",
        chance: 1.0,
        amount: 1,
      },
      {
        itemId: "bookOfDivineKnowledge",
        chance: 0.5,
        amount: 1,
      },
    ];
  }
}

addContextListener("blackSheepEncountered", (oldState, newState) => {
  if (
    getExplorationStatus(oldState) != ExplorationStatus.Combat &&
    getExplorationStatus(newState) == ExplorationStatus.Combat &&
    getCurrentEnemy(newState).getId() === "blackSheep"
  ) {
    return grantTemporaryEffect("EnemyStunPrevention", "Stun Protection", 600, {
      combatOnly: true,
      isEnemyTemporaryEffect: true,
    });
  }
});

export default new BlackSheep();
