import { ItemBase } from "../../Item";

class FireSpeck extends ItemBase {
  getId(): string {
    return "fireSpeck";
  }

  getPicture() {
    return require("./fireSpeck.png");
  }

  getBaseName(): string {
    return "Fire Speck";
  }

  getTags() {
    return [{ tag: "Material" }];
  }

  getDescription(): string | undefined {
    return "A small red gem. Full of fire elemental power.";
  }

  getEffect(): string | undefined {
    return undefined;
  }

  getSalePrice(): number {
    return 500;
  }

  getCraftingSchoolLevelRequirements() {
    return {};
  }

  getCraftingMaterialsBase() {
    return {
      resources: {},
      items: {},
    };
  }
}

export default new FireSpeck();
