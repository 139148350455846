import {
  registerTransformation,
  TransformationType,
} from "../../../calculation/Calculation";
import { TransformationTags } from "../../../calculation/TransformationTags";
import Demo from "../../../Demo";
import {
  buildEvent,
  buildEventMessage,
} from "../../../events/EventMessageBuilder";
import { triggerEvent } from "../../../events/Events";
import { createGameEvent } from "../../../events/SimpleGameEvent";
import {
  hasFlag,
  hasGlobalFlag,
  hasGlobalFlagIncludingNextRun,
  setFlag,
  setGlobalFlagForNextRun,
} from "../../../Flags";
import { PlayerContextState } from "../../../PlayerContext";
import { Storyline, Storylines } from "../../../storylines/Storylines";
import {
  grantTemporaryEffect,
  hasTemporaryEffect,
} from "../../../timetick/TemporaryEffects";
import { getSecondsPlayed } from "../../../timetick/TotalTimePlayed";
import { setDungeonFloorCompleted } from "../../dungeons/Dungeons";
import {
  getCurrentEnemyHP,
  standardEnemyAttackEffect,
} from "../../Exploration";
import { BattlerAction, BattlerStats, Enemy } from "../Enemy";

class AncientHeadmaster extends Enemy {
  getId(): string {
    return "ancientHeadmaster";
  }

  getName(): string {
    return "Ancient Headmaster";
  }

  getPicture() {
    return require("./ancientHeadmaster.png");
  }

  getBaseStats(): BattlerStats {
    return {
      maxHP: 2200,
      defense: 8,
      attack: 24,
      attackDelay: 4.0,
      dodgeChance: 0,
      critChance: 0,
    };
  }

  getNextAction(state: PlayerContextState): BattlerAction {
    if (getCurrentEnemyHP(state) < 550) {
      return {
        name: "Rock Tomb",
        delay: 12.0,
        transform: (state: PlayerContextState) =>
          standardEnemyAttackEffect(state, 1.0, 105),
      };
    }
    if (!hasTemporaryEffect(state, "SilencePlayer")) {
      const silenceChance = 0.5;
      if (Math.random() < silenceChance) {
        return {
          name: "Silence!",
          delay: 5.0,
          transform: grantTemporaryEffect("SilencePlayer", "Silence", 20, {
            isBeneficial: false,
            mode: "add",
          }),
        };
      }
    }
    const stalagmiteChance = 0.4;
    if (Math.random() < stalagmiteChance) {
      return {
        name: "Stalagmite",
        delay: 5.0,
        transform: (state: PlayerContextState) =>
          standardEnemyAttackEffect(state, 1.0, 56),
      };
    }
    return {
      name: "Attack",
      delay: this.getAttackDelay(state),
      transform: (state: PlayerContextState) =>
        standardEnemyAttackEffect(state, 1.0, this.getAttack(state)),
    };
  }

  getCoinsAwarded(state: PlayerContextState): number {
    return 1200;
  }

  getMonstiumAwarded(state: PlayerContextState): number {
    return 99;
  }

  getItemsAwarded() {
    return [];
  }

  isBoss(): boolean {
    return true;
  }

  getBackdropColor(): string | undefined {
    return "#400";
  }

  onDeath(
    state: PlayerContextState,
    isFromCreatures: boolean,
  ): PlayerContextState {
    state = super.onDeath(state, isFromCreatures);
    state = setDungeonFloorCompleted(state, "schoolBasementB3");
    if (!hasFlag(state, "headmaster_event_2_triggered")) {
      state = triggerEvent(headmasterDefeatedEvent)(state);
    }
    if (
      !hasGlobalFlagIncludingNextRun(state, "headmaster_under_15_minutes") &&
      getSecondsPlayed(state) < 60 * 15
    ) {
      state = triggerEvent(headmasterDefeatedUnder15MinutesEvent)(state);
    }
    if (Demo.isDemo()) {
      state = triggerEvent(headmasterDefeatedDemoEvent)(state);
    }
    return state;
  }
}

const headmasterDefeatedDemoEventBuilder = buildEvent(
  "demo_finished",
  "Thanks for playing the Demo",
);

const headmasterDefeatedDemoEvent = headmasterDefeatedDemoEventBuilder
  .createMessage()
  .setSimpleDescription(
    `You have beaten the Ancient Headmaster! This is the end of the Demo of Magic Research. **Thank you for playing!**
  
In the full game, you can expect much more content: many new dungeons, new items and features, and countless new secrets... Your progress from the Demo can be easily transferred to the full game.

Are you talented enough to lead your school to win the Tournament of Magic?

**Get the full game now for Android in [the Google Play Store](https://play.google.com/store/apps/details?id=com.mcolotto.magicresearchfull)!**`,
  )
  .build();

const headmasterDefeatedUnder15MinutesEvent = buildEvent(
  "ancient_headmaster_15_minutes_event",
  "(Storyline) Swift Start",
)
  .setOnTrigger(setGlobalFlagForNextRun("headmaster_under_15_minutes"))
  .createMessage()
  .setSimpleDescription(
    `You felled the Ancient Headmaster, but it doesn't feel like the first time. How many times has this happened? It is so familiar, it is like you are able to do it in nearly no time at all - it hasn't even been 15 minutes since you opened your institution!
  
You do notice, however, a page that is shining purple on the book you brought back with you. This is new! You quickly copy it, before it disappears into thin air.

You don't understand much yet, but you can tell it has instructions on how to build more efficiently. Perhaps future generations could benefit from this.

**You have defeated the Ancient Headmaster in under 15 minutes and completed the "Swift Start" Storyline! In future runs, cost for all buildings will scale 20% slower, enabling you to build much more with the same storage!**`,
  )
  .build();

Storylines.register(
  new Storyline(
    "Swift Start",
    (state) =>
      hasGlobalFlagIncludingNextRun(state, "headmaster_under_15_minutes"),
    (state) => hasGlobalFlag(state, "headmaster_under_15_minutes"),
    "Defeat the Ancient Headmaster within 15 minutes of beginning a run...",
    "Cost for buildings scales 20% slower",
    (state) => true,
  ),
);

registerTransformation(
  [[TransformationTags.CostScale, "Category:Buildings"]],
  "swift_start",
  TransformationType.Multiplier,
  (state, params) =>
    hasGlobalFlag(state, "headmaster_under_15_minutes") ? 0.8 : 1.0,
);

const postHeadmasterEventMessage = buildEventMessage(
  "ancient_headmaster_event_2",
  "History of a Magical School",
)
  .setSimpleDescription(
    `As you strike the final blow, the old body of the Ancient Headmaster shakes violently, falls to its knees, and finally disintegrates into dark particles. You emerge victorious! You wipe the sweat off your face; that was a difficult battle.`,
  )
  .addOption(
    "Continue",
    buildEventMessage(
      "ancient_headmaster_event_3",
      "History of a Magical School",
    )
      .setSimpleDescription(
        `You examine the room. Several bookshelves line up the walls, with a desk in the middle of the room. It feels like this could have been the study of the being you have just felled, back when they were still alive.

On top of the desk lies a single book: "School Improvement for Novice Headmasters". You are surprised something like this would be the one thing on the desk of the Ancient Headmaster. You decide to take it with you.`,
      )
      .addOption(
        "Continue",
        buildEventMessage(
          "ancient_headmaster_event_4",
          "History of a Magical School",
        )
          .setSimpleDescription(
            `Back in campus, you find your researcher and your apprentice chatting with each other. You show them the book and relate what happened at the basement.
          
With a sigh of relief that you made it back out in one piece, the researcher takes the book from your hands and starts skimming it. Her eyes go wide open.

"Th-this is...", she begins saying. "This is incredible! This book describes a ton of ideas and plans we could use to improve the school, in a way we haven't thought of before!"`,
          )
          .addOption(
            "Continue",
            buildEventMessage(
              "ancient_headmaster_event_5",
              "History of a Magical School",
            )
              .setSimpleDescription(
                `"The one catch, is that all of these require a strange material, the substance we call Monstium." She stops and looks at you. "You have been saving it, haven't you?"
              
You nod, and ask for the book back. She gives it to you. "I'm looking forward to see what we can do next!", she exclaims.

**You have unlocked the "Upgrades" feature! Spend accumulated :monstium: to improve various aspects of the game. You can access this feature from the left-side menu.**`,
              )
              .build(),
            setFlag("upgrades_unlocked"),
          )
          .build(),
      )
      .build(),
  )
  .build();

const headmasterDefeatedEvent = createGameEvent(
  "ancient_headmaster_event_2",
  postHeadmasterEventMessage,
  setFlag("headmaster_event_2_triggered"),
);

export default new AncientHeadmaster();
