import { DataStore } from "../generic/DataStore";
import { PlayerContextState } from "../PlayerContext";

/**
 * PlayerDeathInterceptors return true if player death is to be prevented.
 * Use in conjunction with some ContextListener that does some action.
 */

export type PlayerDeathInterceptor = (state: PlayerContextState) => boolean;

const PlayerDeathInterceptors = new DataStore<PlayerDeathInterceptor>();

export { PlayerDeathInterceptors };
