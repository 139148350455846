import { formatNumber } from "../../../utils/FormattingUtils";
import { ActionEffect } from "../../actions/Action";
import { TransformationTags } from "../../calculation/TransformationTags";
import { hasFlag, setFlag } from "../../Flags";
import { PlayerContextState } from "../../PlayerContext";
import { addContextListener } from "../../PlayerContextListeners";
import { getResourceAmount, grantResource, Resource } from "../../Resources";
import { registerTimeTickListener } from "../../timetick/TimeTick";
import { BuildingActionBase } from "../Building";
import { getBuildingAmount } from "../Buildings";

class ManaSpout extends BuildingActionBase {
  constructor() {
    super();
  }

  isVisible(state: PlayerContextState): boolean {
    return (
      hasFlag(state, "has_gathered_at_least_1_stone") &&
      hasFlag(state, "has_gathered_at_least_1_water")
    );
  }

  getBuildingName(): string {
    return "Mana Spout";
  }

  getArea(): string {
    return "Campus";
  }

  getDisplayDescription(): string {
    return "Automatically generate Mana and refill your supply.";
  }

  protected getBaseActionEffects(): Record<string, ActionEffect> {
    return {
      mana: {
        value: 0.5,
        tags: [Resource.Mana, TransformationTags.Production],
      },
    };
  }

  getDisplayEffect(state: PlayerContextState): string {
    const effects = this.getActionEffects(state);
    return `+${formatNumber(effects.mana, { showDecimals: true })}:mana:/sec`;
  }

  getBaseResourceCost(): Record<string, number> {
    return {
      Stone: 10,
      Water: 2,
    };
  }

  getBaseResourceScale(): Record<string, number> {
    return {
      Stone: 1.3,
      Water: 1.3,
    };
  }
}

const manaSpout = new ManaSpout();

function onTick(
  state: PlayerContextState,
  elapsedTimeSec: number,
): PlayerContextState {
  const spouts = getBuildingAmount(state, manaSpout);
  if (spouts === 0) {
    return state;
  }
  const effects = manaSpout.getActionEffects(state);
  return grantResource(
    Resource.Mana,
    spouts * effects.mana * elapsedTimeSec,
  )(state);
}

registerTimeTickListener("ManaSpout", onTick);

addContextListener("mana_spout_unlocked_1", (oldState, newState) => {
  if (
    !hasFlag(newState, "has_gathered_at_least_1_stone") &&
    getResourceAmount(newState, Resource.Stone) >= 1
  ) {
    return setFlag("has_gathered_at_least_1_stone");
  }
});

addContextListener("mana_spout_unlocked_2", (oldState, newState) => {
  if (
    !hasFlag(newState, "has_gathered_at_least_1_water") &&
    getResourceAmount(newState, Resource.Water) >= 1
  ) {
    return setFlag("has_gathered_at_least_1_water");
  }
});

export default manaSpout as ManaSpout;
