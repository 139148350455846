import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { StyleSheet, useWindowDimensions, View } from "react-native";
import { Button, Icon, Overlay } from "react-native-elements";
import {
  EventOccurrenceData,
  executeEventOccurrenceAction,
  getEventMessageById,
  getEventOccurrenceById,
  removeOccurrenceFromQueue,
} from "../../backend/events/Events";
import { EventMessage } from "../../backend/events/GameEvent";
import { PlayerContext, PlayerContextState } from "../../backend/PlayerContext";
import {
  addContextListener,
  clearContextListener,
} from "../../backend/PlayerContextListeners";
import { getUICommands } from "../../backend/UICommands";
import { Markdown } from "../utility/Markdown";
import { Text } from "../utility/Text";

export function EventOverlay() {
  const playerContext = useContext(PlayerContext);
  const [currentEventOccurrenceId, setCurrentEventOccurrenceId] = useState(
    undefined as string | undefined,
  );

  const processUICommand = useCallback((uiCommand: string) => {
    if (uiCommand == "closeEventOverlay") {
      setCurrentEventOccurrenceId(undefined);
      setCurrentLookbackMessageId("");
    }
  }, []);

  const processUICommands = useCallback((state: PlayerContextState) => {
    const uiCommands = getUICommands(state);
    uiCommands.forEach(processUICommand);
    state.uiCommands = uiCommands.filter(
      (uiCommand) => uiCommand != "closeEventOverlay",
    );
    return state;
  }, []);

  useEffect(() => {
    addContextListener("eventOverlayUiCommand", (oldState, newState) => {
      const uiCommands = getUICommands(newState);
      if (uiCommands.length > 0) {
        return (state) => processUICommands(state);
      }
    });
  }, []);

  const currentEventOccurrenceIdRef = useRef(currentEventOccurrenceId);
  currentEventOccurrenceIdRef.current = currentEventOccurrenceId;

  const [currentLookbackMessageId, setCurrentLookbackMessageId] = useState("");

  useEffect(() => {
    const contextListener = addContextListener(
      "event_overlay",
      (oldState, newState) => {
        if (
          newState.eventQueue.length > 0 &&
          (!currentEventOccurrenceIdRef.current ||
            getEventOccurrenceById(
              newState,
              currentEventOccurrenceIdRef.current,
            ) == null)
        ) {
          // Get a new event
          let newEventOccurrenceId = null;
          const eventsToRemoveFromQueue: string[] = [];

          do {
            newEventOccurrenceId = newState.eventQueue.pop();
            if (newEventOccurrenceId != null) {
              eventsToRemoveFromQueue.push(newEventOccurrenceId);
              const occurrence = getEventOccurrenceById(
                newState,
                newEventOccurrenceId,
              );
              const message = occurrence?.currentMessageId;
              if (occurrence != null && message != null) {
                if (
                  (occurrence?.timestampSecondsPlayed ?? 0) <= 0.1 &&
                  getEventMessageById(message)?.isTerminal()
                ) {
                  newEventOccurrenceId = null;
                }
              }
            }
          } while (
            newState.eventQueue.length > 0 &&
            newEventOccurrenceId == null
          );

          if (newEventOccurrenceId) {
            setCurrentEventOccurrenceId(newEventOccurrenceId);
            setCurrentLookbackMessageId("");

            // This next line is required because otherwise, if many events are
            // in the queue before the next rerender, we will swallow them
            currentEventOccurrenceIdRef.current = newEventOccurrenceId;
          }

          return (state) =>
            eventsToRemoveFromQueue.reduce(
              (accumulatedState, eventToRemove) =>
                removeOccurrenceFromQueue(eventToRemove)(accumulatedState),
              state,
            );
        }
      },
    );
    return () => clearContextListener("event_overlay");
  }, []);

  let eventOccurrence: EventOccurrenceData | null | undefined = null,
    eventMessage: EventMessage | null | undefined = null,
    optionDescriptions = null,
    totalText: string | null = null,
    messageInLookbackIdx: number = -1,
    messageLength: number = 0,
    totalMessageCount: number = -1;
  if (currentEventOccurrenceId != null) {
    eventOccurrence = getEventOccurrenceById(
      playerContext,
      currentEventOccurrenceId,
    );
    messageInLookbackIdx =
      eventOccurrence?.messageHistory?.findIndex(
        (value) => value === currentLookbackMessageId,
      ) ?? -1;
    if (messageInLookbackIdx < 0) {
      eventMessage = (eventOccurrence?.currentMessageId &&
        getEventMessageById(eventOccurrence.currentMessageId)) as EventMessage;
    } else {
      eventMessage = getEventMessageById(
        currentLookbackMessageId as string,
      ) as EventMessage;
    }
    if (eventMessage) {
      optionDescriptions = eventMessage
        .getAllowedOptions(playerContext, eventOccurrence?.params)
        .map((option) => option.description + option.isEnabled);
    }
    messageLength = eventOccurrence?.messageHistory?.length ?? 0;
    if (messageLength > 0) {
      totalMessageCount = messageLength + 1;
      const idxToDisplay =
        messageInLookbackIdx >= 0
          ? messageInLookbackIdx + 1
          : totalMessageCount;
      totalText = `(${idxToDisplay}/${totalMessageCount})`;
    }
  }

  const { height, width } = useWindowDimensions();

  return useMemo(() => {
    if (currentEventOccurrenceId && eventMessage) {
      return (
        <Overlay
          isVisible={true}
          overlayStyle={[
            styles.overlay,
            { maxWidth: Math.min(width - 16, 640) },
          ]}
        >
          <View style={styles.overlayContainer}>
            <Button
              icon={<Icon name="close" size={24} color="white" />}
              containerStyle={styles.overlayCloseButton}
              style={styles.overlayCloseButtonInner}
              iconContainerStyle={styles.overlayCloseButtonInner}
              buttonStyle={styles.overlayCloseButtonInner}
              onPress={() => {
                setCurrentEventOccurrenceId(undefined);
                setCurrentLookbackMessageId("");
              }}
            />

            <View style={styles.titleContainer}>
              <View
                style={[
                  styles.titleTextContainer,
                  { maxWidth: messageLength > 0 ? width - 164 : width - 68 },
                ]}
              >
                <Text h1>
                  {eventMessage.getTitle(eventOccurrence?.params)} {totalText}{" "}
                </Text>
              </View>
              {messageLength > 0 && (
                <View style={styles.historyContainer}>
                  <Button
                    disabled={messageInLookbackIdx === 0 || messageLength === 0}
                    containerStyle={styles.historyButton}
                    style={styles.overlayCloseButtonInner}
                    iconContainerStyle={styles.overlayCloseButtonInner}
                    buttonStyle={styles.overlayCloseButtonInner}
                    icon={<Icon name="arrow-back" size={24} color="white" />}
                    onPress={() =>
                      setCurrentLookbackMessageId(
                        messageInLookbackIdx > 0
                          ? eventOccurrence?.messageHistory?.[
                              messageInLookbackIdx - 1
                            ] ?? ""
                          : eventOccurrence?.messageHistory?.[
                              totalMessageCount - 2
                            ] ?? "",
                      )
                    }
                  />
                  <Button
                    disabled={
                      messageInLookbackIdx < 0 ||
                      messageInLookbackIdx > totalMessageCount - 2
                    }
                    containerStyle={styles.historyButton}
                    style={styles.overlayCloseButtonInner}
                    iconContainerStyle={styles.overlayCloseButtonInner}
                    buttonStyle={styles.overlayCloseButtonInner}
                    icon={<Icon name="arrow-forward" size={24} color="white" />}
                    onPress={() =>
                      setCurrentLookbackMessageId(
                        messageInLookbackIdx < totalMessageCount - 2
                          ? eventOccurrence?.messageHistory?.[
                              messageInLookbackIdx + 1
                            ] ?? ""
                          : "",
                      )
                    }
                  />
                </View>
              )}
            </View>
            <View style={styles.description}>
              <Markdown>
                {eventMessage.getDescription(
                  playerContext,
                  eventOccurrence?.params,
                )}
              </Markdown>
            </View>
            {messageInLookbackIdx < 0 && (
              <View style={styles.optionRow}>
                {eventMessage
                  .getAllowedOptions(playerContext, eventOccurrence?.params)
                  .map((option) => {
                    const optionPress = () => {
                      if (!option.nextMessage && !option.transform) {
                        setCurrentEventOccurrenceId(undefined);
                        setCurrentLookbackMessageId("");
                        return;
                      }
                      playerContext.apply(
                        executeEventOccurrenceAction(
                          currentEventOccurrenceId,
                          option,
                        ),
                      );
                    };
                    return (
                      <Button
                        key={option.description}
                        onPress={optionPress}
                        title={option.description}
                        containerStyle={[
                          styles.optionButton,
                          { maxWidth: Math.min(width - 70, 586) },
                        ]}
                        disabled={!option.isEnabled}
                      />
                    );
                  })}
              </View>
            )}
          </View>
        </Overlay>
      );
    }
    return null;
  }, [
    JSON.stringify(eventOccurrence),
    optionDescriptions?.join(","),
    messageInLookbackIdx,
    currentLookbackMessageId,
    width,
  ]);
}

const styles = StyleSheet.create({
  overlay: {
    alignSelf: "center",
    marginHorizontal: 16,
    maxWidth: 640,
  },
  overlayContainer: {
    padding: 8,
  },
  historyButton: {
    height: 32,
    width: 32,
    borderRadius: 16,
    marginLeft: 8,
  },
  overlayCloseButton: {
    height: 32,
    width: 32,
    borderRadius: 16,
    position: "absolute",
    right: -16,
    top: -16,
  },
  overlayCloseButtonInner: {
    paddingHorizontal: 0,
    paddingVertical: 0,
    height: 32,
  },
  titleContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  titleTextContainer: {},
  historyContainer: {
    marginHorizontal: 8,
    flexDirection: "row",
    width: 80,
  },
  description: {
    marginVertical: 8,
  },
  optionRow: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },
  optionButton: {
    marginTop: 8,
    maxWidth: 600,
  },
});
