import { PlayerContextState } from "../../../PlayerContext";
import { EquipmentSlot } from "../../Equipment";
import { ItemParams } from "../../Item";
import { EquippableItem } from "../EquippableItem";

class CloakOfRegeneration extends EquippableItem {
  getId(): string {
    return "cloakOfRegeneration";
  }

  getPicture() {
    return require("./cloakOfRegeneration.png");
  }

  getBaseName(): string {
    return "Cloak of Regeneration";
  }

  getDescription(): string | undefined {
    return "A special cloak that slowly restores the wielder's life when worn.";
  }

  getDefenseBonus(state: PlayerContextState, params: ItemParams): number {
    return 4;
  }

  getHPRegenBonus(state: PlayerContextState, params: ItemParams): number {
    return 0.3;
  }

  getSlot(): EquipmentSlot {
    return EquipmentSlot.Accessory;
  }

  getSalePrice(): number {
    return 3600;
  }

  getCraftingSchoolLevelRequirements() {
    return {
      Enchantment: 11,
    };
  }

  getCraftingMaterialsBase() {
    return {
      resources: { Mana: 2800 },
      items: { cloak: 1, cloth: 20 },
    };
  }
}

export default new CloakOfRegeneration();
