import { applyTransformations } from "../../calculation/Calculation";
import { TransformationTags } from "../../calculation/TransformationTags";
import { Identifiable } from "../../generic/Identifiable";
import {
  PlayerContextState,
  PlayerContextTransform,
} from "../../PlayerContext";
import { pushToCreatureMessageLog } from "../Creatures";
import { Enemy } from "../enemies/Enemy";
import { getSuccessfulExplorationsForFloor, startCombat } from "../Exploration";

export interface ExplorationPossibility {
  weight: number;
  transforms: ExplorationOutcome;
}

export interface ExplorationOutcome {
  normal: PlayerContextTransform;
  breeding: PlayerContextTransform;
}

export function encounterEnemy(enemy: Enemy): ExplorationOutcome {
  return {
    normal: startCombat(enemy),
    breeding: (state) => {
      state = pushToCreatureMessageLog(
        state,
        `A creature defeated ${enemy.getName()}!`,
      );
      state = enemy.onDeath(state, true);
      return state;
    },
  };
}

export abstract class DungeonFloor implements Identifiable {
  abstract getId(): string;
  abstract getFloorName(): string;

  getExplorationRequiredTimeSec(): number {
    return 5.0;
  }

  _getExplorationSuccessesForBoss(): number {
    return 50;
  }

  getExplorationSuccessesForBoss(state: PlayerContextState): number {
    return applyTransformations(
      [TransformationTags.ExplorationBossRequirement],
      state,
      this._getExplorationSuccessesForBoss(),
      { floor: this },
    );
  }

  doExplore(state: PlayerContextState): ExplorationOutcome | null {
    const possibilities = this.getExplorationPossibilities(state);
    const totalWeight = possibilities.reduce(
      (sumToNow, possibility) => sumToNow + possibility.weight,
      0,
    );
    let explorationRoll = Math.random() * totalWeight;
    for (let idx in possibilities) {
      const possibility = possibilities[idx];
      const weight = possibility.weight;
      explorationRoll -= weight;
      if (explorationRoll < 0) {
        return possibility.transforms;
      }
    }
    return null;
  }

  abstract getBoss(state: PlayerContextState): Enemy | undefined;

  abstract getBreedingLevel(): number;

  doFightBoss(state: PlayerContextState): PlayerContextTransform | undefined {
    const boss = this.getBoss(state);
    if (boss) {
      return startCombat(boss);
    }
  }

  canFightBoss(state: PlayerContextState): boolean {
    return (
      getSuccessfulExplorationsForFloor(state, this.getId()) >=
        this.getExplorationSuccessesForBoss(state) &&
      this.getBoss(state) != undefined
    );
  }

  isUnlocked(state: PlayerContextState): boolean {
    return false;
  }

  getNextFloor(): DungeonFloor | undefined {
    return undefined;
  }

  abstract getExplorationPossibilities(
    state: PlayerContextState,
  ): ExplorationPossibility[];
}
