import { hasFlag, setFlag } from "../../Flags";
import { EquipmentSlot, getEquippedItem } from "../../items/Equipment";
import { addContextListener } from "../../PlayerContextListeners";
import { getTotalElementAndSchoolExp } from "../../spells/ElementsAndSchools";
import { buildEventMessage } from "../EventMessageBuilder";
import { triggerEvent } from "../Events";
import { createGameEvent } from "../SimpleGameEvent";

const message = buildEventMessage("exploration_intro_1", "The School Basement")
  .setSimpleDescription(
    `"E-Excuse me, headmaster...?" You hear a timid voice coming from the door. You gaze up, to find your apprentice at the door, and motion for him to come inside. He walks towards you, looking disheveled and holding a club.
    
"I found a trap door in the school garden, behind some bushes", he begins explaining. "I found it curious, so I decided to see what was below it." He glances at you. You can tell that, despite his usual fear of you, he is actually quite enthusiastic about this.`,
  )
  .addOption(
    "Continue",
    buildEventMessage("exploration_intro_2", "The School Basement")
      .setSimpleDescription(
        `"It's a very spacious basement, with a lot of rooms, and it seems to go very deep!", he says. "Headmaster, you should definitely investigate. I feel there's a lot of secrets to be found!"
  
"B-but it was, well... dangerous", he continues, while looking down. "Please be prepared before going down there. There are scary... ...creatures, roaming around the place."`,
      )
      .addOption(
        "Continue",
        buildEventMessage("exploration_intro_3", "The School Basement")
          .setSimpleDescription(
            `You nod, and bid farewell to the apprentice.
          
You are aware this is quite the old building, where wizards used to live and study several generations ago. You consider the basement may be full of things that could help expand your knowledge. However, you recall the apprentice's warning: it will probably be dangerous. You strongly consider arming yourself with a weapon and some protection before heading in, though you admit you may already be equipped for it.

Maybe this is why the researcher was also excited about making weapons...?

**You have unlocked the "Exploration" feature! You can access this feature from the left-side menu. Explore various locations to discover a wide array of new things; but beware, as powerful foes may be lurking behind every corner!**`,
          )
          .build(),
      )
      .build(),
  )
  .build();

const event = createGameEvent(
  "exploration_intro_1",
  message,
  setFlag("exploration_unlocked"),
);

addContextListener("exploration_intro", (oldState, newState) => {
  if (
    !hasFlag(newState, "exploration_unlocked") &&
    hasFlag(newState, "wizard_robe_bought") &&
    getEquippedItem(newState, EquipmentSlot.Hand) &&
    getEquippedItem(newState, EquipmentSlot.Body) &&
    getTotalElementAndSchoolExp(newState) >= 33000
  ) {
    return triggerEvent(event);
  }
});

export default event;
