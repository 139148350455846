import { formatNumber } from "../../../utils/FormattingUtils";
import { ActionEffect } from "../../actions/Action";
import { TransformationTags } from "../../calculation/TransformationTags";
import { buildEventMessage } from "../../events/EventMessageBuilder";
import { triggerEvent } from "../../events/Events";
import { createGameEvent } from "../../events/SimpleGameEvent";
import { hasFlag, setFlag } from "../../Flags";
import { PlayerContextState } from "../../PlayerContext";
import { addContextListener } from "../../PlayerContextListeners";
import { getResourceAmount, grantResource, Resource } from "../../Resources";
import { registerTimeTickListener } from "../../timetick/TimeTick";
import { BuildingActionBase } from "../Building";
import { getBuildingAmount } from "../Buildings";
import ManaSpout from "./ManaSpout";

class ManaGeyser extends BuildingActionBase {
  constructor() {
    super();
  }

  isVisible(state: PlayerContextState): boolean {
    return hasFlag(state, "mana_geyser_unlocked");
  }

  getBuildingName(): string {
    return "Mana Geyser";
  }

  getArea(): string {
    return "Campus";
  }

  getDisplayDescription(): string {
    return "A metal-reinforced building that produces mana.";
  }

  protected getBaseActionEffects(): Record<string, ActionEffect> {
    return {
      mana: {
        value: 2.0,
        tags: [Resource.Mana, TransformationTags.Production],
      },
    };
  }

  getDisplayEffect(state: PlayerContextState): string {
    const effects = this.getActionEffects(state);
    return `+${formatNumber(effects.mana, { showDecimals: true })}:mana:/sec`;
  }

  getBaseResourceCost(): Record<string, number> {
    return {
      Coins: 600,
      Water: 150,
      Iron: 30,
    };
  }

  getBaseResourceScale(): Record<string, number> {
    return {
      Coins: 1.3,
      Water: 1.3,
      Iron: 1.3,
    };
  }
}

const manaGeyser = new ManaGeyser();

function onTick(
  state: PlayerContextState,
  elapsedTimeSec: number,
): PlayerContextState {
  const geysers = getBuildingAmount(state, manaGeyser);
  if (geysers === 0) {
    return state;
  }
  const effects = manaGeyser.getActionEffects(state);
  return grantResource(
    Resource.Mana,
    geysers * effects.mana * elapsedTimeSec,
  )(state);
}

registerTimeTickListener("ManaGeyser", onTick);

const message = buildEventMessage("mana_geyser_unlocked", "Mana Geysers")
  .setSimpleDescription(
    `As you slowly start getting more and more iron, you think of a plan to improve on your Mana Spouts. You are thinking that, by adding iron, you should be able to create a structure that can produce mana at a much faster rate than a simple spout.
    
You will definitely require a lot of iron, but it'll be worth it in the long run, you tell yourself. Mana is the cornerstone of magic!

**You can now build Mana Geysers!**`,
  )
  .build();

const event = createGameEvent(
  "mana_geyser_unlocked",
  message,
  setFlag("mana_geyser_unlocked"),
);

addContextListener("mana_geyser_unlocked", (oldState, newState) => {
  if (
    !hasFlag(newState, "mana_geyser_unlocked") &&
    getResourceAmount(newState, Resource.Iron) >= 10 &&
    getBuildingAmount(newState, ManaSpout) >= 1
  ) {
    return triggerEvent(event);
  }
});

export default manaGeyser as ManaGeyser;
