import { formatNumber } from "../../../../utils/FormattingUtils";
import { applyTransformations } from "../../../calculation/Calculation";
import { TransformationTags } from "../../../calculation/TransformationTags";
import { PlayerAttackListeners } from "../../../exploration/AttackListeners";
import { getLastPlayerActionResult } from "../../../exploration/Exploration";
import { PlayerContextState } from "../../../PlayerContext";
import { Resource } from "../../../Resources";
import { grantTemporaryEffect } from "../../../timetick/TemporaryEffects";
import { EquipmentSlot, getEquippedItem } from "../../Equipment";
import { ItemParams } from "../../Item";
import { EquippableItem } from "../EquippableItem";

class LightningWand extends EquippableItem {
  getId(): string {
    return "lightningWand";
  }

  getPicture() {
    return require("./lightningWand.png");
  }

  getBaseName(): string {
    return "Lightning Wand";
  }

  getDescription(): string | undefined {
    return "A wizard's magical wand with the power of Lightning. May stun.";
  }

  getAttackBonus(state: PlayerContextState, params: ItemParams): number {
    return 15;
  }

  getManaRegenBonus(state: PlayerContextState, params: ItemParams): number {
    return applyTransformations(
      [this.getId(), Resource.Mana, TransformationTags.Production],
      state,
      16.0,
    );
  }

  getMaxManaBonus(state: PlayerContextState, params: ItemParams): number {
    return 400;
  }

  getEffect(state: PlayerContextState, params: ItemParams): string | undefined {
    const amountPerSec = this.getManaRegenBonus(state, params);
    return `+15:attack:, +${formatNumber(amountPerSec, {
      showDecimals: true,
    })}:mana:/sec, +400 Max:mana:, +10% chance to apply "Stunned"`;
  }

  getSlot(): EquipmentSlot {
    return EquipmentSlot.Hand;
  }

  isCraftable(state: PlayerContextState): boolean {
    return super.isCraftable(state) && state.primaryElement == "Lightning";
  }

  getSalePrice(): number {
    return 1800;
  }

  getCraftingSchoolLevelRequirements() {
    return { Conjuration: 8, Enchantment: 8 };
  }

  getCraftingMaterialsBase() {
    return {
      resources: {
        Wood: 3000,
        Mana: 2500,
      },
      items: {
        lightningSpeck: 1,
      },
    };
  }
}

const lightningWand = new LightningWand();

PlayerAttackListeners.register((oldState, state) => {
  if (
    getLastPlayerActionResult(state)?.isHit &&
    getEquippedItem(state, EquipmentSlot.Hand)?.itemId == "lightningWand"
  ) {
    const isStun = Math.random() < 0.1;
    if (isStun) {
      state = grantTemporaryEffect("EnemyStun", "Stunned!", 1.0, {
        isBeneficial: false,
        combatOnly: true,
        isEnemyTemporaryEffect: true,
      })(state);
    }
  }
  return state;
});

export default lightningWand;
