import { buildEventMessage } from "../../../events/EventMessageBuilder";
import { triggerEvent } from "../../../events/Events";
import { createGameEvent } from "../../../events/SimpleGameEvent";
import { hasFlag, setFlag } from "../../../Flags";
import { PlayerContextState } from "../../../PlayerContext";
import { addContextListener } from "../../../PlayerContextListeners";
import { Enemy } from "../../enemies/Enemy";
import AncientHeadmaster from "../../enemies/schoolbasement/AncientHeadmaster";
import AngrySheep from "../../enemies/schoolbasement/AngrySheep";
import RedSlime from "../../enemies/schoolbasement/RedSlime";
import Zombie from "../../enemies/schoolbasement/Zombie";
import { DungeonFloor, encounterEnemy } from "../DungeonFloor";

class SchoolBasementB3 extends DungeonFloor {
  getId(): string {
    return "schoolBasementB3";
  }

  getFloorName(): string {
    return "School Basement B3";
  }

  getBoss(): Enemy {
    return AncientHeadmaster;
  }

  isUnlocked(state: PlayerContextState): boolean {
    return hasFlag(state, "school_basement_b3_unlocked");
  }

  getBreedingLevel(): number {
    return 11;
  }

  getExplorationPossibilities(state: PlayerContextState) {
    return [
      {
        weight: 60,
        transforms: encounterEnemy(AngrySheep),
      },
      {
        weight: 80,
        transforms: encounterEnemy(Zombie),
      },
      {
        weight: 40,
        transforms: encounterEnemy(RedSlime),
      },
    ];
  }
}

const schoolBasementB3 = new SchoolBasementB3();

const headmasterEventMessage = buildEventMessage(
  "ancient_headmaster_event_1",
  "The End of the Basement",
)
  .setSimpleDescription(
    `You have been going deeper and deeper into the basement and it has become more dangerous as you delve into it.
    
There is one last room you haven't checked. It's very well decorated, with golden patterns lining the door. You have a terrible feeling that something powerful lies beyond that door.

**You have unlocked the boss fight for School Basement B3!**`,
  )
  .build();

const thirdFloorBossUnlockedEvent = createGameEvent(
  "ancient_headmaster_event_1",
  headmasterEventMessage,
  setFlag("headmaster_event_1_triggered"),
);

addContextListener(
  "HeadmasterB3SchoolBasementUnlocker",
  (oldState, newState) => {
    if (
      !hasFlag(newState, "headmaster_event_1_triggered") &&
      schoolBasementB3.canFightBoss(newState)
    ) {
      return triggerEvent(thirdFloorBossUnlockedEvent);
    }
  },
);

export default schoolBasementB3 as SchoolBasementB3;
