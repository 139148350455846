import { formatNumber } from "../../utils/FormattingUtils";
import { Action, ActionBase, DoActionArgs } from "../actions/Action";
import { registerAction } from "../actions/Actions";
import { applyTransformations } from "../calculation/Calculation";
import { TransformationTags } from "../calculation/TransformationTags";
import { PlayerContextState } from "../PlayerContext";
import { grantResource, Resource } from "../Resources";
import { hasTemporaryEffect } from "../timetick/TemporaryEffects";
import { getSecondsPlayed } from "../timetick/TotalTimePlayed";
import {
  grantSchoolExp,
  meetsRequirements,
  School,
  SpellElement,
} from "./ElementsAndSchools";
import { addRecentSpellCast, registerSpell, setLastCastedTime } from "./Spells";

export interface Spell extends Action {
  getSpellName(): string;
  canAfford(state: PlayerContextState): boolean;
  getSchoolLevelRequirements(): Partial<Record<School, number>>;
}

export abstract class SpellActionBase extends ActionBase implements Spell {
  abstract getSpellName(): string;
  abstract getManaCost(state: PlayerContextState): number;
  abstract doSpellAction(state: PlayerContextState): PlayerContextState;
  abstract getSchoolLevelRequirements(): Partial<Record<School, number>>;

  constructor() {
    super();
    registerSpell(this);
    registerAction(this);
  }

  getElement(): SpellElement | undefined {
    return undefined;
  }

  getTags(): string[] {
    const tags = [
      ...super.getTags(),
      TransformationTags.Spell,
      ...Object.keys(this.getSchoolLevelRequirements()),
    ];
    const element = this.getElement();
    if (element) {
      tags.push(element);
    }
    return tags;
  }

  getName(): string {
    return this.getSpellName();
  }

  getCost(state: PlayerContextState): {
    resources: Partial<Record<Resource, number>>;
    items: Record<string, number>;
  } {
    return {
      resources: {
        Mana: applyTransformations(
          [...this.getTags(), TransformationTags.Cost, Resource.Mana],
          state,
          this.getManaCost(state),
        ),
      },
      items: {},
    };
  }

  getDisplayName(state: PlayerContextState): string {
    const lastCastTime = state?.lastSpellCastTimes?.[this.getSpellName()] || 0;
    if (lastCastTime > 0) {
      const cooldown = lastCastTime + this.getCooldown(state);
      const secTillOver = cooldown - getSecondsPlayed(state);
      if (secTillOver > 0) {
        return `${this.getName()} (${formatNumber(secTillOver, {
          showDecimals: true,
        })} sec)`;
      }
    }
    return super.getDisplayName(state);
  }

  getCategory(): string {
    return "Spells";
  }

  getBaseCooldown(): number {
    return 0.0;
  }

  getCooldown(state: PlayerContextState): number {
    return applyTransformations(
      [...this.getTags(), TransformationTags.SpellCooldown],
      state,
      this.getBaseCooldown(),
    );
  }

  isVisible(state: PlayerContextState): boolean {
    const schoolRequirements = this.getSchoolLevelRequirements();
    return meetsRequirements(state, schoolRequirements);
  }

  isEnabled(state: PlayerContextState): boolean {
    const lastCastTime = state?.lastSpellCastTimes?.[this.getSpellName()] || 0;
    if (
      lastCastTime > 0 &&
      getSecondsPlayed(state) < lastCastTime + this.getCooldown(state)
    ) {
      return false;
    }

    if (
      hasTemporaryEffect(state, "SilencePlayer") ||
      hasTemporaryEffect(state, "PlayerStun")
    ) {
      return false;
    }

    const transformationResult = applyTransformations(
      [...this.getTags(), TransformationTags.CanCastSpell],
      state,
      1.0,
    );
    if (transformationResult <= 0) {
      return false;
    }

    return super.isEnabled(state);
  }

  doAction(args: DoActionArgs, state: PlayerContextState): PlayerContextState {
    if (!this.canAfford(state)) {
      return state;
    }

    const cost = this.getCost(state);
    (Object.keys(cost.resources) as Array<Resource>).forEach((resourceName) => {
      state = grantResource(
        resourceName,
        -(cost?.resources?.[resourceName] ?? 0),
      )(state);
    });

    const schoolRequirements = this.getSchoolLevelRequirements();
    Object.keys(schoolRequirements).forEach((school) => {
      state = grantSchoolExp(
        school as School,
        (schoolRequirements[school as School] || -4) * 3 + 12,
      )(state);
    });

    if (!args.isAutomatic) {
      state = addRecentSpellCast(this)(state);
    }

    state = setLastCastedTime(this)(state);

    return this.doSpellAction(state);
  }
}
