import { Enemies } from "./Enemies";
import AncientHeadmaster from "./schoolbasement/AncientHeadmaster";
import AngrySheep from "./schoolbasement/AngrySheep";
import BasementBat from "./schoolbasement/BasementBat";
import LittleDevil from "./schoolbasement/LittleDevil";
import mimic from "./schoolbasement/Mimic";
import Mouse from "./schoolbasement/Mouse";
import RedSlime from "./schoolbasement/RedSlime";
import Skeleton from "./schoolbasement/Skeleton";
import Slime from "./schoolbasement/Slime";
import Zombie from "./schoolbasement/Zombie";

export function loadEnemiesDemo() {
  // School Basement
  Enemies.register(Mouse);
  Enemies.register(BasementBat);
  Enemies.register(Skeleton);
  Enemies.register(AngrySheep);
  Enemies.register(Slime);
  Enemies.register(LittleDevil);
  Enemies.register(mimic);
  Enemies.register(Zombie);
  Enemies.register(RedSlime);
  Enemies.register(AncientHeadmaster);
}
