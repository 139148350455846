import { DataStore } from "../../generic/DataStore";
import { PlayerContextState } from "../../PlayerContext";
import { Enemy } from "./Enemy";

const Enemies = new DataStore<Enemy>();

export { Enemies };

export function getCurrentEnemy(state: PlayerContextState): Enemy {
  return Enemies.getById(state.exploration?.currentEnemy?.id || "");
}
