import { ItemBase } from "../Item";

class Wool extends ItemBase {
  getId(): string {
    return "wool";
  }

  getPicture() {
    return require("./wool.png");
  }

  getBaseName(): string {
    return "Wool";
  }

  getTags() {
    return [{ tag: "Material" }];
  }

  getDescription(): string | undefined {
    return "Wool. Can be used to make cloth.";
  }

  getEffect(): string | undefined {
    return undefined;
  }

  getSalePrice(): number {
    return 16;
  }

  getCraftingSchoolLevelRequirements() {
    return {};
  }

  getCraftingMaterialsBase() {
    return {
      resources: {},
      items: {},
    };
  }
}

export default new Wool();
