import React, { useContext, useMemo, useRef } from "react";
import { StyleSheet, View } from "react-native";
import { Button } from "react-native-elements";
import { getTotalAmountOfItem } from "../../backend/items/Inventory";
import { Item } from "../../backend/items/Item";
import { PlayerContext } from "../../backend/PlayerContext";
import { CostViewer } from "../utility/CostViewer";
import { Markdown } from "../utility/Markdown";
import { ItemInfo } from "./ItemInfo";

export interface CraftingDetailsProps {
  item: Item;
}

export function CraftingDetails(props: CraftingDetailsProps) {
  const playerContext = useContext(PlayerContext);
  const playerContextRef = useRef(playerContext);
  playerContextRef.current = playerContext;

  const item = props.item;
  const params = item.getDefaultParams();
  const totalOwnedText = `Total owned: ${getTotalAmountOfItem(
    item,
    playerContext,
  )}`;

  const cost = item.getCraftingMaterials(playerContext);

  return useMemo(
    () => (
      <View style={styles.craftingDetailsContainer}>
        <ItemInfo
          itemStack={{
            itemOccurrence: { itemId: item.getId(), params },
            amount: 1,
          }}
        />
        <Markdown>{totalOwnedText}</Markdown>
        <View style={styles.costContainer}>
          <Markdown>Cost:</Markdown>
          <CostViewer resources={cost.resources} items={cost.items} />
        </View>
        <View style={styles.actionRow}>
          <Button
            title="Craft"
            disabled={!item.canCraft(playerContext)}
            onPress={() => {
              playerContextRef.current.apply(item.craft.bind(item));
            }}
          />
        </View>
      </View>
    ),
    [
      item.getId(),
      JSON.stringify(params),
      totalOwnedText,
      JSON.stringify(cost.resources),
      JSON.stringify(cost.items),
      item.canCraft(playerContext),
      playerContextRef,
    ],
  );
}

const styles = StyleSheet.create({
  craftingDetailsContainer: {
    marginBottom: 16,
  },
  tagRow: {
    marginVertical: 4,
  },
  actionRow: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 8,
  },
  actionButtonContainer: {
    marginRight: 8,
  },
  costContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginTop: 4,
  },
});
