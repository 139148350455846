import {
  registerTransformation,
  TransformationType,
} from "../../calculation/Calculation";
import { AttackTarget } from "../../exploration/AttackTarget";
import { CombatStat } from "../../exploration/CombatStats";
import { PlayerContextState } from "../../PlayerContext";
import { Upgrade } from "../Upgrade";
import healthBoost from "./HealthBoost";

class LargerHealthBoost extends Upgrade {
  getName(): string {
    return "Larger Health Boost";
  }

  getMonstiumCost(): number {
    return 3000;
  }

  getDisplayDescription(state: PlayerContextState): string {
    return "Further condition your body in order to be able to withstand more blows.";
  }

  getDisplayEffect(state: PlayerContextState): string {
    return "+60 Max:hp:";
  }

  getUpgradeRequirements() {
    return [healthBoost];
  }
}

const largerHealthBoost = new LargerHealthBoost();

registerTransformation(
  [[AttackTarget.Player, CombatStat.MaxHP]],
  "LargerHealthBoost",
  TransformationType.Addition,
  (state) => (largerHealthBoost.isPurchased(state) ? 60 : 0),
);

export default largerHealthBoost;
