import { hasFlag, setFlag } from "../../Flags";
import { addContextListener } from "../../PlayerContextListeners";
import { getResourceAmount, grantResource, Resource } from "../../Resources";
import { getTotalElementAndSchoolExp } from "../../spells/ElementsAndSchools";
import { buildEventMessage } from "../EventMessageBuilder";
import { triggerEvent } from "../Events";
import { createGameEvent } from "../SimpleGameEvent";

const message = buildEventMessage("crafting_intro_1", "Crafting Station")
  .setSimpleDescription(
    `Your focus is interrupted by your researcher, who yells at you. "Headmaster!", she says.

"Gathering resources like wood and iron is great", she continues. "Wouldn't it even be more interesting if we could make more complex stuff from them?"`,
  )
  .addOption(
    "Continue",
    buildEventMessage("crafting_intro_1_2", "Crafting Station")
      .setSimpleDescription(
        `You try to convey to your researcher that we have, in fact, been creating complex things from resources: the buildings. But she frowns.
      
"No, that's not what I meant", she says, unhappily. "Buildings are huge! I'm thinking more of useful, smaller things. Potions? Weapons? Magical items?"`,
      )
      .addOption(
        "Continue",
        buildEventMessage("crafting_intro_1_3", "Crafting Station")
          .setSimpleDescription(
            `You continue listening to her.
          
"If we had a crafting station, we could make a lot of these... if only it wasn't so expensive to build, I could do it myself and learn more about it!", she says, somewhat frustrated.

You aren't sure whether to pay heed to it - why would you need things like weapons in a magical institution? You think about it some more...`,
          )
          .addOption(
            "Build a Crafting Station (2000 Coins, 2000 Wood, 100 Iron)",
            buildEventMessage("crafting_intro_1_4", "Crafting Station")
              .setSimpleDescription(
                `It was difficult, but the crafting station is finished! Your researcher, filled with joy, runs towards it and examines it from every angle. You notice your apprentice also taking notice of it, and looking at it curiously.
              
A crafting station... You wonder if you might be able to do something with it right away?

"Conjuration and Enchantment," says your researcher, as she passes by you with the apprentice. "Focus our research in those schools, and we should be able to make items." They head outside together.

**You have unlocked the "Inventory" and "Crafting" features! You can access your inventory via the left-side menu. You can craft from the inventory screen.**`,
              )
              .build(),
            (state) => {
              state = grantResource(Resource.Coins, -2000)(state);
              state = grantResource(Resource.Wood, -2000)(state);
              state = grantResource(Resource.Iron, -100)(state);
              state = setFlag("inventory_unlocked")(state);
              return state;
            },
            undefined,
            (state) =>
              getResourceAmount(state, Resource.Coins) >= 2000 &&
              getResourceAmount(state, Resource.Wood) >= 2000 &&
              getResourceAmount(state, Resource.Iron) >= 100,
          )
          .addOption("Maybe later")
          .build(),
      )
      .build(),
  )
  .build();

const event = createGameEvent(
  "crafting_intro_1",
  message,
  setFlag("crafting_intro"),
);

addContextListener("crafting_intro", (oldState, newState) => {
  if (
    !hasFlag(newState, "crafting_intro") &&
    hasFlag(newState, "gather_iron_unlocked") &&
    !hasFlag(newState, "inventory_unlocked") &&
    getTotalElementAndSchoolExp(newState) >= 10000
  ) {
    return triggerEvent(event);
  }
});

export default event;
