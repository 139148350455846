import { formatNumber } from "../../../utils/FormattingUtils";
import { ActionEffect } from "../../actions/Action";
import { TransformationTags } from "../../calculation/TransformationTags";
import { buildEventMessage } from "../../events/EventMessageBuilder";
import { triggerEvent } from "../../events/Events";
import { createGameEvent } from "../../events/SimpleGameEvent";
import { hasFlag, setFlag } from "../../Flags";
import { PlayerContextState } from "../../PlayerContext";
import { addContextListener } from "../../PlayerContextListeners";
import { grantResource, Resource } from "../../Resources";
import { getTotalElementAndSchoolExp } from "../../spells/ElementsAndSchools";
import { registerTimeTickListener } from "../../timetick/TimeTick";
import { BuildingActionBase } from "../Building";
import { getBuildingAmount } from "../Buildings";

class Mine extends BuildingActionBase {
  constructor() {
    super();
  }

  isVisible(state: PlayerContextState): boolean {
    return hasFlag(state, "mine_unlocked");
  }

  getBuildingName(): string {
    return "Mine";
  }

  getArea(): string {
    return "Campus";
  }

  getDisplayDescription(): string {
    return "A structured specially designed to harvest stone and ores from the ground.";
  }

  protected getBaseActionEffects(): Record<string, ActionEffect> {
    return {
      stone: {
        value: 27,
        tags: [Resource.Stone, TransformationTags.Production],
      },
      iron: { value: 2, tags: [Resource.Iron, TransformationTags.Production] },
    };
  }

  getDisplayEffect(state: PlayerContextState): string {
    const effects = this.getActionEffects(state);
    return `+${formatNumber(effects.stone, {
      showDecimals: true,
    })}:stone:/sec, +${formatNumber(effects.iron, {
      showDecimals: true,
    })}:iron:/sec`;
  }

  getBaseResourceCost(): Record<string, number> {
    return {
      Mana: 1000,
      Coins: 12000,
      Wood: 8000,
      Iron: 1500,
    };
  }

  getBaseResourceScale(): Record<string, number> {
    return {
      Mana: 1.0,
      Coins: 1.3,
      Wood: 1.3,
      Iron: 1.3,
    };
  }
}

const mine = new Mine();

function onTick(
  state: PlayerContextState,
  elapsedTimeSec: number,
): PlayerContextState {
  const mines = getBuildingAmount(state, mine);
  if (mines === 0) {
    return state;
  }
  const effects = mine.getActionEffects(state);
  state = grantResource(
    Resource.Stone,
    mines * effects.stone * elapsedTimeSec,
  )(state);
  state = grantResource(
    Resource.Iron,
    mines * effects.iron * elapsedTimeSec,
  )(state);
  return state;
}

registerTimeTickListener("Mine", onTick);

const message = buildEventMessage("mine_unlocked", "Mining")
  .setSimpleDescription(
    `In one of your trips to the rocky cliff with iron, you start thinking that it would be a lot easier if there was a building that could extract the minerals on its own. You suddenly get an idea for a new building - a way in which you could get stones and iron without the need to manually gather them!

You can't wait to try it out.

**You can now build Mines!**`,
  )
  .build();

const event = createGameEvent(
  "mine_unlocked",
  message,
  setFlag("mine_unlocked"),
);

addContextListener("mine_unlocked", (oldState, newState) => {
  if (
    !hasFlag(newState, "mine_unlocked") &&
    hasFlag(newState, "gather_iron_unlocked") &&
    getTotalElementAndSchoolExp(newState) > 600000
  ) {
    return triggerEvent(event);
  }
});

export default mine as Mine;
