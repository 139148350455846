import { GameEvent, EventMessage, EventTransform } from "./GameEvent";
import { registerGameEvent } from "./Events";
import { PlayerContextTransform } from "../PlayerContext";

class SimpleGameEvent implements GameEvent {
  constructor(
    private id: string,
    private message: EventMessage,
    private onTriggerTransform?: EventTransform,
  ) {
    registerGameEvent(this);
  }

  onTrigger(): EventTransform | undefined {
    return this.onTriggerTransform;
  }

  getId(): string {
    return this.id;
  }

  getMainEventMessage(): EventMessage {
    return this.message;
  }
}

export function createGameEvent(
  id: string,
  message: EventMessage,
  onTrigger?: EventTransform,
): GameEvent {
  return new SimpleGameEvent(id, message, onTrigger);
}
