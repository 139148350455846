import { ActionEffect } from "../../actions/Action";
import { TransformationTags } from "../../calculation/TransformationTags";
import { hasGlobalFlag } from "../../Flags";
import { PlayerContextState } from "../../PlayerContext";
import { CombatSpellBase } from "../CombatSpellBase";
import { SpellElement } from "../ElementsAndSchools";
import {
  getStandardAirAttackEffect,
  getStandardAttackEffectText,
} from "../Utils";

class Gale extends CombatSpellBase {
  getSpellName(): string {
    return "Gale";
  }

  getElement(): SpellElement | undefined {
    return SpellElement.Air;
  }

  getDisplayDescription(): string {
    return "Blast an enemy with a strong gust of wind.";
  }

  protected getBaseActionEffects(): Record<string, ActionEffect> {
    return {
      average: {
        value: 75,
        tags: [TransformationTags.ActionMagnitude],
      },
      variance: {
        value: 5,
        tags: [TransformationTags.ActionVariance],
      },
    };
  }

  getDisplayEffect(state: PlayerContextState): string {
    const effects = this.getActionEffects(state);
    const extraEffect = hasGlobalFlag(state, "advanced_evocation_unlocked")
      ? ", extra accurate"
      : "";
    return (
      getStandardAttackEffectText(effects.average, effects.variance) +
      extraEffect
    );
  }

  doSpellAction(state: PlayerContextState): PlayerContextState {
    return getStandardAirAttackEffect(state, this);
  }

  getBaseCooldown(): number {
    return 1.0;
  }

  isVisible(state: PlayerContextState): boolean {
    return super.isVisible(state) && state.primaryElement == "Air";
  }

  getManaCost(): number {
    return 200;
  }

  getSchoolLevelRequirements(): Record<string, number> {
    return {
      Evocation: 8,
    };
  }
}

export default new Gale();
