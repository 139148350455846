import { PlayerContextState } from "../../PlayerContext";
import {
  clearTemporaryEffect,
  getTemporaryEffects,
} from "../../timetick/TemporaryEffects";
import { SpellActionBase } from "../Spell";

class DispelSelf extends SpellActionBase {
  getSpellName(): string {
    return "Dispel Self";
  }

  getArea(): string {
    return "Exploration";
  }

  getDisplayDescription(): string {
    return "Get rid of any negative status effects affecting you.";
  }

  getDisplayEffect(): string {
    return "Heals all :debuff:debuffs";
  }

  getManaCost(): number {
    return 4700;
  }

  getSchoolLevelRequirements(): Record<string, number> {
    return {
      Protection: 19,
    };
  }

  doSpellAction(state: PlayerContextState): PlayerContextState {
    const temporaryEffects = getTemporaryEffects(state);
    temporaryEffects.forEach((effect) => {
      if (!effect.isBeneficial && !effect.isEnemyTemporaryEffect) {
        state = clearTemporaryEffect(effect.id)(state);
      }
    });
    return state;
  }
}

export default new DispelSelf();
