import { formatNumber } from "../../../utils/FormattingUtils";
import { TransformationTags } from "../../calculation/TransformationTags";
import { buildEventMessage } from "../../events/EventMessageBuilder";
import { triggerEvent } from "../../events/Events";
import { createGameEvent } from "../../events/SimpleGameEvent";
import { hasFlag, setFlag } from "../../Flags";
import { PlayerContextState } from "../../PlayerContext";
import { addContextListener } from "../../PlayerContextListeners";
import {
  getResourceAmount,
  getResourceCap,
  grantResource,
  Resource,
} from "../../Resources";
import { getTotalElementAndSchoolExp } from "../../spells/ElementsAndSchools";
import { ActionBase, ActionEffect, DoActionArgs } from "../Action";

class GatherIron extends ActionBase {
  constructor() {
    super();
  }

  isVisible(state: PlayerContextState): boolean {
    return hasFlag(state, "gather_iron_unlocked");
  }

  isEnabled(state: PlayerContextState): boolean {
    return (
      getResourceAmount(state, Resource.Iron) <
      getResourceCap(state, Resource.Iron)
    );
  }

  getTags(): string[] {
    return [
      ...super.getTags(),
      TransformationTags.ManualGathering,
      TransformationTags.Production,
      Resource.Iron,
    ];
  }

  getName(): string {
    return "Gather Iron";
  }

  getArea(): string {
    return "Campus";
  }

  getDisplayDescription(): string {
    return "Mine for iron.";
  }

  getBaseActionEffects(): Record<string, ActionEffect> {
    return {
      iron: { value: 0.1 },
    };
  }

  getDisplayEffect(state: PlayerContextState): string {
    const effects = this.getActionEffects(state);
    return `+${formatNumber(effects.iron, {
      showDecimals: true,
    })}:iron:`;
  }

  getCost(state: PlayerContextState): {
    resources: Partial<Record<Resource, number>>;
    items: Record<string, number>;
  } {
    return { resources: {}, items: {} };
  }

  doAction(args: DoActionArgs, state: PlayerContextState): PlayerContextState {
    return grantResource(
      Resource.Iron,
      this.getActionEffects(state).iron,
    )(state);
  }
}

const message = buildEventMessage("gather_iron_unlocked", "The Quarry")
  .setSimpleDescription(
    `Your researcher calls your attention. "There is a place you should see", she exclaims, walking outside the campus. "Follow me." You do as instructed.
    
You end up in what seems to be a fairly rocky cliff. However, you notice the rock in a wall shining a different color. "That's iron," your researcher says. "This is a sturdy metal. Not too useful for casting magic, but it could be great for buildings... or..."

You agree with her. Learning about this place for the first time, you thank the researcher. You'll be sure to come back prepared next time - this could be useful!

**You unlocked the ability to gather iron!**`,
  )
  .build();

const event = createGameEvent(
  "gather_iron_unlocked",
  message,
  setFlag("gather_iron_unlocked"),
);

addContextListener("gather_iron_unlocked", (oldState, newState) => {
  if (
    !hasFlag(newState, "gather_iron_unlocked") &&
    hasFlag(newState, "apprentice_dorm_event_triggered") &&
    getTotalElementAndSchoolExp(newState) >= 5000
  ) {
    return triggerEvent(event);
  }
});

export default new GatherIron();
