import mem from "mem";
import { formatNumber } from "../../../utils/FormattingUtils";
import {
  registerTransformation,
  TransformationType,
} from "../../calculation/Calculation";
import { TransformationTags } from "../../calculation/TransformationTags";
import { getExplorationStatus } from "../../exploration/Exploration";
import { ExplorationStatus } from "../../exploration/ExplorationStatus";
import { PlayerContextState } from "../../PlayerContext";
import { hasTemporaryEffect } from "../../timetick/TemporaryEffects";
import { getSecondsPlayed } from "../../timetick/TotalTimePlayed";
import { getAmountOfItem, removeFromInventory } from "../Inventory";
import { ItemAction, ItemBase, ItemParams, ItemTag } from "../Item";
import { getItemById } from "../Items";

const memoizedPrimary = mem(
  (
    cooldownLeft: number,
    numberInInventory: number,
    isStunned: boolean,
    id: string,
    params: ItemParams,
  ) => {
    const enabled = cooldownLeft <= 0 && numberInInventory > 0 && !isStunned;
    return {
      description: enabled
        ? "Use"
        : `Use (${formatNumber(cooldownLeft, { showDecimals: true })} sec)`,
      isEnabled: enabled,
      transform: (state: PlayerContextState) => {
        state = removeFromInventory({ itemId: id, params }, 1, state);
        state.lastItemUsedTimes[id] = getSecondsPlayed(state);
        state = (getItemById(id) as ConsumableItem).doConsumableItemEffect(
          state,
          params,
        );
        return state;
      },
    };
  },
  { cacheKey: JSON.stringify },
);

export abstract class ConsumableItem extends ItemBase {
  abstract doConsumableItemEffect(
    state: PlayerContextState,
    params: ItemParams,
  ): PlayerContextState;

  getTags(state: PlayerContextState, params: ItemParams): ItemTag[] {
    return [{ tag: "Consumable" }];
  }

  getCooldownSec(state: PlayerContextState): number {
    return 10.0;
  }

  getCooldownLeftSec(state: PlayerContextState): number {
    const lastUsedTime = state.lastItemUsedTimes[this.getId()] || 0;
    const cooldownLeft =
      lastUsedTime + this.getCooldownSec(state) - getSecondsPlayed(state);
    const enabled =
      getExplorationStatus(state) != ExplorationStatus.Combat ||
      cooldownLeft <= 0;
    return enabled ? 0 : cooldownLeft;
  }

  getPrimaryAction(
    state: PlayerContextState,
    params: ItemParams,
  ): ItemAction | undefined {
    const cooldownLeft = this.getCooldownLeftSec(state);
    const numberInInventory = getAmountOfItem(
      { itemId: this.getId(), params },
      state,
    );
    const isStunned = hasTemporaryEffect(state, "PlayerStun");
    return memoizedPrimary(
      cooldownLeft,
      numberInInventory,
      isStunned,
      this.getId(),
      params,
    );
  }
}

registerTransformation(
  [["Consumable", TransformationTags.ItemEffect]],
  "ConsumableItemQuality",
  TransformationType.Multiplier,
  (state, params) => {
    const quality = params?.params?.itemQuality || 0;
    return Math.pow(1.02, quality);
  },
);
