import {
  registerTransformation,
  TransformationType,
} from "../../calculation/Calculation";
import { TransformationTags } from "../../calculation/TransformationTags";
import { PlayerContextState } from "../../PlayerContext";
import { Upgrade } from "../Upgrade";
import largePouch from "./LargePouch";

class EnchantedPouch extends Upgrade {
  getName(): string {
    return "Enchanted Pouch";
  }

  getMonstiumCost(): number {
    return 2000;
  }

  getDisplayDescription(state: PlayerContextState): string {
    return "Further increases the size of the pouch you use to store items, allowing you to store even more items.";
  }

  getDisplayEffect(state: PlayerContextState): string {
    return "+4 Inventory slots";
  }

  getUpgradeRequirements() {
    return [largePouch];
  }
}

const enchantedPouch = new EnchantedPouch();

registerTransformation(
  [[TransformationTags.InventorySlot]],
  "EnchantedPouch",
  TransformationType.Addition,
  (state) => (enchantedPouch.isPurchased(state) ? 4 : 0),
);

export default enchantedPouch;
