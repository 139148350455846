import { Picker } from "@react-native-picker/picker";
import React, { useContext, useMemo, useRef, useState } from "react";
import { StyleSheet, View } from "react-native";
import { getCraftableItems } from "../../backend/items/Items";
import { PlayerContext } from "../../backend/PlayerContext";
import { addContextListener } from "../../backend/PlayerContextListeners";
import { CraftingDetails } from "./CraftingDetails";
import { ItemGrid } from "./ItemGrid";

export default function Crafting() {
  const playerContext = useContext(PlayerContext);
  const [selectedIndex, setSelectedIndex] = useState(null as number | null);
  const [selectedTag, setSelectedTag] = useState("All");
  let craftableItems = getCraftableItems(playerContext);

  const selectedIndexRef = useRef(selectedIndex);
  selectedIndexRef.current = selectedIndex;

  addContextListener("CraftingComponent", (oldState, newState) => {
    const oldCraftable = getCraftableItems(oldState);
    const newCraftable = getCraftableItems(newState);
    const oldSelectedItem =
      selectedIndexRef.current != null
        ? oldCraftable[selectedIndexRef.current]
        : undefined;
    const newSelectedItem =
      selectedIndexRef.current != null
        ? newCraftable[selectedIndexRef.current]
        : undefined;

    if (
      newSelectedItem === undefined ||
      oldSelectedItem?.getId() !== newSelectedItem?.getId()
    ) {
      setSelectedIndex(null);
    }
  });

  const onPressItem = React.useCallback(
    (item: any, index: number) => {
      setSelectedIndex(index);
    },
    [setSelectedIndex],
  );

  const tagSet = new Set<string>();
  craftableItems.forEach((item) => {
    const tags = item.getTags(playerContext, item.getDefaultParams());
    tags.forEach((tag) => tagSet.add(tag.tag));
  });
  const tags = Array.from(tagSet);

  const tagButtons = useMemo(
    () => (
      <Picker
        onValueChange={setSelectedTag}
        style={styles.picker}
        selectedValue={selectedTag}
      >
        <Picker.Item label="All" value="All" key="All" />
        {tags.map((tag, idx) => (
          <Picker.Item label={tag} value={tag} key={tag} />
        ))}
      </Picker>
    ),
    [setSelectedIndex, setSelectedTag, selectedTag, JSON.stringify(tags)],
  );

  if (selectedTag !== "All") {
    craftableItems = craftableItems
      .filter((item) =>
        item
          .getTags(playerContext, item.getDefaultParams())
          .find((tag) => tag.tag == selectedTag),
      )
      .sort((a, b) => {
        const maxA = Math.max(
          ...(Object.values(
            a.getCraftingSchoolLevelRequirements(playerContext),
          ).filter((n) => n != undefined) as number[]),
        );
        const maxB = Math.max(
          ...(Object.values(
            b.getCraftingSchoolLevelRequirements(playerContext),
          ).filter((n) => n != undefined) as number[]),
        );
        return maxA - maxB;
      });
  }

  const selectedItem =
    selectedIndex != null ? craftableItems[selectedIndex] : undefined;

  const itemGridElements = craftableItems.map((item) => ({
    itemId: item.getId(),
    amount: 1,
  }));

  const itemGrid = useMemo(
    () => <ItemGrid items={itemGridElements} onPressItem={onPressItem} />,
    [JSON.stringify(itemGridElements), onPressItem],
  );

  const details = useMemo(
    () => (
      <View style={styles.detailsContainer}>
        {selectedItem && <CraftingDetails item={selectedItem} />}
      </View>
    ),
    [selectedItem?.getId()],
  );

  return (
    <View style={styles.container}>
      <View style={styles.tagPickerContainer}>{tagButtons}</View>
      {itemGrid}
      {details}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "stretch",
    justifyContent: "flex-start",
    width: "100%",
  },
  detailsContainer: {
    marginTop: 16,
  },
  buttonGroupContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
    height: undefined,
    flexWrap: "wrap",
    justifyContent: "flex-start",
    borderWidth: 0,
  },
  buttonGroupButtonContainer: {
    flex: 1,
    height: 40,
    borderWidth: 1,
    borderColor: "#eee",
    margin: 4,
  },
  picker: {
    flex: 1,
  },
  tagPickerContainer: {
    width: 200,
    alignSelf: "flex-start",
    marginBottom: 8,
    backgroundColor: "#eee",
    borderRadius: 10,
  },
});
