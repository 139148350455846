import {
  registerTransformation,
  TransformationType,
} from "../../calculation/Calculation";
import { TransformationTags } from "../../calculation/TransformationTags";
import {
  hasFlag,
  hasGlobalFlag,
  hasGlobalFlagIncludingNextRun,
  setFlag,
  setGlobalFlagForNextRun,
} from "../../Flags";
import { getResourceAmount, grantResource, Resource } from "../../Resources";
import TimeDilation from "../../spells/illusion/TimeDilation";
import { Storyline, Storylines } from "../../storylines/Storylines";
import { registerRandomEventTrigger } from "../autorandom/RandomEventTrigger";
import { buildEvent } from "../EventMessageBuilder";

const timeRitualEventBuilder = buildEvent(
  "time_ritual",
  "(Storyline) The Time Ritual",
);

timeRitualEventBuilder
  .setOnTrigger(setFlag("time_ritual_triggered"))
  .createMessage()
  .setSimpleDescription(
    `As you look at the :timepiece:Time Pieces you have accumulated, your researcher and apprentice surprise you, holding a large scroll.
  
"Those t-time pieces... they are the same, aren't they." The apprentice is looking at the scroll, pointing at a drawing of something on it.`,
  )
  .addOption(
    "Continue",
    timeRitualEventBuilder
      .createMessage()
      .setSimpleDescription(
        `"Headmaster, we have found instructions for a strange ritual," says your researcher, looking at you intently. "With enough mana and time pieces, it should be possible to bend time to our will."

The prospect is tempting. You lean over and look at the scroll - and you see there is not just one set of instructions, but three.

You glance at your apprentice. You are going to need help with the ritual.`,
      )
      .addOption(
        "Do the ritual to hoard more time (2500 Mana, 25K Time Pieces)",
        {
          transform: (state) => {
            state = grantResource(Resource.Mana, -2500)(state);
            state = grantResource(Resource.TimePieces, -25000)(state);
            return state;
          },
          isEnabled: (state) =>
            getResourceAmount(state, Resource.Mana) >= 2500 &&
            getResourceAmount(state, Resource.TimePieces) >= 25000,
          isVisible: (state) =>
            !hasGlobalFlagIncludingNextRun(state, "hoard_time_ritual_finished"),
        },
        timeRitualEventBuilder
          .createMessage()
          .setSimpleDescription(
            `You move to a large room while carrying a lot of Time Pieces, together with your apprentice and researcher. You place the Time Pieces in the middle of the room, and together with your apprentice, you start chanting.

Images of an empty room fill your vision. Then a time piece appears and falls into the room. Then another one. Then two more. Then ten more. And more, and more. So many time pieces. They pile up in the center, reach the ceiling, but they continue to grow until the walls of the room. And they keep coming. You are trapped in between time pieces, and there is no escape. You feel your consciousness fading...`,
          )
          .addOption(
            "Continue",
            {
              transform: setGlobalFlagForNextRun("hoard_time_ritual_finished"),
            },
            timeRitualEventBuilder.createMessage()
              .setSimpleDescription(`When you come to, you are where you started, looking at your Time Pieces. Except there are no time pieces. You feel odd. You hear the researcher calling for you from behind, and turn around to see her and the apprentice calling you for supper, as if nothing had happened.

Did the ritual work? You aren't sure, but something does seem different...

**You have completed the "The Time Ritual" storyline! In future runs, you will be able to store 70K more :timepiece:Time Pieces!**`),
          ),
      )
      .addOption(
        "Do the ritual to make time pass faster (3500 Mana, 25K Time Pieces)",
        {
          transform: (state) => {
            state = grantResource(Resource.Mana, -3500)(state);
            state = grantResource(Resource.TimePieces, -25000)(state);
            return state;
          },
          isEnabled: (state) =>
            getResourceAmount(state, Resource.Mana) >= 3500 &&
            getResourceAmount(state, Resource.TimePieces) >= 25000,
          isVisible: (state) =>
            !hasGlobalFlagIncludingNextRun(
              state,
              "speed_up_time_ritual_finished",
            ),
        },
        timeRitualEventBuilder
          .createMessage()
          .setSimpleDescription(
            `You move to a large room while carrying a lot of Time Pieces, together with your apprentice and researcher. You place the Time Pieces in the middle of the room, and together with your apprentice, you start chanting.

Images of all the time you have spent at the campus flash before your eyes. They seem unnaturally sped up. They get more vivid, until you can no longer see anything else except them. They continue getting faster and faster and you start feeling overwhelmed. You feel your consciousness fading...`,
          )
          .addOption(
            "Continue",
            {
              transform: setGlobalFlagForNextRun(
                "speed_up_time_ritual_finished",
              ),
            },
            timeRitualEventBuilder.createMessage()
              .setSimpleDescription(`When you come to, you are where you started, looking at your Time Pieces. Except there are no time pieces. You feel odd. You hear the researcher calling for you from behind, and turn around to see her and the apprentice calling you for supper, as if nothing had happened.

Did the ritual work? You aren't sure, but something does seem different...

**You have completed the "The Time Ritual" storyline! In future runs, consuming :timepiece:Time Pieces will speed up the time by four times instead of by two times!**`),
          ),
      )
      .addOption(
        "Do the ritual to make time pass slower (4500 Mana, 25K Time Pieces)",
        {
          transform: (state) => {
            state = grantResource(Resource.Mana, -4500)(state);
            state = grantResource(Resource.TimePieces, -25000)(state);
            return state;
          },
          isEnabled: (state) =>
            getResourceAmount(state, Resource.Mana) >= 4500 &&
            getResourceAmount(state, Resource.TimePieces) >= 25000,
          isVisible: (state) =>
            TimeDilation.isVisible(state) &&
            !hasGlobalFlagIncludingNextRun(
              state,
              "time_dilation_time_ritual_finished",
            ),
        },
        timeRitualEventBuilder
          .createMessage()
          .setSimpleDescription(
            `You move to a large room while carrying a lot of Time Pieces, together with your apprentice and researcher. You place the Time Pieces in the middle of the room, and together with your apprentice, you start chanting.

Images of all the time you have spent at the campus flash before your eyes. Or at least, that's what it seems, because movement seems to be in comically slow motion. You stare at your researcher in those images, who is introducing herself when you first met her, but her mouth moves ever so slow, and it becomes slower and slower. Unbearably slow. You feel your consciousness fading...`,
          )
          .addOption(
            "Continue",
            {
              transform: setGlobalFlagForNextRun(
                "time_dilation_time_ritual_finished",
              ),
            },
            timeRitualEventBuilder.createMessage()
              .setSimpleDescription(`When you come to, you are where you started, looking at your Time Pieces. Except there are no time pieces. You feel odd. You hear the researcher calling for you from behind, and turn around to see her and the apprentice calling you for supper, as if nothing had happened.

Did the ritual work? You aren't sure, but something does seem different...

**You have completed the "The Time Ritual" storyline! In future runs, the "Time Dilation" spell will cost 0:mana:!**`),
          ),
      )
      .addOption(
        "Do the ritual to convert into more research (5500 Mana, 25K Time Pieces)",
        {
          transform: (state) => {
            state = grantResource(Resource.Mana, -5500)(state);
            state = grantResource(Resource.TimePieces, -25000)(state);
            return state;
          },
          isEnabled: (state) =>
            getResourceAmount(state, Resource.Mana) >= 5500 &&
            getResourceAmount(state, Resource.TimePieces) >= 25000,
          isVisible: (state) =>
            !hasGlobalFlagIncludingNextRun(
              state,
              "research_time_ritual_finished",
            ),
        },
        timeRitualEventBuilder
          .createMessage()
          .setSimpleDescription(
            `You move to a large room while carrying a lot of Time Pieces, together with your apprentice and researcher. You place the Time Pieces in the middle of the room, and together with your apprentice, you start chanting.

A room full of books. You spot your researcher. She seems to be frantically doing a lot of work. Faster and faster. The books pile up in front of her as she moves and reads in impossible speeds. It continues to get faster and the books continue to pile up until you can no longer see. You feel your consciousness fading...`,
          )
          .addOption(
            "Continue",
            {
              transform: setGlobalFlagForNextRun(
                "research_time_ritual_finished",
              ),
            },
            timeRitualEventBuilder.createMessage()
              .setSimpleDescription(`When you come to, you are where you started, looking at your Time Pieces. Except there are no time pieces. You feel odd. You hear the researcher calling for you from behind, and turn around to see her and the apprentice calling you for supper, as if nothing had happened.

Did the ritual work? You aren't sure, but something does seem different...

**You have completed the "The Time Ritual" storyline! In future runs, the "Use Time Pieces" button under "Research" will give 2.5x more experience!**`),
          ),
      ),
  );

Storylines.register(
  new Storyline(
    "The Time Ritual (Hoard Time)",
    (state) =>
      hasGlobalFlagIncludingNextRun(state, "hoard_time_ritual_finished"),
    (state) => hasGlobalFlag(state, "hoard_time_ritual_finished"),
    "There's a chance of this happening randomly if you have unlocked Research, Apprentices, and you have 10K:timepiece:...",
    "+70K Max:timepiece:",
    (state) => true,
  ),
);
Storylines.register(
  new Storyline(
    "The Time Ritual (Speed Up)",
    (state) =>
      hasGlobalFlagIncludingNextRun(state, "speed_up_time_ritual_finished"),
    (state) => hasGlobalFlag(state, "speed_up_time_ritual_finished"),
    "There's a chance of this happening randomly if you have unlocked Research, Apprentices, and you have 10K:timepiece:...",
    "2x Time Piece speedup",
    (state) => true,
  ),
);
Storylines.register(
  new Storyline(
    "The Time Ritual (Slow Down)",
    (state) =>
      hasGlobalFlagIncludingNextRun(
        state,
        "time_dilation_time_ritual_finished",
      ),
    (state) => hasGlobalFlag(state, "time_dilation_time_ritual_finished"),
    "There's a chance of this happening randomly if you have unlocked Research, Apprentices, and you have 10K:timepiece:...",
    '"Time Dilation" spell costs 0:mana:',
    (state) => true,
  ),
);
Storylines.register(
  new Storyline(
    "The Time Ritual (Research)",
    (state) =>
      hasGlobalFlagIncludingNextRun(state, "research_time_ritual_finished"),
    (state) => hasGlobalFlag(state, "research_time_ritual_finished"),
    "There's a chance of this happening randomly if you have unlocked Research, Apprentices, and you have 10K:timepiece:...",
    '"Use Time Pieces" inside "Research" gives 2.5x Exp',
    (state) => true,
  ),
);

registerTransformation(
  [[Resource.TimePieces, TransformationTags.Cap]],
  "TimeRitualCap",
  TransformationType.Addition,
  (state) => (hasGlobalFlag(state, "hoard_time_ritual_finished") ? 70000 : 0),
);

registerTransformation(
  [[TransformationTags.GameSpeed]],
  "TimeRitualGameSpeed",
  TransformationType.Multiplier,
  (state) =>
    hasGlobalFlag(state, "speed_up_time_ritual_finished") &&
    hasFlag(state, "time_piece_speedup_enabled")
      ? 2
      : 1,
);

registerTransformation(
  [[TimeDilation.getName(), TransformationTags.Cost]],
  "TimeRitualDilationCost",
  TransformationType.Multiplier,
  (state) =>
    hasGlobalFlag(state, "time_dilation_time_ritual_finished") ? 0 : 1.0,
);

registerTransformation(
  [[TransformationTags.TimePiecePerResearch]],
  "TimeRitualResearchCost",
  TransformationType.Multiplier,
  (state) =>
    hasGlobalFlag(state, "research_time_ritual_finished") ? 0.5 : 1.0,
);

const timeRitualEvent = timeRitualEventBuilder.build();

registerRandomEventTrigger(
  timeRitualEvent,
  15,
  (state) =>
    !hasFlag(state, "time_ritual_triggered") &&
    getResourceAmount(state, Resource.TimePieces) > 10000 &&
    hasFlag(state, "research_unlocked") &&
    hasFlag(state, "autocast_unlocked") &&
    (!hasGlobalFlag(state, "hoard_time_ritual_finished") ||
      !hasGlobalFlag(state, "speed_up_time_ritual_finished") ||
      !hasGlobalFlag(state, "research_time_ritual_finished") ||
      (!hasGlobalFlag(state, "time_dilation_time_ritual_finished") &&
        TimeDilation.isVisible(state))),
);

export default timeRitualEvent;
