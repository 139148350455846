import React, { useContext, useMemo } from "react";
import { StyleSheet, View } from "react-native";
import ProgressBar from "react-native-progress/Bar";
import {
  getExplorationStatus,
  getPlayerActionProgress,
} from "../../backend/exploration/Exploration";
import { ExplorationStatus } from "../../backend/exploration/ExplorationStatus";
import { PlayerContext } from "../../backend/PlayerContext";
import { GameIcon } from "../utility/icons/GameIcon";

export function PlayerActionDisplay() {
  const playerContext = useContext(PlayerContext);
  const inCombat =
    getExplorationStatus(playerContext) == ExplorationStatus.Combat;
  let progress = 0;
  if (inCombat) {
    progress = getPlayerActionProgress(playerContext);
  }
  return useMemo(
    () => (
      <View style={styles.row}>
        <GameIcon icon="attack" style={styles.icon} />
        <ProgressBar progress={progress} useNativeDriver={true} width={120} />
      </View>
    ),
    [progress],
  );
}

const styles = StyleSheet.create({
  icon: {
    marginRight: 4,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
});
