import { PlayerContextState } from "../../../PlayerContext";
import { EquipmentSlot } from "../../Equipment";
import { ItemParams } from "../../Item";
import { EquippableItem } from "../EquippableItem";

class Spear extends EquippableItem {
  getId(): string {
    return "spear";
  }

  getPicture() {
    return require("./spear.png");
  }

  getBaseName(): string {
    return "Spear";
  }

  getDescription(): string | undefined {
    return "A sharp, dangerous weapon for attacking at a distance.";
  }

  getAttackBonus(state: PlayerContextState, params: ItemParams): number {
    return 30;
  }

  getAttackDelayBonusPct(
    state: PlayerContextState,
    params: ItemParams,
  ): number {
    return -15;
  }

  getSlot(): EquipmentSlot {
    return EquipmentSlot.Hand;
  }

  getSalePrice(): number {
    return 1200;
  }

  isCraftable(state: PlayerContextState): boolean {
    return super.isCraftable(state) && state.primaryElement == "Air";
  }

  getCraftingSchoolLevelRequirements() {
    return { Conjuration: 5 };
  }

  getCraftingMaterialsBase() {
    return {
      resources: {
        Iron: 80,
        Wood: 500,
        Temperature: 500,
      },
      items: {},
    };
  }
}

export default new Spear();
