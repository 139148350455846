import { ItemBase } from "../Item";

class BatWing extends ItemBase {
  getId(): string {
    return "batWing";
  }

  getPicture() {
    return require("./batWing.png");
  }

  getBaseName(): string {
    return "Bat Wing";
  }

  getTags() {
    return [{ tag: "Material" }];
  }

  getDescription(): string | undefined {
    return "The clipped wing of a bat that you felled.";
  }

  getEffect(): string | undefined {
    return undefined;
  }

  getSalePrice(): number {
    return 36;
  }

  getCraftingSchoolLevelRequirements() {
    return {};
  }

  getCraftingMaterialsBase() {
    return {
      resources: {},
      items: {},
    };
  }
}

export default new BatWing();
