import { getBuildingAmount } from "../buildings/Buildings";
import apprenticeDorm from "../buildings/campus/ApprenticeDorm";
import manaSpout from "../buildings/campus/ManaSpout";
import { hasFlag } from "../Flags";
import { getResourceAmount, Resource } from "../Resources";
import CreateStone from "../spells/conjuration/CreateStone";
import CreateWater from "../spells/conjuration/CreateWater";
import {
  getSchoolLevel,
  getTotalElementAndSchoolExp,
  School,
} from "../spells/ElementsAndSchools";
import { clearAllHints, pushHint } from "./Hints";

export function loadHintsDemo() {
  clearAllHints();
  pushHint({
    description: "Gather more Mana",
    isCompleted: (state) =>
      CreateStone.isVisible(state) && CreateWater.isVisible(state),
    progress: (state) => getResourceAmount(state, Resource.Mana) / 20.0,
  });
  pushHint({
    description: "Create more Stone and Water",
    isCompleted: (state) => manaSpout.isVisible(state),
  });
  pushHint({
    description: "Gather more Mana",
    isCompleted: (state) => hasFlag(state, "has_gathered_at_least_50_mana"),
    progress: (state) => getResourceAmount(state, Resource.Mana) / 50.0,
  });
  pushHint({
    description: "Build a Mana Shard",
    isCompleted: (state) => hasFlag(state, "Research_Intro_Triggered"),
  });
  pushHint({
    description: "Hire the Researcher",
    isCompleted: (state) => hasFlag(state, "research_unlocked"),
  });
  pushHint({
    description: "Continue doing research",
    isCompleted: (state) => hasFlag(state, "apprentice_dorm_event_triggered"),
    progress: (state) => getTotalElementAndSchoolExp(state) / 3000.0,
  });
  pushHint({
    description: 'Finish the "Apprentices Need Sleep" event',
    isCompleted: (state) => hasFlag(state, "apprentice_dorm_unlocked"),
  });
  pushHint({
    description: "Build an Apprentice Dorm",
    isCompleted: (state) => getBuildingAmount(state, apprenticeDorm) >= 1,
  });
  pushHint({
    description: "Gather 800 coins",
    isCompleted: (state) => hasFlag(state, "vault_unlocked"),
    progress: (state) => getResourceAmount(state, Resource.Coins) / 800.0,
  });
  pushHint({
    description: "Continue doing research",
    isCompleted: (state) =>
      hasFlag(state, "crafting_intro") || hasFlag(state, "inventory_unlocked"),
    progress: (state) => getTotalElementAndSchoolExp(state) / 10000.0,
  });
  pushHint({
    description: "Build the crafting station",
    isCompleted: (state) => hasFlag(state, "inventory_unlocked"),
  });
  pushHint({
    description: "Craft an item",
    isCompleted: (state) => hasFlag(state, "has_crafted"),
  });
  pushHint({
    description: "Continue doing research",
    isCompleted: (state) => hasFlag(state, "shop_intro"),
    progress: (state) => getTotalElementAndSchoolExp(state) / 16000.0,
  });
  pushHint({
    description: "Raise Conjuration and Enchantment to Lv3 or above",
    isCompleted: (state) =>
      getSchoolLevel(state, School.Conjuration) >= 3 &&
      getSchoolLevel(state, School.Enchantment) >= 3,
  });
  pushHint({
    description: "Appease the businessman",
    isCompleted: (state) => hasFlag(state, "selling_unlocked"),
  });
  pushHint({
    description: "Continue doing research",
    isCompleted: (state) => hasFlag(state, "wizard_robe_triggered"),
    progress: (state) => getTotalElementAndSchoolExp(state) / 24000.0,
  });
  pushHint({
    description: "Purchase the Wizard's Robe",
    isCompleted: (state) => hasFlag(state, "wizard_robe_bought"),
  });
  pushHint({
    description: "Continue doing research",
    isCompleted: (state) => getTotalElementAndSchoolExp(state) >= 33000,
    progress: (state) => getTotalElementAndSchoolExp(state) / 33000.0,
  });
  pushHint({
    description: "Equip a hand item and a body item",
    isCompleted: (state) => hasFlag(state, "exploration_unlocked"),
  });
  pushHint({
    description: "Explore the School Basement",
    isCompleted: (state) => hasFlag(state, "upgrades_unlocked"),
  });
  pushHint({
    description:
      "You have completed the demo! Please purchase the full game to continue, or retire to start again",
    isCompleted: (state) => false,
  });
}
