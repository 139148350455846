import { formatNumber } from "../../../utils/FormattingUtils";
import { ActionEffect } from "../../actions/Action";
import { TransformationTags } from "../../calculation/TransformationTags";
import { AttackTarget } from "../../exploration/AttackTarget";
import { modifyPlayerCurrentHP } from "../../exploration/Exploration";
import { PlayerContextState } from "../../PlayerContext";
import { getSecondsPlayed } from "../../timetick/TotalTimePlayed";
import { ItemParams } from "../Item";
import { ConsumableItem } from "./ConsumableItem";

class MedicinalHerb extends ConsumableItem {
  doConsumableItemEffect(
    state: PlayerContextState,
    params: ItemParams,
  ): PlayerContextState {
    const healAmt = this.getItemEffects(state, params).magnitude;
    state = modifyPlayerCurrentHP(state, healAmt);
    const actionResult = {
      id: Math.random().toString(),
      damage: -healAmt,
      isCrit: false,
      isHit: true,
      time: getSecondsPlayed(state),
      target: AttackTarget.Player,
      tags: [],
    };
    state.exploration.lastEnemyActionResult = actionResult;
    state.exploration.actionResultQueue.push(actionResult);
    return state;
  }

  protected getBaseItemEffects(): Record<string, ActionEffect> {
    return {
      magnitude: {
        value: 20,
        tags: [TransformationTags.ActionMagnitude],
      },
    };
  }

  getId(): string {
    return "medicinalHerb";
  }

  getPicture() {
    return require("./medicinalHerb.png");
  }

  getBaseName(): string {
    return "Medicinal Herb";
  }

  getDescription(): string | undefined {
    return "A bundle of herbs. Restores a little :hp:.";
  }

  getEffect(state: PlayerContextState, params: ItemParams): string | undefined {
    const effects = this.getItemEffects(state, params);
    return `+${formatNumber(effects.magnitude)}:hp:`;
  }

  getTags(state: PlayerContextState, params: Record<string, any>) {
    return super.getTags(state, params).concat([{ tag: "Material" }]);
  }

  getSalePrice(): number {
    return 25;
  }

  getCraftingSchoolLevelRequirements() {
    return {};
  }

  getCraftingMaterialsBase() {
    return {
      resources: {},
      items: {},
    };
  }
}

export default new MedicinalHerb();
