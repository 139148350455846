import { clone } from "../../utils/CoreUtils";
import { PlayerContextState, PlayerContextTransform } from "../PlayerContext";
import { getSecondsPlayed } from "../timetick/TotalTimePlayed";
import {
  getSchoolLevel,
  getTotalExpRequiredForLevel,
  getUnlockedSchools,
  School,
} from "./ElementsAndSchools";
import { Spell } from "./Spell";

const spells: Record<string, Spell> = {};

export function registerSpell(spell: Spell) {
  spells[spell.getSpellName()] = spell;
}

export function getSpellByName(name: string): Spell {
  return spells?.[name] as Spell;
}

export function getVisibleSpellsBySchool(
  state: PlayerContextState,
): Record<string, Spell[]> {
  const spellsBySchool: Record<string, Spell[]> = Object.fromEntries(
    Object.values(School).map((school) => [school, []]),
  );
  for (let spellName in spells) {
    const spell = spells[spellName];
    if (spell.isVisible(state)) {
      const schoolRequirements = spell.getSchoolLevelRequirements();
      for (let school in schoolRequirements) {
        if (spellsBySchool?.[school] === undefined) {
          spellsBySchool[school] = [];
        }
        spellsBySchool[school].push(spell);
      }
    }
  }
  for (let school in spellsBySchool) {
    if (spellsBySchool[school].length === 0) {
      delete spellsBySchool[school];
    }
  }
  return spellsBySchool;
}

export function getVisibleSpellsForSchool(
  state: PlayerContextState,
  school: School,
  extraLevels?: number,
) {
  if (!extraLevels) {
    return getVisibleSpellsBySchool(state)?.[school] || [];
  }
  const fakeState = clone(state);
  fakeState.schoolExperience[school] = getTotalExpRequiredForLevel(
    fakeState,
    getSchoolLevel(state, school) + extraLevels,
    school,
  );
  return getVisibleSpellsBySchool(fakeState)?.[school] || [];
}

export function getSpellsForSchoolThatCanBeLearnedSoon(
  state: PlayerContextState,
  school: School,
) {
  const currentSpells = getVisibleSpellsForSchool(state, school);
  const otherUnlockedSchools = getUnlockedSchools(state).filter(
    (unlockedSchool) => unlockedSchool != school,
  );

  let learnedSoon: Spell[] = [];

  for (let schoolIdx in otherUnlockedSchools) {
    const otherSchool = otherUnlockedSchools[schoolIdx];
    const fakeState = clone(state);
    fakeState.schoolExperience[otherSchool] = getTotalExpRequiredForLevel(
      fakeState,
      getSchoolLevel(state, otherSchool) + 1,
      otherSchool,
    );
    const soonVisibleSpells = getVisibleSpellsForSchool(fakeState, school);
    learnedSoon = learnedSoon.concat(
      soonVisibleSpells.filter((spell) => !currentSpells.includes(spell)),
    );
  }

  return learnedSoon;
}

export function getRecentSpellsCast(state: PlayerContextState): Spell[] {
  return (state?.recentSpellsCast || [])
    .map(getSpellByName)
    .filter((spell) => spell != null);
}

function addRecentSpellCastImpl(spell: Spell, state: PlayerContextState) {
  if (!state?.recentSpellsCast) {
    state.recentSpellsCast = [];
  }
  const spellName = spell.getSpellName();
  state.recentSpellsCast = state.recentSpellsCast.filter(
    (recentSpellName) => recentSpellName != spellName,
  );
  state.recentSpellsCast.unshift(spell.getSpellName());
  state.recentSpellsCast = state.recentSpellsCast.slice(0, 3);
  return state;
}

export function addRecentSpellCast(
  this: any,
  spell: Spell,
): PlayerContextTransform {
  // Do it this way to avoid creating extra functions and avoid rerenders
  return addRecentSpellCastImpl.bind(this, spell);
}

function setLastCastedTimeImpl(spell: Spell, state: PlayerContextState) {
  if (!state?.lastSpellCastTimes) {
    state.lastSpellCastTimes = {};
  }
  const spellName = spell.getSpellName();
  state.lastSpellCastTimes[spellName] = getSecondsPlayed(state);
  return state;
}

export function setLastCastedTime(
  this: any,
  spell: Spell,
): PlayerContextTransform {
  // Do it this way to avoid creating extra functions and avoid rerenders
  return setLastCastedTimeImpl.bind(this, spell);
}

export function getFavoriteSpells(state: PlayerContextState): Spell[] {
  return (
    state?.favoriteSpells?.map((spellName) => getSpellByName(spellName)) ?? []
  );
}

export function addFavoriteSpell(
  state: PlayerContextState,
  spell: Spell,
): PlayerContextState {
  if (isInFavoriteSpells(state, spell)) {
    return state;
  }
  state.favoriteSpells.push(spell.getName());
  return state;
}

export function removeFromFavoriteSpells(
  state: PlayerContextState,
  spell: Spell,
): PlayerContextState {
  const index = state.favoriteSpells.findIndex(
    (spellName) => spell.getSpellName() === spellName,
  );
  if (index < 0) {
    return state;
  }
  state.favoriteSpells.splice(index, 1);
  return state;
}

export function isInFavoriteSpells(
  state: PlayerContextState,
  spell: Spell,
): boolean {
  return (
    state.favoriteSpells.findIndex(
      (spellName) => spell.getSpellName() === spellName,
    ) >= 0
  );
}
