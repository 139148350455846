import { PlayerContextState, PlayerContextTransform } from "../PlayerContext";
import { getSecondsPlayed } from "./TotalTimePlayed";

const TEMPORARY_EFFECT_DELTA = 1e10;
const CLEAR_EPSILON = TEMPORARY_EFFECT_DELTA / 2;

export type TemporaryEffectData = {
  id: string;
  name: string;
  endTime: number;
  isBeneficial: boolean;
  combatOnly: boolean;
  isEnemyTemporaryEffect: boolean;
  params: Record<string, any>;
};

export function getTemporaryEffectEndTime(
  state: PlayerContextState,
  id: string,
): number {
  return (state.temporaryEffects?.[id] as TemporaryEffectData)?.endTime || 0;
}

export function wasTemporaryEffectCleared(
  state: PlayerContextState,
  id: string,
): boolean {
  const endTime = getTemporaryEffectEndTime(state, id);
  return (
    endTime < getSecondsPlayed(state) - TEMPORARY_EFFECT_DELTA + CLEAR_EPSILON
  );
}

export function hasTemporaryEffect(
  state: PlayerContextState,
  id: string,
): boolean {
  return getTemporaryEffectEndTime(state, id) > getSecondsPlayed(state);
}

export function getTemporaryEffects(
  state: PlayerContextState,
): Array<TemporaryEffectData> {
  let temporaryEffects = [];
  for (let effectName in state.temporaryEffects) {
    if (hasTemporaryEffect(state, effectName)) {
      const temporaryEffect = state.temporaryEffects[effectName];
      if (typeof temporaryEffect === "number") {
        temporaryEffects.push({
          id: effectName,
          name: effectName,
          endTime: state.temporaryEffects[effectName] as number,
          isBeneficial: true,
          combatOnly: false,
          isEnemyTemporaryEffect: false,
          params: {},
        });
      } else {
        temporaryEffects.push(temporaryEffect);
      }
    }
  }
  return temporaryEffects;
}

function grantTemporaryEffectImpl(
  id: string,
  name: string,
  durationSec: number,
  extra: {
    mode?: "replace" | "add";
    isBeneficial?: boolean;
    combatOnly?: boolean;
    isEnemyTemporaryEffect?: boolean;
    params?: Record<string, any>;
  },
  state: PlayerContextState,
) {
  const mode = extra.mode || "replace";
  const isBeneficial = extra.isBeneficial ?? true;
  const combatOnly = extra.combatOnly ?? false;
  const isEnemyTemporaryEffect = extra.isEnemyTemporaryEffect ?? false;
  const params = extra.params ?? {};
  const currentEndTime = getTemporaryEffectEndTime(state, id);
  const newEndTime =
    mode === "add"
      ? Math.max(
          currentEndTime + durationSec,
          getSecondsPlayed(state) + durationSec,
        )
      : getSecondsPlayed(state) + durationSec;
  if (!state.temporaryEffects) {
    state.temporaryEffects = {};
  }
  state.temporaryEffects[id] = {
    id: id,
    name: name,
    endTime: newEndTime,
    isBeneficial: isBeneficial,
    combatOnly: combatOnly,
    isEnemyTemporaryEffect: isEnemyTemporaryEffect,
    params: params,
  };
  return state;
}

export function grantTemporaryEffect(
  this: any,
  id: string,
  name: string,
  durationSec: number,
  extra: {
    mode?: "replace" | "add";
    isBeneficial?: boolean;
    combatOnly?: boolean;
    isEnemyTemporaryEffect?: boolean;
    params?: any;
  },
): PlayerContextTransform {
  // Do it this way to avoid creating extra functions and avoid rerenders
  return grantTemporaryEffectImpl.bind(this, id, name, durationSec, extra);
}

export function clearTemporaryEffect(
  this: any,
  id: string,
): PlayerContextTransform {
  return grantTemporaryEffectImpl.bind(
    this,
    id,
    "",
    -TEMPORARY_EFFECT_DELTA,
    {},
  );
}

export function clearCombatTemporaryEffects(
  state: PlayerContextState,
): PlayerContextState {
  const effects = getTemporaryEffects(state);
  effects.forEach((effect) => {
    if (effect.combatOnly) {
      state = clearTemporaryEffect(effect.id)(state);
    }
  });
  return state;
}
