import React, { useContext, useMemo, useRef } from "react";
import { ActivityIndicator, StyleSheet, View } from "react-native";
import { Button, Divider, Icon, useTheme } from "react-native-elements";
import { DungeonFloor } from "../backend/exploration/dungeons/DungeonFloor";
import {
  getUnlockedDungeons,
  isDungeonFloorCompletedThisRun,
} from "../backend/exploration/dungeons/Dungeons";
import { getCurrentEnemy } from "../backend/exploration/enemies/Enemies";
import {
  endExploration,
  getCurrentDungeonFloor,
  getExplorationStatus,
  getSuccessfulExplorationsForFloor,
  startBossExploration,
  startExplorationOnFloor,
} from "../backend/exploration/Exploration";
import { ExplorationStatus } from "../backend/exploration/ExplorationStatus";
import { hasCurrentActiveSummon } from "../backend/exploration/Summoning";
import { getCurrentSummon } from "../backend/exploration/summons/Summons";
import { PlayerContext } from "../backend/PlayerContext";
import { EnemyActionDisplay } from "./exploration/EnemyActionDisplay";
import { EnemyHPBar } from "./exploration/EnemyHPBar";
import { EnemySpriteTile } from "./exploration/EnemySpriteTile";
import { ExplorationBottomComponent } from "./exploration/ExplorationBottomComponent";
import { ExplorationProgressDisplay } from "./exploration/ExplorationProgressDisplay";
import { PlayerActionDisplay } from "./exploration/PlayerActionDisplay";
import { PlayerHPBar } from "./exploration/PlayerHPBar";
import { PlayerMessageLog } from "./exploration/PlayerMessageLog";
import { SummonActionDisplay } from "./exploration/SummonActionDisplay";
import { SummonHPBar } from "./exploration/SummonHPBar";
import { SummonSpriteTile } from "./exploration/SummonSpriteTile";
import { TemporaryEffectsViewer } from "./utility/TemporaryEffectsViewer";
import { Text } from "./utility/Text";

function ExplorationNone() {
  const playerContext = useContext(PlayerContext);
  const allDungeons = getUnlockedDungeons(playerContext);
  return (
    <View style={styles.container}>
      <View style={styles.topRow}>
        <Text h2>Exploration</Text>
      </View>
      <Text>Select a destination.</Text>
      {allDungeons.map((dungeon) => {
        return (
          <Button
            key={dungeon.getId()}
            title={dungeon.getName()}
            containerStyle={styles.dungeonButton}
            onPress={() =>
              playerContext.apply((state) =>
                startExplorationOnFloor(state, dungeon.getDungeonFloors()[0]),
              )
            }
          />
        );
      })}
    </View>
  );
}

function ExplorationMain() {
  const playerContext = useContext(PlayerContext);
  const playerContextRef = useRef(playerContext);
  playerContextRef.current = playerContext;
  const explorationStatus = getExplorationStatus(playerContext);

  const dungeonFloor = getCurrentDungeonFloor(playerContext);
  const dungeonTitle = dungeonFloor.getFloorName();
  const currentEnemySprite =
    explorationStatus == ExplorationStatus.Combat &&
    getCurrentEnemy(playerContext).getPicture(playerContext);
  const toUnlockBoss =
    dungeonFloor.getExplorationSuccessesForBoss(playerContext) -
    getSuccessfulExplorationsForFloor(playerContext, dungeonFloor.getId());
  const currentEnemyName =
    explorationStatus == ExplorationStatus.Combat &&
    getCurrentEnemy(playerContext).getName();

  const checkmark =
    getSuccessfulExplorationsForFloor(playerContext, dungeonFloor.getId()) >=
    dungeonFloor.getExplorationSuccessesForBoss(playerContext);
  const canFightBoss = dungeonFloor.canFightBoss(playerContext);
  const canMoveToNextFloor = dungeonFloor
    .getNextFloor()
    ?.isUnlocked(playerContext);

  const explorationStatusContainer = useMemo(() => {
    let explorationStatusContainer = null;
    if (explorationStatus == ExplorationStatus.Combat) {
      explorationStatusContainer = (
        <View style={styles.explorationStatusContainer}>
          <Text h3>{currentEnemyName}</Text>
          <EnemyHPBar />
          <EnemyActionDisplay />
          <TemporaryEffectsViewer isEnemy={true} />
        </View>
      );
    } else if (
      explorationStatus == ExplorationStatus.Exploring ||
      explorationStatus == ExplorationStatus.ExploringBoss
    ) {
      explorationStatusContainer = (
        <View style={styles.explorationStatusContainer}>
          <Text h3>Exploring...</Text>
          <ExplorationProgressDisplay />
        </View>
      );
    } else if (explorationStatus == ExplorationStatus.Reviving) {
      explorationStatusContainer = (
        <View style={styles.explorationStatusContainer}>
          <Text h3>Recovering...</Text>
          <ActivityIndicator />
        </View>
      );
    }
    return explorationStatusContainer;
  }, [explorationStatus, currentEnemyName]);

  const hasActiveSummon = hasCurrentActiveSummon(playerContext);
  const currentSummonSprite =
    hasActiveSummon && getCurrentSummon(playerContext)?.getPicture();

  const isFloorCompleted = isDungeonFloorCompletedThisRun(
    playerContext,
    dungeonFloor,
  );
  const { theme } = useTheme();

  const backdropColor =
    explorationStatus == ExplorationStatus.Combat &&
    getCurrentEnemy(playerContext).getBackdropColor();

  const topContainer = useMemo(
    () => (
      <View>
        <View style={[styles.row, styles.enemyContainer]}>
          <EnemySpriteTile
            sprite={currentEnemySprite}
            backdropColor={backdropColor}
          />
          {explorationStatusContainer}
        </View>
        <Divider />
        <View style={[styles.row, styles.playerContainer]}>
          <View style={{ flex: 1, marginRight: 4 }}>
            <PlayerHPBar />
          </View>
          <PlayerActionDisplay />
        </View>
        {hasActiveSummon && (
          <View style={[styles.row, styles.summonContainer]}>
            <SummonSpriteTile sprite={currentSummonSprite} />
            <View style={{ flex: 1, marginLeft: 4, marginRight: 4 }}>
              <SummonHPBar />
            </View>
            <SummonActionDisplay />
          </View>
        )}
        <View style={styles.row}>
          <View style={styles.row}>
            <Text>To unlock boss: {toUnlockBoss <= 0 ? "" : toUnlockBoss}</Text>
            {checkmark && (
              <Icon
                name="check"
                containerStyle={styles.checkmark}
                color={isFloorCompleted ? theme.colors.primary : undefined}
              />
            )}
          </View>
          <Button
            title="Fight Boss"
            onPress={() => playerContextRef.current.apply(startBossExploration)}
            disabled={!canFightBoss}
          />
        </View>
        <View style={[styles.row, styles.moveRow]}>
          <Text>Move:</Text>
          <View style={styles.row}>
            <Button
              containerStyle={styles.exitButtonContainer}
              title="Exit"
              onPress={() => playerContextRef.current.apply(endExploration)}
            />
            <Button
              title="Next Floor"
              onPress={() =>
                playerContextRef.current.apply((state) =>
                  startExplorationOnFloor(
                    state,
                    getCurrentDungeonFloor(
                      state,
                    ).getNextFloor() as DungeonFloor,
                  ),
                )
              }
              disabled={!canMoveToNextFloor}
            />
          </View>
        </View>
      </View>
    ),
    [
      currentEnemySprite,
      explorationStatusContainer,
      toUnlockBoss,
      checkmark,
      playerContextRef,
      startBossExploration,
      canFightBoss,
      endExploration,
      canMoveToNextFloor,
      hasActiveSummon,
      currentSummonSprite,
      isFloorCompleted,
      backdropColor,
      theme,
    ],
  );

  return useMemo(
    () => (
      <View style={styles.wholeContainer}>
        <View style={styles.container}>
          <View style={[styles.topRow]}>
            <Icon
              name="arrow-back"
              color="#000"
              containerStyle={styles.arrowBack}
              onPress={() => playerContextRef.current.apply(endExploration)}
              hitSlop={{ top: 30, left: 30, bottom: 30, right: 30 }}
            />
            <Text h2>Exploration - {dungeonTitle}</Text>
          </View>
          <View style={styles.restOfScreen}>
            {topContainer}
            <View style={styles.playerMessageLogContainer}>
              <PlayerMessageLog />
            </View>
          </View>
        </View>
        <View style={styles.bottomComponentContainer}>
          <ExplorationBottomComponent />
        </View>
      </View>
    ),
    [playerContextRef, endExploration, dungeonTitle, topContainer],
  );
}

export default function Exploration() {
  const playerContext = useContext(PlayerContext);

  const explorationStatus = getExplorationStatus(playerContext);

  if (explorationStatus == ExplorationStatus.None) {
    return <ExplorationNone />;
  }

  // We are exploring, reviving, or in combat
  return <ExplorationMain />;
}

const styles = StyleSheet.create({
  wholeContainer: { flex: 1 },
  container: {
    flex: 1,
    padding: 8,
  },
  topRow: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: 16,
    height: 40,
  },
  arrowBack: {
    marginRight: 8,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  checkmark: {
    marginLeft: 8,
  },
  enemyContainer: {
    marginBottom: 8,
  },
  playerContainer: {
    marginTop: 8,
  },
  summonContainer: {
    marginTop: 8,
  },
  playerMessageLogContainer: {
    flex: 1,
    padding: 8,
    borderColor: "#444",
    borderWidth: 1,
    marginTop: 8,
  },
  moveRow: {
    marginTop: 8,
  },
  exitButtonContainer: { marginRight: 8 },
  explorationStatusContainer: {
    flex: 1,
  },
  restOfScreen: {
    flex: 1,
    justifyContent: "space-between",
    marginBottom: 48,
  },
  bottomComponentContainer: {
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
  },
  dungeonButton: {
    marginTop: 8,
  },
  gradient: {
    left: 0,
    top: 0,
    width: "100%",
    height: "60%",
    position: "absolute",
    opacity: 0.3,
  },
});
