import {
  registerTransformation,
  TransformationType,
} from "../../calculation/Calculation";
import { TransformationTags } from "../../calculation/TransformationTags";
import { AttackTarget } from "../../exploration/AttackTarget";
import { CombatStat } from "../../exploration/CombatStats";
import SchoolBasementB1 from "../../exploration/dungeons/schoolbasement/SchoolBasementB1";
import SchoolBasementB2 from "../../exploration/dungeons/schoolbasement/SchoolBasementB2";
import SchoolBasementB3 from "../../exploration/dungeons/schoolbasement/SchoolBasementB3";
import {
  getCurrentDungeonFloor,
  getExplorationStatus,
} from "../../exploration/Exploration";
import { ExplorationStatus } from "../../exploration/ExplorationStatus";
import {
  hasFlag,
  hasGlobalFlag,
  hasGlobalFlagIncludingNextRun,
  setFlag,
  setGlobalFlagForNextRun,
} from "../../Flags";
import {
  getTotalAmountOfItem,
  removeFromInventoryWithAnyParams,
} from "../../items/Inventory";
import BatWing from "../../items/other/BatWing";
import RatTail from "../../items/other/RatTail";
import { addContextListener } from "../../PlayerContextListeners";
import { getResourceAmount, grantResource, Resource } from "../../Resources";
import { Storyline, Storylines } from "../../storylines/Storylines";
import { registerRandomEventTrigger } from "../autorandom/RandomEventTrigger";
import { buildEventMessage } from "../EventMessageBuilder";
import { createGameEvent } from "../SimpleGameEvent";

const finalMessage = buildEventMessage(
  "magic_library_final",
  "(Storyline) The Magic Library",
)
  .setSimpleDescription(
    `You follow the librarian to one of the furthest shelves in the room. He points to a section of the bookshelf.

"These are my most valuable books. Choose one. Choose carefully - the old headmaster enchanted those books, so that the rest will vanish if you take one."`,
  )
  .addOption(
    "Take the Blue Tome",
    buildEventMessage("magic_library_f1", "(Storyline) The Magic Library")
      .setSimpleDescription(
        `You take the Blue Tome from the shelf. The librarian bows at you deeply. "It was a pleasure to meet you, and to know that my library is now alive again." With a swoosh, he vanishes. You look back at the shelf where the tome was at and notice it is now empty.
      
You pass the tome to your researcher, who opens it and looks at it for a while, but she seems troubled.

"This book is way too advanced", she says. "It would probably take us a *lifetime* to understand what it says!"

You try to argue back with her, but you quickly realize she's being genuine.`,
      )
      .addOption(
        "Continue",
        buildEventMessage("magic_library_f1_2", "(Storyline) The Magic Library")
          .setSimpleDescription(
            `You sigh, disappointed by the turn of events. However, you still ask her to look into the matter. You are hopeful that even if it doesn't benefit you, it'll serve a good purpose for someone else in the future...
          
**You have completed "The Magic Library" storyline by selecting the Blue Tome! In future runs, the knowledge from the tome will let you start the game with the Inventory, Crafting and Exploration features unlocked from the beginning!**`,
          )
          .build(),
        setGlobalFlagForNextRun("magic_library_blue_tome_taken"),
      )
      .build(),
    undefined,
    (state, params) => !hasGlobalFlag(state, "magic_library_blue_tome_taken"),
  )
  .addOption(
    "Take the Yellow Tome",
    buildEventMessage("magic_library_f2", "(Storyline) The Magic Library")
      .setSimpleDescription(
        `You take the Yellow Tome from the shelf. The librarian bows at you deeply. "It was a pleasure to meet you, and to know that my library is now alive again." With a swoosh, he vanishes. You look back at the shelf where the tome was at and notice it is now empty.
      
You pass the tome to your researcher, who opens it and looks at it for a while, but she seems troubled.

"This book is way too advanced", she says. "It would probably take us a *lifetime* to understand what it says!"

You try to argue back with her, but you quickly realize she's being genuine.`,
      )
      .addOption(
        "Continue",
        buildEventMessage("magic_library_f2_2", "(Storyline) The Magic Library")
          .setSimpleDescription(
            `You sigh, disappointed by the turn of events. However, you still ask her to look into the matter. You are hopeful that even if it doesn't benefit you, it'll serve a good purpose for someone else in the future...
          
**You have completed "The Magic Library" storyline by selecting the Yellow Tome! In future runs, the knowledge from the tome will let you be more efficient with storage, allowing you to store 20% more of every resource except :mana:Mana!**`,
          )
          .build(),
        setGlobalFlagForNextRun("magic_library_yellow_tome_taken"),
      )
      .build(),
    undefined,
    (state, params) => !hasGlobalFlag(state, "magic_library_yellow_tome_taken"),
  )
  .addOption(
    "Take the Green Tome",
    buildEventMessage("magic_library_f3", "(Storyline) The Magic Library")
      .setSimpleDescription(
        `You take the Green Tome from the shelf. The librarian bows at you deeply. "It was a pleasure to meet you, and to know that my library is now alive again." With a swoosh, he vanishes. You look back at the shelf where the tome was at and notice it is now empty.
      
You pass the tome to your researcher, who opens it and looks at it for a while, but she seems troubled.

"This book is way too advanced", she says. "It would probably take us a *lifetime* to understand what it says!"

You try to argue back with her, but you quickly realize she's being genuine.`,
      )
      .addOption(
        "Continue",
        buildEventMessage("magic_library_f3_2", "(Storyline) The Magic Library")
          .setSimpleDescription(
            `You sigh, disappointed by the turn of events. However, you still ask her to look into the matter. You are hopeful that even if it doesn't benefit you, it'll serve a good purpose for someone else in the future...
          
**You have completed "The Magic Library" storyline by selecting the Green Tome! In future runs, the knowledge from the tome will let you explore twice as fast!**`,
          )
          .build(),
        setGlobalFlagForNextRun("magic_library_green_tome_taken"),
      )
      .build(),
    undefined,
    (state, params) => !hasGlobalFlag(state, "magic_library_green_tome_taken"),
  )
  .addOption(
    "Take the Red Tome",
    buildEventMessage("magic_library_f4", "(Storyline) The Magic Library")
      .setSimpleDescription(
        `You take the Red Tome from the shelf. The librarian bows at you deeply. "It was a pleasure to meet you, and to know that my library is now alive again." With a swoosh, he vanishes. You look back at the shelf where the tome was at and notice it is now empty.
      
You pass the tome to your researcher, who opens it and looks at it for a while, but she seems troubled.

"This book is way too advanced", she says. "It would probably take us a *lifetime* to understand what it says!"

You try to argue back with her, but you quickly realize she's being genuine.`,
      )
      .addOption(
        "Continue",
        buildEventMessage("magic_library_f4_2", "(Storyline) The Magic Library")
          .setSimpleDescription(
            `You sigh, disappointed by the turn of events. However, you still ask her to look into the matter. You are hopeful that even if it doesn't benefit you, it'll serve a good purpose for someone else in the future...
          
**You have completed "The Magic Library" storyline by selecting the Red Tome! In future runs, the knowledge from the tome will grant you a permanent +100 Max:hp: and +0.1:hpRegen:/sec boost!**`,
          )
          .build(),
        setGlobalFlagForNextRun("magic_library_red_tome_taken"),
      )
      .build(),
    undefined,
    (state, params) => !hasGlobalFlag(state, "magic_library_red_tome_taken"),
  )
  .build();

const message = buildEventMessage(
  "magic_library_1",
  "(Storyline) The Magic Library",
)
  .setSimpleDescription(
    `As you delve through the corridors of the school basement, you encounter a large room, full of old books. Shelves and shelves of books are assembled in rows across the room.
    
You walk between the shelves. The dust is thick. You take a peek at one of the books, but can't immediately deduce what it's about.

You decide to fetch your researcher and come back; you quickly head out of the basement, interrupt her, and ask her to come see the room.`,
  )
  .addOption(
    "Continue",
    buildEventMessage("magic_library_2", "(Storyline) The Magic Library")
      .setSimpleDescription(
        `The researcher looks around the room, with clear interest.
      
"This looks like it used to be the library of the school, a very long time ago", she says. "There could be a ton of knowledge here, but we need to fix this place. There is just too much dust for this to be any useful."

You ready yourself to clear the dust with magic...`,
      )
      .addOption(
        "Conjure a breeze to clear the dust (2400 Mana)",
        buildEventMessage("magic_library_3", "(Storyline) The Magic Library")
          .setSimpleDescription(
            `"A lot better!", you hear as you finish the spell, although it sounded quite odd. You turn to face your researcher and see her expression, but you don't find her looking at you. She is instead looking directly at a corner. You follow her gaze...
          
...and you find *a ghost, staring right back at you and moving towards you and your researcher!*`,
          )
          .addOption(
            "Continue",
            buildEventMessage(
              "magic_library_4",
              "(Storyline) The Magic Library",
            )
              .setSimpleDescription(
                `You instinctively spring into a defensive position and ready yourself for combat. The ghost, however, stops in its tracks.
              
The ghastly figure in front of you looks like the white silhouette of an old man, with a top hat, a mustache and a coat. It (he?) looks at you curiously.

"Please, I mean no harm!", he begins to say. You realize the voice you had heard was actually from this ghost. You try to calm yourself down.`,
              )
              .addOption(
                "Continue",
                buildEventMessage(
                  "magic_library_5",
                  "(Storyline) The Magic Library",
                )
                  .setSimpleDescription(
                    `"Let me introduce myself", he says. "I am the librarian of this magic school. Or at least, I *used to be,* back when I still had a body..." He sighs, with a sad expression on his ghastly face.
                  
"After the headmaster passed away, the place fell into disrepair. The students left. There was nobody left. But I couldn't bring myself to leave. I spent more than half of my life taking care of this very room. And now... this place is so full of monsters."`,
                  )
                  .addOption(
                    "Continue",
                    buildEventMessage(
                      "magic_library_6",
                      "(Storyline) The Magic Library",
                    )
                      .setSimpleDescription(
                        `"Can you do me a favor?", he asks you. "It would make me feel at peace to be able to see this place restored to how it was supposed to be. If you can exterminate the mice and bats around here, and bring me their tails and wings as proof, I can show you where some of the most powerful books are in this room."
                        
"100 Rat Tails and 100 Bat Wings should do the trick."`,
                      )
                      .addOption(
                        "Hand over the tails and wings",
                        buildEventMessage(
                          "magic_library_7",
                          "(Storyline) The Magic Library",
                        )
                          .setSimpleDescription(
                            `You hand over the materials. It sure took you a while to accumulate those...
                          
The librarian ghost seems quite happy.

"This is... I feel I can finally rest in peace now", he exclaims. You hear his voice breaking a bit as he says that.

"Very well. Follow me."`,
                          )
                          .addOption("Continue", finalMessage)
                          .build(),
                        (state) => {
                          state = removeFromInventoryWithAnyParams(
                            RatTail,
                            100,
                            state,
                          );
                          state = removeFromInventoryWithAnyParams(
                            BatWing,
                            100,
                            state,
                          );
                          return state;
                        },
                        undefined,
                        (state, params) =>
                          getTotalAmountOfItem(RatTail, state) >= 100 &&
                          getTotalAmountOfItem(BatWing, state) >= 100,
                      )
                      .addOption("Maybe later")
                      .build(),
                  )
                  .build(),
              )
              .build(),
          )
          .build(),
        grantResource(Resource.Mana, -2400),
        undefined,
        (state, params) => getResourceAmount(state, Resource.Mana) >= 2400,
      )
      .addOption("Maybe later")
      .build(),
  )
  .build();

const magicLibraryEvent = createGameEvent(
  "magic_library",
  message,
  setFlag("magic_library_event_triggered"),
);

// ============== Blue tome ===============

addContextListener("magic_library_blue_tome", (oldState, newState) => {
  if (
    hasGlobalFlag(newState, "magic_library_blue_tome_taken") &&
    (!hasFlag(newState, "exploration_unlocked") ||
      !hasFlag(newState, "inventory_unlocked"))
  ) {
    return (state) => {
      state = setFlag("exploration_unlocked")(state);
      state = setFlag("inventory_unlocked")(state);
      return state;
    };
  }
});

Storylines.register(
  new Storyline(
    "The Magic Library (Blue Tome)",
    (state) =>
      hasGlobalFlagIncludingNextRun(state, "magic_library_blue_tome_taken"),
    (state) => hasGlobalFlag(state, "magic_library_blue_tome_taken"),
    "There's a chance of this happening randomly if you are exploring the School Basement...",
    "Start game with Inventory, Crafting and Exploration unlocked",
    (state) => true,
  ),
);

// ============== Yellow Tome ===============

for (let resource in Resource) {
  if (resource != Resource.Mana) {
    registerTransformation(
      [[resource, TransformationTags.Cap]],
      resource + "MagicLibraryYellowTome",
      TransformationType.Multiplier,
      (state) =>
        hasGlobalFlag(state, "magic_library_yellow_tome_taken") ? 1.2 : 1.0,
    );
  }
}

Storylines.register(
  new Storyline(
    "The Magic Library (Yellow Tome)",
    (state) =>
      hasGlobalFlagIncludingNextRun(state, "magic_library_yellow_tome_taken"),
    (state) => hasGlobalFlag(state, "magic_library_yellow_tome_taken"),
    "There's a chance of this happening randomly if you are exploring the School Basement...",
    "+20% max of every resource except :mana:Mana",
    (state) => true,
  ),
);

// ============== Green Tome ===============

registerTransformation(
  [[TransformationTags.ExplorationDelay]],
  "MagicLibraryGreenTomeExplorationDelay",
  TransformationType.Multiplier,
  (state) =>
    hasGlobalFlag(state, "magic_library_green_tome_taken") ? 0.5 : 1.0,
);

Storylines.register(
  new Storyline(
    "The Magic Library (Green Tome)",
    (state) =>
      hasGlobalFlagIncludingNextRun(state, "magic_library_green_tome_taken"),
    (state) => hasGlobalFlag(state, "magic_library_green_tome_taken"),
    "There's a chance of this happening randomly if you are exploring the School Basement...",
    "-50% Exploration delay",
    (state) => true,
  ),
);

// ============== Red Tome ===============

registerTransformation(
  [[AttackTarget.Player, CombatStat.MaxHP]],
  "MagicLibraryRedTomeMaxHP",
  TransformationType.Addition,
  (state) => (hasGlobalFlag(state, "magic_library_red_tome_taken") ? 100 : 0),
);

registerTransformation(
  [[AttackTarget.Player, CombatStat.HPRegen]],
  "MagicLibraryRedTomeHPRegen",
  TransformationType.Addition,
  (state) => (hasGlobalFlag(state, "magic_library_red_tome_taken") ? 0.1 : 0),
);

Storylines.register(
  new Storyline(
    "The Magic Library (Red Tome)",
    (state) =>
      hasGlobalFlagIncludingNextRun(state, "magic_library_red_tome_taken"),
    (state) => hasGlobalFlag(state, "magic_library_red_tome_taken"),
    "There's a chance of this happening randomly if you are exploring the School Basement...",
    "+100 Max:hp:, +0.1:hpRegen:",
    (state) => true,
  ),
);

// ====================

registerRandomEventTrigger(
  magicLibraryEvent,
  30,
  (state) =>
    !hasFlag(state, "magic_library_event_triggered") &&
    getExplorationStatus(state) != ExplorationStatus.None &&
    [
      SchoolBasementB1.getId(),
      SchoolBasementB2.getId(),
      SchoolBasementB3.getId(),
    ].includes(getCurrentDungeonFloor(state).getId()) &&
    (!hasGlobalFlag(state, "magic_library_blue_tome_taken") ||
      !hasGlobalFlag(state, "magic_library_yellow_tome_taken") ||
      !hasGlobalFlag(state, "magic_library_green_tome_taken") ||
      !hasGlobalFlag(state, "magic_library_red_tome_taken")),
);

export default magicLibraryEvent;
