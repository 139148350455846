import { ActionBase, DoActionArgs } from "../actions/Action";
import { hasFlag, setFlag } from "../Flags";
import { Identifiable } from "../generic/Identifiable";
import { removeFromInventoryWithAnyParams } from "../items/Inventory";
import { getItemById } from "../items/Items";
import { PlayerContextState } from "../PlayerContext";
import { grantResource, Resource } from "../Resources";

export abstract class Upgrade extends ActionBase implements Identifiable {
  abstract getName(): string;
  abstract getMonstiumCost(): number;
  abstract getDisplayDescription(state: PlayerContextState): string;
  abstract getDisplayEffect(state: PlayerContextState): string;

  getId(): string {
    return this.getName();
  }

  getUpgradeRequirements(): Upgrade[] {
    return [];
  }

  getArea(): string {
    return "Upgrades";
  }

  getCost(state: PlayerContextState): {
    resources: Partial<Record<Resource, number>>;
    items: Record<string, number>;
  } {
    return {
      resources: {
        Monstium: this.getMonstiumCost(),
      },
      items: this.getItemCost(),
    };
  }

  getItemCost(): Record<string, number> {
    return {};
  }

  doAction(args: DoActionArgs, state: PlayerContextState): PlayerContextState {
    state = grantResource(Resource.Monstium, -this.getMonstiumCost())(state);
    const itemsRequired = this.getItemCost();
    for (let itemId in itemsRequired) {
      state = removeFromInventoryWithAnyParams(
        getItemById(itemId),
        itemsRequired[itemId],
        state,
      );
    }
    return setFlag("upgrade:" + this.getName())(state);
  }

  isPurchased(state: PlayerContextState): boolean {
    return hasFlag(state, "upgrade:" + this.getName());
  }

  isVisibleExtraChecks(state: PlayerContextState): boolean {
    return true;
  }

  isVisible(state: PlayerContextState): boolean {
    if (this.isPurchased(state)) {
      return false;
    }

    const requirements = this.getUpgradeRequirements();
    if (requirements.some((requirement) => !requirement.isPurchased(state))) {
      return false;
    }

    if (!this.isVisibleExtraChecks(state)) {
      return false;
    }

    return true;
  }
}
