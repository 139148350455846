import { formatNumber } from "../../../utils/FormattingUtils";
import { ActionEffect } from "../../actions/Action";
import {
  registerTransformation,
  TransformationType,
} from "../../calculation/Calculation";
import { TransformationTags } from "../../calculation/TransformationTags";
import { buildEventMessage } from "../../events/EventMessageBuilder";
import { triggerEvent } from "../../events/Events";
import { createGameEvent } from "../../events/SimpleGameEvent";
import { hasFlag, setFlag } from "../../Flags";
import { PlayerContextState } from "../../PlayerContext";
import { addContextListener } from "../../PlayerContextListeners";
import {
  getResourceAmount,
  grantResource,
  recalculateCaps,
  Resource,
} from "../../Resources";
import {
  getTotalElementAndSchoolExp,
  SpellElement,
} from "../../spells/ElementsAndSchools";
import { registerTimeTickListener } from "../../timetick/TimeTick";
import { BuildingActionBase } from "../Building";
import {
  buildingAmountsChanged,
  getBuildingAmount,
  getBuildingAmountTurnedOn,
} from "../Buildings";

class Furnace extends BuildingActionBase {
  constructor() {
    super();
  }

  isVisible(state: PlayerContextState): boolean {
    return hasFlag(state, "furnace_unlocked");
  }

  getBuildingName(): string {
    return "Furnace";
  }

  getArea(): string {
    return "Campus";
  }

  getDisplayDescription(): string {
    return "An oven with a very hot flame. Useful for crafting.";
  }

  protected getBaseActionEffects(): Record<string, ActionEffect> {
    return {
      wood: {
        value: 3,
        tags: [Resource.Wood, TransformationTags.Consumption],
      },
      temperature: {
        value: 1,
        tags: [Resource.Temperature, TransformationTags.Production],
      },
      temperatureCap: {
        value: 500,
        tags: [Resource.Temperature, TransformationTags.PerBuildingCap],
      },
    };
  }

  getDisplayEffect(state: PlayerContextState): string {
    const effects = this.getActionEffects(state);
    return `+${formatNumber(effects.temperature, {
      showDecimals: true,
    })}:temperature:/sec, -${formatNumber(effects.wood, {
      showDecimals: true,
    })}:wood:/sec, +${formatNumber(effects.temperatureCap)} Max:temperature:`;
  }

  canTurnOff(): boolean {
    return true;
  }

  getBaseResourceCost(): Record<string, number> {
    return {
      Coins: 1500,
      Stone: 3000,
      Iron: 100,
    };
  }

  getBaseResourceScale(): Record<string, number> {
    return {
      Coins: 1.3,
      Stone: 1.3,
      Iron: 1.3,
    };
  }
}

const furnace = new Furnace();

registerTransformation(
  [[furnace.getId(), Resource.Temperature, TransformationTags.Production]],
  "FurnaceFireTransform",
  TransformationType.Multiplier,
  (state) => (state.primaryElement == SpellElement.Fire ? 2.0 : 1.0),
);

function onTick(
  state: PlayerContextState,
  elapsedTimeSec: number,
): PlayerContextState {
  const furnaces = getBuildingAmountTurnedOn(state, furnace);
  if (furnaces === 0) {
    return state;
  }
  const effects = furnace.getActionEffects(state);

  const totalCost = effects.wood * furnaces * elapsedTimeSec;
  if (getResourceAmount(state, Resource.Wood) < totalCost) {
    return state;
  }

  state = grantResource(Resource.Wood, -totalCost)(state);
  return grantResource(
    Resource.Temperature,
    furnaces * effects.temperature * elapsedTimeSec,
  )(state);
}

registerTimeTickListener("FurnaceProduction", onTick);

addContextListener("furnace_cap_listener", (oldState, newState) => {
  if (buildingAmountsChanged(oldState, newState, furnace)) {
    return (state) => {
      return recalculateCaps(state);
    };
  }
});

registerTransformation(
  [[Resource.Temperature, TransformationTags.Cap]],
  "FurnaceTemperatureCap",
  TransformationType.Addition,
  (state) =>
    getBuildingAmount(state, furnace) *
    furnace.getActionEffects(state).temperatureCap,
);

const message = buildEventMessage("furnace_unlocked", "A Really Hot Oven")
  .setSimpleDescription(
    `You see your apprentice by the door of your room. You approach him and ask him what is wrong. He shakes a bit, but then finds courage and speaks.

"I-I have been thinking about crafting, but it's very hard to shape metal right now," he says. "Maybe if we had a really hot flame, we would be able to be much more effective with crafting... maybe even make real weapons?"

You realize he has a point. You still don't know why your apprentice and your researcher are both eager to make weapons, but building a furnace could make it a lot easier to craft multiple types of items.

**You can now build Furnaces!**`,
  )
  .build();

const event = createGameEvent(
  "furnace_unlocked",
  message,
  setFlag("furnace_unlocked"),
);

addContextListener("furnace_unlocked", (oldState, newState) => {
  if (
    !hasFlag(newState, "furnace_unlocked") &&
    hasFlag(newState, "inventory_unlocked") &&
    getTotalElementAndSchoolExp(newState) >= 13000
  ) {
    return triggerEvent(event);
  }
});

export default furnace as Furnace;
