import { hasFlag, hasGlobalFlag, setFlag, setGlobalFlag } from "../../Flags";
import { DataStore } from "../../generic/DataStore";
import { PlayerContextState } from "../../PlayerContext";
import { Dungeon } from "./Dungeon";
import { DungeonFloor } from "./DungeonFloor";

const Dungeons = new DataStore<Dungeon>();
const DungeonFloors = new DataStore<DungeonFloor>();

export { Dungeons, DungeonFloors };

export function getUnlockedDungeons(state: PlayerContextState): Dungeon[] {
  return Dungeons.getAll().filter((dungeon) => dungeon.isUnlocked(state));
}

export function isDungeonFloorCompletedThisRun(
  state: PlayerContextState,
  floor: DungeonFloor | string,
): boolean {
  return hasFlag(
    state,
    (typeof floor === "string" ? floor : floor.getId()) +
      "_dungeon_floor_completed",
  );
}

export function isDungeonFloorEverCompleted(
  state: PlayerContextState,
  floor: DungeonFloor | string,
): boolean {
  return hasGlobalFlag(
    state,
    (typeof floor === "string" ? floor : floor.getId()) +
      "_dungeon_floor_completed",
  );
}

export function setDungeonFloorCompleted(
  state: PlayerContextState,
  floorId: string,
): PlayerContextState {
  state = setFlag(floorId + "_dungeon_floor_completed")(state);
  return setGlobalFlag(floorId + "_dungeon_floor_completed")(state);
}
