import warehouse from "../../buildings/campus/Warehouse";
import {
  registerTransformation,
  TransformationType,
} from "../../calculation/Calculation";
import { TransformationTags } from "../../calculation/TransformationTags";
import { PlayerContextState } from "../../PlayerContext";
import { addContextListener } from "../../PlayerContextListeners";
import { recalculateCaps } from "../../Resources";
import { Upgrade } from "../Upgrade";

class ReinforcedWarehouses extends Upgrade {
  getName(): string {
    return "Reinforced Warehouses";
  }

  getMonstiumCost(): number {
    return 450;
  }

  getDisplayDescription(state: PlayerContextState): string {
    return "Strengthen the walls and the structure of your warehouses, allowing it to store more items.";
  }

  getDisplayEffect(state: PlayerContextState): string {
    return "2.5x Warehouse storage";
  }
}

const reinforcedWarehouses = new ReinforcedWarehouses();

registerTransformation(
  [[warehouse.getName(), TransformationTags.PerBuildingCap]],
  "ReinforcedWarehouses",
  TransformationType.Multiplier,
  (state) => (reinforcedWarehouses.isPurchased(state) ? 2.5 : 1),
);

addContextListener("ReinforcedWarehousesListener", (oldState, newState) => {
  if (
    !reinforcedWarehouses.isPurchased(oldState) &&
    reinforcedWarehouses.isPurchased(newState)
  ) {
    return recalculateCaps;
  }
});

export default reinforcedWarehouses;
