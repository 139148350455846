import {
  applyTransformations,
  registerTransformation,
  TransformationType,
} from "../calculation/Calculation";
import { hasFlag } from "../Flags";
import { PlayerContextState } from "../PlayerContext";
import { AttackTarget } from "./AttackTarget";

export enum CombatStat {
  MaxHP = "MaxHP",
  Attack = "Attack",
  AttackDelay = "AttackDelay",
  Defense = "Defense",
  HPRegen = "HPRegen",
  Dodge = "Dodge",
  Accuracy = "Accuracy",
  CritChance = "CritChance",
}

export function calculatePlayerMaxHP(state: PlayerContextState) {
  return applyTransformations(
    [AttackTarget.Player, CombatStat.MaxHP],
    state,
    100,
  );
}

export function calculatePlayerAttack(state: PlayerContextState) {
  return Math.max(
    applyTransformations([AttackTarget.Player, CombatStat.Attack], state, 5),
    1,
  );
}

export function calculatePlayerAttackDelaySec(state: PlayerContextState) {
  return applyTransformations(
    [AttackTarget.Player, CombatStat.AttackDelay],
    state,
    2.5,
  );
}

export function calculatePlayerDefense(state: PlayerContextState) {
  return Math.max(
    applyTransformations([AttackTarget.Player, CombatStat.Defense], state, 0),
    0,
  );
}

export function calculatePlayerHPRegenPerSec(state: PlayerContextState) {
  return applyTransformations(
    [AttackTarget.Player, CombatStat.HPRegen],
    state,
    0.1,
  );
}

export function calculatePlayerDodgeChance(state: PlayerContextState) {
  return applyTransformations(
    [AttackTarget.Player, CombatStat.Dodge],
    state,
    0,
  );
}

export function calculatePlayerAccuracy(state: PlayerContextState) {
  return applyTransformations(
    [AttackTarget.Player, CombatStat.Accuracy],
    state,
    1.0,
  );
}

export function calculatePlayerCritChance(state: PlayerContextState) {
  return applyTransformations(
    [AttackTarget.Player, CombatStat.CritChance],
    state,
    0.0,
  );
}

registerTransformation(
  [
    [AttackTarget.Player, CombatStat.MaxHP],
    [AttackTarget.Player, CombatStat.HPRegen],
  ],
  "DebugInvincibility",
  TransformationType.Addition,
  (state) => {
    if (hasFlag(state, "debug_invincibility")) {
      return 99999999;
    }
    return 0;
  },
);

registerTransformation(
  [[AttackTarget.Player, CombatStat.Attack]],
  "DebugInsvincibility",
  TransformationType.Addition,
  (state) => {
    if (hasFlag(state, "debug_invincibility")) {
      return 9999;
    }
    return 0;
  },
);
