import { hasFlag } from "../../../Flags";
import { PlayerContextState } from "../../../PlayerContext";
import { Enemy } from "../../enemies/Enemy";
import AngrySheep from "../../enemies/schoolbasement/AngrySheep";
import BasementBat from "../../enemies/schoolbasement/BasementBat";
import LittleDevil from "../../enemies/schoolbasement/LittleDevil";
import Mouse from "../../enemies/schoolbasement/Mouse";
import Slime from "../../enemies/schoolbasement/Slime";
import { DungeonFloor, encounterEnemy } from "../DungeonFloor";
import SchoolBasementB3 from "./SchoolBasementB3";

class SchoolBasementB2 extends DungeonFloor {
  getId(): string {
    return "schoolBasementB2";
  }

  getFloorName(): string {
    return "School Basement B2";
  }

  getBoss(): Enemy {
    return LittleDevil;
  }

  isUnlocked(state: PlayerContextState): boolean {
    return hasFlag(state, "school_basement_b2_unlocked");
  }

  getBreedingLevel(): number {
    return 7;
  }

  getNextFloor(): DungeonFloor {
    return SchoolBasementB3;
  }

  getExplorationPossibilities(state: PlayerContextState) {
    return [
      {
        weight: 20,
        transforms: encounterEnemy(Mouse),
      },
      {
        weight: 80,
        transforms: encounterEnemy(BasementBat),
      },
      {
        weight: 60,
        transforms: encounterEnemy(Slime),
      },
      {
        weight: 60,
        transforms: encounterEnemy(AngrySheep),
      },
    ];
  }
}

export default new SchoolBasementB2();
