import { registerAction } from "../actions/Actions";
import explorationTraining from "./exploration/ExplorationTraining";
import healthBoost from "./exploration/HealthBoost";
import innateRegeneration from "./exploration/InnateRegeneration";
import largerHealthBoost from "./exploration/LargerHealthBoost";
import enchantedPouch from "./inventory/EnchantedPouch";
import largePouch from "./inventory/LargePouch";
import woolFarm from "./other/WoolFarm";
import researcherFocus from "./research/ResearcherFocus";
import researchFair from "./research/ResearchFair";
import researchPublication from "./research/ResearchPublication";
import manaCrystals from "./storage/ManaCrystals";
import reinforcedVaults from "./storage/ReinforcedVaults";
import reinforcedWarehouses from "./storage/ReinforcedWarehouses";
import reinforcedWaterTanks from "./storage/ReinforcedWaterTanks";

export function loadUpgradesDemo() {
  // Research
  registerAction(researchFair);
  registerAction(researchPublication);
  registerAction(researcherFocus);

  // Inventory
  registerAction(largePouch);
  registerAction(enchantedPouch);

  // Storage
  registerAction(reinforcedWarehouses);
  registerAction(reinforcedWaterTanks);
  registerAction(manaCrystals);
  registerAction(reinforcedVaults);

  // Exploration
  registerAction(healthBoost);
  registerAction(largerHealthBoost);
  registerAction(innateRegeneration);
  registerAction(explorationTraining);

  // Other
  registerAction(woolFarm);
}
