import React, { useState } from "react";
import {
  ScrollView,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import InventoryItems from "../inventory/InventoryItems";
import SpellsContainer from "../spells/SpellsContainer";
import { Text } from "../utility/Text";
import { QuickbarContainer } from "./QuickbarContainer";

export function ExplorationBottomComponent() {
  const [openedTab, setOpenedTab] = useState("");
  return (
    <View>
      {openedTab ? (
        <View style={styles.mainComponent}>
          <ScrollView
            style={[
              styles.mainScrollView,
              openedTab == "Quickbar" && styles.quickbarScrollView,
            ]}
            nestedScrollEnabled={true}
          >
            {openedTab == "Inventory" && (
              <InventoryItems disableSelling={true} showQuickbar={true} />
            )}
            {openedTab == "Spells" && (
              <SpellsContainer
                area="Exploration"
                hideRecent={true}
                showAddToQuickbar={true}
                onlyBySchool={true}
              />
            )}
            {openedTab == "Quickbar" && <QuickbarContainer />}
          </ScrollView>
        </View>
      ) : (
        false
      )}
      <View style={styles.tabsContainer}>
        <TouchableWithoutFeedback
          onPress={() => {
            if (openedTab != "Inventory") {
              setOpenedTab("Inventory");
            } else {
              setOpenedTab("");
            }
          }}
        >
          <View
            style={[styles.tab, openedTab == "Inventory" && styles.selectedTab]}
          >
            <Text h3>Inventory</Text>
          </View>
        </TouchableWithoutFeedback>
        <TouchableWithoutFeedback
          onPress={() => {
            if (openedTab != "Spells") {
              setOpenedTab("Spells");
            } else {
              setOpenedTab("");
            }
          }}
        >
          <View
            style={[styles.tab, openedTab == "Spells" && styles.selectedTab]}
          >
            <Text h3>Spells</Text>
          </View>
        </TouchableWithoutFeedback>
        <TouchableWithoutFeedback
          onPress={() => {
            if (openedTab != "Quickbar") {
              setOpenedTab("Quickbar");
            } else {
              setOpenedTab("");
            }
          }}
        >
          <View
            style={[styles.tab, openedTab == "Quickbar" && styles.selectedTab]}
          >
            <Text h3>Quickbar</Text>
          </View>
        </TouchableWithoutFeedback>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  mainComponent: {
    backgroundColor: "#eee",
    padding: 8,
    marginHorizontal: 2,
    borderWidth: 0.5,
  },
  mainScrollView: { height: 296 },
  quickbarScrollView: { height: 226 },
  tabsContainer: {
    marginHorizontal: 8,
    marginBottom: 8,
    flexDirection: "row",
    alignItems: "flex-end",
  },
  tab: {
    borderWidth: 0.5,
    borderRadius: 8,
    marginHorizontal: 8,
    padding: 8,
    top: -1,
    backgroundColor: "#eee",
  },
  selectedTab: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderTopWidth: 0,
    paddingTop: 16,
    position: "relative",
  },
});
