import { ItemBase } from "../Item";

class LightweightChest extends ItemBase {
  getId(): string {
    return "lightweightChest";
  }

  getPicture() {
    return require("./lightweightChest.png");
  }

  getBaseName(): string {
    return "Lightweight Chest";
  }

  getTags() {
    return [{ tag: "Quest Item" }];
  }

  getDescription(): string | undefined {
    return "A very light wooden chest. Great for carrying items.";
  }

  getEffect(): string | undefined {
    return undefined;
  }

  getSalePrice(): number {
    return 300;
  }

  getCraftingSchoolLevelRequirements() {
    return { Conjuration: 3, Enchantment: 3 };
  }

  getCraftingMaterialsBase() {
    return {
      resources: {
        Mana: 750,
        Wood: 4500,
        Iron: 10,
      },
      items: {},
    };
  }
}

export default new LightweightChest();
