import mem from "mem";
import { formatNumber } from "../../../utils/FormattingUtils";
import {
  registerTransformation,
  TransformationType,
} from "../../calculation/Calculation";
import { TransformationTags } from "../../calculation/TransformationTags";
import { AttackTarget } from "../../exploration/AttackTarget";
import { CombatStat } from "../../exploration/CombatStats";
import { PlayerContextState } from "../../PlayerContext";
import { addContextListener } from "../../PlayerContextListeners";
import { grantResource, recalculateCaps, Resource } from "../../Resources";
import { registerTimeTickListener } from "../../timetick/TimeTick";
import {
  canEquipItem,
  EquipmentSlot,
  equipToSlot,
  getEquippedItem,
} from "../Equipment";
import {
  ItemAction,
  ItemBase,
  ItemOccurrence,
  ItemParams,
  ItemTag,
} from "../Item";
import { getItemById } from "../Items";

const memoizedEquip = mem(
  (
    canEquipItem: boolean,
    slot: EquipmentSlot,
    id: string,
    params: ItemParams,
  ) => {
    return {
      description: "Equip",
      isEnabled: canEquipItem,
      transform: (state: PlayerContextState) => {
        state = equipToSlot(state, slot, {
          itemId: id,
          params,
        });
        return state;
      },
    };
  },
  { cacheKey: JSON.stringify },
);

export abstract class EquippableItem extends ItemBase {
  abstract getSlot(): EquipmentSlot;

  getTags(state: PlayerContextState, params: ItemParams): ItemTag[] {
    return [{ tag: "Equipment" }, { tag: this.getSlot() }];
  }

  getPrimaryAction(
    state: PlayerContextState,
    params: ItemParams,
  ): ItemAction | undefined {
    return memoizedEquip(
      canEquipItem(state, this.getSlot(), { itemId: this.getId(), params }),
      this.getSlot(),
      this.getId(),
      params,
    );
  }

  generateCraftedParams(): ItemParams {
    return {
      randomId: Math.random(),
      ...this.generateEquipmentCraftedParams(),
    };
  }

  generateEquipmentCraftedParams(): ItemParams {
    return {};
  }

  getAttackBonus(state: PlayerContextState, params: ItemParams): number {
    return 0;
  }

  getDefenseBonus(state: PlayerContextState, params: ItemParams): number {
    return 0;
  }

  getMaxHPBonus(state: PlayerContextState, params: ItemParams): number {
    return 0;
  }

  getHPRegenBonus(state: PlayerContextState, params: ItemParams): number {
    return 0;
  }

  getManaRegenBonus(state: PlayerContextState, params: ItemParams): number {
    return 0;
  }

  getMaxManaBonus(state: PlayerContextState, params: ItemParams): number {
    return 0;
  }

  getAttackDelayBonusPct(
    state: PlayerContextState,
    params: ItemParams,
  ): number {
    return 0;
  }

  getPlayerAccuracyBonusPct(
    state: PlayerContextState,
    params: ItemParams,
  ): number {
    return 0;
  }

  getPlayerDodgeBonusPct(
    state: PlayerContextState,
    params: ItemParams,
  ): number {
    return 0;
  }

  getPlayerCritChanceBonusPct(
    state: PlayerContextState,
    params: ItemParams,
  ): number {
    return 0;
  }

  getEffect(state: PlayerContextState, params: ItemParams): string | undefined {
    return this.getEffectBase(state, params);
  }

  getEffectExtra(
    state: PlayerContextState,
    params: ItemParams,
  ): string | undefined {
    return undefined;
  }

  getEffectBase(
    state: PlayerContextState,
    params: ItemParams,
  ): string | undefined {
    const bonuses: Record<string, number> = {
      attack: this.getAttackBonus(state, params),
      defense: this.getDefenseBonus(state, params),
      maxHP: this.getMaxHPBonus(state, params),
      hpRegen: this.getHPRegenBonus(state, params),
      manaRegen: this.getManaRegenBonus(state, params),
      maxMana: this.getMaxManaBonus(state, params),
      attackDelay: this.getAttackDelayBonusPct(state, params),
      accuracy: this.getPlayerAccuracyBonusPct(state, params),
      dodge: this.getPlayerDodgeBonusPct(state, params),
      critChance: this.getPlayerCritChanceBonusPct(state, params),
    };

    const bonusStrings = Object.keys(bonuses).map((stat) => {
      let value = bonuses?.[stat];
      if (value == 0) {
        return null;
      }
      let prefix = "+";
      if (value < 0) {
        prefix = "-";
        value = Math.abs(value);
      }
      switch (stat) {
        case "attack":
        case "defense":
        case "hpRegen":
          return (
            prefix +
            formatNumber(value, { showDecimals: true }) +
            ":" +
            stat +
            ":"
          );
        case "manaRegen":
          return prefix + formatNumber(value) + ":mana:/sec";
        case "maxHP":
          return prefix + formatNumber(value) + " Max:hp:";
        case "maxMana":
          return prefix + formatNumber(value) + " Max:mana:";
        case "attackDelay":
        case "accuracy":
        case "dodge":
        case "critChance":
          return prefix + formatNumber(value) + "%:" + stat + ":";
      }
    });

    const bonusEffect = this.getEffectExtra(state, params);
    bonusStrings.push(bonusEffect);

    return bonusStrings.filter((bonusString) => bonusString != null).join(", ");
  }
}

type EquippedItemFunction = (itemOccurrence: ItemOccurrence) => void;

const forEachEquippedItem = function (
  state: PlayerContextState,
  each: EquippedItemFunction,
): void {
  const equippedItemHand = getEquippedItem(state, EquipmentSlot.Hand);
  const equippedItemBody = getEquippedItem(state, EquipmentSlot.Body);
  const equippedItemAccessory = getEquippedItem(state, EquipmentSlot.Accessory);

  [equippedItemHand, equippedItemBody, equippedItemAccessory].forEach(
    (itemOccurrence) => {
      if (itemOccurrence == null) {
        return;
      }
      each(itemOccurrence);
    },
  );
};

type EquippedItemBonus = (
  state: PlayerContextState,
  item: EquippableItem,
  params: ItemParams,
) => number;

const additiveTransform = (bonusFn: EquippedItemBonus) => {
  return (state: PlayerContextState) => {
    let total = 0;
    forEachEquippedItem(state, (itemOccurrence) => {
      const item = getItemById(itemOccurrence.itemId) as EquippableItem;

      const bonus = bonusFn(state, item, itemOccurrence.params);
      if (bonus != 0) {
        total += bonus;
      }
    });

    return total;
  };
};

const pctMultiplicativeTransform = (bonusFn: EquippedItemBonus) => {
  return (state: PlayerContextState) => {
    let totalMultiplier = 1.0;
    forEachEquippedItem(state, (itemOccurrence) => {
      const item = getItemById(itemOccurrence.itemId) as EquippableItem;

      const bonus = bonusFn(state, item, itemOccurrence.params);
      if (bonus != 0) {
        totalMultiplier *= (100 + bonus) / 100.0;
      }
    });

    return totalMultiplier;
  };
};

registerTimeTickListener("EquippableItem", (state, timeElapsedSec) => {
  forEachEquippedItem(state, (itemOccurrence) => {
    const item = getItemById(itemOccurrence.itemId) as EquippableItem;

    const manaRegen = item.getManaRegenBonus(state, itemOccurrence.params);
    if (manaRegen != 0) {
      state = grantResource(Resource.Mana, manaRegen * timeElapsedSec)(state);
    }
  });

  return state;
});

registerTransformation(
  [[AttackTarget.Player, CombatStat.Attack]],
  "EquippableItemAttack",
  TransformationType.Addition,
  additiveTransform((state, item, params) =>
    item.getAttackBonus(state, params),
  ),
);

registerTransformation(
  [[AttackTarget.Player, CombatStat.Defense]],
  "EquippableItemDefense",
  TransformationType.Addition,
  additiveTransform((state, item, params) =>
    item.getDefenseBonus(state, params),
  ),
);

registerTransformation(
  [[AttackTarget.Player, CombatStat.MaxHP]],
  "EquippableItemMaxHP",
  TransformationType.Addition,
  additiveTransform((state, item, params) => item.getMaxHPBonus(state, params)),
);

registerTransformation(
  [[AttackTarget.Player, CombatStat.HPRegen]],
  "EquippableItemHPRegen",
  TransformationType.Addition,
  additiveTransform((state, item, params) =>
    item.getHPRegenBonus(state, params),
  ),
);

registerTransformation(
  [[AttackTarget.Player, CombatStat.AttackDelay]],
  "EquippableItemAttackDelay",
  TransformationType.Multiplier,
  pctMultiplicativeTransform((state, item, params) =>
    item.getAttackDelayBonusPct(state, params),
  ),
);

registerTransformation(
  [[AttackTarget.Player, CombatStat.Accuracy]],
  "EquippableItemAccuracy",
  TransformationType.Multiplier,
  pctMultiplicativeTransform((state, item, params) =>
    item.getPlayerAccuracyBonusPct(state, params),
  ),
);

registerTransformation(
  [[AttackTarget.Player, CombatStat.Dodge]],
  "EquippableItemDodge",
  TransformationType.Addition,
  additiveTransform(
    (state, item, params) => item.getPlayerDodgeBonusPct(state, params) / 100.0,
  ),
);

registerTransformation(
  [[AttackTarget.Player, CombatStat.CritChance]],
  "EquippableItemCritChance",
  TransformationType.Addition,
  additiveTransform(
    (state, item, params) =>
      item.getPlayerCritChanceBonusPct(state, params) / 100.0,
  ),
);

registerTransformation(
  [[Resource.Mana, TransformationTags.Cap]],
  "EquippableItemManaCap",
  TransformationType.Addition,
  (state) => {
    let capBonus = 0;
    forEachEquippedItem(state, (itemOccurrence) => {
      const item = getItemById(itemOccurrence.itemId) as EquippableItem;

      const maxManaBonus = item.getMaxManaBonus(state, itemOccurrence.params);
      if (maxManaBonus != 0) {
        capBonus += maxManaBonus;
      }
    });

    return capBonus;
  },
);

addContextListener("equippableItem_cap_listener", (oldState, newState) => {
  let shouldRecalculate = false;
  [EquipmentSlot.Hand, EquipmentSlot.Body, EquipmentSlot.Accessory].forEach(
    (equipmentSlot) => {
      const oldItem = getEquippedItem(oldState, equipmentSlot);
      const newItem = getEquippedItem(newState, equipmentSlot);
      if (oldItem?.itemId != newItem?.itemId) {
        shouldRecalculate = true;
      }
    },
  );
  if (shouldRecalculate) {
    return (state) => {
      return recalculateCaps(state);
    };
  }
});
