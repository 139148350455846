import { applyTransformations } from "../calculation/Calculation";
import { TransformationTags } from "../calculation/TransformationTags";
import { PlayerContextState, PlayerContextTransform } from "../PlayerContext";
import { grantSchoolExp, School } from "../spells/ElementsAndSchools";
import { registerTimeTickListener } from "../timetick/TimeTick";

function onTick(
  state: PlayerContextState,
  elapsedTimeSec: number,
): PlayerContextState {
  const researcherAllocation = state.research.researcherAllocation;
  for (let school in researcherAllocation.schools) {
    const allocatedResearchers =
      researcherAllocation.schools?.[school as School] || 0;
    if (allocatedResearchers > 0) {
      const researchRatePerResearcher = applyTransformations(
        [school, TransformationTags.Research],
        state,
        1.0,
      );
      state = grantSchoolExp(
        school as School,
        allocatedResearchers * researchRatePerResearcher * elapsedTimeSec,
      )(state);
    }
  }
  return state;
}

export function getBaseResearchRate(state: PlayerContextState) {
  return applyTransformations([TransformationTags.Research], state, 1.0);
}

export function getFreeResearchers(state: PlayerContextState): number {
  const researcherAllocation = state.research.researcherAllocation;
  let researchersAllocated = 0;
  for (let school in researcherAllocation.schools) {
    researchersAllocated += researcherAllocation.schools[school as School] || 0;
  }
  return state.research.totalResearchers - researchersAllocated;
}

function allocateResearchersToSchoolImpl(
  school: School,
  amount: number,
  state: PlayerContextState,
) {
  const currentAllocation =
    state.research.researcherAllocation.schools?.[school] || 0;
  const actualAmountToAllocate =
    amount > 0
      ? Math.min(getFreeResearchers(state), amount)
      : Math.max(-currentAllocation, amount);
  state.research.researcherAllocation.schools[school] =
    currentAllocation + actualAmountToAllocate;
  return state;
}

export function allocateResearchersToSchool(
  this: any,
  school: School,
  amount: number,
): PlayerContextTransform {
  // Do it this way to avoid creating extra functions and avoid rerenders
  return allocateResearchersToSchoolImpl.bind(this, school, amount);
}

function grantNewResearcherImpl(amount: number, state: PlayerContextState) {
  state.research.totalResearchers = Math.max(
    0,
    state.research.totalResearchers + amount,
  );
  return state;
}

export function grantNewResearcher(
  this: any,
  amount: number,
): PlayerContextTransform {
  // Do it this way to avoid creating extra functions and avoid rerenders
  return grantNewResearcherImpl.bind(this, amount);
}

export function calculateResearcherCap(state: PlayerContextState): number {
  return applyTransformations(
    [TransformationTags.Researcher, TransformationTags.Cap],
    state,
    1,
  );
}

export function loadResearchers(): void {
  registerTimeTickListener("Researchers", onTick);
}
