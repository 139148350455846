import { formatNumber } from "../../../utils/FormattingUtils";
import { ActionEffect } from "../../actions/Action";
import {
  registerTransformation,
  TransformationType,
} from "../../calculation/Calculation";
import { TransformationTags } from "../../calculation/TransformationTags";
import { AttackTarget } from "../../exploration/AttackTarget";
import { CombatStat } from "../../exploration/CombatStats";
import { hasFlag } from "../../Flags";
import { PlayerContextState } from "../../PlayerContext";
import {
  grantTemporaryEffect,
  hasTemporaryEffect,
} from "../../timetick/TemporaryEffects";
import { SpellActionBase } from "../Spell";

class Stoneskin extends SpellActionBase {
  constructor() {
    super();
  }

  isVisible(state: PlayerContextState): boolean {
    return super.isVisible(state) && hasFlag(state, "exploration_unlocked");
  }

  getSpellName(): string {
    return "Stoneskin";
  }

  getArea(): string {
    return "Exploration";
  }

  getDisplayDescription(): string {
    return "Harden your skin to render yourself stronger against attacks.";
  }

  protected getBaseActionEffects(): Record<string, ActionEffect> {
    return {
      magnitude: {
        value: 10,
        tags: [TransformationTags.TemporaryEffectMagnitude],
      },
      duration: {
        value: 40,
        tags: [TransformationTags.TemporaryEffectDuration],
      },
    };
  }

  doSpellAction(state: PlayerContextState): PlayerContextState {
    const effects = this.getActionEffects(state);
    return grantTemporaryEffect(
      this.getId(),
      this.getSpellName(),
      effects.duration,
      {},
    )(state);
  }

  getDisplayEffect(state: PlayerContextState): string {
    const effects = this.getActionEffects(state);
    return `+${formatNumber(effects.magnitude)}:defense: for ${formatNumber(
      effects.duration,
    )} sec`;
  }

  getManaCost(): number {
    return 480;
  }

  getSchoolLevelRequirements(): Record<string, number> {
    return {
      Enchantment: 6,
    };
  }
}

const stoneskin = new Stoneskin();

registerTransformation(
  [[AttackTarget.Player, CombatStat.Defense]],
  "Stoneskin",
  TransformationType.Addition,
  (state) =>
    hasTemporaryEffect(state, "Stoneskin")
      ? stoneskin.getActionEffects(state).magnitude
      : 0,
);

export default stoneskin;
