import { applyTransformations } from "../../../calculation/Calculation";
import { TransformationTags } from "../../../calculation/TransformationTags";
import { buildEventMessage } from "../../../events/EventMessageBuilder";
import { triggerEvent } from "../../../events/Events";
import { createGameEvent } from "../../../events/SimpleGameEvent";
import { hasFlag, setFlag } from "../../../Flags";
import { PlayerContextState } from "../../../PlayerContext";
import { addContextListener } from "../../../PlayerContextListeners";
import { getResourceAmount, grantResource, Resource } from "../../../Resources";
import {
  getSchoolLevel,
  getTotalElementAndSchoolExp,
  School,
} from "../../../spells/ElementsAndSchools";
import { EquipmentSlot } from "../../Equipment";
import { addToInventory } from "../../Inventory";
import { ItemParams } from "../../Item";
import { EquippableItem } from "../EquippableItem";

class WizardRobe extends EquippableItem {
  getId(): string {
    return "wizardRobe";
  }

  getPicture() {
    return require("./wizardRobe.png");
  }

  getBaseName(): string {
    return "Wizard's Robe";
  }

  getDescription(): string | undefined {
    return "Magically infused clothing, for those who favor spellcasting.";
  }

  getDefenseBonus(state: PlayerContextState, params: ItemParams): number {
    return 6;
  }

  getManaRegenBonus(state: PlayerContextState, params: ItemParams): number {
    return applyTransformations(
      [this.getId(), Resource.Mana, TransformationTags.Production],
      state,
      10.0,
    );
  }

  getMaxManaBonus(state: PlayerContextState, params: ItemParams): number {
    return 400;
  }

  getSlot(): EquipmentSlot {
    return EquipmentSlot.Body;
  }

  getSalePrice(): number {
    return 2000;
  }

  getCraftingSchoolLevelRequirements() {
    return {
      Conjuration: 6,
      Enchantment: 8,
    };
  }

  getCraftingMaterialsBase() {
    return {
      resources: { Mana: 1600 },
      items: { cloth: 12 },
    };
  }
}

const wizardRobe = new WizardRobe();

const message = buildEventMessage(
  "WizardRobe_Merchant",
  "Clothing for a Wizard",
)
  .setSimpleDescription(
    `You hear a familiar voice. "You won't believe the deal I have for yoooooou."
    
You turn around, only to see the familiar face of the businessman who has been buying your items.`,
  )
  .addOption(
    "Continue",
    buildEventMessage("WizardRobe_Merchant_1", "Clothing for a Wizard")
      .setSimpleDescription(
        `He shows you a finely crafted :wizardRobe:*Wizard's Robe*. You inspect it and find no fault with it; it is magically infused and you can feel a faint power coming from it.
        
"Would you want to buy it? It can be yooooours... for the *bargain* price of 30000 coins. And I'm sure it would look great on you..."`,
      )
      .addOption(
        "Charm the Businessman (1400 Mana)",
        buildEventMessage("WizardRobe_Merchant_4", "Clothing for a Wizard")
          .setSimpleDescription(
            `You use your Illusion prowess to charm the businessman; the price is too high. You attempt to barter for the robe...
            
"Hmm..." He observes you, top to bottom. "Ok. I will go lower. How about... 5000 coins?"

*Now* you think the price is more fair.`,
          )
          .addOption(
            "Pay 5000 Coins",
            buildEventMessage("WizardRobe_Merchant_5", "Clothing for a Wizard")
              .setSimpleDescription(
                `You hand over the coins and buy the robe. The businessman grins slightly. "Great doooooing business with you!", he exclaims, before handing the robe to you.
                
**You have obtained a :wizardRobe:Wizard's Robe!**`,
              )
              .build(),
            (state) => {
              state = grantResource(Resource.Coins, -5000)(state);
              state = addToInventory(
                { itemId: wizardRobe.getId(), params: {} },
                1,
                state,
              );
              state = setFlag("wizard_robe_bought")(state);
              return state;
            },
            undefined,
            (state) => getResourceAmount(state, Resource.Coins) >= 5000,
          )
          .addOption("Maybe later")
          .build(),
        (state) => {
          state = grantResource(Resource.Mana, -1400)(state);
          return state;
        },
        (state) => getSchoolLevel(state, School.Illusion) >= 6,
        (state) => getResourceAmount(state, Resource.Mana) >= 1400,
      )
      .addOption(
        "Pay 30000 Coins",
        buildEventMessage("WizardRobe_Merchant_2", "Clothing for a Wizard")
          .setSimpleDescription(
            `You hand over the coins and buy the robe. The businessman grins. "Great doooooing business with you!", he exclaims, before handing the robe to you.

**You have obtained a :wizardRobe:Wizard's Robe!**`,
          )
          .build(),
        (state) => {
          state = grantResource(Resource.Coins, -30000)(state);
          state = addToInventory(
            { itemId: wizardRobe.getId(), params: {} },
            1,
            state,
          );
          state = setFlag("wizard_robe_bought")(state);
          return state;
        },
        undefined,
        (state) => getResourceAmount(state, Resource.Coins) >= 30000,
      )
      .addOption("Maybe later")
      .build(),
  )
  .build();

const event = createGameEvent(
  "Wizard_Robe",
  message,
  setFlag("wizard_robe_triggered"),
);

addContextListener("wizard_robe", (oldState, newState) => {
  if (
    !hasFlag(newState, "wizard_robe_triggered") &&
    hasFlag(newState, "selling_unlocked") &&
    getTotalElementAndSchoolExp(newState) >= 24000
  ) {
    return triggerEvent(event);
  }
});

export default new WizardRobe();
