import React, { useContext, useEffect, useRef } from "react";
import { Animated, StyleSheet, View } from "react-native";
import { PlayerContext } from "../../backend/PlayerContext";
import {
  getResourceAmount,
  getResourceCap,
  Resource,
} from "../../backend/Resources";
import { formatNumber } from "../../utils/FormattingUtils";
import { GameIcon } from "../utility/icons/GameIcon";
import { Text } from "../utility/Text";

/*
 * This component displays a mana bar. However, it's a total hack and will
 * certainly not look ok if it's not full-screen-width.
 */
export default function ManaBar() {
  const playerContext = useContext(PlayerContext);
  const mana = getResourceAmount(playerContext, Resource.Mana);
  const manaCap = getResourceCap(playerContext, Resource.Mana);
  const ratioFilled = mana / manaCap;

  const filledAnim = useRef(new Animated.Value(ratioFilled)).current;

  useEffect(() => {
    Animated.timing(filledAnim, {
      toValue: Math.max(ratioFilled, 1e-5),
      duration: 100,
      useNativeDriver: true,
    }).start();
  }, [ratioFilled]);
  return (
    <View style={styles.container}>
      <Animated.View
        style={[styles.filled, { transform: [{ scaleX: filledAnim }] }]}
      />
      <View style={styles.row}>
        <GameIcon icon="mana" style={styles.icon} />
        <Text style={styles.textOverlay}>
          {formatNumber(mana)}/{formatNumber(manaCap)}
        </Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
    backgroundColor: "#014",
    height: 29,
  },
  filled: {
    backgroundColor: "#04c",
    position: "absolute",
    left: "-100%",
    top: 0,
    height: "100%",
    width: "200%",
  },
  textOverlay: {
    color: "#fff",
    fontWeight: "bold",
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    height: 16,
    width: 16,
    marginRight: 4,
  },
});
