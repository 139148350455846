import React, { useContext, useMemo, useRef } from "react";
import { StyleSheet, View } from "react-native";
import { Button } from "react-native-elements";
import {
  EquipmentSlot,
  getEquippedItem,
  getUnequipAction,
} from "../../backend/items/Equipment";
import { EquippableItem } from "../../backend/items/equipment/EquippableItem";
import { getItemById } from "../../backend/items/Items";
import { PlayerContext } from "../../backend/PlayerContext";
import { Text } from "../utility/Text";
import { ItemTile } from "./ItemTile";

function EquippedItemSlot(props: {
  slot: EquipmentSlot;
  onPressEquippedItem?: (slot: EquipmentSlot) => void;
}) {
  const playerContext = useContext(PlayerContext);
  const slot = props.slot;
  const equippedItem = getEquippedItem(playerContext, slot);
  const unequipAction = getUnequipAction(playerContext, slot);

  const playerContextRef = useRef(playerContext);
  playerContextRef.current = playerContext;

  const item = equippedItem && getItemById(equippedItem.itemId);
  if (item && !(item instanceof EquippableItem)) {
    throw new Error("Only equippable items should be equipped");
  }

  return useMemo(() => {
    if (!equippedItem) {
      return (
        <View key={slot} style={styles.equipmentRow}>
          <View style={styles.row}>
            <ItemTile />
            <Text numberOfLines={2} style={styles.labelStyle} h4>
              {slot}: Empty
            </Text>
          </View>
          <Button
            title={unequipAction.description}
            disabled={!unequipAction.isEnabled}
            onPress={() =>
              unequipAction.transform &&
              playerContextRef.current.apply(unequipAction.transform)
            }
          />
        </View>
      );
    }
    return (
      <View key={slot} style={styles.equipmentRow}>
        <View style={styles.row}>
          <ItemTile
            icon={(item as EquippableItem).getId()}
            onPress={
              props.onPressEquippedItem &&
              (() =>
                props.onPressEquippedItem && props.onPressEquippedItem(slot))
            }
          />
          <Text numberOfLines={2} style={styles.labelStyle} h4>
            {slot}: {(item as EquippableItem).getName(equippedItem.params)}
          </Text>
        </View>
        <Button
          title={unequipAction.description}
          disabled={!unequipAction.isEnabled}
          onPress={() =>
            unequipAction.transform &&
            playerContext.apply(unequipAction.transform)
          }
        />
      </View>
    );
  }, [
    JSON.stringify(equippedItem),
    slot,
    unequipAction.description,
    unequipAction.isEnabled,
    unequipAction.transform,
    playerContextRef,
    item?.getName(equippedItem?.params || {}),
    props.onPressEquippedItem,
  ]);
}

export default function EquippedItems(props: {
  onPressEquippedItem?: (slot: EquipmentSlot) => void;
}) {
  const slots = Object.keys(EquipmentSlot);
  const slotComponents = slots.map((slot) => {
    return (
      <EquippedItemSlot
        key={slot}
        slot={slot as EquipmentSlot}
        onPressEquippedItem={props.onPressEquippedItem}
      />
    );
  });

  return <View>{slotComponents}</View>;
}

const styles = StyleSheet.create({
  equipmentRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    flex: 1,
  },
  labelStyle: {
    marginHorizontal: 8,
    flex: 1,
  },
});
