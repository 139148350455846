import { ActionEffect } from "../../actions/Action";
import { TransformationTags } from "../../calculation/TransformationTags";
import { PlayerContextState } from "../../PlayerContext";
import { CombatSpellBase } from "../CombatSpellBase";
import { SpellElement } from "../ElementsAndSchools";
import {
  getStandardAttackEffectText,
  getStandardSpellAttackEffect,
} from "../Utils";

class RockTomb extends CombatSpellBase {
  getSpellName(): string {
    return "Rock Tomb";
  }

  getElement(): SpellElement | undefined {
    return SpellElement.Earth;
  }

  getDisplayDescription(): string {
    return "Bury an enemy below a pile of heavy stones.";
  }

  protected getBaseActionEffects(): Record<string, ActionEffect> {
    return {
      average: {
        value: 170,
        tags: [TransformationTags.ActionMagnitude],
      },
      variance: {
        value: 50,
        tags: [TransformationTags.ActionVariance],
      },
    };
  }

  getDisplayEffect(state: PlayerContextState): string {
    const effects = this.getActionEffects(state);
    return getStandardAttackEffectText(effects.average, effects.variance);
  }

  doSpellAction(state: PlayerContextState): PlayerContextState {
    return getStandardSpellAttackEffect(state, this);
  }

  getBaseCooldown(): number {
    return 3.5;
  }

  isVisible(state: PlayerContextState): boolean {
    return super.isVisible(state) && state.primaryElement == "Earth";
  }

  getManaCost(): number {
    return 800;
  }

  getSchoolLevelRequirements(): Record<string, number> {
    return {
      Evocation: 8,
    };
  }
}

export default new RockTomb();
