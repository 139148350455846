import React, { useContext, useMemo } from "react";
import { StyleSheet, View } from "react-native";
import {
  calculatePlayerAccuracy,
  calculatePlayerAttack,
  calculatePlayerAttackDelaySec,
  calculatePlayerCritChance,
  calculatePlayerDefense,
  calculatePlayerDodgeChance,
  calculatePlayerHPRegenPerSec,
  calculatePlayerMaxHP,
} from "../../backend/exploration/CombatStats";
import { getCurrentPlayerHP } from "../../backend/exploration/Exploration";
import { PlayerContext } from "../../backend/PlayerContext";
import { formatNumber } from "../../utils/FormattingUtils";
import { GameIcon } from "./icons/GameIcon";
import { Text } from "./Text";

function CombatStatsRow(props: {
  stat: string;
  icon: string;
  value: string;
  hideText?: boolean;
}) {
  return (
    <View style={styles.inlineRow}>
      <View style={styles.inlineRow}>
        <GameIcon icon={props.icon} style={styles.icon} small />
        {!props.hideText && <Text>{props.stat}: </Text>}
      </View>
      <Text>{props.value}</Text>
    </View>
  );
}

const CombatStatsRowMemo = React.memo(CombatStatsRow);

export function CombatStatsViewer(props: {
  hideText?: boolean;
  isVisible?: boolean;
}) {
  const playerContext = useContext(PlayerContext);
  const currentHP = getCurrentPlayerHP(playerContext);
  const maxHP = calculatePlayerMaxHP(playerContext);
  const accuracy = Math.max(0, calculatePlayerAccuracy(playerContext));
  const dodgeChance = calculatePlayerDodgeChance(playerContext);
  const critChance = calculatePlayerCritChance(playerContext);
  return useMemo(
    () => (
      <View style={styles.container}>
        <CombatStatsRowMemo
          stat="HP"
          icon="hp"
          hideText={props.hideText}
          value={formatNumber(currentHP) + "/" + formatNumber(maxHP)}
        />
        <CombatStatsRowMemo
          stat="Attack"
          icon="attack"
          hideText={props.hideText}
          value={formatNumber(calculatePlayerAttack(playerContext))}
        />
        <CombatStatsRowMemo
          stat="Attack Delay"
          icon="attackDelay"
          hideText={props.hideText}
          value={
            formatNumber(calculatePlayerAttackDelaySec(playerContext), {
              showDecimals: true,
            }) + " sec"
          }
        />
        <CombatStatsRowMemo
          stat="Defense"
          icon="defense"
          hideText={props.hideText}
          value={formatNumber(calculatePlayerDefense(playerContext))}
        />
        <CombatStatsRowMemo
          stat="HP Regen"
          icon="hpRegen"
          hideText={props.hideText}
          value={
            formatNumber(calculatePlayerHPRegenPerSec(playerContext), {
              showDecimals: true,
            }) + "/sec"
          }
        />
        {accuracy != 1.0 && (
          <CombatStatsRowMemo
            stat="Accuracy"
            icon="accuracy"
            hideText={props.hideText}
            value={
              formatNumber(accuracy * 100, {
                showDecimals: false,
              }) + "%"
            }
          />
        )}
        {dodgeChance > 0 && (
          <CombatStatsRowMemo
            stat="Dodge Chance"
            icon="dodge"
            hideText={props.hideText}
            value={
              formatNumber(dodgeChance * 100, {
                showDecimals: false,
              }) + "%"
            }
          />
        )}
        {critChance > 0 && (
          <CombatStatsRowMemo
            stat="Critical Chance"
            icon="critChance"
            hideText={props.hideText}
            value={
              formatNumber(critChance * 100, {
                showDecimals: false,
              }) + "%"
            }
          />
        )}
      </View>
    ),
    [props.isVisible !== false ? playerContext : false, props.hideText],
  );
}

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "stretch",
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  inlineRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  icon: {
    marginRight: 2,
  },
});
