import {
  registerTransformation,
  TransformationType,
} from "../calculation/Calculation";
import { TransformationTags } from "../calculation/TransformationTags";
import {
  PlayerAttackListeners,
  SummonAttackListeners,
} from "../exploration/AttackListeners";
import { AttackTarget } from "../exploration/AttackTarget";
import { CombatStat } from "../exploration/CombatStats";
import {
  getCurrentSummonHP,
  getLastPlayerActionResult,
  modifyEnemyCurrentHP,
  modifyPlayerCurrentHP,
  modifySummonCurrentHP,
} from "../exploration/Exploration";
import { addContextListener } from "../PlayerContextListeners";
import { School } from "../spells/ElementsAndSchools";
import DispelSelf from "../spells/protection/DispelSelf";
import {
  getTemporaryEffects,
  hasTemporaryEffect,
  TemporaryEffectData,
} from "./TemporaryEffects";
import { registerTimeTickListener } from "./TimeTick";
import { getSecondsPlayed } from "./TotalTimePlayed";

export function loadCommonTemporaryEffects() {
  registerTimeTickListener("CommonDoT", (state, timeElapsedSec) => {
    const temporaryEffects = getTemporaryEffects(state).filter(
      (effect) => effect.params?.commonDoTAmount != null,
    );
    temporaryEffects.forEach((effect) => {
      if (effect.isEnemyTemporaryEffect) {
        state = modifyEnemyCurrentHP(
          state,
          -effect.params.commonDoTAmount * timeElapsedSec,
        );
      } else {
        state = modifyPlayerCurrentHP(
          state,
          -effect.params.commonDoTAmount * timeElapsedSec,
        );
      }
    });
    return state;
  });

  Object.keys(AttackTarget).forEach((target) => {
    Object.keys(CombatStat).forEach((stat) => {
      registerTransformation(
        [[target, stat]],
        "CommonBuff" + target + stat,
        TransformationType.Addition,
        (state) => {
          const temporaryEffects = getTemporaryEffects(state).filter(
            (effect) => effect.params?.commonBuff?.[target]?.[stat] != null,
          );
          let totalValue = 0;
          temporaryEffects.forEach((effect) => {
            totalValue += effect.params?.commonBuff?.[target]?.[stat] ?? 0;
          });
          return totalValue;
        },
      );
      registerTransformation(
        [[target, stat]],
        "CommonBuffMultiplication" + target + stat,
        TransformationType.Multiplier,
        (state) => {
          const temporaryEffects = getTemporaryEffects(state).filter(
            (effect) =>
              effect.params?.commonBuff?.[target]?.[stat + "Multiplier"] !=
              null,
          );
          let totalValue = 1.0;
          temporaryEffects.forEach((effect) => {
            totalValue *=
              effect.params?.commonBuff?.[target]?.[stat + "Multiplier"] ?? 1.0;
          });
          return totalValue;
        },
      );
    });
  });

  Object.keys(School).forEach((school) => {
    registerTransformation(
      [
        [school, TransformationTags.CanCastSpell],
        // All selective silence blocks dispel
        [DispelSelf.getId(), TransformationTags.CanCastSpell],
      ],
      "SelectiveSilence" + school,
      TransformationType.Multiplier,
      (state, params) =>
        hasTemporaryEffect(state, "SelectiveSilence" + school) ? 0 : 1,
    );
  });

  registerTimeTickListener("PlayerPoison", (state, timeElapsedSec) => {
    if (hasTemporaryEffect(state, "PlayerPoison")) {
      state = modifyPlayerCurrentHP(
        state,
        -(state.temporaryEffects["PlayerPoison"] as TemporaryEffectData).params
          .amount * timeElapsedSec,
      );
    }
    return state;
  });
  registerTimeTickListener("Enemy", (state, timeElapsedSec) => {
    if (hasTemporaryEffect(state, "EnemyRegeneration")) {
      state = modifyEnemyCurrentHP(
        state,
        (state.temporaryEffects["EnemyRegeneration"] as TemporaryEffectData)
          .params.amount * timeElapsedSec,
      );
    }
    if (hasTemporaryEffect(state, "EnemyPoison")) {
      state = modifyEnemyCurrentHP(
        state,
        -(state.temporaryEffects["EnemyPoison"] as TemporaryEffectData).params
          .amount * timeElapsedSec,
      );
    }
    return state;
  });
  registerTimeTickListener("Summon", (state, timeElapsedSec) => {
    if (
      hasTemporaryEffect(state, "SummonRegeneration") &&
      getCurrentSummonHP(state) > 0
    ) {
      state = modifySummonCurrentHP(
        state,
        (state.temporaryEffects["SummonRegeneration"] as TemporaryEffectData)
          .params.amount * timeElapsedSec,
      );
    }
    return state;
  });
  PlayerAttackListeners.register((oldState, state) => {
    if (hasTemporaryEffect(state, "CloakOfFlames")) {
      const damage = getLastPlayerActionResult(state)?.damage;
      if (damage && damage > 0) {
        state = modifyPlayerCurrentHP(state, -damage * 0.3);

        const actionResult = {
          id: Math.random().toString(),
          damage: damage * 0.3,
          isHit: true,
          isCrit: false,
          time: getSecondsPlayed(state),
          target: AttackTarget.Player,
          tags: [],
        };
        state.exploration.lastEnemyActionResult = actionResult;
        state.exploration.actionResultQueue.push(actionResult);
      }
    }
    return state;
  });
  SummonAttackListeners.register((oldState, state) => {
    if (hasTemporaryEffect(state, "CloakOfFlames")) {
      const damage = getLastPlayerActionResult(state)?.damage;
      if (damage && damage > 0) {
        state = modifySummonCurrentHP(state, -damage * 0.3);

        const actionResult = {
          id: Math.random().toString(),
          damage,
          isHit: true,
          isCrit: false,
          time: getSecondsPlayed(state),
          target: AttackTarget.Summon,
          tags: [],
        };
        state.exploration.lastEnemyActionResult = actionResult;
        state.exploration.actionResultQueue.push(actionResult);
      }
    }
    return state;
  });

  addContextListener("EnemyReflect", (oldState, newState) => {
    if (!hasTemporaryEffect(newState, "EnemyReflect")) {
      return;
    }
    const hasNewAttack =
      newState.exploration?.lastPlayerActionResult?.id !==
      oldState.exploration?.lastPlayerActionResult?.id;
    if (!hasNewAttack) {
      return;
    }
    const isSpellAttack =
      newState.exploration?.lastPlayerActionResult?.tags?.includes(
        TransformationTags.Spell,
      );
    const damage = newState.exploration?.lastPlayerActionResult?.damage || 0;
    if (isSpellAttack && damage > 0) {
      return (state) => {
        state = modifyPlayerCurrentHP(state, -damage);

        const actionResult = {
          id: Math.random().toString(),
          damage: damage,
          isHit: true,
          isCrit: false,
          time: getSecondsPlayed(state),
          target: AttackTarget.Player,
          tags: [],
        };
        state.exploration.lastEnemyActionResult = actionResult;
        state.exploration.actionResultQueue.push(actionResult);
        return state;
      };
    }
  });
}
