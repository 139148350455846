import { formatNumber } from "../../../utils/FormattingUtils";
import { ActionEffect } from "../../actions/Action";
import { TransformationTags } from "../../calculation/TransformationTags";
import { hasGlobalFlag } from "../../Flags";
import { PlayerContextState } from "../../PlayerContext";
import { CombatSpellBase } from "../CombatSpellBase";
import { SpellElement } from "../ElementsAndSchools";
import {
  getStandardAttackEffectText,
  getStandardWaterAttackEffect,
} from "../Utils";

class Blizzard extends CombatSpellBase {
  getSpellName(): string {
    return "Blizzard";
  }

  getElement(): SpellElement | undefined {
    return SpellElement.Water;
  }

  getDisplayDescription(): string {
    return "Materialize a bitter cold blizzard to damage your enemy.";
  }

  protected getBaseActionEffects(): Record<string, ActionEffect> {
    return {
      average: {
        value: 175,
        tags: [TransformationTags.ActionMagnitude],
      },
      variance: {
        value: 15,
        tags: [TransformationTags.ActionVariance],
      },
      slowDuration: {
        value: 2.5,
        tags: [TransformationTags.TemporaryEffectDuration],
      },
    };
  }

  getDisplayEffect(state: PlayerContextState): string {
    const effects = this.getActionEffects(state);
    if (hasGlobalFlag(state, "advanced_evocation_unlocked")) {
      return (
        getStandardAttackEffectText(effects.average, effects.variance) +
        `, +20:attackDelay: for ${formatNumber(effects.slowDuration, {
          showDecimals: true,
        })} sec`
      );
    }
    return getStandardAttackEffectText(effects.average, effects.variance);
  }

  doSpellAction(state: PlayerContextState): PlayerContextState {
    return getStandardWaterAttackEffect(state, this);
  }

  getBaseCooldown(): number {
    return 4.5;
  }

  isVisible(state: PlayerContextState): boolean {
    return super.isVisible(state) && state.primaryElement == "Water";
  }

  getManaCost(): number {
    return 550;
  }

  getSchoolLevelRequirements(): Record<string, number> {
    return {
      Evocation: 13,
    };
  }
}

export default new Blizzard();
