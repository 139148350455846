import React, { useContext, useRef, useState } from "react";
import { StyleSheet, View } from "react-native";
import {
  calculateInventoryCap,
  getInventory,
} from "../../backend/items/Inventory";
import { getItemById } from "../../backend/items/Items";
import { PlayerContext } from "../../backend/PlayerContext";
import { addContextListener } from "../../backend/PlayerContextListeners";
import { ItemDetails } from "./ItemDetails";
import { ItemGrid } from "./ItemGrid";

export default function InventoryItems(props: {
  disableSelling?: boolean;
  showQuickbar?: boolean;
}) {
  const playerContext = useContext(PlayerContext);
  const [selectedIndex, setSelectedIndex] = useState(null as number | null);
  const inventory = getInventory(playerContext);
  const itemGridElements = inventory.map((itemStack) => ({
    itemId: itemStack.itemOccurrence.itemId,
    amount: itemStack.amount,
  }));
  const selectedItemOccurrence =
    selectedIndex != null
      ? inventory[selectedIndex]?.itemOccurrence
      : undefined;
  const selectedItemParams = selectedItemOccurrence
    ? selectedItemOccurrence.params
    : undefined;
  const selectedItem = selectedItemOccurrence
    ? getItemById(selectedItemOccurrence.itemId)
    : undefined;
  const emptySlots = calculateInventoryCap(playerContext) - inventory.length;

  const selectedIndexRef = useRef(selectedIndex);
  selectedIndexRef.current = selectedIndex;

  addContextListener("InventoryComponent", (oldState, newState) => {
    const oldInventory = getInventory(oldState);
    const newInventory = getInventory(newState);
    const oldSelectedItemOccurrence =
      selectedIndexRef.current != null
        ? oldInventory[selectedIndexRef.current]?.itemOccurrence
        : undefined;
    const newSelectedItemOccurrence =
      selectedIndexRef.current != null
        ? newInventory[selectedIndexRef.current]?.itemOccurrence
        : undefined;

    if (
      JSON.stringify(oldSelectedItemOccurrence) !=
      JSON.stringify(newSelectedItemOccurrence)
    ) {
      setSelectedIndex(null);
    }
  });

  const itemDetails = selectedIndex != null && selectedItemOccurrence && (
    <ItemDetails
      disableSelling={props.disableSelling}
      showQuickbar={props.showQuickbar}
      itemStack={inventory[selectedIndex]}
    />
  );

  const onPressItem = React.useCallback(
    (item: any, index: number) => {
      setSelectedIndex(index);
    },
    [setSelectedIndex],
  );

  return (
    <View>
      <ItemGrid
        items={itemGridElements}
        onPressItem={onPressItem}
        dummySlotAmount={emptySlots}
      />
      <View style={styles.detailsContainer}>{itemDetails}</View>
    </View>
  );
}

const styles = StyleSheet.create({
  detailsContainer: {
    marginTop: 18,
  },
});
