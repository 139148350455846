import { useCallback, useContext, useRef } from "react";
import { StyleSheet, View } from "react-native";
import { CheckBox } from "react-native-elements";
import { PlayerContext } from "../../backend/PlayerContext";

export function AnalyticsContainer() {
  const playerContext = useContext(PlayerContext);
  const playerContextRef = useRef(playerContext);
  playerContextRef.current = playerContext;

  const analyticsToggle = useCallback(() => {
    playerContextRef.current.apply((oldState) => {
      oldState.global.allowAnalytics = !oldState.global.allowAnalytics;
      return oldState;
    });
  }, []);

  return (
    <View style={styles.targetFpsContainer}>
      <CheckBox
        title="Allow progress data collection"
        checked={playerContext.global.allowAnalytics}
        onPress={analyticsToggle}
        textStyle={styles.checkboxText}
        containerStyle={styles.checkboxContainer}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  targetFpsContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  checkboxContainer: {
    marginLeft: 0,
    marginTop: 8,
    padding: 0,
  },
  checkboxText: {
    fontWeight: "normal",
  },
});
