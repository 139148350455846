import { PlayerContextState } from "../../PlayerContext";
import { Upgrade } from "../Upgrade";
import reinforcedWarehouses from "./ReinforcedWarehouses";

class ManaCrystals extends Upgrade {
  getName(): string {
    return "Mana Crystals";
  }

  getMonstiumCost(): number {
    return 3500;
  }

  getDisplayDescription(state: PlayerContextState): string {
    return "Unlock the ability to build Mana Crystals, which let you store more :mana: than Mana Shards.";
  }

  getDisplayEffect(state: PlayerContextState): string {
    return "Unlock Mana Crystals";
  }

  getUpgradeRequirements() {
    return [reinforcedWarehouses];
  }
}

const manaCrystals = new ManaCrystals();

export default manaCrystals;
