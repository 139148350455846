import React, { useCallback, useContext, useMemo, useState } from "react";
import { StyleSheet, View } from "react-native";
import { Divider } from "react-native-elements";
import { getActionsForAreaByCategory } from "../../backend/actions/Actions";
import { PlayerContext } from "../../backend/PlayerContext";
import { ActionContainer } from "./ActionContainer";
import CategoryContainer from "./CategoryContainer";

type ActionsContainerProps = { area: string; showAddToQuickbar?: boolean };

function ActionCategoryContainer(
  props: ActionsContainerProps & {
    category: string;
    expandedAction: string;
    setActionCallback: (actionName: string) => void;
  },
) {
  const categories = getActionsForAreaByCategory(props.area);
  const category = props.category;
  const playerContext = useContext(PlayerContext);
  const actionNames = Object.keys(categories[category] || {});
  const visibleActionNames = actionNames.filter((actionName) => {
    return categories[category][actionName].isVisible(playerContext);
  });

  const actionContainers = useMemo(
    () =>
      visibleActionNames.map((actionName) => {
        const action = categories[category][actionName];
        return (
          <ActionContainer
            key={actionName}
            action={action}
            collapsed={props.expandedAction != actionName}
            onPressExpand={props.setActionCallback.bind(null, actionName)}
            showAddToQuickbar={props.showAddToQuickbar}
          />
        );
      }),
    [
      visibleActionNames.join(","),
      category,
      props.expandedAction,
      props.setActionCallback,
      props.showAddToQuickbar,
    ],
  );

  return useMemo(() => {
    if (visibleActionNames.length === 0) {
      return null;
    }
    return (
      <CategoryContainer title={category} key={category}>
        <View key={visibleActionNames[0]}>{actionContainers[0]}</View>
        {actionContainers.slice(1).map((actionContainer, idx) => {
          return (
            <View key={visibleActionNames[idx]}>
              <Divider />
              {actionContainer}
            </View>
          );
        })}
      </CategoryContainer>
    );
  }, [visibleActionNames.join(","), actionContainers, category]);
}

export default function ActionsContainer(props: ActionsContainerProps) {
  const [expandedAction, setExpandedAction] = useState("");
  const setActionCallback = useCallback(
    (actionName: string) => {
      if (expandedAction == actionName) {
        setExpandedAction("");
      } else {
        setExpandedAction(actionName);
      }
    },
    [expandedAction],
  );

  const actionCategories = ["Actions", "Buildings"].map((category) => (
    <ActionCategoryContainer
      {...props}
      category={category}
      expandedAction={expandedAction}
      key={category}
      setActionCallback={setActionCallback}
    />
  ));

  return <View>{actionCategories}</View>;
}

const styles = StyleSheet.create({
  category: { marginTop: 16 },
  categoryTitle: { marginBottom: 4 },
  categoryDivider: { height: StyleSheet.hairlineWidth * 2 },
});
