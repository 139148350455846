import { PlayerContextState } from "../../../PlayerContext";
import { calculatePlayerDefense } from "../../CombatStats";
import {
  calculateDamage,
  modifyEnemyCurrentHP,
  modifyPlayerCurrentHP,
  standardEnemyAttackEffect,
} from "../../Exploration";
import { BattlerAction, BattlerStats, Enemy } from "../Enemy";

class BasementBat extends Enemy {
  getId(): string {
    return "basementBat";
  }

  getName(): string {
    return "Basement Bat";
  }

  getPicture() {
    return require("./basementBat.png");
  }

  getBaseStats(): BattlerStats {
    return {
      maxHP: 30,
      defense: 0,
      attack: 11,
      attackDelay: 1.1,
      dodgeChance: 0,
      critChance: 0,
    };
  }

  getNextAction(state: PlayerContextState): BattlerAction {
    const suckBloodChance = 0.25;
    if (Math.random() < suckBloodChance) {
      return {
        name: "Suck Blood",
        delay: this.getAttackDelay(state),
        transform: (state: PlayerContextState) => {
          const damage = calculateDamage(
            this.getAttack(state) * 1.2,
            calculatePlayerDefense(state),
          );
          state = modifyPlayerCurrentHP(state, -damage);
          state = modifyEnemyCurrentHP(state, +damage);
          return state;
        },
      };
    }
    return {
      name: "Attack",
      delay: this.getAttackDelay(state),
      transform: (state: PlayerContextState) =>
        standardEnemyAttackEffect(state, 1.0, this.getAttack(state)),
    };
  }

  getCoinsAwarded(state: PlayerContextState): number {
    return 50;
  }

  getMonstiumAwarded(state: PlayerContextState): number {
    return 6;
  }

  getItemsAwarded() {
    return [
      { itemId: "medicinalHerb", chance: 0.15, amount: 1 },
      { itemId: "batWing", chance: 0.25, amount: 2 },
    ];
  }
}

export default new BasementBat();
