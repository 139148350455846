import React, { useContext } from "react";
import { AttackTarget } from "../../backend/exploration/AttackTarget";
import { getCurrentEnemy } from "../../backend/exploration/enemies/Enemies";
import {
  getCurrentEnemyHP,
  getExplorationStatus,
} from "../../backend/exploration/Exploration";
import { ExplorationStatus } from "../../backend/exploration/ExplorationStatus";
import { PlayerContext } from "../../backend/PlayerContext";
import { HPBar } from "./HPBar";

export function EnemyHPBar() {
  const playerContext = useContext(PlayerContext);
  const inCombat =
    getExplorationStatus(playerContext) == ExplorationStatus.Combat;
  let currentHP = 0;
  let maxHP = 1;
  if (inCombat) {
    currentHP = getCurrentEnemyHP(playerContext);
    maxHP = getCurrentEnemy(playerContext).getMaxHP(playerContext);
  }

  return (
    <HPBar
      empty={!inCombat}
      current={currentHP}
      max={maxHP}
      actionTarget={AttackTarget.Enemy}
    />
  );
}
