import AsyncStorage from "@react-native-async-storage/async-storage";
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { FlatList, StyleSheet, View } from "react-native";
import { Button, Card, Input, useThemeMode } from "react-native-elements";
import AudioManager from "../audio/AudioManager";
import {
  explainTransformations,
  TransformationType,
} from "../backend/calculation/Calculation";
import { cleanup } from "../backend/Cleanup";
import { setGameEnding } from "../backend/endings/Endings";
import { debugRandomEvents } from "../backend/events/autorandom/RandomEventTrigger";
import { getAllGameEvents, triggerEvent } from "../backend/events/Events";
import { clearFlag, hasFlag, setFlag } from "../backend/Flags";
import {
  GLOBAL_INITIAL_STATE,
  PlayerContext,
  PLAYER_INITIAL_STATE,
} from "../backend/PlayerContext";
import { grantResource, Resource } from "../backend/Resources";
import { saveDataUnsafe } from "../backend/SaveData";
import { grantSchoolExp, School } from "../backend/spells/ElementsAndSchools";
import { getSecondsPlayed } from "../backend/timetick/TotalTimePlayed";
import { formatNumber } from "../utils/FormattingUtils";
import { logAnalyticsEventGeneric } from "../utils/GameAnalytics";
import CategoryContainer from "./utility/CategoryContainer";
import { Text } from "./utility/Text";

export default function Debug() {
  const events = getAllGameEvents();
  const playerContext = useContext(PlayerContext);
  const playerContextRef = useRef(playerContext);
  playerContextRef.current = playerContext;
  const eventButtons = useMemo(
    () => (
      <CategoryContainer title="Trigger events">
        <Button
          title="Trigger random event"
          onPress={() =>
            playerContextRef.current.apply((state) => {
              state.nextRandomEvent = 0;
              return state;
            })
          }
        />
        <FlatList
          data={Object.values(events)}
          renderItem={({ item }) => {
            return (
              <Button
                key={item.getId()}
                title={item.getId()}
                onPress={() =>
                  playerContextRef.current.apply(triggerEvent(item))
                }
              />
            );
          }}
          keyExtractor={(event) => event.getId()}
        />
      </CategoryContainer>
    ),
    [JSON.stringify(Object.keys(events))],
  );
  const playerContextJson = JSON.stringify(
    playerContext.exploration,
    null,
    "\t",
  );
  const [newPlayerContextState, setNewPlayerContextState] = useState(
    JSON.stringify(playerContext, null, "  "),
  );
  const overridePlayerContext = useCallback(async () => {
    const deserializedJSON = JSON.parse(newPlayerContextState);
    playerContextRef.current.overwrite((_oldState) => {
      return deserializedJSON;
    });
    await saveDataUnsafe(deserializedJSON);
  }, [newPlayerContextState]);

  const [transformDebugInput, setTransformDebugInput] = useState("");
  const [transformDebugOutput, setTransformDebugOutput] = useState("");

  const calculate = useCallback(() => {
    const tags = transformDebugInput.split(",");

    const output = explainTransformations(tags, playerContextRef.current, 1.0);
    const debugOutput = Object.keys(output)
      .map((key) => {
        const eachType = output[key as TransformationType].map(
          ({ id, value }) =>
            `${id}: ${
              value != null
                ? formatNumber(value, { showDecimals: true })
                : "undefined"
            }`,
        );
        return key + ": " + eachType.join(", ");
      })
      .join("\n");
    setTransformDebugOutput(debugOutput);
  }, [transformDebugInput, setTransformDebugOutput]);

  const themeMode = useThemeMode();

  return (
    <View style={styles.container}>
      <CategoryContainer title="Other actions">
        <Button
          title="Clear Quickbar"
          onPress={() =>
            playerContextRef.current.apply((state) => {
              state.quickbar = [];
              return state;
            })
          }
        />
        <Button
          title="Max All Storages"
          onPress={() =>
            playerContextRef.current.apply((state) => {
              for (let resource in Resource) {
                if (resource != "TimePieces") {
                  state = grantResource(resource as Resource, 9999999)(state);
                }
              }
              state = grantResource(Resource.TimePieces, 9999999)(state);
              return state;
            })
          }
        />
        <Button
          title="Toggle Dark Mode"
          onPress={() => themeMode.setMode("dark")}
        />
        <Button
          title="Grant 10k School Exp"
          onPress={() =>
            playerContextRef.current.apply((state) => {
              state = grantSchoolExp(School.Conjuration, 10000)(state);
              state = grantSchoolExp(School.Enchantment, 10000)(state);
              state = grantSchoolExp(School.Illusion, 10000)(state);
              return state;
            })
          }
        />
        <Button
          title="Play Sound"
          onPress={() => AudioManager.play("menuClick")}
        />
        <Button
          title="Artifically pass 10 minutes"
          onPress={() =>
            playerContextRef.current.apply((state) => {
              state.skipAheadTime = 60 * 10;
              return state;
            })
          }
        />
        <Button
          title="Toggle 2x speed"
          onPress={() =>
            playerContextRef.current.apply((state) => {
              if (hasFlag(state, "debug_2x_speed")) {
                state = clearFlag("debug_2x_speed")(state);
              } else {
                state = setFlag("debug_2x_speed")(state);
              }
              return state;
            })
          }
        />
        <Button
          title="Toggle invincibility"
          onPress={() =>
            playerContextRef.current.apply((state) => {
              if (hasFlag(state, "debug_invincibility")) {
                state = clearFlag("debug_invincibility")(state);
              } else {
                state = setFlag("debug_invincibility")(state);
              }
              return state;
            })
          }
        />
        <Button
          title="Send Test Analytics Event"
          onPress={() =>
            logAnalyticsEventGeneric(
              playerContextRef.current,
              "test_analytics_event",
              { testParam: Math.random() },
            )
          }
        />
        <Button
          onPress={() => {
            AsyncStorage.removeItem("PlayerContextState");
            AsyncStorage.removeItem("LastSaveSecondsPlayed");
          }}
          title={"Clear storage"}
          key="clearstorage"
        />
        <Button
          onPress={() => {
            playerContext.apply((state) => {
              state.global = GLOBAL_INITIAL_STATE;
              return state;
            });
          }}
          title={"Reset global state"}
          key="resetglobal"
        />
        <Button
          onPress={() => {
            playerContext.apply(cleanup);
          }}
          title={"Clean up playercontext"}
          key="cleanupplayercontext"
        />
        <Button
          onPress={() => {
            playerContext.apply((state) => {
              return setGameEnding(state, "standard_ending");
            });
          }}
          title={"Trigger standard ending"}
          key="triggerending"
        />
        <Button
          onPress={() => {
            playerContext.apply((state) => {
              return PLAYER_INITIAL_STATE;
            });
            AsyncStorage.removeItem("PlayerContextState");
            AsyncStorage.removeItem("LastSaveSecondsPlayed");
          }}
          title={"Restart game (WARNING: ALSO DELETES SAVED DATA)"}
          key="restartgame"
        />
      </CategoryContainer>
      <Card>
        <Card.Title>Multiplier Debugging</Card.Title>
        <Text>Separate tags by commas</Text>
        <Input
          onChangeText={setTransformDebugInput}
          value={transformDebugInput}
          onSubmitEditing={calculate}
        />
        <Button onPress={calculate} title="Debug multipliers" />
        <Text>{transformDebugOutput}</Text>
      </Card>
      <Card>
        <Card.Title>Random Event Debugging</Card.Title>
        <Text>
          Next event in{" "}
          {formatNumber(
            playerContext.nextRandomEvent - getSecondsPlayed(playerContext),
          )}{" "}
          sec
        </Text>
        <Text>
          {JSON.stringify(debugRandomEvents(playerContext), null, "\t")}
        </Text>
      </Card>
      <Card>
        <Card.Title>Player Context Editing</Card.Title>
        <Input
          multiline={true}
          onChangeText={setNewPlayerContextState}
          value={newPlayerContextState}
          style={{ height: 400, fontFamily: "monospace, Courier New" }}
        />
        <Button
          onPress={overridePlayerContext}
          title={"Set player context"}
          key="setPlayerContext"
        />
      </Card>

      <Card>
        <Card.Title>Trigger Events</Card.Title>
        {eventButtons}
      </Card>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "stretch",
    justifyContent: "flex-start",
    width: "100%",
  },
});
