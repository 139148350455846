import { formatNumber } from "../../../utils/FormattingUtils";
import { ActionEffect } from "../../actions/Action";
import {
  registerTransformation,
  TransformationType,
} from "../../calculation/Calculation";
import { TransformationTags } from "../../calculation/TransformationTags";
import { AttackTarget } from "../../exploration/AttackTarget";
import { CombatStat } from "../../exploration/CombatStats";
import { hasFlag } from "../../Flags";
import { PlayerContextState } from "../../PlayerContext";
import {
  grantTemporaryEffect,
  hasTemporaryEffect,
} from "../../timetick/TemporaryEffects";
import { SpellActionBase } from "../Spell";

class Quickness extends SpellActionBase {
  constructor() {
    super();
  }

  isVisible(state: PlayerContextState): boolean {
    return super.isVisible(state) && hasFlag(state, "exploration_unlocked");
  }

  getSpellName(): string {
    return "Quickness";
  }

  getArea(): string {
    return "Exploration";
  }

  getDisplayDescription(): string {
    return "Make yourself move and attack faster.";
  }

  protected getBaseActionEffects(): Record<string, ActionEffect> {
    return {
      magnitude: {
        value: 0.15,
        tags: [TransformationTags.TemporaryEffectMagnitude],
      },
      duration: {
        value: 40,
        tags: [TransformationTags.TemporaryEffectDuration],
      },
    };
  }

  doSpellAction(state: PlayerContextState): PlayerContextState {
    const effects = this.getActionEffects(state);
    return grantTemporaryEffect(
      this.getId(),
      this.getSpellName(),
      effects.duration,
      {},
    )(state);
  }

  getDisplayEffect(state: PlayerContextState): string {
    const effects = this.getActionEffects(state);
    return `-${formatNumber(
      Math.abs(1 - 1 / (effects.magnitude + 1)) * 100,
    )}%:attackDelay: for ${formatNumber(effects.duration)} sec`;
  }

  getManaCost(): number {
    return 580;
  }

  getSchoolLevelRequirements(): Record<string, number> {
    return {
      Enchantment: 7,
    };
  }
}

const quickness = new Quickness();

registerTransformation(
  [[AttackTarget.Player, CombatStat.AttackDelay]],
  "Quickness",
  TransformationType.Multiplier,
  (state) =>
    hasTemporaryEffect(state, "Quickness")
      ? 1.0 / (1 + quickness.getActionEffects(state).magnitude)
      : 1.0,
);

export default quickness;
