import { PlayerContextState } from "../../../PlayerContext";
import { EquipmentSlot } from "../../Equipment";
import { ItemParams } from "../../Item";
import { EquippableItem } from "../EquippableItem";

class Dagger extends EquippableItem {
  getId(): string {
    return "dagger";
  }

  getPicture() {
    return require("./dagger.png");
  }

  getBaseName(): string {
    return "Dagger";
  }

  getDescription(): string | undefined {
    return "An agile, lightweight weapon.";
  }

  getAttackBonus(state: PlayerContextState, params: ItemParams): number {
    return 10;
  }

  getAttackDelayBonusPct(
    state: PlayerContextState,
    params: ItemParams,
  ): number {
    return -50;
  }

  getSlot(): EquipmentSlot {
    return EquipmentSlot.Hand;
  }

  getSalePrice(): number {
    return 600;
  }

  getCraftingSchoolLevelRequirements() {
    return { Conjuration: 4 };
  }

  getCraftingMaterialsBase() {
    return {
      resources: {
        Iron: 60,
        Wood: 40,
        Temperature: 300,
      },
      items: {},
    };
  }
}

export default new Dagger();
