import React from "react";
import { BuildingData } from "./buildings/Buildings";
import Demo from "./Demo";
import { EventOccurrenceData } from "./events/Events";
import { AttackTarget } from "./exploration/AttackTarget";
import { CombatStat } from "./exploration/CombatStats";
import { ExplorationStatus } from "./exploration/ExplorationStatus";
import { EquipmentSlot } from "./items/Equipment";
import { ItemOccurrence, ItemStack } from "./items/Item";
import { QuickbarData } from "./Quickbar";
import { Resource, ResourceData } from "./Resources";
import { School, SpellElement } from "./spells/ElementsAndSchools";
import { TemporaryEffectData } from "./timetick/TemporaryEffects";

export type PlayerContextGlobalState = {
  secondsPlayed: number;
  flags: Record<string, boolean>;
  maxPrimarySchoolLevels: Partial<Record<School, number>>;
  unlockedElements: Record<string, boolean>;
  unlockedSchools: Record<School, boolean>;
  researchersHiredLastRun: number;
  timeCapsulesLastRun: number;
  totalTimesResetted: number;
  targetFps: number;
  endingsTriggered: string[];
  startTimestamp: number;
  allowAnalytics: boolean;
  appVersionTimestamp: number;
  isFullVersionSave: boolean;
};

export type PlayerContextEquipmentState = Record<
  EquipmentSlot,
  ItemOccurrence | undefined
>;

export type CombatActionResult = {
  id: string;
  damage: number;
  isHit: boolean;
  isCrit: boolean;
  time: number;
  target: AttackTarget;
  tags: string[];
};

export type MessageLog = {
  id: string;
  timestamp: number;
  message: string;
};

export type EndingStats = {
  endingId: string;
  totalTimePlayed: number;
  totalTimePlayedThisRun: number;
  primarySchool: School;
  primaryElement: SpellElement;
  primarySchoolLevelOnEnding: number;
  mpl: number;
  researchMultiplier: number;
  productionMultiplier: number;
  completedStorylines: number;
};

export type PlayerContextExplorationState = {
  currentHP: number;
  explorationStatus: ExplorationStatus;
  explorationStartTime: number;
  currentDungeonFloorId: string;
  currentEnemy: {
    id: string;
    currentHP: number;
  };
  currentSummon:
    | {
        id: string;
        currentHP: number;
      }
    | undefined;
  successfulExplorationsPerFloor: Record<string, number>;
  playerActionProgress: number;
  summonActionProgress: number;
  enemyActionProgress: number;
  lastPlayerActionResult: CombatActionResult | undefined;
  lastEnemyActionResult: CombatActionResult | undefined;
  actionResultQueue: CombatActionResult[];
  variables: Record<string, boolean>;
};

export type PlayerContextState = {
  resources: Partial<Record<Resource, ResourceData>>;
  buildings: Record<string, BuildingData>;
  flags: Record<string, boolean>;
  elementExperience: Record<string, number>;
  schoolExperience: Partial<Record<School, number>>;
  schoolExponents: Partial<Record<School, number>>;
  research: {
    totalResearchers: number;
    researcherAllocation: {
      schools: Partial<Record<School, number>>;
    };
  };
  autocast: {
    apprenticeAllocation: Record<string, number>;
    spellAutocastProgress: Record<string, number>;
    apprenticesPaused: boolean;
    loadouts: Record<string, Record<string, number>>;
    lastLoadoutUsed: number;
  };
  creatures: {
    totalCreatures: number;
    creatureAllocation: Record<string, number>;
    lastRewardTime: Record<string, number>;
  };
  eventQueue: string[];
  eventOccurrences: Record<string, EventOccurrenceData>;
  global: PlayerContextGlobalState;
  thisRun: {
    secondsPlayed: number;
    newFlagsOnRetirement: Record<string, boolean>;
    endingTriggered: EndingStats | undefined;
    startTimestamp: number;
  };
  temporaryEffects: Record<string, TemporaryEffectData | number>;
  recentSpellsCast: string[];
  nextRandomEvent: number;
  primaryElement: string | undefined;
  primarySchool: School | undefined;
  inventory: ItemStack[];
  equipment: PlayerContextEquipmentState;
  exploration: PlayerContextExplorationState;
  explorationMessageLog: MessageLog[];
  creatureMessageLog: MessageLog[];
  lastSpellCastTimes: Record<string, number>;
  uiCommands: string[];
  lastProcessedTime: number;
  lastItemUsedTimes: Record<string, number>;
  quickbar: QuickbarData[];
  favoriteSpells: string[];
  skipAheadTime: number;
  summoningBlockedUntilTime: number;
  strengtheningsBought: Record<CombatStat, number>;
};

export type PlayerContextTransform = (
  oldState: PlayerContextState,
) => PlayerContextState;

export type PlayerContextValue = PlayerContextState & {
  apply: (transform: PlayerContextTransform) => void;
};

export const EXPLORATION_INITIAL_STATE: PlayerContextExplorationState = {
  currentHP: 100,
  explorationStatus: ExplorationStatus.None,
  explorationStartTime: 0,
  currentDungeonFloorId: "",
  currentEnemy: {
    id: "",
    currentHP: 1,
  },
  currentSummon: undefined,
  successfulExplorationsPerFloor: {},
  playerActionProgress: 0.0,
  summonActionProgress: 0.0,
  enemyActionProgress: 0.0,
  lastPlayerActionResult: undefined,
  lastEnemyActionResult: undefined,
  actionResultQueue: [],
  variables: {},
};

export const EQUIPMENT_INITIAL_STATE: PlayerContextEquipmentState = {
  Hand: undefined,
  Body: undefined,
  Accessory: undefined,
};

export const GLOBAL_INITIAL_STATE: PlayerContextGlobalState = {
  secondsPlayed: 0,
  flags: {},
  maxPrimarySchoolLevels: {},
  unlockedElements: { Fire: true, Earth: true, Water: true, Air: true },
  unlockedSchools: {
    Conjuration: true,
    Enchantment: true,
    Illusion: true,
    Alchemy: false,
    Evocation: false,
    Protection: false,
    Breeding: false,
    Summoning: false,
    Divination: false,
  },
  researchersHiredLastRun: 0,
  timeCapsulesLastRun: 0,
  totalTimesResetted: 0,
  targetFps: 30,
  endingsTriggered: [],
  startTimestamp: Date.now(),
  allowAnalytics: false,
  appVersionTimestamp: 0,
  isFullVersionSave: !Demo.isDemo(),
};

export const THIS_RUN_INITIAL_STATE = {
  secondsPlayed: 0,
  newFlagsOnRetirement: {},
  endingTriggered: undefined,
  startTimestamp: Date.now(),
};

export const PLAYER_INITIAL_STATE: PlayerContextState = {
  resources: {},
  buildings: {},
  flags: {},
  elementExperience: {
    Fire: 0,
    Earth: 0,
    Water: 0,
    Air: 0,
  },
  schoolExperience: {
    Conjuration: 0,
    Enchantment: 0,
    Illusion: 0,
  },
  schoolExponents: {},
  research: {
    totalResearchers: 0,
    researcherAllocation: {
      schools: {},
    },
  },
  autocast: {
    apprenticeAllocation: {},
    spellAutocastProgress: {},
    apprenticesPaused: false,
    loadouts: {},
    lastLoadoutUsed: 0,
  },
  creatures: {
    totalCreatures: 0,
    creatureAllocation: {},
    lastRewardTime: {},
  },
  eventQueue: [],
  eventOccurrences: {},
  global: GLOBAL_INITIAL_STATE,
  thisRun: THIS_RUN_INITIAL_STATE,
  temporaryEffects: {},
  recentSpellsCast: [],
  nextRandomEvent: 600,
  primaryElement: undefined,
  primarySchool: undefined,
  inventory: [],
  equipment: EQUIPMENT_INITIAL_STATE,
  exploration: EXPLORATION_INITIAL_STATE,
  explorationMessageLog: [],
  creatureMessageLog: [],
  lastSpellCastTimes: {},
  uiCommands: [],
  lastProcessedTime: Date.now(),
  lastItemUsedTimes: {},
  quickbar: [],
  favoriteSpells: [],
  skipAheadTime: 0,
  summoningBlockedUntilTime: 0,
  strengtheningsBought: {
    MaxHP: 0,
    Attack: 0,
    AttackDelay: 0,
    Defense: 0,
    HPRegen: 0,
    Dodge: 0,
    Accuracy: 0,
    CritChance: 0,
  },
};

export const PlayerContext = React.createContext({
  ...PLAYER_INITIAL_STATE,
  apply: (transform: PlayerContextTransform) => {},
  overwrite: (transform: PlayerContextTransform) => {},
});
