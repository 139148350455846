import React, { useContext, useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { PlayerContext } from "../../backend/PlayerContext";
import { getTemporaryEffects } from "../../backend/timetick/TemporaryEffects";
import { getSecondsPlayed } from "../../backend/timetick/TotalTimePlayed";
import { formatNumber } from "../../utils/FormattingUtils";
import { GameIcon } from "./icons/GameIcon";
import { Text } from "./Text";

function TemporaryEffectRow(props: { text: string; isBeneficial: boolean }) {
  return (
    <View style={styles.row}>
      <GameIcon
        icon={props.isBeneficial ? "boost" : "debuff"}
        style={styles.icon}
      />
      <Text style={styles.boostText}>{props.text}</Text>
    </View>
  );
}

const TemporaryEffectRowMemo = React.memo(TemporaryEffectRow);

export function TemporaryEffectsViewer(props: {
  isEnemy?: boolean;
  isVisible?: boolean;
}) {
  const playerContext = useContext(PlayerContext);
  const temporaryEffects = getTemporaryEffects(playerContext)
    .sort((a, b) => (a.isBeneficial ? 1 : 0) - (b.isBeneficial ? 1 : 0))
    .filter((temporaryEffect) =>
      props.isEnemy
        ? temporaryEffect.isEnemyTemporaryEffect
        : !temporaryEffect.isEnemyTemporaryEffect,
    );
  return useMemo(() => {
    const rows = temporaryEffects.map((temporaryEffect) => {
      const { name, endTime, isBeneficial } = temporaryEffect;
      const timeLeftSec = endTime - getSecondsPlayed(playerContext);
      const text = `${name} (${formatNumber(timeLeftSec)} sec)`;
      return (
        <TemporaryEffectRowMemo
          key={name}
          isBeneficial={isBeneficial}
          text={text}
        />
      );
    });
    return <View style={styles.container}>{rows}</View>;
  }, [
    props.isVisible !== false ? playerContext : false,
    props.isVisible !== false ? temporaryEffects : false,
  ]);
}

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "stretch",
  },
  icon: {
    height: 20,
    width: 20,
    marginTop: 2,
  },
  row: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: 4,
  },
  boostText: {
    marginLeft: 8,
    flex: 1,
  },
});
