import { formatNumber } from "../../../utils/FormattingUtils";
import { ActionEffect } from "../../actions/Action";
import {
  registerTransformation,
  TransformationType,
} from "../../calculation/Calculation";
import { TransformationTags } from "../../calculation/TransformationTags";
import { buildEventMessage } from "../../events/EventMessageBuilder";
import { triggerEvent } from "../../events/Events";
import { createGameEvent } from "../../events/SimpleGameEvent";
import { hasFlag, setFlag } from "../../Flags";
import { PlayerContextState } from "../../PlayerContext";
import { addContextListener } from "../../PlayerContextListeners";
import { getResourceAmount, recalculateCaps, Resource } from "../../Resources";
import { BuildingActionBase } from "../Building";
import { buildingAmountsChanged, getBuildingAmount } from "../Buildings";

class Monstruarium extends BuildingActionBase {
  constructor() {
    super();
  }

  isVisible(state: PlayerContextState): boolean {
    return hasFlag(state, "monstruarium_unlocked");
  }

  getBuildingName(): string {
    return "Monstruarium";
  }

  getArea(): string {
    return "Campus";
  }

  getDisplayDescription(): string {
    return "Magically infused storage building that can also hold hazardous materials.";
  }

  protected getBaseActionEffects(): Record<string, ActionEffect> {
    return {
      stone: {
        value: 1200,
        tags: [Resource.Stone, TransformationTags.PerBuildingCap],
      },
      wood: {
        value: 1200,
        tags: [Resource.Wood, TransformationTags.PerBuildingCap],
      },
      iron: {
        value: 600,
        tags: [Resource.Iron, TransformationTags.PerBuildingCap],
      },
      monstium: {
        value: 4000,
        tags: [Resource.Monstium, TransformationTags.PerBuildingCap],
      },
    };
  }

  getDisplayEffect(state: PlayerContextState): string {
    const effects = this.getActionEffects(state);
    const resourceEffectMap: Partial<Record<Resource, string>> = {
      Stone: `+${formatNumber(effects.stone)} Max:stone:`,
      Wood: `+${formatNumber(effects.wood)} Max:wood:`,
      Iron: `+${formatNumber(effects.iron)} Max:iron:`,
      Monstium: `+${formatNumber(effects.monstium)} Max:monstium:`,
    };
    return (Object.keys(resourceEffectMap) as Array<Resource>)
      .filter((resource) => state.resources?.[resource] !== undefined)
      .map((resource) => resourceEffectMap[resource])
      .join(", ");
  }

  getBaseResourceCost(): Record<string, number> {
    return {
      Mana: 1100,
      Coins: 8000,
      Iron: 500,
    };
  }

  getBaseResourceScale(): Record<string, number> {
    return {
      Mana: 1.3,
      Coins: 1.3,
      Iron: 1.3,
    };
  }
}

function calculateStoneCapIncrease(state: PlayerContextState) {
  return monstruarium.getActionEffects(state).stone;
}

function calculateWoodCapIncrease(state: PlayerContextState) {
  return monstruarium.getActionEffects(state).wood;
}

function calculateIronCapIncrease(state: PlayerContextState) {
  return monstruarium.getActionEffects(state).iron;
}

function calculateMonstiumCapIncrease(state: PlayerContextState) {
  return monstruarium.getActionEffects(state).monstium;
}

const monstruarium = new Monstruarium();

registerTransformation(
  [[TransformationTags.Cap, Resource.Stone]],
  "MonstruariumStoneWoodCap",
  TransformationType.Addition,
  (state) =>
    getBuildingAmount(state, monstruarium) * calculateStoneCapIncrease(state),
);

registerTransformation(
  [[TransformationTags.Cap, Resource.Wood]],
  "MonstruariumStoneWoodCap",
  TransformationType.Addition,
  (state) =>
    getBuildingAmount(state, monstruarium) * calculateWoodCapIncrease(state),
);

registerTransformation(
  [[TransformationTags.Cap, Resource.Iron]],
  "MonstruariumIronCap",
  TransformationType.Addition,
  (state) =>
    getBuildingAmount(state, monstruarium) * calculateIronCapIncrease(state),
);

registerTransformation(
  [[TransformationTags.Cap, Resource.Monstium]],
  "MonstruariumMonstiumCap",
  TransformationType.Addition,
  (state) =>
    getBuildingAmount(state, monstruarium) *
    calculateMonstiumCapIncrease(state),
);

addContextListener("monstruarium_caps_recalculation", (oldState, newState) => {
  if (buildingAmountsChanged(oldState, newState, monstruarium)) {
    return (state) => {
      return recalculateCaps(state);
    };
  }
});

const message = buildEventMessage(
  "monstruarium_unlocked",
  "A New Hazardous Resource",
)
  .setSimpleDescription(
    `Felling those enemies is interesting, as sometimes you can find useful items afterwards. You have noticed, however, that they also leave behind some material that you have been calling "Monstium". You are not sure what it does, but it feels somewhat hazardous.
    
There might be a way to store more of this... You think for a bit, and devise a larger storage unit, with more capacity than warehouses, and with a special compartment dedicated to potentially dangerous materials. To make it truly work, you think of imbuing it with mana. You are eager to give it a try.

**You can now build Monstruariums!**`,
  )
  .build();

const event = createGameEvent(
  "monstruarium_unlocked",
  message,
  setFlag("monstruarium_unlocked"),
);

addContextListener("monstruarium_unlocked", (oldState, newState) => {
  if (
    !hasFlag(newState, "monstruarium_unlocked") &&
    getResourceAmount(newState, Resource.Monstium) >= 200
  ) {
    return triggerEvent(event);
  }
});

export default monstruarium as Monstruarium;
