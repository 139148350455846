import { registerRootComponent } from "expo";
import App from "./App";
import { loadAllDemo } from "./DemoLoader";

loadAllDemo();

function DemoMain() {
  return <App />;
}

registerRootComponent(DemoMain);
