import { formatNumber } from "../../../utils/FormattingUtils";
import { ActionEffect } from "../../actions/Action";
import {
  registerTransformation,
  TransformationType,
} from "../../calculation/Calculation";
import { TransformationTags } from "../../calculation/TransformationTags";
import { hasFlag } from "../../Flags";
import { PlayerContextState } from "../../PlayerContext";
import {
  grantTemporaryEffect,
  hasTemporaryEffect,
} from "../../timetick/TemporaryEffects";
import { SpellActionBase } from "../Spell";

class Levitation extends SpellActionBase {
  constructor() {
    super();
  }

  isVisible(state: PlayerContextState): boolean {
    return super.isVisible(state) && hasFlag(state, "exploration_unlocked");
  }

  getSpellName(): string {
    return "Levitation";
  }

  getArea(): string {
    return "Exploration";
  }

  getDisplayDescription(): string {
    return "Allows you to float a short distance from the ground. Makes you more efficient at exploration.";
  }

  protected getBaseActionEffects(): Record<string, ActionEffect> {
    return {
      magnitude: {
        value: 2.0,
        tags: [TransformationTags.TemporaryEffectMagnitude],
      },
      duration: {
        value: 40,
        tags: [TransformationTags.TemporaryEffectDuration],
      },
    };
  }

  doSpellAction(state: PlayerContextState): PlayerContextState {
    const effects = this.getActionEffects(state);
    return grantTemporaryEffect(
      this.getId(),
      this.getSpellName(),
      effects.duration,
      {},
    )(state);
  }

  getDisplayEffect(state: PlayerContextState): string {
    const effects = this.getActionEffects(state);
    return `-${formatNumber(
      (1 - 1.0 / effects.magnitude) * 100,
    )}% exploration time for ${formatNumber(effects.duration)} sec`;
  }

  getManaCost(): number {
    return 1300;
  }

  getSchoolLevelRequirements(): Record<string, number> {
    return {
      Enchantment: 13,
    };
  }
}

const levitation = new Levitation();

registerTransformation(
  [[TransformationTags.ExplorationDelay]],
  "Levitation",
  TransformationType.Multiplier,
  (state) =>
    hasTemporaryEffect(state, "Levitation")
      ? 1.0 / levitation.getActionEffects(state).magnitude
      : 1.0,
);

export default levitation;
