import React from "react";
import { StyleSheet, View } from "react-native";
import { Text as ReactNativeElementsText, Icon } from "react-native-elements";

function TextNonMemoized(props: any) {
  return (
    <ReactNativeElementsText
      h1Style={styles.h1}
      h2Style={styles.h2}
      h3Style={styles.h3}
      h4Style={styles.h4}
      {...props}
    />
  );
}

const Text = React.memo(TextNonMemoized);

export { Text };

/*
export function Text(props: any) {
  let children = props.children || [];
  if (!Array.isArray(children)) {
    children = [children];
  }
  const newChildren = children.map((child: any) => {
    if (typeof child == "string") {
      const splitString = child.split("{wood}");
      const newStringChildren = [
        <StyledText props={props}>{splitString[0]}</StyledText>,
      ] as Object[];
      for (let i = 1; i < splitString.length; i++) {
        newStringChildren.push(<Icon name="close" />);
        newStringChildren.push(
          <StyledText props={props}>{splitString[i]}</StyledText>,
        );
      }
      return <View>{newStringChildren}</View>;
    }
    //return child;
  });
  return <View>{newChildren}</View>;
}*/

const styles = StyleSheet.create({
  h1: {
    fontSize: 20,
  },
  h2: {
    fontSize: 18,
  },
  h3: {
    fontSize: 16,
  },
  h4: {
    fontSize: 14,
  },
});
