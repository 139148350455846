import { formatNumber } from "../../../utils/FormattingUtils";
import { ActionEffect } from "../../actions/Action";
import { hasFlag } from "../../Flags";
import { PlayerContextState } from "../../PlayerContext";
import { grantResource, Resource } from "../../Resources";
import { SpellElement } from "../ElementsAndSchools";
import { SpellActionBase } from "../Spell";

class CreateWater extends SpellActionBase {
  constructor() {
    super();
  }

  isVisible(state: PlayerContextState): boolean {
    return (
      super.isVisible(state) && hasFlag(state, "has_gathered_at_least_10_mana")
    );
  }

  getSpellName(): string {
    return "Create Water";
  }

  getElement(): SpellElement | undefined {
    return SpellElement.Water;
  }

  getArea(): string {
    return "Campus";
  }

  getDisplayDescription(): string {
    return "Convert Mana into some drops of water.";
  }

  protected getBaseActionEffects(): Record<string, ActionEffect> {
    return {
      water: {
        value: 2,
        tags: [Resource.Water],
      },
    };
  }

  getDisplayEffect(state: PlayerContextState): string {
    const effects = this.getActionEffects(state);
    return `+${formatNumber(effects.water)}:water:`;
  }

  doSpellAction(state: PlayerContextState): PlayerContextState {
    return grantResource(
      Resource.Water,
      this.getActionEffects(state).water,
    )(state);
  }

  getManaCost(): number {
    return 20;
  }

  getSchoolLevelRequirements(): Record<string, number> {
    return {
      Conjuration: 1,
    };
  }
}

export default new CreateWater();
