import waterTank from "../../buildings/campus/WaterTank";
import {
  registerTransformation,
  TransformationType,
} from "../../calculation/Calculation";
import { TransformationTags } from "../../calculation/TransformationTags";
import { PlayerContextState } from "../../PlayerContext";
import { addContextListener } from "../../PlayerContextListeners";
import { recalculateCaps } from "../../Resources";
import { Upgrade } from "../Upgrade";
import reinforcedWarehouses from "./ReinforcedWarehouses";

class ReinforcedWaterTanks extends Upgrade {
  getName(): string {
    return "Reinforced Water Tanks";
  }

  getMonstiumCost(): number {
    return 450;
  }

  getDisplayDescription(state: PlayerContextState): string {
    return "Renew your water tanks, allowing them to store plenty more water.";
  }

  getDisplayEffect(state: PlayerContextState): string {
    return "5x Water Tank storage";
  }

  getUpgradeRequirements() {
    return [reinforcedWarehouses];
  }
}

const reinforcedWaterTanks = new ReinforcedWaterTanks();

registerTransformation(
  [[waterTank.getName(), TransformationTags.PerBuildingCap]],
  "ReinforcedWaterTanks",
  TransformationType.Multiplier,
  (state) => (reinforcedWaterTanks.isPurchased(state) ? 5 : 1),
);

addContextListener("ReinforcedWaterTanksListener", (oldState, newState) => {
  if (
    !reinforcedWaterTanks.isPurchased(oldState) &&
    reinforcedWaterTanks.isPurchased(newState)
  ) {
    return recalculateCaps;
  }
});

export default reinforcedWaterTanks;
