import { registerBuilding } from "./Buildings";
import ApprenticeDorm from "./campus/ApprenticeDorm";
import Furnace from "./campus/Furnace";
import LumberYard from "./campus/LumberYard";
import ManaCrystal from "./campus/ManaCrystal";
import ManaGeyser from "./campus/ManaGeyser";
import ManaShard from "./campus/ManaShard";
import ManaSpout from "./campus/ManaSpout";
import Mine from "./campus/Mine";
import Monstruarium from "./campus/Monstruarium";
import ResearcherCabin from "./campus/ResearcherCabin";
import Vault from "./campus/Vault";
import Warehouse from "./campus/Warehouse";
import WaterTank from "./campus/WaterTank";

export function loadBuildingsDemo() {
  registerBuilding(ManaSpout);
  registerBuilding(ManaGeyser);
  registerBuilding(LumberYard);
  registerBuilding(Mine);
  registerBuilding(ManaShard);
  registerBuilding(ManaCrystal);
  registerBuilding(Warehouse);
  registerBuilding(Monstruarium);
  registerBuilding(WaterTank);
  registerBuilding(Vault);
  registerBuilding(Furnace);
  registerBuilding(ResearcherCabin);
  registerBuilding(ApprenticeDorm);
}
