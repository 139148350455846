import { DataStore } from "../../generic/DataStore";
import { PlayerContextState } from "../../PlayerContext";
import { Summon } from "./Summon";

const Summons = new DataStore<Summon>();

export { Summons };

export function getCurrentSummon(
  state: PlayerContextState,
): Summon | undefined {
  return Summons.getById(state.exploration?.currentSummon?.id || "");
}
