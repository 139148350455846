import { PlayerContextState } from "../../PlayerContext";
import { Upgrade } from "../Upgrade";
import woolFarm from "./WoolFarm";

class WoolFarmExpansion extends Upgrade {
  getName(): string {
    return "Wool Farm Expansion";
  }

  getMonstiumCost(): number {
    return 17500;
  }

  getDisplayDescription(state: PlayerContextState): string {
    return "Expands the Wool Farm to be able to find and fight Enchanted Sheep.";
  }

  getDisplayEffect(state: PlayerContextState): string {
    return 'Unlocks "Magical Pen" exploration area inside "Wool Farm"';
  }

  getUpgradeRequirements() {
    return [woolFarm];
  }
}

const woolFarmExpansion = new WoolFarmExpansion();

export default woolFarmExpansion;
