import { formatNumber } from "../../../utils/FormattingUtils";
import { TransformationTags } from "../../calculation/TransformationTags";
import { buildEventMessage } from "../../events/EventMessageBuilder";
import { triggerEvent } from "../../events/Events";
import { createGameEvent } from "../../events/SimpleGameEvent";
import { hasFlag, setFlag } from "../../Flags";
import { PlayerContextState } from "../../PlayerContext";
import { addContextListener } from "../../PlayerContextListeners";
import {
  getResourceAmount,
  getResourceCap,
  grantResource,
  Resource,
} from "../../Resources";
import { getTotalElementAndSchoolExp } from "../../spells/ElementsAndSchools";
import { ActionBase, ActionEffect, DoActionArgs } from "../Action";

class GatherWood extends ActionBase {
  constructor() {
    super();
  }

  isVisible(state: PlayerContextState): boolean {
    return hasFlag(state, "gather_wood_unlocked");
  }

  isEnabled(state: PlayerContextState): boolean {
    return (
      getResourceAmount(state, Resource.Wood) <
      getResourceCap(state, Resource.Wood)
    );
  }

  getTags(): string[] {
    return [
      ...super.getTags(),
      TransformationTags.ManualGathering,
      TransformationTags.Production,
      Resource.Wood,
    ];
  }

  getName(): string {
    return "Gather Wood";
  }

  getArea(): string {
    return "Campus";
  }

  getDisplayDescription(): string {
    return "Collect branches from the nearby forest.";
  }

  getBaseActionEffects(): Record<string, ActionEffect> {
    return {
      wood: { value: 1 },
    };
  }

  getDisplayEffect(state: PlayerContextState): string {
    return `+${formatNumber(this.getActionEffects(state).wood, {
      showDecimals: true,
    })}:wood:`;
  }

  getCost(state: PlayerContextState): {
    resources: Partial<Record<Resource, number>>;
    items: Record<string, number>;
  } {
    return { resources: {}, items: {} };
  }

  doAction(args: DoActionArgs, state: PlayerContextState): PlayerContextState {
    return grantResource(
      Resource.Wood,
      this.getActionEffects(state).wood,
    )(state);
  }
}

const message = buildEventMessage(
  "gather_wood_unlocked",
  "Time to Look Outside",
)
  .setSimpleDescription(
    `Our studies of magic are starting to become more fruitful! With our recent advances, we are ready to start broadening our horizons. The campus is big, but there is only so much in it!
    
It seems like there is a nearby forest where we may be able to find wood, which could help us develop our institution even further...

**You can now gather:wood: Wood!**`,
  )
  .build();

const event = createGameEvent(
  "gather_wood_unlocked",
  message,
  setFlag("gather_wood_unlocked"),
);

addContextListener("gather_wood_unlocked", (oldState, newState) => {
  if (
    !hasFlag(newState, "gather_wood_unlocked") &&
    newState.primaryElement == "Nature"
  ) {
    // If nature element, unlock without the event
    return setFlag("gather_wood_unlocked");
  }
  if (
    !hasFlag(newState, "gather_wood_unlocked") &&
    getTotalElementAndSchoolExp(newState) >= 500
  ) {
    return triggerEvent(event);
  }
});

export default new GatherWood();
