import { LinearGradient } from "expo-linear-gradient";
import React from "react";
import { ImageSourcePropType, StyleSheet, View } from "react-native";
import { Image } from "react-native-elements";

export function EnemySpriteTile(props: {
  sprite?: ImageSourcePropType;
  backdropColor?: string | false;
}) {
  const LinearGradientComponent = LinearGradient;
  return (
    <View style={styles.tile}>
      {props.backdropColor && (
        <LinearGradientComponent
          colors={[`${props.backdropColor}`, `${props.backdropColor}0`]}
          style={styles.gradient}
        />
      )}
      {props.sprite && (
        <Image
          resizeMode="contain"
          source={props.sprite}
          style={styles.image}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  tile: {
    borderColor: "rgba(128, 128, 128, 0.2)",
    borderWidth: 1,
    height: 144,
    width: 144,
    justifyContent: "center",
    alignItems: "stretch",
    margin: 8,
  },
  image: {
    height: 144,
    width: 144,
  },
  gradient: {
    height: 144,
    width: 144,
    position: "absolute",
    left: 0,
    top: 0,
    opacity: 0.2,
  },
});
