import { hasFlag } from "../../Flags";
import { PlayerContextState } from "../../PlayerContext";
import { Upgrade } from "../Upgrade";
import woolFarmExpansion from "./WoolFarmExpansion";

class WoolFarmRemake extends Upgrade {
  getName(): string {
    return "Wool Farm Remake";
  }

  isVisibleExtraChecks(state: PlayerContextState): boolean {
    return hasFlag(state, "wizards_arena_unlocked");
  }

  getMonstiumCost(): number {
    return 1000000;
  }

  getItemCost(): Record<string, number> {
    return {
      bookOfDivineKnowledge: 20,
    };
  }

  getDisplayDescription(state: PlayerContextState): string {
    return "Expands the Wool Farm even more to be able to find and fight Black Sheep.";
  }

  getDisplayEffect(state: PlayerContextState): string {
    return 'Unlocks "Special Stockade" exploration area inside "Wool Farm"';
  }

  getUpgradeRequirements() {
    return [woolFarmExpansion];
  }
}

const woolFarmRemake = new WoolFarmRemake();

export default woolFarmRemake;
