class Demo {
  demoMode = false;

  setDemoMode(demoMode: boolean): void {
    this.demoMode = demoMode;
  }

  isDemo(): boolean {
    return this.demoMode;
  }
}

export default new Demo();
