import React, { useContext, useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { PlayerContext } from "../../backend/PlayerContext";
import {
  getResourceInfo,
  Resource,
  ResourceData,
} from "../../backend/Resources";
import { formatNumber } from "../../utils/FormattingUtils";
import { GameIcon } from "./icons/GameIcon";
import { Text } from "./Text";

function ResourceItem(props: {
  resource: Resource;
  current: number;
  cap: number;
}) {
  const resourceInfo = getResourceInfo(props.resource);
  return (
    <View style={styles.item}>
      <GameIcon icon={resourceInfo.icon} style={styles.icon} small />
      <Text>
        {formatNumber(props.current, { showDecimals: true })}/
        {formatNumber(props.cap)}
      </Text>
    </View>
  );
}

const ResourceItemMemo = React.memo(ResourceItem);

export function BottomResourceViewer(props: { isVisible?: boolean }) {
  const playerContext = useContext(PlayerContext);
  const resourceKeys = Object.keys(playerContext.resources) as Array<Resource>;
  const filteredResourceKeys = resourceKeys.filter(
    (resource) => resource !== Resource.Mana,
  );
  const rows = useMemo(
    () =>
      filteredResourceKeys.map((resourceName) => {
        const resource = playerContext.resources[resourceName] as ResourceData;
        return (
          <ResourceItemMemo
            resource={resourceName}
            current={resource.current}
            cap={resource.cap}
            key={resourceName}
          />
        );
      }),
    [props.isVisible !== false ? playerContext : false],
  );
  return <View style={styles.container}>{rows}</View>;
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },
  item: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    width: 106,
  },
  inlineRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  icon: {
    marginRight: 2,
  },
});
