import { PlayerContextState } from "../../../PlayerContext";
import { EquipmentSlot } from "../../Equipment";
import { ItemParams } from "../../Item";
import { EquippableItem } from "../EquippableItem";

class EnchantedCloak extends EquippableItem {
  getId(): string {
    return "enchantedCloak";
  }

  getPicture() {
    return require("./enchantedCloak.png");
  }

  getBaseName(): string {
    return "Enchanted Cloak";
  }

  getDescription(): string | undefined {
    return "A cloak that has been reformed and enhanced with magic.";
  }

  getDefenseBonus(state: PlayerContextState, params: ItemParams): number {
    return 9;
  }

  getSlot(): EquipmentSlot {
    return EquipmentSlot.Accessory;
  }

  getSalePrice(): number {
    return 2400;
  }

  getCraftingSchoolLevelRequirements() {
    return {
      Enchantment: 8,
    };
  }

  getCraftingMaterialsBase() {
    return {
      resources: { Mana: 2200 },
      items: { cloak: 1, cloth: 8 },
    };
  }
}

export default new EnchantedCloak();
