import { PlayerContextState } from "../../../PlayerContext";
import AngrySheep from "../../enemies/schoolbasement/AngrySheep";
import { DungeonFloor, encounterEnemy } from "../DungeonFloor";
import TheMagicalPen from "./TheMagicalPen";

class TheCorral extends DungeonFloor {
  getId(): string {
    return "theCorral";
  }

  getFloorName(): string {
    return "The Corral";
  }

  getExplorationRequiredTimeSec(): number {
    return 6.0;
  }

  _getExplorationSuccessesForBoss(): number {
    return 0;
  }

  getBoss() {
    return undefined;
  }

  getBreedingLevel(): number {
    return 8;
  }

  isUnlocked(state: PlayerContextState): boolean {
    return true;
  }

  getExplorationPossibilities(state: PlayerContextState) {
    return [
      {
        weight: 100,
        transforms: encounterEnemy(AngrySheep),
      },
    ];
  }

  getNextFloor() {
    return TheMagicalPen;
  }
}

export default new TheCorral();
