import SchoolBasementB1 from "../../exploration/dungeons/schoolbasement/SchoolBasementB1";
import { getSuccessfulExplorationsForFloor } from "../../exploration/Exploration";
import { addContextListener } from "../../PlayerContextListeners";
import {
  getUnlockedSchools,
  School,
  unlockSchool,
} from "../../spells/ElementsAndSchools";
import { Storyline, Storylines } from "../../storylines/Storylines";
import { buildEventMessage } from "../EventMessageBuilder";
import { triggerEvent } from "../Events";
import { createGameEvent } from "../SimpleGameEvent";

const message = buildEventMessage("evocation_intro_1", "(Storyline) Evocation")
  .setSimpleDescription(
    `As you continue fighting the animals that infest the school basement, you get a sudden realization. What if you actually used magic to fight back? You give it a try, and are easily able to fell one of those mice that pester you as you look around the basement.
    
That was definitely powerful! You can't wait to go back and ask your researcher to start studying this. This is an entirely new school of magic of its own.

**You have completed the "Evocation" storyline and have permanently unlocked the Evocation school! You may now do research on Evocation, which will let you learn damaging combat spells. This will persist even after restarting the game!**`,
  )
  .build();

const evocationEvent = createGameEvent(
  "evocation_intro",
  message,
  unlockSchool(School.Evocation),
);

addContextListener("EvocationIntro", (oldState, newState) => {
  if (
    !getUnlockedSchools(newState).includes(School.Evocation) &&
    getSuccessfulExplorationsForFloor(newState, SchoolBasementB1.getId()) >= 10
  ) {
    return triggerEvent(evocationEvent);
  }
});

Storylines.register(
  new Storyline(
    "Evocation",
    (state) => getUnlockedSchools(state).includes(School.Evocation),
    (state) => getUnlockedSchools(state).includes(School.Evocation),
    "Defeat 10 monsters in School Basement B1",
    "Evocation school unlocked",
    (state) => true,
  ),
);

export default evocationEvent;
