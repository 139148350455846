import {
  applyTransformations,
  registerTransformation,
  TransformationType,
} from "../../calculation/Calculation";
import { TransformationTags } from "../../calculation/TransformationTags";
import {
  hasFlag,
  hasGlobalFlag,
  hasGlobalFlagIncludingNextRun,
  setFlag,
  setGlobalFlagForNextRun,
} from "../../Flags";
import { PlayerContextState } from "../../PlayerContext";
import { getResourceAmount, grantResource, Resource } from "../../Resources";
import { getSchoolLevel, School } from "../../spells/ElementsAndSchools";
import { Storyline, Storylines } from "../../storylines/Storylines";
import { registerTimeTickListener } from "../../timetick/TimeTick";
import { registerRandomEventTrigger } from "../autorandom/RandomEventTrigger";
import { buildEventMessage } from "../EventMessageBuilder";
import { createGameEvent } from "../SimpleGameEvent";

const message = buildEventMessage("statue_1", "(Storyline) The Statue")
  .setSimpleDescription(
    `You hear footsteps and turn around to see the researcher and the apprentice walking towards you.
    
"Good morning!", exclaims the researcher. "We were thinking", she continues, "that we should build a statue in campus. It would serve as a landmark and a way to make our institution unique."

You think it could be a great idea, but you know you will need a lot of materials...`,
  )
  .addOption(
    "Build a statue (Requires Lv11 Conjuration, 7200 Stone, 800 Iron)",
    buildEventMessage("statue_2", "(Storyline) The Statue")
      .setSimpleDescription(
        `You put together all the stones and the iron and ready yourself to cast a spell to shape the new statue. You decide to...`,
      )
      .addOption(
        "Build a statue of the researcher (2000 Mana)",
        buildEventMessage("statue_3_researcher", "(Storyline) The Statue")
          .setSimpleDescription(
            `You shape the materials creating a splitting image of the researcher. She gasps, surprised, and blushes in embarrassment.
          
"A statue of *myself*? Why?!", she pouts. You explain to her that magical research is the cornerstone to any respectable school, and by building a statue of her, you honor and highlight this fact. She is unconvinced by your argument, but you know better.

**You have completed "The Statue" storyline by building a statue of the researcher! In future runs, your researchers will be inspired by this statue and work 50% faster!**`,
          )
          .build(),
        (state) => {
          state = grantResource(Resource.Mana, -2000)(state);
          state = setGlobalFlagForNextRun("researcher_statue_built")(state);
          return state;
        },
        (state) => !hasGlobalFlag(state, "researcher_statue_built"),
        (state) => getResourceAmount(state, Resource.Mana) >= 2000,
      )
      .addOption(
        "Build a statue of the apprentice (2000 Mana)",
        buildEventMessage("statue_3_apprentice", "(Storyline) The Statue")
          .setSimpleDescription(
            `You shape the materials creating a splitting image of the apprentice. He stares at it with his mouth open; thankfully his newly built statue looks a lot more determined!
          
"W-Wow", he begins to say, but you can tell he is at a loss of words. Your decision, however, was very clear. Students are the soul of any school, and he was the very first one.

**You have completed "The Statue" storyline by building a statue of the apprentice! In future runs, you will have an extra 2 apprentices right from when you unlock the feature, without the need to build housing!**`,
          )
          .build(),
        (state) => {
          state = grantResource(Resource.Mana, -2000)(state);
          state = setGlobalFlagForNextRun("apprentice_statue_built")(state);
          return state;
        },
        (state) => !hasGlobalFlag(state, "apprentice_statue_built"),
        (state) => getResourceAmount(state, Resource.Mana) >= 2000,
      )
      .addOption(
        "Build a statue of yourself (2000 Mana)",
        buildEventMessage("statue_3_headmaster", "(Storyline) The Statue")
          .setSimpleDescription(
            `You shape the materials creating a splitting image of yourself. You stare at it: it looks glorious and you feel proud of it.
          
"Amazing", says the researcher. "This is the best way to honor our school - what can be better than a statue of our very leader?" The apprentice bows deeply as a show of respect.

**You have completed "The Statue" storyline by building a statue of yourself! In future runs, the statue's presence will grant you an extra +2:mana:/sec and +200 Max:mana:!**`,
          )
          .build(),
        (state) => {
          state = grantResource(Resource.Mana, -2000)(state);
          state = setGlobalFlagForNextRun("headmaster_statue_built")(state);
          return state;
        },
        (state) => !hasGlobalFlag(state, "headmaster_statue_built"),
        (state) => getResourceAmount(state, Resource.Mana) >= 2000,
      )
      .build(),
    (state) => {
      state = grantResource(Resource.Stone, -7200)(state);
      state = grantResource(Resource.Iron, -800)(state);
      return state;
    },
    undefined,
    (state) =>
      getSchoolLevel(state, School.Conjuration) >= 11 &&
      getResourceAmount(state, Resource.Stone) >= 7200 &&
      getResourceAmount(state, Resource.Iron) >= 800,
  )
  .build();

const statueEvent = createGameEvent(
  "statue",
  message,
  setFlag("statue_event_triggered"),
);

// ============== Researcher statue ===============

registerTransformation(
  [[TransformationTags.Research]],
  "ResearcherStatueResearch",
  TransformationType.Multiplier,
  (state) => (hasGlobalFlag(state, "researcher_statue_built") ? 1.5 : 1.0),
);

Storylines.register(
  new Storyline(
    "The Statue (Researcher)",
    (state) => hasGlobalFlagIncludingNextRun(state, "researcher_statue_built"),
    (state) => hasGlobalFlag(state, "researcher_statue_built"),
    "There's a chance of this happening randomly if your inventory is unlocked...",
    "+50% Research speed",
    (state) => true,
  ),
);

// ============== Apprentice statue ===============

registerTransformation(
  [[TransformationTags.Apprentice]],
  "ApprenticeStatue",
  TransformationType.Addition,
  (state) => (hasGlobalFlag(state, "apprentice_statue_built") ? 2 : 0),
);

Storylines.register(
  new Storyline(
    "The Statue (Apprentice)",
    (state) => hasGlobalFlagIncludingNextRun(state, "apprentice_statue_built"),
    (state) => hasGlobalFlag(state, "apprentice_statue_built"),
    "There's a chance of this happening randomly if your inventory is unlocked...",
    "Start runs with 2 extra apprentices",
    (state) => true,
  ),
);

// ============== Headmaster statue ===============

// This cap transformation does not need to recalculate caps since
// it doesn't take effect until the game restarts
registerTransformation(
  [[Resource.Mana, TransformationTags.Cap]],
  "HeadmasterStatue",
  TransformationType.Addition,
  (state) => (hasGlobalFlag(state, "headmaster_statue_built") ? 200 : 0),
);

registerTimeTickListener(
  "headmaster_statue",
  (state: PlayerContextState, timeElapsedSec: number) => {
    if (hasGlobalFlag(state, "headmaster_statue_built")) {
      const manaPerSec = applyTransformations(
        [Resource.Mana, TransformationTags.Production],
        state,
        2.0,
      );
      state = grantResource(Resource.Mana, timeElapsedSec * manaPerSec)(state);
    }
    return state;
  },
);

Storylines.register(
  new Storyline(
    "The Statue (Headmaster)",
    (state) => hasGlobalFlagIncludingNextRun(state, "headmaster_statue_built"),
    (state) => hasGlobalFlag(state, "headmaster_statue_built"),
    "There's a chance of this happening randomly if your inventory is unlocked...",
    "+200 Max:mana:, +2:mana:/sec",
    (state) => true,
  ),
);

// ====================

registerRandomEventTrigger(
  statueEvent,
  20,
  (state) =>
    !hasFlag(state, "statue_event_triggered") &&
    hasFlag(state, "inventory_unlocked") &&
    (!hasGlobalFlag(state, "researcher_statue_built") ||
      !hasGlobalFlag(state, "apprentice_statue_built") ||
      !hasGlobalFlag(state, "headmaster_statue_built")),
);

export default statueEvent;
