import {
  registerTransformation,
  TransformationType,
} from "../../calculation/Calculation";
import { AttackTarget } from "../../exploration/AttackTarget";
import { CombatStat } from "../../exploration/CombatStats";
import { PlayerContextState } from "../../PlayerContext";
import { Upgrade } from "../Upgrade";

class HealthBoost extends Upgrade {
  getName(): string {
    return "Health Boost";
  }

  getMonstiumCost(): number {
    return 450;
  }

  getDisplayDescription(state: PlayerContextState): string {
    return "Condition your body in order to be able to withstand more blows.";
  }

  getDisplayEffect(state: PlayerContextState): string {
    return "+40 Max:hp:";
  }
}

const healthBoost = new HealthBoost();

registerTransformation(
  [[AttackTarget.Player, CombatStat.MaxHP]],
  "HealthBoost",
  TransformationType.Addition,
  (state) => (healthBoost.isPurchased(state) ? 40 : 0),
);

export default healthBoost;
