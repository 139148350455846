import { hasFlag, setFlag } from "../../Flags";
import {
  getInventory,
  getTotalAmountOfItem,
  removeFromInventoryWithAnyParams,
} from "../../items/Inventory";
import LightweightChest from "../../items/other/LightweightChest";
import { addContextListener } from "../../PlayerContextListeners";
import { getResourceAmount, grantResource, Resource } from "../../Resources";
import {
  getSchoolLevel,
  getTotalElementAndSchoolExp,
  School,
} from "../../spells/ElementsAndSchools";
import { buildEventMessage } from "../EventMessageBuilder";
import { triggerEvent } from "../Events";
import { createGameEvent } from "../SimpleGameEvent";

const message = buildEventMessage("shop_intro", "Selling Items")
  .setSimpleDescription(
    `The new crafting table is doing well, but every time you get rid of an item to make room for another, you feel a little bad inside.
    
As if reading your mind, a fat businessman with gaudy clothes shows up in campus. He grins at you, with a sneaky smile.

"Gooooood afternoon, headmaster", he exclaims to you. He looks at your item collection for a short while, then turns to you again. "May I interest you in a business propooooosal?"`,
  )
  .addOption(
    "Continue",
    buildEventMessage("shop_intro_2", "Selling Items")
      .setSimpleDescription(
        `"You have some very interesting items here", he says. "I would very much like to have them! I'd be happy to even offer you a bit of cooooins for them."
        
That's like music to your ears! But then the businessman continues.

"Of cooourse, that is only possible if I can carry them! You are a very talented individual. Would you be able to craft me a :lightweightChest: Lightweight Chest for that purpose? If you dooooo... I'll make sure to come back ooooften and buy your items, multiple times!"

You notice he already has a backpack...`,
      )
      .addOption(
        "Charm the Businessman (1200 Mana)",
        buildEventMessage("shop_intro_4", "Selling Items")
          .setSimpleDescription(
            `You use your Illusion prowess to charm the businessman; you don't think he actually needs the chest. It seems to work!
            
"Ah," he says with a more relaxed grin. "I actually doooon't need the chest. I'll be happy just to buy your items, just let me know. I'll come ooooften." He gives you a glance, then turns back and leaves.

**You can now sell items for :coins:!**`,
          )
          .build(),
        (state) => {
          state = grantResource(Resource.Mana, -1200)(state);
          state = setFlag("selling_unlocked")(state);
          return state;
        },
        (state) => getSchoolLevel(state, School.Illusion) >= 5,
        (state) => getResourceAmount(state, Resource.Mana) >= 1200,
      )
      .addOption(
        "Hand over a Lightweight Chest",
        buildEventMessage("shop_intro_3", "Selling Items")
          .setSimpleDescription(
            `You give the businessman the chest he asked for. His grin widens.
            
"Thank yoooooou", he says. "Let me knoooow if there's anything you would like me to take from you. I'll come ooooften." He gives you a glance, then turns back and leaves.

**You can now sell items for :coins:!**`,
          )
          .build(),
        (state) => {
          state = removeFromInventoryWithAnyParams(LightweightChest, 1, state);
          state = setFlag("selling_unlocked")(state);
          return state;
        },
        undefined,
        (state) => getTotalAmountOfItem(LightweightChest, state) > 0,
      )
      .addOption("Maybe later")
      .build(),
  )
  .build();

const event = createGameEvent("shop_intro", message, setFlag("shop_intro"));

addContextListener("shop_intro_inventory", (oldState, newState) => {
  if (!hasFlag(newState, "has_crafted") && getInventory(newState).length > 0) {
    return setFlag("has_crafted");
  }
});

addContextListener("shop_intro", (oldState, newState) => {
  if (
    !hasFlag(newState, "shop_intro") &&
    hasFlag(newState, "inventory_unlocked") &&
    hasFlag(newState, "has_crafted") &&
    getTotalElementAndSchoolExp(newState) >= 16000
  ) {
    return triggerEvent(event);
  }
});

export default event;
