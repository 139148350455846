import { PlayerContextState } from "../../../PlayerContext";
import { Enemy } from "../../enemies/Enemy";
import BasementBat from "../../enemies/schoolbasement/BasementBat";
import Mouse from "../../enemies/schoolbasement/Mouse";
import Skeleton from "../../enemies/schoolbasement/Skeleton";
import { DungeonFloor, encounterEnemy } from "../DungeonFloor";
import SchoolBasementB2 from "./SchoolBasementB2";

class SchoolBasementB1 extends DungeonFloor {
  getId(): string {
    return "schoolBasementB1";
  }

  getFloorName(): string {
    return "School Basement B1";
  }

  getBoss(): Enemy {
    return Skeleton;
  }

  isUnlocked(state: PlayerContextState): boolean {
    return true;
  }

  getBreedingLevel(): number {
    return 2;
  }

  getExplorationPossibilities(state: PlayerContextState) {
    return [
      {
        weight: 100,
        transforms: encounterEnemy(Mouse),
      },
      {
        weight: 40,
        transforms: encounterEnemy(BasementBat),
      },
    ];
  }

  getNextFloor() {
    return SchoolBasementB2;
  }
}

export default new SchoolBasementB1();
