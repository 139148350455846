import { clone } from "../utils/CoreUtils";
import { PlayerContextState, PLAYER_INITIAL_STATE } from "./PlayerContext";

export type RetirementListenerTransform = (
  state: PlayerContextState,
  isMock: boolean,
) => PlayerContextState;
const retirementListeners: Record<string, RetirementListenerTransform> = {};
const postRetirementListeners: Record<string, RetirementListenerTransform> = {};

export function registerRetirementListener(
  id: string,
  listener: RetirementListenerTransform,
) {
  retirementListeners[id] = listener;
}

export function registerPostRetirementListener(
  id: string,
  listener: RetirementListenerTransform,
) {
  postRetirementListeners[id] = listener;
}

export function retire(state: PlayerContextState, isMock: boolean) {
  Object.keys(retirementListeners).forEach((listenerId) => {
    state = retirementListeners[listenerId](state, isMock);
  });

  state.global.totalTimesResetted += 1;

  let newState = clone(PLAYER_INITIAL_STATE);
  newState.lastProcessedTime = Date.now();
  newState.global = state.global;
  Object.keys(postRetirementListeners).forEach((listenerId) => {
    newState = postRetirementListeners[listenerId](newState, isMock);
  });
  return newState;
}
