import { ItemBase } from "../../Item";

class DarknessSpeck extends ItemBase {
  getId(): string {
    return "darknessSpeck";
  }

  getPicture() {
    return require("./darknessSpeck.png");
  }

  getBaseName(): string {
    return "Darkness Speck";
  }

  getTags() {
    return [{ tag: "Material" }];
  }

  getDescription(): string | undefined {
    return "A small black gem. Full of darkness elemental power.";
  }

  getEffect(): string | undefined {
    return undefined;
  }

  getSalePrice(): number {
    return 500;
  }

  getCraftingSchoolLevelRequirements() {
    return {};
  }

  getCraftingMaterialsBase() {
    return {
      resources: {},
      items: {},
    };
  }
}

export default new DarknessSpeck();
