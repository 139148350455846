import { color } from "@rneui/base/dist/helpers";
import React, { useCallback, useContext, useMemo, useRef } from "react";
import { Platform, StyleSheet, View } from "react-native";
import { Button, Icon, useTheme } from "react-native-elements";
import { PlayerContext } from "../../backend/PlayerContext";
import {
  addActionToQuickbar,
  isInQuickbar,
  isQuickbarFull,
} from "../../backend/Quickbar";
import { Spell, SpellActionBase } from "../../backend/spells/Spell";
import {
  addFavoriteSpell,
  isInFavoriteSpells,
  removeFromFavoriteSpells,
} from "../../backend/spells/Spells";
import { formatNumber } from "../../utils/FormattingUtils";
import { GameIcon } from "./icons/GameIcon";
import { Markdown } from "./Markdown";
import { DescriptionViewer } from "./Miscellaneous";
import { Text } from "./Text";

export function SpellView(props: {
  spell: Spell;
  showAddToQuickbar?: boolean;
  collapsed?: boolean;
  toggleCollapsed?: () => void;
  isMinimal?: boolean;
}) {
  const playerContext = useContext(PlayerContext);
  const playerContextRef = useRef(playerContext);
  const spell = props.spell;
  const showAddToQuickbar = props.showAddToQuickbar || false;

  const descriptionContainer = useMemo(() => {
    const descriptionContents = `${spell.getDisplayDescription(
      playerContext,
    )} **${spell.getDisplayEffect(playerContext)}**`;
    return (
      <View style={styles.descriptionTextContainer}>
        <DescriptionViewer style={{ flex: 1 }}>
          {descriptionContents}
        </DescriptionViewer>
      </View>
    );
  }, [
    spell.getDisplayDescription(playerContext),
    spell.getDisplayEffect(playerContext),
  ]);

  const cooldownContainer = useMemo(() => {
    const cooldown = (spell as SpellActionBase).getCooldown(playerContext);
    const cooldownText = `${formatNumber(cooldown, {
      showDecimals: true,
    })} sec`;
    return (
      <View>
        {cooldown > 0 && (
          <View style={styles.costContainer}>
            <Markdown>Cooldown:</Markdown>
            <Markdown>{cooldownText}</Markdown>
          </View>
        )}
      </View>
    );
  }, [(spell as SpellActionBase).getCooldown(playerContext)]);

  const toggleFavorite = useCallback(() => {
    playerContextRef.current.apply((state) =>
      isInFavoriteSpells(state, spell)
        ? removeFromFavoriteSpells(state, spell)
        : addFavoriteSpell(state, spell),
    );
  }, []);

  const theme = useTheme();

  const favoritesButton = useMemo(
    () => (
      <Icon
        onPress={toggleFavorite}
        type="ionicon"
        name={
          isInFavoriteSpells(playerContext, spell) ? "heart" : "heart-outline"
        }
        containerStyle={[styles.expandContainer]}
        size={32}
      />
    ),
    [isInFavoriteSpells(playerContext, spell), toggleFavorite],
  );

  const disabled = !spell.isEnabled(playerContext);
  const buttonTitleStyles = useMemo(
    () => [
      {
        color: "white",
      },
      styles.buttonTitle,
      disabled && {
        color: color(theme?.theme?.colors?.disabled).darken(0.3).string(),
      },
      styles.smallButtonTitle,
    ],
    [theme?.theme?.colors?.disabled, disabled],
  );
  const spellDisplayName = spell.getDisplayName(playerContext);
  const spellCost = spell.getCost(playerContext)?.resources?.Mana ?? 0;
  const button = useMemo(
    () => (
      <Button
        key={spell.getName()}
        onPress={() =>
          playerContextRef.current.apply(
            spell.doAction.bind(spell, { isAutomatic: false }),
          )
        }
        title={
          <View style={styles.spellButtonTitleContainer}>
            <View style={styles.spellButtonTitleOnlyContainer}>
              <Text style={buttonTitleStyles}>{spellDisplayName}</Text>
            </View>
            <View style={styles.spellButtonCostContainer}>
              <Text style={buttonTitleStyles}>{spellCost}</Text>
              <GameIcon icon="mana" style={styles.spellManaIcon} />
            </View>
          </View>
        }
        titleStyle={styles.smallButtonTitle}
        containerStyle={[styles.buttonContainer, styles.buttonContainerSmall]}
        disabled={disabled}
      />
    ),
    [
      spell.getDisplayName(playerContext),
      buttonTitleStyles,
      spellDisplayName,
      spellCost,
      disabled,
    ],
  );

  const addToQuickbarButton = useMemo(
    () => (
      <Button
        onPress={() =>
          playerContextRef.current.apply((state) =>
            addActionToQuickbar(state, spell),
          )
        }
        title="Add to Quickbar"
        titleStyle={styles.smallButtonTitle}
        containerStyle={[styles.quickbarButtonContainer]}
        disabled={
          isInQuickbar(playerContext, {
            type: "action",
            name: spell.getName(),
          }) || isQuickbarFull(playerContext)
        }
      />
    ),
    [
      isInQuickbar(playerContext, {
        type: "action",
        name: spell.getName(),
      }),
      isQuickbarFull(playerContext),
    ],
  );

  return useMemo(() => {
    if (props.isMinimal) {
      return <View style={styles.buttonContainerView}>{button}</View>;
    }
    return (
      <View style={styles.actionContainer} key={spell.getName()}>
        <View style={styles.buttonRowContainer}>
          <View style={styles.buttonContainerView}>{button}</View>
          <Icon
            name={props.collapsed ? "chevron-right" : "chevron-down"}
            type="entypo"
            containerStyle={[styles.expandContainer]}
            onPress={props.toggleCollapsed}
          />
        </View>
        <View style={props.collapsed && { display: "none" }}>
          <View style={styles.descriptionRow}>
            {descriptionContainer}
            {showAddToQuickbar && addToQuickbarButton}
            {!showAddToQuickbar && favoritesButton}
          </View>
          {cooldownContainer}
        </View>
      </View>
    );
  }, [
    spell.getName(),
    !props.collapsed && cooldownContainer,
    !props.collapsed && descriptionContainer,
    showAddToQuickbar,
    addToQuickbarButton,
    JSON.stringify(playerContext.quickbar),
    props.collapsed,
    theme,
    toggleFavorite,
    props.isMinimal,
    props.toggleCollapsed,
    favoritesButton,
    button,
  ]);
}

const styles = StyleSheet.create({
  actionContainer: { marginVertical: 8 },
  buttonRowContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  quickbarButtonContainer: {
    marginTop: 4,
    marginLeft: 8,
  },
  expandContainer: {
    width: 40,
  },
  costContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginTop: 4,
  },
  descriptionTextContainer: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    paddingRight: 4,
  },
  buttonTitle: {
    fontSize: 16,
    textAlign: "center",
    paddingVertical: 1,
    ...Platform.select({
      android: {
        fontFamily: "sans-serif-medium",
      },
      default: {
        fontSize: 18,
      },
    }),
  },
  smallButtonTitle: {
    fontSize: 12,
  },
  buttonContainerView: {
    flexDirection: "row",
    flex: 1,
  },
  buttonContainer: {
    flex: 1,
  },
  buttonContainerSmall: {
    marginRight: 0,
  },
  disabledActionText: {
    color: "#aaa",
  },
  capTooLowText: {
    color: "#d99",
  },
  turnOnRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 8,
  },
  turnOnQuantityContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  plusMinusContainer: {
    marginLeft: 8,
  },
  spellButtonTitleContainer: {
    flex: 1,
    flexDirection: "row",
  },
  spellButtonTitleOnlyContainer: {
    flex: 1,
  },
  spellButtonCostContainer: {
    flexDirection: "row",
    width: 40,
    justifyContent: "flex-end",
  },
  spellManaIcon: {
    height: 16,
    width: 16,
    justifyContent: "flex-end",
    alignItems: "center",
    top: 1,
    marginLeft: 2,
  },
  descriptionRow: {
    flexDirection: "row",
    alignItems: "flex-start",
    marginTop: 8,
  },
});
