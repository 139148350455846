import { clone } from "../../utils/CoreUtils";
import { applyTransformations } from "../calculation/Calculation";
import { TransformationTags } from "../calculation/TransformationTags";
import { PlayerContextState } from "../PlayerContext";
import { Item, ItemOccurrence, ItemStack } from "./Item";

export function getInventory(state: PlayerContextState): ItemStack[] {
  return state?.inventory || [];
}

function getItemOccurrenceIndex(
  itemOccurrence: ItemOccurrence,
  state: PlayerContextState,
): number {
  const itemOccurrenceJSON = JSON.stringify(itemOccurrence);
  return getInventory(state).findIndex((itemStack) => {
    return itemOccurrenceJSON == JSON.stringify(itemStack.itemOccurrence);
  });
}

export function calculateInventoryCap(state: PlayerContextState): number {
  return applyTransformations([TransformationTags.InventorySlot], state, 12);
}

export function addToInventory(
  itemOccurrence: ItemOccurrence,
  amount: number,
  state: PlayerContextState,
): PlayerContextState {
  const previousIndex = getItemOccurrenceIndex(itemOccurrence, state);

  if (previousIndex >= 0) {
    if (!state.inventory) {
      state.inventory = [];
    }
    state.inventory[previousIndex] = {
      itemOccurrence,
      amount: state.inventory[previousIndex].amount + amount,
    };
    return state;
  }

  const inventorySize = getInventory(state).length;
  if (inventorySize >= calculateInventoryCap(state)) {
    return state;
  }

  if (!state.inventory) {
    state.inventory = [];
  }
  state.inventory.push({
    itemOccurrence,
    amount,
  });

  return state;
}

export function removeFromInventory(
  itemOccurrence: ItemOccurrence,
  amount: number,
  state: PlayerContextState,
): PlayerContextState {
  const previousIndex = getItemOccurrenceIndex(itemOccurrence, state);
  if (previousIndex < 0) {
    return state;
  }

  if (!state.inventory) {
    state.inventory = [];
  }
  const newAmount = state.inventory[previousIndex].amount - amount;
  if (newAmount > 0) {
    state.inventory[previousIndex] = {
      itemOccurrence,
      amount: newAmount,
    };
  } else {
    state.inventory.splice(previousIndex, 1);
  }
  return state;
}

export function getAmountOfItem(
  itemOccurrence: ItemOccurrence,
  state: PlayerContextState,
): number {
  const index = getItemOccurrenceIndex(itemOccurrence, state);
  if (index === -1) {
    return 0;
  }
  return getInventory(state)[index].amount;
}

export function getTotalAmountOfItem(
  item: Item,
  state: PlayerContextState,
): number {
  return getInventory(state)
    .filter((itemStack) => itemStack.itemOccurrence.itemId == item.getId())
    .reduce((count, current) => count + current.amount, 0);
}

export function removeFromInventoryWithAnyParams(
  item: Item,
  amount: number,
  state: PlayerContextState,
): PlayerContextState {
  let newState = clone(state);

  while (amount > 0) {
    const inventory = getInventory(newState);
    const firstIndex = inventory.findIndex(
      (itemStack) => itemStack.itemOccurrence.itemId == item.getId(),
    );
    if (firstIndex < 0) {
      return state; // abort
    }
    const itemAmount = inventory[firstIndex].amount;
    newState = removeFromInventory(
      inventory[firstIndex].itemOccurrence,
      Math.min(itemAmount, amount),
      newState,
    );
    amount -= itemAmount;
  }

  return newState;
}
