import moment from "moment";
import { clone } from "../../utils/CoreUtils";
import { applyTransformations } from "../calculation/Calculation";
import { TransformationTags } from "../calculation/TransformationTags";
import { resetAllCombatVariables } from "../Flags";
import {
  CombatActionResult,
  EXPLORATION_INITIAL_STATE,
  PlayerContextState,
  PlayerContextTransform,
} from "../PlayerContext";
import { addContextListener } from "../PlayerContextListeners";
import { clearCombatTemporaryEffects } from "../timetick/TemporaryEffects";
import { registerTimeTickListener } from "../timetick/TimeTick";
import { getSecondsPlayed } from "../timetick/TotalTimePlayed";
import {
  EnemyAttackListeners,
  PlayerAttackListeners,
  SummonAttackListeners,
} from "./AttackListeners";
import { AttackTarget } from "./AttackTarget";
import {
  calculatePlayerAccuracy,
  calculatePlayerAttack,
  calculatePlayerAttackDelaySec,
  calculatePlayerCritChance,
  calculatePlayerDefense,
  calculatePlayerDodgeChance,
  calculatePlayerHPRegenPerSec,
  calculatePlayerMaxHP,
  CombatStat,
} from "./CombatStats";
import { DungeonFloor } from "./dungeons/DungeonFloor";
import { DungeonFloors } from "./dungeons/Dungeons";
import { getCurrentEnemy } from "./enemies/Enemies";
import { BattlerAction, Enemy } from "./enemies/Enemy";
import { ExplorationStatus } from "./ExplorationStatus";
import { PlayerDeathInterceptors } from "./PlayerDeathInterceptors";
import { hasCurrentActiveSummon } from "./Summoning";
import { Summon } from "./summons/Summon";
import { getCurrentSummon } from "./summons/Summons";

let lastSelectedEnemyAction: BattlerAction | undefined = undefined;
let lastSelectedSummonAction: BattlerAction | undefined = undefined;

export function startExplorationOnFloor(
  state: PlayerContextState,
  dungeonFloor: DungeonFloor,
): PlayerContextState {
  if (!state.exploration) {
    state.exploration = EXPLORATION_INITIAL_STATE;
  }
  state.exploration.currentDungeonFloorId = dungeonFloor.getId();
  return startExploration(state);
}

export function startExploration(
  state: PlayerContextState,
): PlayerContextState {
  if (!state.exploration) {
    state.exploration = EXPLORATION_INITIAL_STATE;
  }
  state.exploration.explorationStartTime = getSecondsPlayed(state);
  state.exploration.explorationStatus = ExplorationStatus.Exploring;
  return state;
}

export function startBossExploration(
  state: PlayerContextState,
): PlayerContextState {
  if (!state.exploration) {
    state.exploration = EXPLORATION_INITIAL_STATE;
  }
  state.exploration.explorationStartTime = getSecondsPlayed(state);
  state.exploration.explorationStatus = ExplorationStatus.ExploringBoss;
  return state;
}

export function getExplorationStartTime(state: PlayerContextState): number {
  return state.exploration?.explorationStartTime || 0;
}

export function getCurrentDungeonFloor(
  state: PlayerContextState,
): DungeonFloor {
  return DungeonFloors.getById(state.exploration?.currentDungeonFloorId || "");
}

export function getExplorationStatus(
  state: PlayerContextState,
): ExplorationStatus {
  return state.exploration?.explorationStatus || ExplorationStatus.None;
}

function startCombatImpl(
  enemy: Enemy,
  state: PlayerContextState,
): PlayerContextState {
  if (!state.exploration) {
    state.exploration = EXPLORATION_INITIAL_STATE;
  }
  state.exploration.explorationStatus = ExplorationStatus.Combat;
  state.exploration.currentEnemy = {
    id: enemy.getId(),
    currentHP: enemy.getMaxHP(state),
  };
  lastSelectedEnemyAction = enemy.getNextAction(state);
  lastSelectedSummonAction = undefined;
  const hasSummon = hasCurrentActiveSummon(state);
  if (hasSummon) {
    const summon = getCurrentSummon(state) as Summon;
    lastSelectedSummonAction = summon.getNextAction(state);
    state.exploration.summonActionProgress = 0.0;
  }
  state.exploration.playerActionProgress = 0.0;
  state.exploration.enemyActionProgress = 0.0;
  return state;
}

export function getPlayerActionProgress(state: PlayerContextState): number {
  return state.exploration?.playerActionProgress || 0.0;
}

export function getEnemyActionProgress(state: PlayerContextState): number {
  return state.exploration?.enemyActionProgress || 0.0;
}

export function getSummonActionProgress(state: PlayerContextState): number {
  return state.exploration?.summonActionProgress || 0.0;
}

export function startCombat(this: any, enemy: Enemy): PlayerContextTransform {
  return startCombatImpl.bind(this, enemy);
}

export function getCurrentPlayerHP(state: PlayerContextState): number {
  return state.exploration?.currentHP || 0;
}

export function getCurrentSummonHP(state: PlayerContextState): number {
  return state.exploration?.currentSummon?.currentHP || 0;
}

export function getCurrentEnemyHP(state: PlayerContextState): number {
  return state.exploration?.currentEnemy?.currentHP || 0;
}

export function calculateDamage(attack: number, defense: number): number {
  return Math.max(attack - defense / 2, 1);
}

export function calculateIsHit(
  hitChance: number,
  dodgeChance: number,
): boolean {
  const hitOdds = hitChance - dodgeChance;
  return Math.random() < hitOdds;
}

export function modifyPlayerCurrentHP(
  state: PlayerContextState,
  amount: number,
): PlayerContextState {
  if (!state.exploration) {
    state.exploration = EXPLORATION_INITIAL_STATE;
  }

  state.exploration.currentHP = Math.min(
    calculatePlayerMaxHP(state),
    Math.max(state.exploration.currentHP + amount, 0),
  );
  return state;
}

export function modifySummonCurrentHP(
  state: PlayerContextState,
  amount: number,
): PlayerContextState {
  if (!state.exploration) {
    state.exploration = EXPLORATION_INITIAL_STATE;
  }

  if (state.exploration.currentSummon == null) {
    return state;
  }

  const currentSummon = getCurrentSummon(state) as Summon;
  state.exploration.currentSummon.currentHP = Math.min(
    currentSummon.getMaxHP(state),
    Math.max(state.exploration.currentSummon.currentHP + amount, 0),
  );
  return state;
}

export function modifyEnemyCurrentHP(
  state: PlayerContextState,
  amount: number,
): PlayerContextState {
  if (!state.exploration) {
    state.exploration = EXPLORATION_INITIAL_STATE;
  }

  const currentEnemy = getCurrentEnemy(state);
  state.exploration.currentEnemy.currentHP = Math.min(
    currentEnemy.getMaxHP(state),
    state.exploration.currentEnemy.currentHP + amount,
  );
  return state;
}

export function executePlayerAutoAttack(
  state: PlayerContextState,
): PlayerContextState {
  const oldState = clone(state);
  state = standardPlayerAttackEffect(
    state,
    calculatePlayerAttack(state),
    1.0,
    1.0,
  );
  const listeners = PlayerAttackListeners.getAll();
  listeners.forEach((listener) => {
    state = listener(oldState, state);
  });
  return state;
}

export function executeSummonAction(
  state: PlayerContextState,
): PlayerContextState {
  if (
    lastSelectedSummonAction &&
    state.exploration.currentSummon != null &&
    state.exploration.currentSummon.currentHP > 0
  ) {
    const oldState = clone(state);
    state = lastSelectedSummonAction.transform(state);
    const listeners = SummonAttackListeners.getAll();
    listeners.forEach((listener) => {
      state = listener(oldState, state);
    });
  }
  return state;
}

export function executeEnemyAction(
  state: PlayerContextState,
): PlayerContextState {
  if (lastSelectedEnemyAction) {
    const oldState = clone(state);
    state = lastSelectedEnemyAction.transform(state);
    const listeners = EnemyAttackListeners.getAll();
    listeners.forEach((listener) => {
      state = listener(oldState, state);
    });
  }
  return state;
}

export function calculateAttackTarget(state: PlayerContextState): AttackTarget {
  if (hasCurrentActiveSummon(state)) {
    return AttackTarget.Summon;
  }
  return AttackTarget.Player;
}

export function calculateIsEnemyHit(
  state: PlayerContextState,
  hitChance: number,
  forcedTarget?: AttackTarget,
): boolean {
  const modifiedHitChance = applyTransformations(
    [AttackTarget.Enemy, CombatStat.Accuracy],
    state,
    hitChance,
  );
  const target = forcedTarget ?? calculateAttackTarget(state);
  let dodgeChance = 0;
  if (target === AttackTarget.Summon) {
    const summon = getCurrentSummon(state) as Summon;
    if (summon != null) {
      dodgeChance = summon.getDodgeChance(state);
    }
  } else {
    dodgeChance = calculatePlayerDodgeChance(state);
  }
  return calculateIsHit(modifiedHitChance, dodgeChance);
}

export function standardEnemyAttackEffect(
  state: PlayerContextState,
  hitChance: number,
  attack: number,
  critChance?: number,
  forcedTarget?: AttackTarget,
): PlayerContextState {
  const isHit = calculateIsEnemyHit(state, hitChance, forcedTarget);
  let damage = 0,
    isCrit = false;
  const target = forcedTarget ?? calculateAttackTarget(state);
  if (isHit) {
    isCrit = Math.random() < (critChance ?? 0);
    const critMultiplier = 1.5;

    if (target === AttackTarget.Player) {
      damage = calculateDamage(
        isCrit ? attack * critMultiplier : attack,
        isCrit ? 0 : calculatePlayerDefense(state),
      );
      damage = applyTransformations(
        [TransformationTags.AttackDamageReceived, target],
        state,
        damage,
      );
      state = modifyPlayerCurrentHP(state, -damage);
    } else {
      const summon = getCurrentSummon(state) as Summon;
      if (summon != null) {
        damage = calculateDamage(
          isCrit ? attack * critMultiplier : attack,
          isCrit ? 0 : summon.getDefense(state),
        );
        damage = applyTransformations(
          [TransformationTags.AttackDamageReceived, target],
          state,
          damage,
        );
        state = modifySummonCurrentHP(state, -damage);
      }
    }
  }
  const enemyAction = {
    id: Math.random().toString(),
    damage,
    isHit,
    isCrit,
    time: getSecondsPlayed(state),
    target,
    tags: [],
  };
  state.exploration.lastEnemyActionResult = enemyAction;
  state.exploration.actionResultQueue.push(enemyAction);
  return state;
}

export function aoeEnemyAttackEffect(
  state: PlayerContextState,
  hitChance: number,
  attack: number,
  critChance?: number,
) {
  if (hasCurrentActiveSummon(state)) {
    state = standardEnemyAttackEffect(
      state,
      hitChance,
      attack,
      critChance,
      AttackTarget.Summon,
    );
  }
  state = standardEnemyAttackEffect(
    state,
    hitChance,
    attack,
    critChance,
    AttackTarget.Player,
  );
  return state;
}

export function fractionEnemyAttackEffect(
  state: PlayerContextState,
  hitChance: number,
  fraction: number,
  forcedTarget?: AttackTarget,
): PlayerContextState {
  const isHit = calculateIsEnemyHit(state, hitChance, forcedTarget);
  let damage = 0,
    isCrit = false;
  const target = forcedTarget ?? calculateAttackTarget(state);
  if (isHit) {
    if (target === AttackTarget.Player) {
      damage = getCurrentPlayerHP(state) * fraction;
      state = modifyPlayerCurrentHP(state, -damage);
    } else {
      const summon = getCurrentSummon(state) as Summon;
      damage = getCurrentSummonHP(state) * fraction;
      state = modifySummonCurrentHP(state, -damage);
    }
  }
  const enemyAction = {
    id: Math.random().toString(),
    damage,
    isHit,
    isCrit,
    time: getSecondsPlayed(state),
    target,
    tags: [],
  };
  state.exploration.lastEnemyActionResult = enemyAction;
  state.exploration.actionResultQueue.push(enemyAction);
  return state;
}

export function drainingEnemyAttackEffect(
  state: PlayerContextState,
  attack: number,
): PlayerContextState {
  const target = calculateAttackTarget(state);
  let damage;
  if (target === AttackTarget.Player) {
    damage = calculateDamage(attack, calculatePlayerDefense(state));
    damage = applyTransformations(
      [TransformationTags.AttackDamageReceived, AttackTarget.Player],
      state,
      damage,
    );
    state = modifyPlayerCurrentHP(state, -damage);
    state = modifyEnemyCurrentHP(state, +damage);
  } else {
    const summon = getCurrentSummon(state) as Summon;
    damage = calculateDamage(attack, summon.getDefense(state));

    damage = applyTransformations(
      [TransformationTags.AttackDamageReceived, AttackTarget.Summon],
      state,
      damage,
    );
    state = modifySummonCurrentHP(state, -damage);
    state = modifyEnemyCurrentHP(state, +damage);
  }

  const enemyAction = {
    id: Math.random().toString(),
    damage,
    isHit: true,
    isCrit: false,
    time: getSecondsPlayed(state),
    target: AttackTarget.Player,
    tags: [],
  };
  const playerAction = {
    id: Math.random().toString(),
    damage: -damage,
    isHit: true,
    isCrit: false,
    time: getSecondsPlayed(state),
    target: AttackTarget.Enemy,
    tags: [],
  };
  state.exploration.lastEnemyActionResult = enemyAction;
  state.exploration.lastPlayerActionResult = playerAction;
  state.exploration.actionResultQueue.push(enemyAction, playerAction);

  return state;
}

export function standardSummonAttackEffect(
  state: PlayerContextState,
  hitChance: number,
  attack: number,
  critChance?: number,
): PlayerContextState {
  const dodgeChance = getCurrentEnemy(state).getDodgeChance(state);
  const isHit = calculateIsHit(hitChance, dodgeChance);
  let damage = 0,
    isCrit = false;
  if (isHit) {
    isCrit = Math.random() < (critChance ?? 0);
    const critMultiplier = 1.5;

    damage = calculateDamage(
      isCrit ? attack * critMultiplier : attack,
      isCrit ? 0 : getCurrentEnemy(state).getDefense(state),
    );
    damage = applyTransformations(
      [TransformationTags.AttackDamageReceived, AttackTarget.Enemy],
      state,
      damage,
    );
    state = modifyEnemyCurrentHP(state, -damage);
  }
  const summonAction = {
    id: Math.random().toString(),
    damage,
    isHit,
    isCrit,
    time: getSecondsPlayed(state),
    target: AttackTarget.Enemy,
    tags: [],
  };
  state.exploration.lastPlayerActionResult = summonAction;
  state.exploration.actionResultQueue.push(summonAction);
  return state;
}

export function drainingSummonAttackEffect(
  state: PlayerContextState,
  attack: number,
): PlayerContextState {
  let damage = calculateDamage(
    attack,
    getCurrentEnemy(state).getDefense(state),
  );
  damage = applyTransformations(
    [TransformationTags.AttackDamageReceived, AttackTarget.Enemy],
    state,
    damage,
  );
  state = modifyEnemyCurrentHP(state, -damage);
  state = modifySummonCurrentHP(state, +damage);

  const enemyAction = {
    id: Math.random().toString(),
    damage,
    isHit: true,
    isCrit: false,
    time: getSecondsPlayed(state),
    target: AttackTarget.Enemy,
    tags: [],
  };
  const summonAction = {
    id: Math.random().toString(),
    damage: -damage,
    isHit: true,
    isCrit: false,
    time: getSecondsPlayed(state),
    target: AttackTarget.Summon,
    tags: [],
  };
  state.exploration.actionResultQueue.push(enemyAction, summonAction);

  return state;
}

export function standardPlayerAttackEffect(
  state: PlayerContextState,
  attack: number,
  hitChanceMultiplier?: number,
  critChanceMultiplier?: number,
  attackTags?: string[],
): PlayerContextState {
  const hitChance =
    calculatePlayerAccuracy(state) * (hitChanceMultiplier ?? 1.0);
  const dodgeChance = getCurrentEnemy(state).getDodgeChance(state);
  const isHit = calculateIsHit(hitChance, dodgeChance);
  let damage = 0,
    isCrit = false;
  if (isHit) {
    const critChance = calculatePlayerCritChance(state);
    isCrit = Math.random() < critChance * (critChanceMultiplier ?? 0.0);
    const critMultiplier = 1.5;
    damage = calculateDamage(
      isCrit ? attack * critMultiplier : attack,
      isCrit ? 0 : getCurrentEnemy(state).getDefense(state),
    );
    damage = applyTransformations(
      [TransformationTags.AttackDamageReceived, AttackTarget.Enemy],
      state,
      damage,
    );
    state = modifyEnemyCurrentHP(state, -damage);
  }
  const playerAction = {
    id: Math.random().toString(),
    damage,
    isHit,
    isCrit,
    time: getSecondsPlayed(state),
    target: AttackTarget.Enemy,
    tags: attackTags ?? [],
  };
  state.exploration.lastPlayerActionResult = playerAction;
  state.exploration.actionResultQueue.push(playerAction);
  return state;
}

export function getLastEnemyActionResult(
  state: PlayerContextState,
): CombatActionResult | undefined {
  return state.exploration.lastEnemyActionResult;
}

export function getLastPlayerActionResult(
  state: PlayerContextState,
): CombatActionResult | undefined {
  return state.exploration.lastPlayerActionResult;
}

export function getCurrentEnemyAction(state: PlayerContextState) {
  return lastSelectedEnemyAction;
}

export function getCurrentSummonAction(state: PlayerContextState) {
  return lastSelectedSummonAction;
}

export function getMessageLog(state: PlayerContextState) {
  return state?.explorationMessageLog || [];
}

export function getSuccessfulExplorationsForFloor(
  state: PlayerContextState,
  dungeonFloorId: string,
) {
  return (
    state?.exploration?.successfulExplorationsPerFloor?.[dungeonFloorId] || 0
  );
}

export function pushToMessageLog(
  state: PlayerContextState,
  log: string,
): PlayerContextState {
  if (!state.explorationMessageLog) {
    state.explorationMessageLog = [];
  }
  state.explorationMessageLog.unshift({
    id: Math.random().toString(),
    timestamp: getSecondsPlayed(state),
    message: `[${moment(Date.now()).format("h:mm:ss A")}] ${log}`,
  });
  state.explorationMessageLog = state.explorationMessageLog.slice(0, 40);
  return state;
}

export function endExploration(state: PlayerContextState): PlayerContextState {
  if (!state.exploration) {
    state.exploration = EXPLORATION_INITIAL_STATE;
  }
  state.exploration.explorationStatus = ExplorationStatus.None;
  state = pushToMessageLog(state, `You stop exploring.`);
  return state;
}

export function getExplorationTimeRequirementSec(
  state: PlayerContextState,
): number {
  const explorationStatus = getExplorationStatus(state);
  if (
    explorationStatus != ExplorationStatus.Exploring &&
    explorationStatus != ExplorationStatus.ExploringBoss
  ) {
    return 0;
  }
  const dungeonFloor = getCurrentDungeonFloor(state);
  return applyTransformations(
    [TransformationTags.ExplorationDelay],
    state,
    dungeonFloor.getExplorationRequiredTimeSec(),
  );
}

export function getSummonAttackDelay(
  state: PlayerContextState,
): number | undefined {
  const currentSummon = getCurrentSummon(state);
  if (
    !currentSummon ||
    state.exploration?.explorationStatus != ExplorationStatus.Combat
  ) {
    return undefined;
  }

  if (!lastSelectedSummonAction) {
    lastSelectedSummonAction = currentSummon.getNextAction(state);
  }

  return lastSelectedSummonAction.delay;
}

export function getEnemyAttackDelay(state: PlayerContextState): number {
  const currentEnemy = getCurrentEnemy(state);
  if (!lastSelectedEnemyAction) {
    lastSelectedEnemyAction = currentEnemy.getNextAction(state);
  }

  return applyTransformations(
    [AttackTarget.Enemy, CombatStat.AttackDelay],
    state,
    lastSelectedEnemyAction.delay,
  );
}

export function resetEnemyNextAction(): void {
  lastSelectedEnemyAction = undefined;
}

export type EnemyDeathListener = (
  state: PlayerContextState,
  enemy: Enemy,
) => PlayerContextState;

const enemyDeathListeners: EnemyDeathListener[] = [];

export function addEnemyDeathListener(listener: EnemyDeathListener) {
  enemyDeathListeners.push(listener);
}

export function loadExploration() {
  registerTimeTickListener(
    "Exploration",
    (state: PlayerContextState, timeElapsedSec: number) => {
      const explorationStatus = getExplorationStatus(state);
      if (
        explorationStatus != ExplorationStatus.Exploring &&
        explorationStatus != ExplorationStatus.ExploringBoss
      ) {
        return state;
      }

      const dungeonFloor = getCurrentDungeonFloor(state);
      const explorationTimeRequirement =
        getExplorationTimeRequirementSec(state);

      const nextExplorationTime =
        getExplorationStartTime(state) + explorationTimeRequirement;
      if (getSecondsPlayed(state) > nextExplorationTime) {
        if (!state.exploration) {
          state.exploration = EXPLORATION_INITIAL_STATE;
        }
        state.exploration.explorationStartTime = nextExplorationTime;
        if (explorationStatus == ExplorationStatus.Exploring) {
          const outcome = dungeonFloor.doExplore(state);
          if (outcome != null) {
            return outcome.normal(state);
          }
        } else {
          const transform = dungeonFloor.doFightBoss(state);
          return transform ? transform(state) : state;
        }
      }

      return state;
    },
  );

  registerTimeTickListener(
    "Combat",
    (state: PlayerContextState, timeElapsedSec: number) => {
      const explorationStatus = getExplorationStatus(state);
      if (explorationStatus != ExplorationStatus.Combat) {
        return state;
      }

      if (!state.exploration) {
        state.exploration = EXPLORATION_INITIAL_STATE;
      }

      const currentEnemy = getCurrentEnemy(state);
      const currentSummon = getCurrentSummon(state);

      // First, pass the time
      const attackDelayPlayer = calculatePlayerAttackDelaySec(state);
      const attackDelayEnemy = getEnemyAttackDelay(state);
      const attackDelaySummon = getSummonAttackDelay(state);

      state.exploration.playerActionProgress +=
        timeElapsedSec / attackDelayPlayer;
      state.exploration.enemyActionProgress +=
        timeElapsedSec / attackDelayEnemy;
      if (attackDelaySummon != null) {
        state.exploration.summonActionProgress +=
          timeElapsedSec / attackDelaySummon;
      }

      if (state.exploration.playerActionProgress > 1.0) {
        state.exploration.playerActionProgress -= 1.0;
        let extraSec =
          state.exploration.playerActionProgress * attackDelayPlayer;
        if (extraSec == Infinity) {
          // Guard against stunning
          extraSec = 0;
        }
        state = executePlayerAutoAttack(state);
        if (!state.exploration) {
          state.exploration = EXPLORATION_INITIAL_STATE;
        }
        const newAttackDelay = calculatePlayerAttackDelaySec(state);
        state.exploration.playerActionProgress = extraSec / newAttackDelay;
      }

      if (
        lastSelectedSummonAction != null &&
        attackDelaySummon != null &&
        state.exploration.summonActionProgress > 1.0
      ) {
        state.exploration.summonActionProgress -= 1.0;
        const extraSec =
          state.exploration.summonActionProgress * attackDelaySummon;
        state = executeSummonAction(state);
        lastSelectedSummonAction = currentSummon?.getNextAction(state);
        if (!state.exploration) {
          state.exploration = EXPLORATION_INITIAL_STATE;
        }
        const newAttackDelay = getSummonAttackDelay(state);
        if (newAttackDelay != null) {
          state.exploration.summonActionProgress = extraSec / newAttackDelay;
        }
      }

      if (state.exploration.enemyActionProgress > 1.0) {
        state.exploration.enemyActionProgress -= 1.0;
        let extraSec = state.exploration.enemyActionProgress * attackDelayEnemy;
        if (extraSec == Infinity) {
          // Guard against stunning
          extraSec = 0;
        }
        state = executeEnemyAction(state);
        lastSelectedEnemyAction = currentEnemy.getNextAction(state);
        if (!state.exploration) {
          state.exploration = EXPLORATION_INITIAL_STATE;
        }
        const newAttackDelay = getEnemyAttackDelay(state);
        state.exploration.enemyActionProgress = extraSec / newAttackDelay;
      }

      return state;
    },
  );

  addContextListener(
    "CombatSummonDeathListener",
    (oldState: PlayerContextState, newState: PlayerContextState) => {
      if (getExplorationStatus(newState) != ExplorationStatus.Combat) {
        return;
      }

      if (
        getCurrentSummonHP(oldState) > 0 &&
        getCurrentSummonHP(newState) <= 0
      ) {
        return (state) => {
          if (!state.exploration) {
            state.exploration = EXPLORATION_INITIAL_STATE;
          }
          const currentSummon = getCurrentSummon(state) as Summon;
          state = pushToMessageLog(
            state,
            `Your summoned ` + currentSummon.getName() + " has died!",
          );
          state.exploration.currentSummon = undefined;
          lastSelectedSummonAction = undefined;
          return state;
        };
      }
    },
  );

  addContextListener(
    "CombatCleaningCrew",
    (oldState: PlayerContextState, newState: PlayerContextState) => {
      if (
        getExplorationStatus(oldState) === ExplorationStatus.Combat &&
        getExplorationStatus(newState) !== ExplorationStatus.Combat
      ) {
        return (state) => {
          state = clearCombatTemporaryEffects(state);
          state = resetAllCombatVariables(state);
          lastSelectedSummonAction = undefined;
          return state;
        };
      }
    },
  );

  addContextListener(
    "CombatDeathListener",
    (oldState: PlayerContextState, newState: PlayerContextState) => {
      if (getExplorationStatus(newState) != ExplorationStatus.Combat) {
        return;
      }

      if (getCurrentPlayerHP(newState) <= 0) {
        const shouldPreventDeath = PlayerDeathInterceptors.getAll()
          .map((interceptor) => interceptor(newState))
          .some((value) => value);
        if (!shouldPreventDeath) {
          // Player death occurred
          return (state) => {
            state = clearCombatTemporaryEffects(state);
            state = resetAllCombatVariables(state);
            lastSelectedSummonAction = undefined;
            if (!state.exploration) {
              state.exploration = EXPLORATION_INITIAL_STATE;
            }
            state.exploration.explorationStatus = ExplorationStatus.Reviving;
            state = pushToMessageLog(
              state,
              `You run away to recover from your injuries...`,
            );
            return state;
          };
        }
      }

      if (getCurrentEnemyHP(newState) <= 0) {
        return (state) => {
          const currentEnemy = getCurrentEnemy(state);
          state = pushToMessageLog(
            state,
            `You defeated ${currentEnemy.getName()}!`,
          );
          state = currentEnemy.onDeath(state, false);
          enemyDeathListeners.forEach((listener) => {
            state = listener(state, currentEnemy);
          });
          if (!state.exploration) {
            state.exploration = EXPLORATION_INITIAL_STATE;
          }
          const floor = state.exploration.currentDungeonFloorId;
          state.exploration.successfulExplorationsPerFloor[floor] =
            getSuccessfulExplorationsForFloor(state, floor) + 1;

          return startExploration(state);
        };
      }
    },
  );

  registerTimeTickListener(
    "PlayerHPRegen",
    (state: PlayerContextState, timeElapsedSec: number) => {
      const explorationStatus = getExplorationStatus(state);

      // This is necessary or else the player never dies because they regenerate HP
      // in the same update
      if (
        explorationStatus == ExplorationStatus.Combat &&
        getCurrentPlayerHP(state) <= 0
      ) {
        return state;
      }

      const hpRegenBase = calculatePlayerHPRegenPerSec(state);
      let hpRegenMultiplier = 1.0;
      if (explorationStatus != ExplorationStatus.Combat) {
        hpRegenMultiplier = 10.0;
      }
      state = modifyPlayerCurrentHP(
        state,
        hpRegenBase * hpRegenMultiplier * timeElapsedSec,
      );
      return state;
    },
  );

  addContextListener(
    "ContinueExplorationAfterReviving",
    (oldState, newState) => {
      if (
        getExplorationStatus(newState) == ExplorationStatus.Reviving &&
        getCurrentPlayerHP(newState) == calculatePlayerMaxHP(newState)
      ) {
        return startExploration;
      }
    },
  );
}
