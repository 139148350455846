import React, { useContext, useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { Divider } from "react-native-elements";
import { PlayerContext } from "../../backend/PlayerContext";
import {
  getUnlockableSecretsByType,
  SecretType,
} from "../../backend/secrets/Secrets";
import { SecretContainer } from "./SecretContainer";

type SecretsContainerProps = { secretType: SecretType };

export default function SecretsContainer(props: SecretsContainerProps) {
  const playerContext = useContext(PlayerContext);
  const secrets = getUnlockableSecretsByType(playerContext, props.secretType);

  const secretDisplays = useMemo(
    () =>
      secrets.map((secret) => {
        return <SecretContainer key={secret.id} secret={secret} />;
      }),
    [secrets.map((secret) => secret.id).join(",")],
  );

  return useMemo(() => {
    if (secretDisplays.length === 0) {
      return null;
    }
    return (
      <View>
        <View key={secrets[0].id}>{secretDisplays[0]}</View>
        {secretDisplays.slice(1).map((secretDisplay, idx) => {
          return (
            <View key={secrets[idx].id}>
              <Divider />
              {secretDisplay}
            </View>
          );
        })}
      </View>
    );
  }, [secretDisplays]);
}

const styles = StyleSheet.create({});
