import { SimpleLineIcons } from "@expo/vector-icons";
import { Picker } from "@react-native-picker/picker";
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { Platform, StyleSheet, View } from "react-native";
import { Button } from "react-native-elements";
import { PlayerContext, PlayerContextValue } from "../../backend/PlayerContext";
import { School } from "../../backend/spells/ElementsAndSchools";
import { Spell } from "../../backend/spells/Spell";
import { getVisibleSpellsBySchool } from "../../backend/spells/Spells";
import CategoryContainer from "../utility/CategoryContainer";
import { SpellView } from "../utility/SpellView";
import { Text } from "../utility/Text";
import { FavoriteSpellsContainer } from "./FavoriteSpellsContainer";

function SpellsContainerInside(props: {
  area?: string;
  onlyBySchool?: boolean;
  showAddToQuickbar?: boolean;
  playerContext: PlayerContextValue;
  isVisible?: boolean;
}) {
  const playerContext = props.playerContext;
  let spellsBySchool = getVisibleSpellsBySchool(playerContext);
  if (props.area) {
    for (let school in spellsBySchool) {
      spellsBySchool[school] = spellsBySchool[school].filter(
        (spell) => spell.getArea() == props.area,
      );
      if (spellsBySchool[school].length == 0) {
        delete spellsBySchool[school];
      }
    }
  }

  const [currentSchool, setCurrentSchool] = useState(
    Object.keys(spellsBySchool)?.[0] ?? "Conjuration",
  );
  const [expandedSpells, setExpandedSpells] = useState<Record<string, boolean>>(
    {},
  );
  const expandedSpellsRef = useRef(expandedSpells);
  expandedSpellsRef.current = expandedSpells;

  const schoolButtons = useMemo(
    () =>
      Object.keys(spellsBySchool).map((school) => {
        return (
          <Button
            key={school}
            title={school}
            containerStyle={styles.schoolButton}
            onPress={() => setCurrentSchool(school)}
          />
        );
      }),
    [Object.keys(spellsBySchool).join(",")],
  );

  const allSpellString = (spellsBySchool?.[currentSchool as School] || [])
    .map((spell) => spell.getName())
    .join(",");

  const currentSchoolSpells = useMemo(
    () =>
      (spellsBySchool?.[currentSchool as School] || []).sort(
        (a, b) =>
          (b.getSchoolLevelRequirements()[currentSchool as School] || 0) -
          (a.getSchoolLevelRequirements()[currentSchool as School] || 0),
      ),
    [allSpellString],
  );

  const toggleCollapsed = useCallback((spell: Spell) => {
    const spellName = spell.getName();
    expandedSpellsRef.current[spellName] =
      !expandedSpellsRef?.current?.[spellName];
    setExpandedSpells(expandedSpellsRef.current);
  }, []);

  const boundToggleCollapsed = useMemo(
    () =>
      Object.fromEntries(
        Object.keys(spellsBySchool).map((school) => [
          school,
          spellsBySchool[school].map((spell) =>
            toggleCollapsed.bind(null, spell),
          ),
        ]),
      ),
    [allSpellString],
  );

  const spellActionContainers = useMemo(() => {
    if (props.onlyBySchool) {
      return Object.keys(spellsBySchool).map((school) => {
        const spellArray = spellsBySchool[school];
        return (
          <View style={currentSchool !== school && { display: "none" }}>
            {spellArray.map((spell, idx) => {
              return (
                <SpellView
                  key={spell.getSpellName()}
                  spell={spell}
                  showAddToQuickbar={props.showAddToQuickbar}
                  collapsed={!expandedSpells?.[spell.getSpellName()]}
                  toggleCollapsed={boundToggleCollapsed[school][idx]}
                />
              );
            })}
          </View>
        );
      });
    }

    return currentSchoolSpells.map((spell, idx) => {
      return (
        <SpellView
          key={spell.getSpellName()}
          spell={spell}
          showAddToQuickbar={props.showAddToQuickbar}
          collapsed={!expandedSpells?.[spell.getSpellName()]}
          toggleCollapsed={boundToggleCollapsed[currentSchool][idx]}
        />
      );
    });
  }, [
    props.onlyBySchool
      ? Object.values(spellsBySchool)
          .map((spellArray) =>
            spellArray.map((spell) => spell.getName()).join(","),
          )
          .join(";")
      : currentSchoolSpells.map((spell) => spell.getName()).join(","),
    JSON.stringify(expandedSpells),
    boundToggleCollapsed,
    currentSchool,
  ]);

  const tags = Object.keys(spellsBySchool);

  const spellSchoolPicker = useMemo(
    () => (
      <Picker
        onValueChange={setCurrentSchool}
        style={styles.picker}
        selectedValue={currentSchool}
      >
        {tags.map((tag, idx) => (
          <Picker.Item label={tag} value={tag} key={tag} />
        ))}
      </Picker>
    ),
    [setCurrentSchool, currentSchool, JSON.stringify(tags)],
  );

  const expandContractAll = useCallback(() => {
    let expandedSomething = false;
    currentSchoolSpells.forEach((spell) => {
      const spellName = spell.getName();
      if (!expandedSpells?.[spellName]) {
        expandedSomething = true;
        expandedSpells[spellName] = true;
      }
    });
    if (!expandedSomething) {
      currentSchoolSpells.forEach((spell) => {
        delete expandedSpells[spell.getName()];
      });
    }
    setExpandedSpells(expandedSpells);
  }, [
    expandedSpells,
    currentSchoolSpells.map((spell) => spell.getName()).join(","),
  ]);

  return useMemo(() => {
    return (
      <View>
        <View style={styles.emptyTitleContainer}>
          <SimpleLineIcons
            name="magic-wand"
            size={24}
            style={styles.magicWand}
          />
          <Text h2>Cast Spell</Text>
        </View>
        {!props.onlyBySchool && (
          <View>
            <FavoriteSpellsContainer isVisible={props.isVisible} />
          </View>
        )}
        {Object.keys(spellsBySchool).length > 0 ? (
          <CategoryContainer title="By School">
            <View style={styles.schoolRowContainer}>
              <View style={styles.tagPickerContainer}>{spellSchoolPicker}</View>
              <Button
                titleStyle={styles.buttonTitle}
                title="Expand all"
                onPress={expandContractAll}
              />
            </View>
            <View style={styles.schoolContainer}>{spellActionContainers}</View>
          </CategoryContainer>
        ) : (
          <View style={styles.noSpellsContainer}>
            <Text style={styles.noSpellsText}>No spells yet!</Text>
          </View>
        )}
      </View>
    );
  }, [
    currentSchool,
    setCurrentSchool,
    spellActionContainers,
    schoolButtons,
    props.isVisible,
    spellSchoolPicker,
    expandContractAll,
    props.onlyBySchool,
  ]);
}

export default function SpellsContainer(props: {
  area?: string;
  hideRecent?: boolean;
  showAddToQuickbar?: boolean;
  isVisible?: boolean;
  onlyBySchool?: boolean;
}) {
  const playerContext = useContext(PlayerContext);

  return useMemo(
    () => <SpellsContainerInside playerContext={playerContext} {...props} />,
    [
      props.area,
      props.hideRecent,
      props.showAddToQuickbar,
      props.onlyBySchool,
      props.isVisible !== false ? playerContext : false,
    ],
  );
}

const styles = StyleSheet.create({
  schoolContainer: { marginTop: 8 },
  spellsContainer: { marginTop: 8 },
  schoolButton: { marginVertical: 4 },
  magicWand: { marginRight: 8 },
  schoolTitleContainer: {
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
    justifyContent: "center",
  },
  emptyTitleContainer: {
    flexDirection: "row",
    justifyContent: "center",
  },
  titleContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  schoolTitleTextContainer: {},
  picker: {
    flex: 1,
  },
  schoolRowContainer: {
    marginTop: 8,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  tagPickerContainer: {
    width: 190,
    backgroundColor: "#eee",
    borderRadius: 10,
  },
  buttonTitle: {
    textAlign: "center",
    paddingVertical: 1,
    ...Platform.select({
      android: {
        fontFamily: "sans-serif-medium",
      },
      default: {
        fontSize: 18,
      },
    }),
    fontSize: 12,
  },
  noSpellsText: {
    fontStyle: "italic",
  },
  noSpellsContainer: {
    marginTop: 16,
  },
});
