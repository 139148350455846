import { ItemBase } from "../Item";

class GoldNugget extends ItemBase {
  getId(): string {
    return "goldNugget";
  }

  getPicture() {
    return require("./goldNugget.png");
  }

  getBaseName(): string {
    return "Gold Nugget";
  }

  getTags() {
    return [{ tag: "Material" }];
  }

  getDescription(): string | undefined {
    return "A tiny piece of solid gold. Very valuable, and might be useful for certain crafting.";
  }

  getEffect(): string | undefined {
    return undefined;
  }

  getSalePrice(): number {
    return 1000;
  }

  getCraftingSchoolLevelRequirements() {
    return {
      Alchemy: 20,
    };
  }

  getCraftingMaterialsBase() {
    return {
      resources: {
        Iron: 5000,
        Mana: 3500,
      },
      items: {},
    };
  }
}

export default new GoldNugget();
