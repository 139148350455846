import { applyTransformations } from "../../calculation/Calculation";
import { TransformationTags } from "../../calculation/TransformationTags";
import { PlayerContextState } from "../../PlayerContext";
import { registerTimeTickListener } from "../../timetick/TimeTick";
import { getSecondsPlayed } from "../../timetick/TotalTimePlayed";
import { triggerEvent } from "../Events";
import { GameEvent } from "../GameEvent";

type RandomEvent = {
  event: GameEvent;
  weight: number;
  eligible?: (state: PlayerContextState) => boolean;
  getParams?: (state: PlayerContextState) => any;
};

const randomEvents: Record<string, RandomEvent> = {};

export function registerRandomEventTrigger(
  event: GameEvent,
  weight: number,
  eligible?: (state: PlayerContextState) => boolean,
  getParams?: (state: PlayerContextState) => any,
): void {
  randomEvents[event.getId()] = { event, weight, eligible, getParams };
}

function triggerRandomEvent(state: PlayerContextState): PlayerContextState {
  const eventsThatApply = Object.values(randomEvents).filter(
    (randomEvent) => !randomEvent.eligible || randomEvent.eligible(state),
  );
  if (eventsThatApply.length === 0) {
    return state;
  }
  const totalWeight = eventsThatApply.reduce((sumToNow, randomEvent) => {
    const weight = applyTransformations(
      [TransformationTags.RandomEventWeight],
      state,
      randomEvent.weight,
    );
    return sumToNow + weight;
  }, 0);
  let eventRoll = Math.random() * totalWeight;
  for (let idx in eventsThatApply) {
    const event = eventsThatApply[idx];
    const weight = applyTransformations(
      [TransformationTags.RandomEventWeight],
      state,
      event.weight,
    );
    eventRoll -= weight;
    if (eventRoll < 0) {
      const params = event.getParams ? event.getParams(state) : {};
      return triggerEvent(event.event, params)(state);
    }
  }
  return state;
}

export function debugRandomEvents(
  state: PlayerContextState,
): Record<string, number> {
  const eventsThatApply = Object.values(randomEvents).filter(
    (randomEvent) => !randomEvent.eligible || randomEvent.eligible(state),
  );
  const totalWeight = eventsThatApply.reduce((sumToNow, randomEvent) => {
    const weight = applyTransformations(
      [TransformationTags.RandomEventWeight],
      state,
      randomEvent.weight,
    );
    return sumToNow + weight;
  }, 0);
  const chancesPerEvent: Record<string, number> = {};
  eventsThatApply.forEach((event) => {
    const weight = applyTransformations(
      [TransformationTags.RandomEventWeight],
      state,
      event.weight,
    );
    chancesPerEvent[event.event.getId()] = weight / totalWeight;
  });
  return chancesPerEvent;
}

export function loadRandomEventTriggers(): void {
  registerTimeTickListener(
    "RandomEventTriggers",
    (state: PlayerContextState, elapsedSec: number) => {
      if (getSecondsPlayed(state) > (state?.nextRandomEvent || 0)) {
        state = triggerRandomEvent(state);

        // The next random event will occur in 8 to 16 minutes. However,
        // this period becomes longer and longer as more seconds have been played
        // in the current run: 40 to 80 mins after 24 hours, 72 to 144 mins after 48,
        // about 3 and a half hours after 3 days.
        // This rewards quickly triggering random events after retirement and
        // prevents low quality random event spam later in the game.
        const periodMultiplier = 1.0 + getSecondsPlayed(state) / (60 * 60 * 6);
        state.nextRandomEvent =
          (8 + Math.random() * 8) * 60 * periodMultiplier +
          getSecondsPlayed(state);
      }
      return state;
    },
  );
}
