import React, { useContext } from "react";
import { AttackTarget } from "../../backend/exploration/AttackTarget";
import { calculatePlayerMaxHP } from "../../backend/exploration/CombatStats";
import { getCurrentPlayerHP } from "../../backend/exploration/Exploration";
import { PlayerContext } from "../../backend/PlayerContext";
import { HPBar } from "./HPBar";

export function PlayerHPBar() {
  const playerContext = useContext(PlayerContext);
  const currentHP = getCurrentPlayerHP(playerContext);
  const maxHP = calculatePlayerMaxHP(playerContext);
  return (
    <HPBar
      empty={false}
      current={currentHP}
      max={maxHP}
      actionTarget={AttackTarget.Player}
    />
  );
}
