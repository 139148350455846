import React, { useContext, useMemo, useRef } from "react";
import { StyleSheet, View } from "react-native";
import { Button, Icon } from "react-native-elements";
import { PlayerContext } from "../../backend/PlayerContext";
import {
  getQuickbarActions,
  QuickbarAction,
  removeFromQuickbar,
} from "../../backend/Quickbar";

import { TemporaryEffectsViewer } from "../utility/TemporaryEffectsViewer";
import { Text } from "../utility/Text";

function QuickbarActionContainer(props: { quickbarAction: QuickbarAction }) {
  const playerContext = useContext(PlayerContext);
  const playerContextRef = useRef(playerContext);
  playerContextRef.current = playerContext;

  const quickbarAction = props.quickbarAction;
  const name = quickbarAction.getDisplayName(playerContext);
  const transform = quickbarAction.getTransform(playerContext);
  const data = quickbarAction.toQuickbarData();
  const enabled = quickbarAction.isEnabled(playerContext);

  return useMemo(
    () => (
      <View key={name} style={styles.row}>
        <Button
          title={name}
          containerStyle={styles.mainButton}
          titleStyle={styles.smallButtonTitle}
          onPress={() => playerContextRef.current.apply(transform)}
          disabled={!enabled}
        />
        <Button
          icon={<Icon name="close" size={24} color="white" />}
          containerStyle={styles.deleteButton}
          style={styles.deleteButtonInner}
          iconContainerStyle={styles.deleteButtonInner}
          buttonStyle={styles.deleteButtonInner}
          hitSlop={{ top: 8, bottom: 8, left: 8, right: 8 }}
          onPress={() =>
            playerContextRef.current.apply((state) =>
              removeFromQuickbar(state, data),
            )
          }
        />
      </View>
    ),
    [name, playerContextRef, transform, enabled, removeFromQuickbar],
  );
}

export function QuickbarContainer() {
  const playerContext = useContext(PlayerContext);
  const quickbar = getQuickbarActions(playerContext);
  if (quickbar.length == 0) {
    return (
      <View>
        <Text style={styles.emptyText}>
          Your Quickbar is empty. Add actions to the Quickbar through the
          "Inventory" and "Spells" menus at the bottom!
        </Text>
      </View>
    );
  }
  const mappedActions = quickbar.map((quickbarAction) => (
    <QuickbarActionContainer
      key={quickbarAction.getDisplayName(playerContext)}
      quickbarAction={quickbarAction}
    />
  ));
  return (
    <View style={styles.mainContainer}>
      <View style={styles.actionsContainer}>{mappedActions}</View>
      <View style={styles.infoContainer}>
        <TemporaryEffectsViewer />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  emptyText: { fontWeight: "bold", fontStyle: "italic" },
  row: { flexDirection: "row", alignItems: "center", marginVertical: 4 },
  mainContainer: {
    flexDirection: "row",
    alignItems: "stretch",
  },
  actionsContainer: {
    flex: 1.5,
    marginRight: 8,
  },
  infoContainer: { flex: 1 },
  mainButton: {
    flex: 1,
  },
  smallButtonTitle: {
    fontSize: 12,
  },
  deleteButton: {
    marginLeft: 8,
  },
  deleteButtonInner: {
    paddingHorizontal: 0,
    paddingVertical: 0,
    height: 24,
    width: 24,
    borderRadius: 16,
  },
});
