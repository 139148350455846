import {
  EXPLORATION_INITIAL_STATE,
  PlayerContextState,
} from "../PlayerContext";
import { Summon } from "./summons/Summon";

export function hasCurrentActiveSummon(state: PlayerContextState): boolean {
  return (
    state.exploration.currentSummon != null &&
    state.exploration.currentSummon.currentHP > 0
  );
}

export function activateSummon(
  state: PlayerContextState,
  summon: Summon,
): PlayerContextState {
  if (!state.exploration) {
    state.exploration = EXPLORATION_INITIAL_STATE;
  }
  state.exploration.currentSummon = {
    id: summon.getId(),
    currentHP: summon.getMaxHP(state),
  };
  state.exploration.summonActionProgress = 0.0;
  return state;
}
