import { DataStore } from "../generic/DataStore";
import { Identifiable } from "../generic/Identifiable";
import { PlayerContextTransform } from "../PlayerContext";
import { PlayerContextListener } from "../PlayerContextListeners";

export type OfflineTickTransform = (
  secsPassed: number,
) => PlayerContextTransform | null | undefined | void;

export class OfflineTickListener implements Identifiable {
  id: string;
  listener: OfflineTickTransform;

  constructor(id: string, listener: OfflineTickTransform) {
    this.id = id;
    this.listener = listener;
  }

  getId(): string {
    return this.id;
  }

  getListener(): OfflineTickTransform {
    return this.listener;
  }
}

const OfflineTickListeners = new DataStore<OfflineTickListener>();

export { OfflineTickListeners };
