import React from "react";
import { ItemTag } from "../../backend/items/Item";
import { StyleSheet, View } from "react-native";
import { Text } from "../utility/Text";

export function ItemTags(props: { tags: ItemTag[] }) {
  return (
    <View style={styles.tagRow}>
      {props.tags.map((tag) => (
        <View style={styles.tagStyle} key={tag.tag}>
          <Text style={styles.tagTextStyle}>{tag.tag}</Text>
        </View>
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  tagRow: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  tagStyle: {
    backgroundColor: "#000",
    borderColor: "#000",
    textAlign: "center",
    paddingHorizontal: 6,
    borderRadius: 8,
    height: 18,
    marginRight: 4,
  },
  tagTextStyle: {
    color: "#fff",
    fontSize: 11,
  },
});
