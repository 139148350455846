import {
  EXPLORATION_INITIAL_STATE,
  GLOBAL_INITIAL_STATE,
  PlayerContextState,
  PlayerContextTransform,
  THIS_RUN_INITIAL_STATE,
} from "./PlayerContext";
import { registerRetirementListener } from "./Retire";

export function hasFlag(state: PlayerContextState, flag: string) {
  return state.flags?.[flag] || false;
}

function setFlagImpl(flag: string, state: PlayerContextState) {
  state.flags[flag] = true;
  return state;
}

export function setFlag(this: any, flag: string): PlayerContextTransform {
  // Do it this way to avoid creating extra functions and avoid rerenders
  return setFlagImpl.bind(this, flag);
}

function clearFlagImpl(flag: string, state: PlayerContextState) {
  state.flags[flag] = false;
  return state;
}

export function clearFlag(this: any, flag: string): PlayerContextTransform {
  // Do it this way to avoid creating extra functions and avoid rerenders
  return clearFlagImpl.bind(this, flag);
}

export function setGlobalFlagForNextRunImpl(
  flag: string,
  state: PlayerContextState,
): PlayerContextState {
  if (!state.thisRun) {
    state.thisRun = THIS_RUN_INITIAL_STATE;
  }
  state.thisRun.newFlagsOnRetirement[flag] = true;
  return state;
}

export function setGlobalFlagForNextRun(
  this: any,
  flag: string,
): PlayerContextTransform {
  // Do it this way to avoid creating extra functions and avoid rerenders
  return setGlobalFlagForNextRunImpl.bind(this, flag);
}

export function setGlobalFlagImpl(
  flag: string,
  state: PlayerContextState,
): PlayerContextState {
  if (!state.global) {
    state.global = GLOBAL_INITIAL_STATE;
  }
  state.global.flags[flag] = true;
  return state;
}

export function setGlobalFlag(this: any, flag: string): PlayerContextTransform {
  // Do it this way to avoid creating extra functions and avoid rerenders
  return setGlobalFlagImpl.bind(this, flag);
}

export function hasGlobalFlag(
  state: PlayerContextState,
  flag: string,
): boolean {
  return state?.global?.flags?.[flag] || false;
}

export function hasGlobalFlagIncludingNextRun(
  state: PlayerContextState,
  flag: string,
): boolean {
  return (
    state?.global?.flags?.[flag] ||
    state?.thisRun?.newFlagsOnRetirement?.[flag] ||
    false
  );
}

function setCombatVariableImpl(
  variable: string,
  value: any,
  state: PlayerContextState,
) {
  if (!state.exploration) {
    state.exploration = EXPLORATION_INITIAL_STATE;
  }
  state.exploration.variables[variable] = value;
  return state;
}

export function setCombatVariable(
  this: any,
  variable: string,
  value: any,
): PlayerContextTransform {
  // Do it this way to avoid creating extra functions and avoid rerenders
  return setCombatVariableImpl.bind(this, variable, value);
}

function clearCombatVariableImpl(variable: string, state: PlayerContextState) {
  if (!state.exploration) {
    state.exploration = EXPLORATION_INITIAL_STATE;
  }
  delete state.exploration.variables[variable];
  return state;
}

export function clearCombatVariable(
  this: any,
  variable: string,
): PlayerContextTransform {
  // Do it this way to avoid creating extra functions and avoid rerenders
  return clearCombatVariableImpl.bind(this, variable);
}

export function resetAllCombatVariables(
  state: PlayerContextState,
): PlayerContextState {
  if (!state.exploration) {
    state.exploration = EXPLORATION_INITIAL_STATE;
  }
  state.exploration.variables = {};
  return state;
}

export function getCombatVariable(
  state: PlayerContextState,
  variable: string,
): any {
  if (!state.exploration) {
    state.exploration = EXPLORATION_INITIAL_STATE;
  }
  return state.exploration?.variables[variable];
}

function onRetirement(state: PlayerContextState): PlayerContextState {
  if (!state.global) {
    state.global = GLOBAL_INITIAL_STATE;
  }
  for (let flag in state?.thisRun?.newFlagsOnRetirement || {}) {
    state.global.flags[flag] = true;
  }
  return state;
}

registerRetirementListener("new_flags", onRetirement);
