import { isIdentifiable } from "./Identifiable";

export class DataStore<T> {
  store: Record<string, T> = {};
  maxId: number = 1;

  register(value: T) {
    let id = (this.maxId++).toString();
    if (isIdentifiable(value)) {
      id = value.getId();
    }
    this.store[id] = value;
  }

  getById(id: string): T {
    return this.store[id];
  }

  getAll(): T[] {
    return Object.values(this.store);
  }
}
