import { hasFlag } from "../../Flags";
import { Item } from "../../items/Item";
import { getCraftableItems } from "../../items/Items";
import { PlayerContextState } from "../../PlayerContext";
import { addContextListener } from "../../PlayerContextListeners";
import { getSchoolLevel, School } from "../../spells/ElementsAndSchools";
import { Spell } from "../../spells/Spell";
import { getVisibleSpellsForSchool } from "../../spells/Spells";
import { buildEventMessage } from "../EventMessageBuilder";
import { triggerEvent } from "../Events";
import { createGameEvent } from "../SimpleGameEvent";

function getConcatenatedRequirements(spell: Spell): string {
  let requirements = [];
  const schoolRequirements = spell.getSchoolLevelRequirements();
  for (let school in schoolRequirements) {
    requirements.push(`${school} Lv${schoolRequirements[school as School]}`);
  }
  return requirements.join(", ");
}

function getConcatenatedRequirementsForCraft(
  state: PlayerContextState,
  item: Item,
): string {
  let requirements = [];
  const schoolRequirements = item.getCraftingSchoolLevelRequirements(state);
  for (let school in schoolRequirements) {
    requirements.push(`${school} Lv${schoolRequirements[school as School]}`);
  }
  return requirements.join(", ");
}

export function getMarkdownTextForSpell(spell: Spell): string {
  return `*${spell.getSpellName()}* (${getConcatenatedRequirements(spell)})`;
}

export function getMarkdownTextForCraftableItem(
  state: PlayerContextState,
  item: Item,
): string {
  return `:${item.getId()}:*${item.getName(
    {},
  )}* (${getConcatenatedRequirementsForCraft(state, item)})`;
}

function getLevelUpLearnedSpellsText(
  oldSpells: Spell[],
  newSpells: Spell[],
): string {
  let diffSpells = newSpells.filter((spell) => !oldSpells.includes(spell));
  let newSpellText = "";
  let extraSpellText = "";
  if (diffSpells.length > 3) {
    const extraSpells = diffSpells.length - 3;
    extraSpellText = `and ${extraSpells} more!`;
    diffSpells = diffSpells.slice(0, 3);
  }
  if (diffSpells.length > 0) {
    let newSpellsLearnedText = diffSpells
      .map(getMarkdownTextForSpell)
      .join(", ");
    if (extraSpellText !== "") {
      newSpellsLearnedText = [newSpellsLearnedText, extraSpellText].join(". ");
    }
    newSpellText = `**New spells learned:** ${newSpellsLearnedText}`;
  }
  return newSpellText;
}

function getLevelUpLearnedCraftsText(
  state: PlayerContextState,
  oldItems: Item[],
  newItems: Item[],
): string {
  let diffItems = newItems.filter((item) => !oldItems.includes(item));
  let newCraftsText = "";
  if (hasFlag(state, "inventory_unlocked") && diffItems.length > 0) {
    let extraItemsText = "";
    if (diffItems.length > 3) {
      const extraItems = diffItems.length - 3;
      extraItemsText = `and ${extraItems} more!`;
      diffItems = diffItems.slice(0, 3);
    }
    let newCraftsLearnedText = diffItems
      .map(getMarkdownTextForCraftableItem.bind(null, state))
      .join(", ");
    if (extraItemsText !== "") {
      newCraftsLearnedText = [newCraftsLearnedText, extraItemsText].join(", ");
    }
    newCraftsText = `**New craftable items:**
${newCraftsLearnedText}`;
  }
  return newCraftsText;
}

export function loadLevelUpEvents() {
  for (let school in School) {
    const message = buildEventMessage(
      "Level_Up_" + school,
      school + " Level Up!",
    )
      .setSimpleDescription(
        `Congratulations!
      
Your ${school} level is now {level}!

{levelUpLearnedSpells}

{levelUpLearnedCrafts}`,
      )
      .build();

    const event = createGameEvent("Level_Up_" + school, message);
    addContextListener("Level_Up_" + school, (oldState, newState) => {
      if (
        getSchoolLevel(newState, school as School) >
        getSchoolLevel(oldState, school as School)
      ) {
        const oldSpells = getVisibleSpellsForSchool(oldState, school as School);
        const newSpells = getVisibleSpellsForSchool(newState, school as School);
        const levelUpLearnedSpells = getLevelUpLearnedSpellsText(
          oldSpells,
          newSpells,
        );
        const oldCrafts = getCraftableItems(oldState);
        const newCrafts = getCraftableItems(newState);
        const levelUpLearnedCrafts = getLevelUpLearnedCraftsText(
          newState,
          oldCrafts,
          newCrafts,
        );
        return triggerEvent(event, {
          level: getSchoolLevel(newState, school as School),
          levelUpLearnedSpells: levelUpLearnedSpells,
          levelUpLearnedCrafts: levelUpLearnedCrafts,
        });
      }
    });
  }
}
