import { getEventMessageById } from "./events/Events";
import { PlayerContextState } from "./PlayerContext";
import { hasTemporaryEffect } from "./timetick/TemporaryEffects";

export function cleanup(state: PlayerContextState): PlayerContextState {
  // Cleanup events
  const events = Object.values(state.eventOccurrences).sort(
    (a, b) => b.timestamp - a.timestamp,
  );
  const terminalEvents = events.filter((event) => {
    return (
      event.currentMessageId &&
      getEventMessageById(event.currentMessageId).isTerminal()
    );
  });
  const oldEventOccurrenceIds = terminalEvents
    .slice(20)
    .map((eventOccurrence) => eventOccurrence.eventOccurrenceId);
  oldEventOccurrenceIds.forEach((eventOccurrenceId) => {
    delete state.eventOccurrences[eventOccurrenceId];
  });

  // Cleanup old temporary effect
  for (const key in state.temporaryEffects) {
    if (!hasTemporaryEffect(state, key)) {
      delete state.temporaryEffects[key];
    }
  }

  // Cleanup old action results
  state.exploration.actionResultQueue =
    state.exploration.actionResultQueue.slice(-10);

  return state;
}
