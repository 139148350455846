import { Item } from "./Item";
import { PlayerContextState } from "../PlayerContext";

const items: Record<string, Item> = {};

export function registerItem(item: Item) {
  items[item.getId()] = item;
}

export function getItemById(id: string): Item {
  return items?.[id] as Item;
}

export function getItemByIdNullable(id: string): Item | undefined {
  return items?.[id];
}

export function getCraftableItems(state: PlayerContextState): Item[] {
  return Object.values(items).filter((item) => item.isCraftable(state));
}
