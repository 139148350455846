import { formatNumber } from "../../../utils/FormattingUtils";
import { ActionEffect } from "../../actions/Action";
import {
  registerTransformation,
  TransformationType,
} from "../../calculation/Calculation";
import { TransformationTags } from "../../calculation/TransformationTags";
import { AttackTarget } from "../../exploration/AttackTarget";
import { CombatStat } from "../../exploration/CombatStats";
import { hasFlag } from "../../Flags";
import { PlayerContextState } from "../../PlayerContext";
import {
  grantTemporaryEffect,
  hasTemporaryEffect,
} from "../../timetick/TemporaryEffects";
import { SpellActionBase } from "../Spell";

class Blur extends SpellActionBase {
  constructor() {
    super();
  }

  isVisible(state: PlayerContextState): boolean {
    return super.isVisible(state) && hasFlag(state, "exploration_unlocked");
  }

  getSpellName(): string {
    return "Blur";
  }

  getArea(): string {
    return "Exploration";
  }

  getDisplayDescription(): string {
    return "Alter your physical appearance to make yourself more blurry, increasing your dodge chance.";
  }

  protected getBaseActionEffects(): Record<string, ActionEffect> {
    return {
      magnitude: {
        value: 0.15,
        tags: [TransformationTags.TemporaryEffectMagnitude],
      },
      duration: {
        value: 40,
        tags: [TransformationTags.TemporaryEffectDuration],
      },
    };
  }

  doSpellAction(state: PlayerContextState): PlayerContextState {
    const effects = this.getActionEffects(state);
    return grantTemporaryEffect(
      this.getId(),
      this.getSpellName(),
      effects.duration,
      {},
    )(state);
  }

  getDisplayEffect(state: PlayerContextState): string {
    const effects = this.getActionEffects(state);
    return `+${formatNumber(
      effects.magnitude * 100,
    )}%:dodge: for ${formatNumber(effects.duration)} sec`;
  }

  getManaCost(): number {
    return 850;
  }

  getSchoolLevelRequirements(): Record<string, number> {
    return {
      Illusion: 9,
    };
  }
}

const blur = new Blur();

registerTransformation(
  [[AttackTarget.Player, CombatStat.Dodge]],
  "Blur",
  TransformationType.Addition,
  (state) =>
    hasTemporaryEffect(state, "Blur")
      ? blur.getActionEffects(state).magnitude
      : 0,
);

export default blur;
