import React from "react";
import { StyleSheet } from "react-native";
import { Markdown } from "./Markdown";

export function DescriptionViewer(props: any) {
  return (
    <Markdown style={[styles.descriptionText, props.style || {}]}>
      {props.children}
    </Markdown>
  );
}

export function EffectViewer(props: any) {
  return (
    <Markdown style={[styles.effectText, props.style || {}]}>
      {props.children}
    </Markdown>
  );
}

const styles = StyleSheet.create({
  descriptionText: {
    color: "#666",
  },
  effectText: {
    color: "#666",
    fontWeight: "bold",
  },
});
