import React, { useContext, useState } from "react";
import { StyleSheet, View } from "react-native";
import { Button, ButtonGroup, Card } from "react-native-elements";
import Demo from "../backend/Demo";
import { PlayerContext } from "../backend/PlayerContext";
import {
  getMaxPrimarySchoolLevel,
  getUnlockedElements,
  getUnlockedSchools,
  School,
  selectPrimaryElement,
  selectPrimarySchool,
} from "../backend/spells/ElementsAndSchools";
import { AnalyticsContainer } from "./utility/AnalyticsContainer";
import LoadGameContainer from "./utility/LoadGameContainer";
import { Markdown } from "./utility/Markdown";
import { Text } from "./utility/Text";

export default function Intro(props: { onSelect: () => void }) {
  const playerContext = useContext(PlayerContext);
  const [selectedSchoolIndex, setSelectedSchoolIndex] = useState(
    null as number | null,
  );
  const [selectedElementIndex, setSelectedElementIndex] = useState(
    null as number | null,
  );
  const schools = getUnlockedSchools(playerContext);
  const elements = getUnlockedElements(playerContext);

  const selectedMPL =
    selectedSchoolIndex != null
      ? getMaxPrimarySchoolLevel(playerContext, schools[selectedSchoolIndex])
      : 0;
  const isFirstTime = (playerContext?.global?.totalTimesResetted ?? 0) === 0;
  const showLoadGameContainer = !Demo.isDemo() && isFirstTime;
  const showAnalytics = isFirstTime;
  const beginnerText = isFirstTime
    ? "**If it's your first time playing, choosing Conjuration is recommended.**"
    : "";

  const primarySchoolText = `Please choose a primary school of magic. ${beginnerText}`;

  const schoolDescriptionMap: Record<School, string> = {
    Conjuration:
      "You have selected **Conjuration**! Conjuration is the school that studies creating permanent or semi-permanent physical objects out of nothing. An example spell is *Create Pebble*. **This school is recommended for beginners.**",
    Enchantment:
      "You have selected **Enchantment**! Enchantment is the school that studies temporarily altering the physical properties of existing objects or creatures. An example spell is *Enchant Mana Spouts*.",
    Illusion:
      "You have selected **Illusion**! Illusion is the school that studies altering people's perception of reality. It's often one of the best ways to make money. An example spell is *Wind Show*.",
    Alchemy:
      "You have selected **Alchemy**! Alchemy is the school that studies transforming objects or materials into powerful potions, with various effects. There are also legends that it might be possible to create gold using alchemy.",
    Evocation:
      "You have selected **Evocation**! Evocation is the school that studies damaging combat spells, with the intention to cause harm or destroy. An example spell is *Mana Spike*.",
    Protection:
      "You have selected **Protection**! Protection is the school that studies spells that provide shielding from adverse effects, as well as mend them. An example spell is *Heal*.",
    Breeding:
      "You have selected **Breeding**! Breeding is the school that studies the ability to raise monsters to do your will, exploring and obtaining materials for you.",
    Summoning:
      "You have selected **Summoning**! Summoning is the school that studies invoking monsters out of thin air, to aid you in combat by enduring blows and attacking en enemy. An example spell is *Summon Mouse*.",
    Divination:
      "You have selected **Divination**! Divination is the school that studies learning the unknown by using magic, which can make you more effective. An example spell is *Minor Divination*.",
  };
  const elementDescriptionMap: Record<string, string> = {
    Fire: "You have selected **Fire**! A basic element, it is often quite dangerous but powerful. Fire is key to creating higher temperatures.",
    Earth:
      "You have selected **Earth**! A basic element, one that we often deal with. Includes spells related to metals.",
    Water:
      "You have selected **Water**! A basic element, represents water in its various forms, including ice and snow.",
    Air: "You have selected **Air**! A basic element, it's all around us. It can be very useful when we want to move quicker.",
    Nature:
      "You have selected **Nature**! An element composed of plant life. Great for poisoning effects and gathering wood.",
    Lightning:
      "You have selected **Lightning**! An element that embodies a unique type of energy. Good for fast, high damage.",
    Darkness:
      "You have selected **Darkness**! A forbidden element, with extreme strength - and with some link to dead things...",
  };

  return (
    <View style={styles.container}>
      <View>
        <View style={styles.topView}>
          <Text h1>Welcome to Magic Research!</Text>
          <Text style={styles.topViewText}>
            In Magic Research, you are the headmaster of a magical institution.
            What sort of adventures await you?
          </Text>
          <Text style={styles.topViewText}>
            Before you begin, you will need to choose a primary school of magic
            and a primary element. While you may cast spells from any schools,
            it will be easier to learn new spells in your primary school. Your
            primary element selection may affect availability and effects of
            various spells, among other things. You will be able to change these
            (via retirement) later in the game.
          </Text>
        </View>
        <Card>
          <Card.Title>Schools</Card.Title>
          <Markdown>{primarySchoolText}</Markdown>
          <View>
            <ButtonGroup
              containerStyle={styles.buttonGroupContainer}
              textStyle={styles.buttonGroupText}
              vertical={true}
              onPress={setSelectedSchoolIndex}
              selectedIndex={selectedSchoolIndex}
              buttons={schools}
            />
          </View>
          <View>
            {selectedSchoolIndex != undefined && (
              <View>
                <Markdown>
                  {schoolDescriptionMap[schools[selectedSchoolIndex]]}
                </Markdown>
                {selectedMPL > 0 ? (
                  <Text style={styles.mplText}>
                    Your Maximum Primary Level (MPL) for this school is{" "}
                    {selectedMPL}.
                  </Text>
                ) : (
                  false
                )}
              </View>
            )}
          </View>
        </Card>
        <Card>
          <Card.Title>Elements</Card.Title>
          <Text>Please choose a primary element.</Text>
          <ButtonGroup
            containerStyle={styles.buttonGroupContainer}
            textStyle={styles.buttonGroupText}
            vertical={true}
            onPress={setSelectedElementIndex}
            selectedIndex={selectedElementIndex}
            buttons={elements}
          />
          <View>
            {selectedElementIndex != undefined && (
              <Markdown>
                {elementDescriptionMap[elements[selectedElementIndex]]}
              </Markdown>
            )}
          </View>
        </Card>
        {showAnalytics && (
          <Card>
            <Card.Title>Data Collection Notice</Card.Title>
            <Text>
              Magic Research optionally collects and sends a small amount of
              data related to your progress throughout the game in order to
              improve the game. If you'd like to help improve the game, you can
              opt into this data collection. You may change this setting anytime
              in the "Options" menu.
            </Text>
            <AnalyticsContainer />
          </Card>
        )}
        <Button
          containerStyle={styles.startButton}
          title="Start the Game!"
          disabled={
            selectedSchoolIndex == undefined ||
            selectedElementIndex == undefined
          }
          onPress={() => {
            const selectedSchool = schools[selectedSchoolIndex as number];
            const selectedElement = elements[selectedElementIndex as number];
            props.onSelect();
            playerContext.apply((state) => {
              state = selectPrimarySchool(selectedSchool)(state);
              state = selectPrimaryElement(selectedElement)(state);
              state.lastProcessedTime = Date.now();
              return state;
            });
          }}
        />
        {showLoadGameContainer && (
          <View>
            <Text>
              If you have a save data from the demo version of the game, you may
              load it by pasting it here.
            </Text>
            <LoadGameContainer />
          </View>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-start",
    maxWidth: 600,
  },
  topView: {
    paddingHorizontal: 16,
  },
  topViewText: {
    marginTop: 8,
  },
  buttonGroupContainer: {
    marginVertical: 16,
  },
  buttonGroupText: {
    fontSize: 14,
  },
  startButton: {
    marginVertical: 24,
  },
  mplText: {
    marginTop: 8,
  },
});
