import { formatNumber } from "../../../utils/FormattingUtils";
import { ActionEffect } from "../../actions/Action";
import { PlayerContextState } from "../../PlayerContext";
import { grantResource, Resource } from "../../Resources";
import { SpellElement } from "../ElementsAndSchools";
import { SpellActionBase } from "../Spell";

class CreateBoulder extends SpellActionBase {
  constructor() {
    super();
  }

  getSpellName(): string {
    return "Create Boulder";
  }

  getElement(): SpellElement | undefined {
    return SpellElement.Earth;
  }

  getArea(): string {
    return "Campus";
  }

  getDisplayDescription(): string {
    return "Create a giant rock and boost your Stone reserves.";
  }

  protected getBaseActionEffects(): Record<string, ActionEffect> {
    return {
      stone: {
        value: 1200,
        tags: [Resource.Stone],
      },
    };
  }

  getDisplayEffect(state: PlayerContextState): string {
    const effects = this.getActionEffects(state);
    return `+${formatNumber(effects.stone)}:stone:`;
  }

  getManaCost(): number {
    return 440;
  }

  getSchoolLevelRequirements(): Record<string, number> {
    return {
      Conjuration: 5,
    };
  }

  doSpellAction(state: PlayerContextState): PlayerContextState {
    return grantResource(
      Resource.Stone,
      this.getActionEffects(state).stone,
    )(state);
  }
}

export default new CreateBoulder();
