import React from "react";
import { ImageSourcePropType, StyleSheet, View } from "react-native";
import { Image } from "react-native-elements";

export function SummonSpriteTile(props: { sprite?: ImageSourcePropType }) {
  return (
    <View style={styles.tile}>
      {props.sprite && (
        <Image
          resizeMode="contain"
          source={props.sprite}
          style={styles.image}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  tile: {
    borderColor: "#eee",
    borderWidth: 1,
    height: 48,
    width: 48,
    justifyContent: "center",
    alignItems: "stretch",
  },
  image: {
    height: 46,
    width: 46,
  },
});
