import { generateSpeckForState } from "../../../items/other/crystals/Crystals";
import { PlayerContextState } from "../../../PlayerContext";
import { BattlerStats, Enemy } from "../Enemy";

class Zombie extends Enemy {
  getId(): string {
    return "zombie";
  }

  getName(): string {
    return "Zombie";
  }

  getPicture() {
    return require("./zombie.png");
  }

  getBaseStats(): BattlerStats {
    return {
      maxHP: 330,
      defense: 0,
      attack: 34,
      attackDelay: 5.0,
      dodgeChance: 0,
      critChance: 0,
    };
  }

  getCoinsAwarded(state: PlayerContextState): number {
    return 130;
  }

  getMonstiumAwarded(state: PlayerContextState): number {
    return 18;
  }

  getItemsAwarded(state: PlayerContextState) {
    return [
      { itemId: generateSpeckForState(state).getId(), chance: 0.01, amount: 1 },
      { itemId: "goldNugget", chance: 0.002, amount: 1 },
    ];
  }
}

export default new Zombie();
