import { color } from "@rneui/base/dist/helpers";
import React, { useContext, useMemo } from "react";
import { Platform, StyleSheet, View } from "react-native";
import { Button, Icon, useTheme } from "react-native-elements";
import { Action } from "../../backend/actions/Action";
import { BuildingActionBase } from "../../backend/buildings/Building";
import {
  changeBuildingsTurnedOn,
  getBuildingAmount,
  getBuildingAmountTurnedOn,
} from "../../backend/buildings/Buildings";
import { PlayerContext } from "../../backend/PlayerContext";
import {
  addActionToQuickbar,
  isInQuickbar,
  isQuickbarFull,
} from "../../backend/Quickbar";
import { getResourceCap, Resource } from "../../backend/Resources";
import { Spell, SpellActionBase } from "../../backend/spells/Spell";
import { formatNumber } from "../../utils/FormattingUtils";
import { CostViewer } from "./CostViewer";
import { GameIcon } from "./icons/GameIcon";
import { Markdown } from "./Markdown";
import { DescriptionViewer } from "./Miscellaneous";
import { PlusMinusAllocator } from "./PlusMinusAllocator";
import { SpellView } from "./SpellView";
import { Text } from "./Text";

export function ActionContainer(props: {
  action: Action;
  asSpell?: boolean;
  showAddToQuickbar?: boolean;
  collapsed?: boolean;
  onPressExpand?: () => void;
}) {
  if (props?.asSpell) {
    return SpellView({
      spell: props.action as Spell,
      showAddToQuickbar: props.showAddToQuickbar,
      collapsed: props.collapsed,
      //onPressExpand: props.onPressExpand,
    });
  }

  const playerContext = useContext(PlayerContext);
  const action = props.action;
  const showAddToQuickbar = props.showAddToQuickbar || false;
  const cost = action.getCost(playerContext);
  const isFree =
    Object.keys(cost.resources).length === 0 &&
    Object.keys(cost.items).length === 0;
  const capIsEnough = (Object.keys(cost.resources) as Array<Resource>).every(
    (resource: Resource) => {
      return (
        (cost.resources[resource] ?? 0) <=
        getResourceCap(playerContext, resource)
      );
    },
  );
  const canTurnOff =
    action instanceof BuildingActionBase && action.canTurnOff();
  const buildingAmount =
    action instanceof BuildingActionBase &&
    getBuildingAmount(playerContext, action);
  const buildingAmountOn =
    action instanceof BuildingActionBase &&
    getBuildingAmountTurnedOn(playerContext, action);

  const descriptionContainer = useMemo(() => {
    const descriptionContents = `${action.getDisplayDescription(
      playerContext,
    )} **${action.getDisplayEffect(playerContext)}**`;
    return (
      <View style={styles.descriptionTextContainer}>
        <DescriptionViewer style={{ flex: 1 }}>
          {descriptionContents}
        </DescriptionViewer>
      </View>
    );
  }, [
    action.getDisplayDescription(playerContext),
    action.getDisplayEffect(playerContext),
  ]);

  const costContainer = useMemo(() => {
    if (props.asSpell) {
      const cooldown = (action as SpellActionBase).getCooldown(playerContext);
      const cooldownText = `${formatNumber(cooldown, {
        showDecimals: true,
      })} sec`;
      return (
        <View>
          {cooldown > 0 && (
            <View style={styles.costContainer}>
              <Markdown>Cooldown:</Markdown>
              <Markdown>{cooldownText}</Markdown>
            </View>
          )}
        </View>
      );
    } else {
      return (
        <View style={styles.costContainer}>
          <Markdown>Cost:</Markdown>
          <CostViewer
            resources={action.getCost(playerContext).resources}
            items={action.getCost(playerContext).items}
          />
        </View>
      );
    }
  }, [JSON.stringify(action.getCost(playerContext)), props.asSpell]);

  const turnOffContainer = useMemo(() => {
    if (!canTurnOff) {
      return null;
    }
    const amountActiveText = `${buildingAmountOn}/${buildingAmount}`;
    return (
      <View style={styles.turnOnRow}>
        <Markdown>Amount active:</Markdown>
        <View style={styles.turnOnQuantityContainer}>
          <Markdown>{amountActiveText}</Markdown>
          <View style={styles.plusMinusContainer}>
            <PlusMinusAllocator
              disableMinus={buildingAmountOn === 0}
              disablePlus={buildingAmountOn === buildingAmount}
              onPressMinus={() =>
                playerContext.apply(
                  changeBuildingsTurnedOn(action as BuildingActionBase, -1),
                )
              }
              onPressPlus={() =>
                playerContext.apply(
                  changeBuildingsTurnedOn(action as BuildingActionBase, 1),
                )
              }
            />
          </View>
        </View>
      </View>
    );
  }, [canTurnOff, buildingAmount, buildingAmountOn]);
  const theme = useTheme();

  return useMemo(() => {
    const disabled = !action.isEnabled(playerContext);
    const buttonTitleStyles = [
      {
        color: "white",
      },
      styles.buttonTitle,
      disabled && {
        color: color(theme?.theme?.colors?.disabled).darken(0.3).string(),
      },
      props.asSpell && styles.smallButtonTitle,
    ];
    const button = (
      <Button
        key={action.getName()}
        onPress={() =>
          playerContext.apply(
            action.doAction.bind(action, { isAutomatic: false }),
          )
        }
        title={
          props.asSpell ? (
            <View style={styles.spellButtonTitleContainer}>
              <View style={styles.spellButtonTitleOnlyContainer}>
                <Text style={buttonTitleStyles}>
                  {action.getDisplayName(playerContext)}
                </Text>
              </View>
              <View style={styles.spellButtonCostContainer}>
                <Text style={buttonTitleStyles}>
                  {action.getCost(playerContext)?.resources?.Mana ?? 0}
                </Text>
                <GameIcon icon="mana" style={styles.spellManaIcon} />
              </View>
            </View>
          ) : (
            action.getDisplayName(playerContext)
          )
        }
        titleStyle={props.asSpell && styles.smallButtonTitle}
        containerStyle={[
          styles.buttonContainer,
          props.asSpell && styles.buttonContainerSmall,
        ]}
        disabled={disabled}
      />
    );
    const addToQuickbarButton = (
      <Button
        onPress={() =>
          playerContext.apply((state) => addActionToQuickbar(state, action))
        }
        title="Add to Quickbar"
        titleStyle={styles.smallButtonTitle}
        containerStyle={[styles.quickbarButtonContainer]}
        disabled={
          isInQuickbar(playerContext, {
            type: "action",
            name: action.getName(),
          }) || isQuickbarFull(playerContext)
        }
      />
    );
    return (
      <View style={styles.actionContainer} key={action.getName()}>
        <View style={styles.buttonRowContainer}>
          {props.collapsed ? (
            <Text
              h3
              key={action.getName()}
              onPress={props.onPressExpand}
              style={[
                styles.buttonContainer,
                props.asSpell && styles.buttonContainerSmall,
                disabled && styles.disabledActionText,
                !capIsEnough && styles.capTooLowText,
              ]}
            >
              {action.getDisplayName(playerContext)}
            </Text>
          ) : (
            <View style={styles.buttonContainerView}>
              {button}
              {showAddToQuickbar && addToQuickbarButton}
            </View>
          )}
          {!props.asSpell && (
            <Icon
              name={props.collapsed ? "chevron-right" : "chevron-down"}
              type="entypo"
              containerStyle={styles.expandContainer}
              onPress={props.onPressExpand}
            />
          )}
        </View>
        {!props.collapsed && (
          <View>
            {descriptionContainer}
            {!isFree && costContainer}
            {canTurnOff && turnOffContainer}
          </View>
        )}
      </View>
    );
  }, [
    action.getName(),
    action.getDisplayName(playerContext),
    action.isEnabled(playerContext),
    canTurnOff,
    turnOffContainer,
    !props.collapsed && costContainer,
    !props.collapsed && descriptionContainer,
    capIsEnough,
    showAddToQuickbar,
    JSON.stringify(playerContext.quickbar),
    props.asSpell,
    props.collapsed,
    props.onPressExpand,
    theme,
  ]);
}

const styles = StyleSheet.create({
  actionContainer: { marginVertical: 8 },
  buttonRowContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  quickbarButtonContainer: {
    marginLeft: 8,
  },
  expandContainer: {
    width: 40,
  },
  costContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginTop: 4,
  },
  descriptionTextContainer: {
    marginTop: 8,
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
  },
  buttonTitle: {
    fontSize: 16,
    textAlign: "center",
    paddingVertical: 1,
    ...Platform.select({
      android: {
        fontFamily: "sans-serif-medium",
      },
      default: {
        fontSize: 18,
      },
    }),
  },
  smallButtonTitle: {
    fontSize: 12,
  },
  buttonContainerView: {
    flexDirection: "row",
    flex: 1,
  },
  buttonContainer: {
    flex: 1,
  },
  buttonContainerSmall: {
    marginRight: 0,
  },
  disabledActionText: {
    color: "#aaa",
  },
  capTooLowText: {
    color: "#d99",
  },
  turnOnRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 8,
  },
  turnOnQuantityContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  plusMinusContainer: {
    marginLeft: 8,
  },
  spellButtonTitleContainer: {
    flex: 1,
    flexDirection: "row",
  },
  spellButtonTitleOnlyContainer: {
    flex: 1,
  },
  spellButtonCostContainer: {
    flexDirection: "row",
    width: 40,
    justifyContent: "flex-end",
  },
  spellManaIcon: {
    height: 16,
    width: 16,
    justifyContent: "flex-end",
    alignItems: "center",
    top: 1,
    marginLeft: 2,
  },
});
