import { PlayerContextState } from "../../../PlayerContext";
import { EquipmentSlot } from "../../Equipment";
import { ItemParams } from "../../Item";
import { EquippableItem } from "../EquippableItem";

class ClothShoes extends EquippableItem {
  getId(): string {
    return "clothShoes";
  }

  getPicture() {
    return require("./clothShoes.png");
  }

  getBaseName(): string {
    return "Cloth Shoes";
  }

  getDescription(): string | undefined {
    return "Basic shoes made out of cloth. Makes walking somewhat easier.";
  }

  getDefenseBonus(state: PlayerContextState, params: ItemParams): number {
    return 1;
  }

  getAttackDelayBonusPct(
    state: PlayerContextState,
    params: ItemParams,
  ): number {
    return -10;
  }

  getSlot(): EquipmentSlot {
    return EquipmentSlot.Accessory;
  }

  getSalePrice(): number {
    return 1200;
  }

  getCraftingSchoolLevelRequirements() {
    return {
      Conjuration: 7,
    };
  }

  getCraftingMaterialsBase() {
    return {
      resources: {},
      items: { cloth: 8 },
    };
  }
}

export default new ClothShoes();
