import DefensePotion from "./consumable/DefensePotion";
import HealthPotion from "./consumable/HealthPotion";
import ManaPotion from "./consumable/ManaPotion";
import MedicinalHerb from "./consumable/MedicinalHerb";
import SpeedPotion from "./consumable/SpeedPotion";
import StrengthPotion from "./consumable/StrengthPotion";
import VitalityPotion from "./consumable/VitalityPotion";
import Cloak from "./equipment/accessory/Cloak";
import CloakOfRegeneration from "./equipment/accessory/CloakOfRegeneration";
import ClothShoes from "./equipment/accessory/ClothShoes";
import CrystallizedMana from "./equipment/accessory/CrystallizedMana";
import EnchantedCloak from "./equipment/accessory/EnchantedCloak";
import WoodenSandals from "./equipment/accessory/WoodenSandals";
import ElderWizardRobe from "./equipment/body/ElderWizardRobe";
import IronArmor from "./equipment/body/IronMail";
import WizardRobe from "./equipment/body/WizardRobe";
import airWand from "./equipment/hand/AirWand";
import assassinsDagger from "./equipment/hand/AssassinsDagger";
import branch from "./equipment/hand/Branch";
import Club from "./equipment/hand/Club";
import Dagger from "./equipment/hand/Dagger";
import darknessWand from "./equipment/hand/DarknessWand";
import earthWand from "./equipment/hand/EarthWand";
import fireWand from "./equipment/hand/FireWand";
import glaive from "./equipment/hand/Glaive";
import HandAxe from "./equipment/hand/HandAxe";
import lightningWand from "./equipment/hand/LightningWand";
import natureWand from "./equipment/hand/NatureWand";
import scimitar from "./equipment/hand/Scimitar";
import Sickle from "./equipment/hand/Sickle";
import Spear from "./equipment/hand/Spear";
import spikedClub from "./equipment/hand/SpikedClub";
import Sword from "./equipment/hand/Sword";
import waterWand from "./equipment/hand/WaterWand";
import { registerItem } from "./Items";
import BatWing from "./other/BatWing";
import Cloth from "./other/Cloth";
import AirSpeck from "./other/crystals/AirSpeck";
import DarknessSpeck from "./other/crystals/DarknessSpeck";
import EarthSpeck from "./other/crystals/EarthSpeck";
import FireSpeck from "./other/crystals/FireSpeck";
import LightningSpeck from "./other/crystals/LightningSpeck";
import NatureSpeck from "./other/crystals/NatureSpeck";
import WaterSpeck from "./other/crystals/WaterSpeck";
import GoldNugget from "./other/GoldNugget";
import LightweightChest from "./other/LightweightChest";
import RatTail from "./other/RatTail";
import Wool from "./other/Wool";

export function loadItemsDemo() {
  // TODO: sort this list in an order that makes sense
  registerItem(ManaPotion);
  registerItem(CrystallizedMana);
  registerItem(Club);
  registerItem(LightweightChest);
  registerItem(branch);
  registerItem(Dagger);
  registerItem(HandAxe);
  registerItem(IronArmor);
  registerItem(Spear);
  registerItem(Sword);
  registerItem(WizardRobe);
  registerItem(ElderWizardRobe);
  registerItem(MedicinalHerb);
  registerItem(RatTail);
  registerItem(HealthPotion);
  registerItem(BatWing);
  registerItem(StrengthPotion);
  registerItem(SpeedPotion);
  registerItem(Wool);
  registerItem(Cloth);
  registerItem(Cloak);
  registerItem(EnchantedCloak);
  registerItem(CloakOfRegeneration);
  registerItem(WoodenSandals);
  registerItem(ClothShoes);
  registerItem(GoldNugget);
  registerItem(FireSpeck);
  registerItem(EarthSpeck);
  registerItem(WaterSpeck);
  registerItem(AirSpeck);
  registerItem(NatureSpeck);
  registerItem(LightningSpeck);
  registerItem(DarknessSpeck);
  registerItem(Sickle);
  registerItem(spikedClub);
  registerItem(scimitar);
  registerItem(glaive);
  registerItem(assassinsDagger);
  registerItem(fireWand);
  registerItem(earthWand);
  registerItem(waterWand);
  registerItem(airWand);
  registerItem(natureWand);
  registerItem(lightningWand);
  registerItem(darknessWand);
  registerItem(DefensePotion);
  registerItem(VitalityPotion);
}
