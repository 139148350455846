import { hasFlag, setFlag } from "../../Flags";
import { addContextListener } from "../../PlayerContextListeners";
import { grantNewResearcher } from "../../research/Researchers";
import {
  getResourceAmount,
  getResourceCap,
  grantResource,
  Resource,
} from "../../Resources";
import { buildEventMessage } from "../EventMessageBuilder";
import { triggerEvent } from "../Events";
import { createGameEvent } from "../SimpleGameEvent";

const message = buildEventMessage(
  "Research_Intro",
  "A wild researcher appeared",
)
  .setSimpleDescription(
    `An enthusiastic lady looks at your Mana and notices the large amount you have.

"Wow, this would be great source material," she says.`,
  )
  .addOption(
    "Continue",
    buildEventMessage("Research_Intro_1", "A wild researcher appeared")
      .setSimpleDescription(
        `You look at her, perplexed and somewhat confused. Then she continues explaining.

"Large amounts of Mana are hard to come by, and are almost a requirement for the successful study of Magic."`,
      )
      .addOption(
        "Continue",
        buildEventMessage("Research_Intro_2", "A wild researcher appeared")
          .setSimpleDescription(
            `"Are you trying to study magic here? This is exciting!
            
I'd be willing to work here for a small price... let's say 200 Coins. Of course, you would be the first to learn what I find out, and you would be able to tell me what I should focus on," she says earnestly.

"So what will it be?"`,
          )
          .addOption(
            "Pay 200 Coins",
            buildEventMessage("Research_Intro_3", "A wild researcher appeared")
              .setSimpleDescription(
                `You produce 200 coins out of your pockets, and hand them over to the researcher. She looks at the coins and puts them away in the pockets of her robes.

"Perfect! I will get started right away. I'm sure this is the beginning of a very fruitful relationship for us both!," she exclaims.

**You have unlocked the "Research" feature! You may find it on the left-side menu.**`,
              )
              .build(),
            (state) => {
              state = grantResource(Resource.Coins, -200)(state);
              state = setFlag("research_unlocked")(state);
              state = grantNewResearcher(1)(state);
              return state;
            },
            undefined,
            (state) => getResourceAmount(state, Resource.Coins) >= 200,
          )
          .addOption("Maybe later")
          .build(),
      )
      .build(),
  )
  .build();

const event = createGameEvent(
  "Research_Intro",
  message,
  setFlag("Research_Intro_Triggered"),
);

addContextListener("research_intro_triggered_1", (oldState, newState) => {
  if (
    !hasFlag(newState, "Research_Intro_Triggered") &&
    getResourceCap(newState, Resource.Mana) >= 200
  ) {
    return triggerEvent(event);
  }
});

export default event;
