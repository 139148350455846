import { clone } from "../utils/CoreUtils";
import { PlayerContextState, PlayerContextTransform } from "./PlayerContext";

export type PlayerContextListener = (
  oldState: PlayerContextState,
  newState: PlayerContextState,
) => PlayerContextTransform | null | undefined | void;

const listeners: Record<string, PlayerContextListener> = {};

export function addContextListener(
  id: string,
  listener: PlayerContextListener,
) {
  listeners[id] = listener;
}

export function clearContextListener(id: string) {
  delete listeners[id];
}

export function applyContextListeners(
  oldState: PlayerContextState,
  newState: PlayerContextState,
): PlayerContextTransform[] {
  const transforms = Object.values(listeners).map((listener) =>
    listener(oldState, newState),
  );

  return transforms.filter(
    (transform) => transform != null,
  ) as PlayerContextTransform[];
}

export function applyAllTransforms(
  state: PlayerContextState,
  transform: PlayerContextTransform,
): PlayerContextState {
  let transforms = [transform];
  let oldState = state;
  let newState = oldState;

  do {
    if (transforms.length > 0) {
      oldState = newState;
      // This needs to be cloned because otherwise the states are the same
      newState = clone(oldState);
      newState = transforms.reduce(
        (accumulatedState, currentTransform) =>
          currentTransform(accumulatedState),
        newState,
      );
    }

    transforms = applyContextListeners(oldState, newState);
  } while (transforms.length > 0);

  return newState;
}
