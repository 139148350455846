import React, { useContext, useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { PlayerContext } from "../../backend/PlayerContext";
import {
  getSchoolExp,
  getSchoolLevel,
  getTotalExpRequiredForLevel,
  getUnlockedSchools,
} from "../../backend/spells/ElementsAndSchools";
import { formatNumber } from "../../utils/FormattingUtils";
import { Text } from "./Text";

function SchoolRow(props: {
  name: string;
  level: number;
  isPrimary: boolean;
  currentExp: string;
  toNextLevel: string;
}) {
  return (
    <View style={styles.row}>
      <Text style={props.isPrimary && styles.primaryText}>
        {props.name}: Lv{props.level}
      </Text>

      <Text style={props.isPrimary && styles.primaryText}>
        Exp {props.currentExp}/{props.toNextLevel}
      </Text>
    </View>
  );
}

const SchoolRowMemo = React.memo(SchoolRow);

export function SchoolViewer(props: { isVisible: boolean }) {
  const playerContext = useContext(PlayerContext);
  const keys = getUnlockedSchools(playerContext);
  const rows = useMemo(
    () =>
      keys.map((key) => {
        const level = getSchoolLevel(playerContext, key);
        const currentExp = getSchoolExp(playerContext, key);
        const toNextLevel = getTotalExpRequiredForLevel(
          playerContext,
          level + 1,
          key,
        );
        return (
          <SchoolRowMemo
            name={key}
            level={level}
            isPrimary={playerContext.primarySchool == key}
            currentExp={formatNumber(currentExp)}
            toNextLevel={formatNumber(toNextLevel)}
            key={key}
          />
        );
      }),
    [
      props.isVisible !== false
        ? JSON.stringify(playerContext.schoolExperience)
        : false,
      playerContext.primarySchool,
    ],
  );
  return <View style={styles.container}>{rows}</View>;
}

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "stretch",
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  primaryText: {
    fontWeight: "bold",
  },
});
