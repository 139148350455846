import { getExplorationStatus } from "../exploration/Exploration";
import { ExplorationStatus } from "../exploration/ExplorationStatus";
import { PlayerContextState } from "../PlayerContext";
import { SpellActionBase } from "./Spell";

export abstract class CombatSpellBase extends SpellActionBase {
  getArea(): string {
    return "Exploration";
  }

  isEnabled(state: PlayerContextState): boolean {
    return (
      super.isEnabled(state) &&
      getExplorationStatus(state) == ExplorationStatus.Combat
    );
  }
}
