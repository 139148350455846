import {
  applyTransformations,
  TransformationTag,
} from "../calculation/Calculation";
import { TransformationTags } from "../calculation/TransformationTags";
import { getTotalAmountOfItem } from "../items/Inventory";
import { getItemById } from "../items/Items";
import { PlayerContextState } from "../PlayerContext";
import { getResourceAmount, Resource } from "../Resources";

export type DoActionArgs = { isAutomatic: boolean };

export interface Action {
  getName(): string;
  getArea(): string;
  getCategory(): string;
  getDisplayName(state: PlayerContextState): string;
  getCost(state: PlayerContextState): {
    resources: Partial<Record<Resource, number>>;
    items: Record<string, number>;
  };
  isEnabled(state: PlayerContextState): boolean;
  doAction(args: DoActionArgs, state: PlayerContextState): PlayerContextState;
  isVisible(state: PlayerContextState): boolean;
  getDisplayDescription(state: PlayerContextState): string;
  getDisplayEffect(state: PlayerContextState): string;
}

export type ActionEffect = {
  value: number;
  tags?: TransformationTag[];
};

export abstract class ActionBase implements Action {
  constructor() {}

  abstract getName(): string;
  abstract getArea(): string;
  abstract getCost(state: PlayerContextState): {
    resources: Partial<Record<Resource, number>>;
    items: Record<string, number>;
  };
  abstract doAction(
    args: DoActionArgs,
    state: PlayerContextState,
  ): PlayerContextState;
  abstract isVisible(state: PlayerContextState): boolean;
  abstract getDisplayDescription(state: PlayerContextState): string;
  abstract getDisplayEffect(state: PlayerContextState): string;

  getId(): string {
    return this.getName();
  }

  getDisplayName(state: PlayerContextState): string {
    return this.getName();
  }

  protected getBaseActionEffects(): Record<string, ActionEffect> {
    return {};
  }

  getActionEffects(state: PlayerContextState): Record<string, number> {
    const baseEffects = this.getBaseActionEffects();
    const finalEffects: Record<string, number> = {};
    for (let key in baseEffects) {
      const baseEffect = baseEffects[key];
      finalEffects[key] = applyTransformations(
        [
          ...this.getTags(),
          ...(baseEffect.tags ?? []),
          TransformationTags.ActionEffect,
        ],
        state,
        baseEffect.value,
        {
          action: this,
        },
      );
    }
    return finalEffects;
  }

  getTags(): TransformationTag[] {
    return [
      TransformationTags.Action,
      this.getCategoryTag(),
      this.getAreaTag(),
      this.getId(),
    ];
  }

  getCategory(): string {
    return "Actions";
  }

  getCategoryTag(): string {
    return "Category:" + this.getCategory();
  }

  getAreaTag(): string {
    return "Area:" + this.getArea();
  }

  canAfford(state: PlayerContextState): boolean {
    const cost = this.getCost(state);
    return (
      (Object.keys(cost.resources) as Array<Resource>).every(
        (resource) =>
          getResourceAmount(state, resource) >= (cost.resources[resource] ?? 0),
      ) &&
      (Object.keys(cost.items) as Array<string>).every(
        (itemId) =>
          getTotalAmountOfItem(getItemById(itemId), state) >=
          cost.items[itemId],
      )
    );
  }

  isEnabled(state: PlayerContextState): boolean {
    return this.canAfford(state);
  }
}
