import { formatNumber } from "../../utils/FormattingUtils";
import {
  registerTransformation,
  TransformationType,
} from "../calculation/Calculation";
import { TransformationTags } from "../calculation/TransformationTags";
import { buildEventMessage } from "../events/EventMessageBuilder";
import { triggerEvent } from "../events/Events";
import { createGameEvent } from "../events/SimpleGameEvent";
import { clearFlag, hasFlag, hasGlobalFlag, setGlobalFlag } from "../Flags";
import { getResourceAmount, grantResource, Resource } from "../Resources";
import { OfflineTickListener } from "./OfflineTickListeners";
import { registerTimeTickListener } from "./TimeTick";

const timePieceEvent = createGameEvent(
  "TimePieceEvent",
  buildEventMessage("TimePieceEvent", "Offline Progress")
    .setSimpleDescription(
      `Welcome back to Magic Research!
      
**You obtained {amount}:timepiece: Time Pieces!**`,
    )
    .build(),
  (state, _params) => state,
);

const timePieceEventFirstTime = createGameEvent(
  "TimePieceEventFirstTime",
  buildEventMessage("TimePieceEventFirstTime", "Offline Progress")
    .setSimpleDescription(
      `Welcome back to Magic Research!
      
**You obtained {amount}:timepiece: Time Pieces! Time Pieces allow you to make time pass faster for a duration. You can do so from the left side menu!**`,
    )
    .build(),
  setGlobalFlag("has_seen_offline_progress"),
);

export const timePieceGranter = new OfflineTickListener(
  "TimePieceGranter",
  (totalSecs) => {
    return (state) => {
      const oldTimePieces = getResourceAmount(state, Resource.TimePieces);
      state = grantResource(Resource.TimePieces, totalSecs)(state);
      const newTimePieces = getResourceAmount(state, Resource.TimePieces);
      const amount = newTimePieces - oldTimePieces;
      if (amount > 0) {
        if (hasGlobalFlag(state, "has_seen_offline_progress")) {
          if (amount > 60) {
            state = triggerEvent(timePieceEvent, {
              amount: formatNumber(amount),
            })(state);
          }
        } else {
          state = triggerEvent(timePieceEventFirstTime, {
            amount: formatNumber(amount),
          })(state);
        }
      }
      return state;
    };
  },
);

registerTransformation(
  [[TransformationTags.GameSpeed]],
  "TimePieceGameSpeed",
  TransformationType.Multiplier,
  (state) => (hasFlag(state, "time_piece_speedup_enabled") ? 2 : 1),
);

registerTransformation(
  [[TransformationTags.GameSpeed]],
  "Debug2xGameSpeed",
  TransformationType.Multiplier,
  (state) => (hasFlag(state, "debug_2x_speed") ? 2 : 1),
);

registerTimeTickListener("TimePieceConsumer", (state, timeElapsedSec) => {
  if (hasFlag(state, "time_piece_speedup_enabled")) {
    // times 5 only because time is going at 2x speed if enabled
    state = grantResource(Resource.TimePieces, -timeElapsedSec * 5)(state);
    if (getResourceAmount(state, Resource.TimePieces) <= 0) {
      state = clearFlag("time_piece_speedup_enabled")(state);
    }
  }
  return state;
});
