import { loadActionsDemo } from "./backend/actions/ActionLoaderDemo";
import { loadAutocast } from "./backend/autocast/Autocast";
import { loadBuildingsDemo } from "./backend/buildings/BuildingLoaderDemo";
import Demo from "./backend/Demo";
import { loadGameEventsDemo } from "./backend/events/GameEventLoaderDemo";
import { loadDungeonsDemo } from "./backend/exploration/dungeons/DungeonLoaderDemo";
import { loadEnemiesDemo } from "./backend/exploration/enemies/EnemyLoaderDemo";
import { loadExploration } from "./backend/exploration/Exploration";
import { loadHelpsDemo } from "./backend/help/HelpLoaderDemo";
import { loadHintsDemo } from "./backend/hints/HintLoaderDemo";
import { loadItemsDemo } from "./backend/items/ItemLoaderDemo";
import { loadMisc } from "./backend/MiscLoader";
import { loadResearchers } from "./backend/research/Researchers";
import { loadSecretsDemo } from "./backend/secrets/SecretLoaderDemo";
import { loadSpellsDemo } from "./backend/spells/SpellLoaderDemo";
import { loadCommonTemporaryEffects } from "./backend/timetick/CommonTemporaryEffects";
import { loadTotalTimePlayed } from "./backend/timetick/TotalTimePlayed";
import { loadUpgradesDemo } from "./backend/upgrades/UpgradesLoaderDemo";
import { loadAnalytics } from "./utils/GameAnalytics";

export function loadAllDemo() {
  loadActionsDemo();
  loadAutocast();
  loadBuildingsDemo();
  loadSpellsDemo();
  loadGameEventsDemo();
  loadResearchers();
  loadTotalTimePlayed();
  loadItemsDemo();
  loadDungeonsDemo();
  loadEnemiesDemo();
  loadExploration();
  loadUpgradesDemo();
  loadCommonTemporaryEffects();
  loadHintsDemo();
  loadSecretsDemo();
  loadHelpsDemo();
  loadMisc();
  loadAnalytics();
  Demo.setDemoMode(true);
}
