import React, { useContext } from "react";
import { AttackTarget } from "../../backend/exploration/AttackTarget";
import { getCurrentSummon } from "../../backend/exploration/summons/Summons";
import { PlayerContext } from "../../backend/PlayerContext";
import { HPBar } from "./HPBar";

export function SummonHPBar() {
  const playerContext = useContext(PlayerContext);
  const currentHP = playerContext.exploration?.currentSummon?.currentHP || 0;
  let maxHP = getCurrentSummon(playerContext)?.getMaxHP(playerContext) || 1;
  return (
    <HPBar
      empty={false}
      current={currentHP}
      max={maxHP}
      actionTarget={AttackTarget.Summon}
    />
  );
}
