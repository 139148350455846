import { formatNumber } from "../../../utils/FormattingUtils";
import { ActionEffect } from "../../actions/Action";
import { getBuildingAmount } from "../../buildings/Buildings";
import LumberYard from "../../buildings/campus/LumberYard";
import {
  registerTransformation,
  TransformationType,
} from "../../calculation/Calculation";
import { TransformationTags } from "../../calculation/TransformationTags";
import { PlayerContextState } from "../../PlayerContext";
import {
  grantTemporaryEffect,
  hasTemporaryEffect,
} from "../../timetick/TemporaryEffects";
import { SpellActionBase } from "../Spell";

class EnchantLumberYards extends SpellActionBase {
  constructor() {
    super();
  }

  isVisible(state: PlayerContextState): boolean {
    return super.isVisible(state) && getBuildingAmount(state, LumberYard) > 0;
  }

  getSpellName(): string {
    return "Enchant Lumber Yards";
  }

  getArea(): string {
    return "Campus";
  }

  getDisplayDescription(): string {
    return "Magically alter your Lumber Yards to work faster for a limited time.";
  }

  protected getBaseActionEffects(): Record<string, ActionEffect> {
    return {
      magnitude: {
        value: 2.0,
        tags: [TransformationTags.TemporaryEffectMagnitude],
      },
      duration: {
        value: 30,
        tags: [TransformationTags.TemporaryEffectDuration],
      },
    };
  }

  doSpellAction(state: PlayerContextState): PlayerContextState {
    const effects = this.getActionEffects(state);
    return grantTemporaryEffect(
      this.getId(),
      this.getSpellName(),
      effects.duration,
      {},
    )(state);
  }

  getDisplayEffect(state: PlayerContextState): string {
    const effects = this.getActionEffects(state);
    return `${formatNumber(effects.magnitude, {
      showDecimals: true,
    })}x:wood: produced by Lumber Yards for ${formatNumber(
      effects.duration,
    )} sec.`;
  }

  getManaCost(): number {
    return 80;
  }

  getSchoolLevelRequirements(): Record<string, number> {
    return {
      Enchantment: 2,
    };
  }
}

const enchantLumberYards = new EnchantLumberYards();

registerTransformation(
  [[LumberYard.getName(), TransformationTags.Production]],
  "EnchantLumberYards",
  TransformationType.Multiplier,
  (state) =>
    hasTemporaryEffect(state, "Enchant Lumber Yards")
      ? enchantLumberYards.getActionEffects(state).magnitude
      : 1.0,
);

export default enchantLumberYards;
