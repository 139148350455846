import { addContextListener } from "../PlayerContextListeners";
import {
  hasTemporaryEffect,
  wasTemporaryEffectCleared,
} from "../timetick/TemporaryEffects";
import { modifyPlayerCurrentHP } from "./Exploration";

export function loadDoom() {
  // If the doom timer runs out, the player dies automatically.
  // Everything will be handled by one listener.
  addContextListener("PlayerDoom", (oldState, newState) => {
    if (
      hasTemporaryEffect(oldState, "PlayerDoom") &&
      !hasTemporaryEffect(newState, "PlayerDoom")
    ) {
      // Player's Doom status has disappeared.
      // If it was explicitly cleared, do nothing.
      // If it wasn't explicitly cleared, kill the player.
      if (!wasTemporaryEffectCleared(newState, "PlayerDoom")) {
        // Kill the player!
        return (state) => modifyPlayerCurrentHP(state, -Infinity);
      }
    }
  });
}
