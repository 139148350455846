import { formatNumber } from "../../../utils/FormattingUtils";
import { ActionEffect } from "../../actions/Action";
import { PlayerContextState } from "../../PlayerContext";
import { grantResource, Resource } from "../../Resources";
import { SpellElement } from "../ElementsAndSchools";
import { SpellActionBase } from "../Spell";

class CreateWaterII extends SpellActionBase {
  constructor() {
    super();
  }

  getSpellName(): string {
    return "Create Water II";
  }

  getElement(): SpellElement | undefined {
    return SpellElement.Water;
  }

  getArea(): string {
    return "Campus";
  }

  getDisplayDescription(): string {
    return "Convert Mana into a larger chunk of water.";
  }

  protected getBaseActionEffects(): Record<string, ActionEffect> {
    return {
      water: {
        value: 35,
        tags: [Resource.Water],
      },
    };
  }

  getDisplayEffect(state: PlayerContextState): string {
    const effects = this.getActionEffects(state);
    return `+${formatNumber(effects.water)}:water:`;
  }

  doSpellAction(state: PlayerContextState): PlayerContextState {
    return grantResource(
      Resource.Water,
      this.getActionEffects(state).water,
    )(state);
  }

  getManaCost(): number {
    return 180;
  }

  getSchoolLevelRequirements(): Record<string, number> {
    return {
      Conjuration: 3,
    };
  }
}

export default new CreateWaterII();
