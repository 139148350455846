import { loadDoom } from "./exploration/Doom";
import { loadStunning } from "./exploration/Stunning";
import { OfflineTickListeners } from "./timetick/OfflineTickListeners";
import { timePieceGranter } from "./timetick/TimePieces";

export function loadMisc() {
  OfflineTickListeners.register(timePieceGranter);
  loadStunning();
  loadDoom();
}
