import { formatNumber } from "../../../utils/FormattingUtils";
import { ActionEffect } from "../../actions/Action";
import { buildEventMessage } from "../../events/EventMessageBuilder";
import { triggerEvent } from "../../events/Events";
import { createGameEvent } from "../../events/SimpleGameEvent";
import { hasFlag, setFlag } from "../../Flags";
import { PlayerContextState } from "../../PlayerContext";
import { addContextListener } from "../../PlayerContextListeners";
import { getResourceAmount, grantResource, Resource } from "../../Resources";
import { SpellElement } from "../ElementsAndSchools";
import { SpellActionBase } from "../Spell";

class CreateStone extends SpellActionBase {
  constructor() {
    super();
  }

  isVisible(state: PlayerContextState): boolean {
    return (
      super.isVisible(state) && hasFlag(state, "has_gathered_at_least_10_mana")
    );
  }

  getSpellName(): string {
    return "Create Pebble";
  }

  getElement(): SpellElement | undefined {
    return SpellElement.Earth;
  }

  getArea(): string {
    return "Campus";
  }

  getDisplayDescription(): string {
    return "Convert Mana into a few pebbles.";
  }

  protected getBaseActionEffects(): Record<string, ActionEffect> {
    return {
      stone: {
        value: 5,
        tags: [Resource.Stone],
      },
    };
  }

  getDisplayEffect(state: PlayerContextState): string {
    const effects = this.getActionEffects(state);
    return `+${formatNumber(effects.stone)}:stone:`;
  }

  getManaCost(): number {
    return 10;
  }

  getSchoolLevelRequirements(): Record<string, number> {
    return {
      Conjuration: 1,
    };
  }

  doSpellAction(state: PlayerContextState): PlayerContextState {
    return grantResource(
      Resource.Stone,
      this.getActionEffects(state).stone,
    )(state);
  }
}

const message = buildEventMessage(
  "has_gathered_at_least_10_mana",
  "You are a Wizard",
)
  .setSimpleDescription(
    `Gathering Mana is useful, but why would you do it if you had nothing to use it for?

You slowly recall how to cast spells and remember a basic Conjuration spell, *Create Pebble*. You think of trying it soon!

**You have unlocked the ability to cast spells! You can cast spells by pressing the wand icon at the top right corner of the screen.**`,
  )
  .build();

const event = createGameEvent(
  "has_gathered_at_least_10_mana",
  message,
  setFlag("has_gathered_at_least_10_mana"),
);

addContextListener("create_stone_unlocked", (oldState, newState) => {
  if (
    !hasFlag(newState, "has_gathered_at_least_10_mana") &&
    getResourceAmount(newState, Resource.Mana) >= 10
  ) {
    return triggerEvent(event);
  }
});

export default new CreateStone();
