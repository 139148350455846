import { PlayerContextState } from "./PlayerContext";

export function getUICommands(state: PlayerContextState) {
  return state?.uiCommands || [];
}

export function clearUICommands(state: PlayerContextState) {
  state.uiCommands = [];
  return state;
}

export function addUICommand(
  state: PlayerContextState,
  command: string,
): PlayerContextState {
  if (!state.uiCommands) {
    state.uiCommands = [];
  }
  state.uiCommands.push(command);
  return state;
}
