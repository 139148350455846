import React, { useEffect, useMemo, useRef } from "react";
import {
  Animated,
  ScrollView,
  StyleSheet,
  TouchableWithoutFeedback,
} from "react-native";
import SpellsContainer from "./SpellsContainer";

type Props = {
  visible: boolean;
  onBackgroundPress: () => void;
};

export function SpellMenuContents(props: { visible: boolean }) {
  return (
    <ScrollView contentContainerStyle={styles.magicContainer}>
      <SpellsContainer isVisible={props.visible} />
    </ScrollView>
  );
}

export function SpellMenu(props: Props) {
  const positionAnim = useRef(
    new Animated.Value(props.visible ? 0 : 300),
  ).current;

  const opacityAnim = useRef(
    new Animated.Value(props.visible ? 0.5 : 0),
  ).current;

  useEffect(() => {
    if (props.visible) {
      Animated.timing(positionAnim, {
        toValue: 0,
        duration: 150,
        useNativeDriver: true,
      }).start();
      Animated.timing(opacityAnim, {
        toValue: 0.5,
        duration: 150,
        useNativeDriver: true,
      }).start();
    } else {
      Animated.timing(positionAnim, {
        toValue: 300,
        duration: 150,
        useNativeDriver: true,
      }).start();
      Animated.timing(opacityAnim, {
        toValue: 0,
        duration: 150,
        useNativeDriver: true,
      }).start();
    }
  }, [props.visible]);

  return useMemo(() => {
    return (
      <>
        <TouchableWithoutFeedback onPress={props.onBackgroundPress}>
          <Animated.View
            style={[styles.backdrop, { opacity: opacityAnim }]}
            pointerEvents={props.visible ? "auto" : "none"}
          />
        </TouchableWithoutFeedback>
        <Animated.View
          style={[
            styles.container,
            { transform: [{ translateX: positionAnim }] },
          ]}
        >
          <SpellMenuContents visible={props.visible} />
        </Animated.View>
      </>
    );
  }, [opacityAnim, positionAnim, props.visible, props.onBackgroundPress]);
}

const styles = StyleSheet.create({
  backdrop: {
    position: "absolute",
    height: "100%",
    width: "100%",
    backgroundColor: "#000",
    zIndex: 80,
  },
  backdropTouchable: {
    position: "absolute",
    height: "100%",
    width: "100%",
    zIndex: 90,
  },
  container: {
    justifyContent: "flex-start",
    alignItems: "stretch",
    position: "absolute",
    zIndex: 100,
    height: "100%",
    width: 300,
    right: 0,
    top: 0,
    backgroundColor: "#fff",
  },
  magicContainer: {
    padding: 8,
  },
});
