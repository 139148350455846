import { formatNumber } from "../../utils/FormattingUtils";
import { standardPlayerAttackEffect } from "../exploration/Exploration";
import { hasGlobalFlag } from "../Flags";
import { PlayerContextState } from "../PlayerContext";
import { grantTemporaryEffect } from "../timetick/TemporaryEffects";
import { SpellActionBase } from "./Spell";

export function getStandardRangeEffectText(
  average: number,
  variance: number,
): string {
  const min = average - variance,
    max = average + variance;
  return `${formatNumber(min)}~${formatNumber(max)}`;
}

export function getStandardAttackEffectText(
  average: number,
  variance: number,
): string {
  return `${getStandardRangeEffectText(average, variance)}:attack:`;
}

export function getStandardAirAttackEffect(
  state: PlayerContextState,
  spell: SpellActionBase,
): PlayerContextState {
  const effects = spell.getActionEffects(state);
  return standardPlayerAttackEffect(
    state,
    effects.average + (Math.random() - 0.5) * effects.variance * 2,
    hasGlobalFlag(state, "advanced_evocation_unlocked") ? 4.0 : 1.0,
    undefined,
    spell.getTags(),
  );
}

export function getStandardWaterAttackEffect(
  state: PlayerContextState,
  spell: SpellActionBase,
): PlayerContextState {
  const effects = spell.getActionEffects(state);
  state = standardPlayerAttackEffect(
    state,
    effects.average + (Math.random() - 0.5) * effects.variance * 2,
    undefined,
    undefined,
    spell.getTags(),
  );
  if (hasGlobalFlag(state, "advanced_evocation_unlocked")) {
    state = grantTemporaryEffect("Slow", "Slow", effects.slowDuration, {
      isEnemyTemporaryEffect: true,
      combatOnly: true,
      isBeneficial: false,
    })(state);
  }
  return state;
}

export function getStandardFireAttackEffect(
  state: PlayerContextState,
  spell: SpellActionBase,
): PlayerContextState {
  const effects = spell.getActionEffects(state);
  state = standardPlayerAttackEffect(
    state,
    effects.average + (Math.random() - 0.5) * effects.variance * 2,
    undefined,
    undefined,
    spell.getTags(),
  );
  if (hasGlobalFlag(state, "advanced_evocation_unlocked")) {
    state = grantTemporaryEffect(
      spell.getSpellName() + "_dot",
      spell.getSpellName(),
      effects.dotDuration,
      {
        mode: "add",
        isBeneficial: false,
        combatOnly: true,
        isEnemyTemporaryEffect: true,
        params: { commonDoTAmount: effects.dotStrength },
      },
    )(state);
  }
  return state;
}

export function getStandardSpellAttackEffect(
  state: PlayerContextState,
  spell: SpellActionBase,
): PlayerContextState {
  const effects = spell.getActionEffects(state);
  state = standardPlayerAttackEffect(
    state,
    effects.average + (Math.random() - 0.5) * effects.variance * 2,
    undefined,
    undefined,
    spell.getTags(),
  );
  return state;
}
