export enum TransformationTags {
  Production = "Production",
  Consumption = "Consumption",
  Cost = "Cost",
  ManualGathering = "ManualGathering",
  Loot = "Loot",
  LootChance = "LootChance",
  Cap = "Cap",
  PerBuildingCap = "PerBuildingCap",
  Researcher = "Researcher",
  Apprentice = "Apprentice",
  InventorySlot = "InventorySlot",
  Creature = "Creature",
  Threshold = "Threshold",
  CostScale = "CostScale",
  Research = "Research",
  GameSpeed = "GameSpeed",
  ExplorationBossRequirement = "ExplorationBossRequirement",
  ExplorationDelay = "ExplorationDelay",
  FromExploration = "FromExploration",
  FromCreatures = "FromCreatures",
  StunResistance = "StunResistance",
  AutocastDelay = "AutocastDelay",
  RandomEventWeight = "RandomEventWeight",
  ExpRequirementScale = "ExpRequirementScale",
  TimePiecePerResearch = "TimePiecePerResearch",
  AttackDamageReceived = "AttackDamageReceived",
  PostStunProtectionDuration = "PostStunProtectionDuration",
  CanCastSpell = "CanCastSpell",

  Action = "Action",
  Spell = "Spell",
  ActionEffect = "ActionEffect",
  SpellCooldown = "SpellCooldown",
  ActionMagnitude = "ActionMagnitude",
  ActionVariance = "ActionVariance",
  TemporaryEffectMagnitude = "TemporaryEffectMagnitude",
  TemporaryEffectDuration = "TemporaryEffectDuration",
  ItemEffect = "ItemEffect",
  ItemQuality = "ItemQuality",
  ItemCost = "ItemCost",
}
