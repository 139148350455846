import { formatNumber } from "../../../utils/FormattingUtils";
import { ActionEffect } from "../../actions/Action";
import { TransformationTags } from "../../calculation/TransformationTags";
import { PlayerContextState } from "../../PlayerContext";
import { grantResource, Resource } from "../../Resources";
import { ItemParams } from "../Item";
import { ConsumableItem } from "./ConsumableItem";

class ManaPotion extends ConsumableItem {
  getId(): string {
    return "manaPotion";
  }

  getPicture() {
    return require("./manaPotion.png");
  }

  getBaseName(): string {
    return "Mana Potion";
  }

  getDescription(): string | undefined {
    return "A small blue potion. Restores :mana:.";
  }

  doConsumableItemEffect(
    state: PlayerContextState,
    params: ItemParams,
  ): PlayerContextState {
    const healAmt = this.getItemEffects(state, params).magnitude;
    return grantResource(Resource.Mana, healAmt)(state);
  }

  protected getBaseItemEffects(): Record<string, ActionEffect> {
    return {
      magnitude: {
        value: 200,
        tags: [TransformationTags.ActionMagnitude],
      },
    };
  }

  getEffect(state: PlayerContextState, params: ItemParams): string | undefined {
    const effects = this.getItemEffects(state, params);
    return `+${formatNumber(effects.magnitude)}:mana:`;
  }

  getSalePrice(): number {
    return 50;
  }

  getCraftingSchoolLevelRequirements() {
    return { Alchemy: 1 };
  }

  getCraftingMaterialsBase() {
    return {
      resources: {
        Mana: 300,
        Water: 50,
      },
      items: {},
    };
  }
}

export default new ManaPotion();
