import moment from "moment";
import { applyTransformations } from "../calculation/Calculation";
import { TransformationTags } from "../calculation/TransformationTags";
import { PlayerContextState, PlayerContextTransform } from "../PlayerContext";
import { getSchoolLevel, School } from "../spells/ElementsAndSchools";
import { registerTimeTickListener } from "../timetick/TimeTick";
import { getSecondsPlayed } from "../timetick/TotalTimePlayed";
import { DungeonFloors } from "./dungeons/Dungeons";

function onTick(
  state: PlayerContextState,
  elapsedTimeSec: number,
): PlayerContextState {
  const breedingLevel = getSchoolLevel(state, School.Breeding);
  for (let dungeonFloorId in state.creatures.creatureAllocation) {
    const dungeonFloor = DungeonFloors.getById(dungeonFloorId);
    const targetLevel = dungeonFloor.getBreedingLevel();

    if (!isDungeonFloorValidForCreatures(state, dungeonFloorId)) {
      continue;
    }

    const allocatedCreatures =
      state.creatures.creatureAllocation[dungeonFloorId] || 0;
    if (allocatedCreatures > 0) {
      const lastTime = getLastRewardTimeForDungeonFloor(state, dungeonFloorId);
      // Assignment needed so it starts working
      state.creatures.lastRewardTime[dungeonFloorId] = lastTime;
      // Give reward once per minute per creature allocated + 10% more multiplicative rewards
      // for every 1 level above the target breeding level.
      // Example: Exploring School Basement B1 (target level 2) with a breeding level of 17,
      // with 2 creatures at the same time, results in a reward every 7.18 seconds.
      let threshold =
        60.0 /
        (allocatedCreatures * Math.pow(1.1, breedingLevel - targetLevel));
      threshold = applyTransformations(
        [TransformationTags.Creature, TransformationTags.Threshold],
        state,
        threshold,
      );
      if (getSecondsPlayed(state) - lastTime > threshold) {
        // Get a reward!
        state.creatures.lastRewardTime[dungeonFloorId] =
          getSecondsPlayed(state);
        const outcome = dungeonFloor.doExplore(state);
        if (outcome != null) {
          state = outcome.breeding(state);
        }
      }
    }
  }

  return state;
}

export function getCreatureMessageLog(state: PlayerContextState) {
  return state?.creatureMessageLog || [];
}

export function pushToCreatureMessageLog(
  state: PlayerContextState,
  log: string,
): PlayerContextState {
  if (!state.creatureMessageLog) {
    state.creatureMessageLog = [];
  }
  state.creatureMessageLog.unshift({
    id: Math.random().toString(),
    timestamp: getSecondsPlayed(state),
    message: `[${moment(Date.now()).format("h:mm:ss A")}] ${log}`,
  });
  state.creatureMessageLog = state.creatureMessageLog.slice(0, 40);
  return state;
}

export function isDungeonFloorValidForCreatures(
  state: PlayerContextState,
  dungeonFloorId: string,
): boolean {
  const breedingLevel = getSchoolLevel(state, School.Breeding);
  const dungeonFloor = DungeonFloors.getById(dungeonFloorId);
  const targetLevel = dungeonFloor.getBreedingLevel();

  if (breedingLevel < targetLevel) {
    return false;
  }

  if (!dungeonFloor.isUnlocked(state)) {
    return false;
  }

  if (
    !dungeonFloor.canFightBoss(state) &&
    dungeonFloor.getBoss(state) !== undefined
  ) {
    return false;
  }

  return true;
}

function getLastRewardTimeForDungeonFloor(
  state: PlayerContextState,
  dungeonFloorId: string,
): number {
  return (
    state?.creatures?.lastRewardTime?.[dungeonFloorId] ||
    getSecondsPlayed(state)
  );
}

export function getFreeCreatures(state: PlayerContextState): number {
  const creatureAllocation = state.creatures.creatureAllocation;
  let creaturesAllocated = 0;
  for (let dungeonFloor in creatureAllocation) {
    creaturesAllocated += creatureAllocation[dungeonFloor] || 0;
  }
  return state.creatures.totalCreatures - creaturesAllocated;
}

function allocateCreaturesToDungeonFloorImpl(
  dungeonFloorId: string,
  amount: number,
  state: PlayerContextState,
) {
  const currentAllocation =
    state.creatures.creatureAllocation?.[dungeonFloorId] || 0;
  const actualAmountToAllocate =
    amount > 0
      ? Math.min(getFreeCreatures(state), amount)
      : Math.max(-currentAllocation, amount);
  state.creatures.creatureAllocation[dungeonFloorId] =
    currentAllocation + actualAmountToAllocate;
  return state;
}

export function allocateCreaturesToDungeonFloor(
  this: any,
  dungeonFloorId: string,
  amount: number,
): PlayerContextTransform {
  // Do it this way to avoid creating extra functions and avoid rerenders
  return allocateCreaturesToDungeonFloorImpl.bind(this, dungeonFloorId, amount);
}

function grantNewCreatureImpl(amount: number, state: PlayerContextState) {
  state.creatures.totalCreatures = Math.max(
    0,
    Math.min(
      calculateCreatureCap(state),
      state.creatures.totalCreatures + amount,
    ),
  );
  return state;
}

export function grantNewCreature(
  this: any,
  amount: number,
): PlayerContextTransform {
  // Do it this way to avoid creating extra functions and avoid rerenders
  return grantNewCreatureImpl.bind(this, amount);
}

export function calculateCreatureCap(state: PlayerContextState): number {
  return applyTransformations(
    [TransformationTags.Creature, TransformationTags.Cap],
    state,
    0,
  );
}

export function loadCreatures() {
  registerTimeTickListener("Creatures", onTick);
}
