import { formatNumber } from "../../../utils/FormattingUtils";
import { ActionEffect } from "../../actions/Action";
import {
  registerTransformation,
  TransformationType,
} from "../../calculation/Calculation";
import { TransformationTags } from "../../calculation/TransformationTags";
import { buildEventMessage } from "../../events/EventMessageBuilder";
import { triggerEvent } from "../../events/Events";
import { createGameEvent } from "../../events/SimpleGameEvent";
import { hasFlag, setFlag } from "../../Flags";
import { PlayerContextState } from "../../PlayerContext";
import { addContextListener } from "../../PlayerContextListeners";
import { recalculateCaps } from "../../Resources";
import { getTotalElementAndSchoolExp } from "../../spells/ElementsAndSchools";
import { BuildingActionBase } from "../Building";
import { buildingAmountsChanged, getBuildingAmount } from "../Buildings";

class ResearcherCabin extends BuildingActionBase {
  constructor() {
    super();
  }

  isVisible(state: PlayerContextState): boolean {
    return hasFlag(state, "researcher_cabin_unlocked");
  }

  getBuildingName(): string {
    return "Researcher Cabin";
  }

  getArea(): string {
    return "Campus";
  }

  getDisplayDescription(): string {
    return "Housing for researchers to live in campus.";
  }

  protected getBaseActionEffects(): Record<string, ActionEffect> {
    return {
      maxResearchers: {
        value: 1,
        tags: [
          TransformationTags.Researcher,
          TransformationTags.PerBuildingCap,
        ],
      },
    };
  }

  getDisplayEffect(state: PlayerContextState): string {
    const effects = this.getActionEffects(state);
    return `+${formatNumber(effects.maxResearchers)} Max Researchers`;
  }

  getBaseResourceCost(): Record<string, number> {
    return {
      Coins: 150,
      Wood: 320,
      Stone: 110,
    };
  }

  getBaseResourceScale(): Record<string, number> {
    return {
      Coins: 1.3,
      Wood: 1.3,
      Stone: 1.3,
    };
  }
}

const researcherCabin = new ResearcherCabin();

registerTransformation(
  [[TransformationTags.Researcher, TransformationTags.Cap]],
  "ResearcherCabin",
  TransformationType.Addition,
  (state) =>
    getBuildingAmount(state, researcherCabin) *
    researcherCabin.getActionEffects(state).maxResearchers,
);

addContextListener(
  "researcher_cabin_cap_recalculation",
  (oldState, newState) => {
    if (buildingAmountsChanged(oldState, newState, researcherCabin)) {
      return (state) => {
        return recalculateCaps(state);
      };
    }
  },
);

const message = buildEventMessage(
  "researcher_cabin_unlocked",
  "Housing for Researchers",
)
  .setSimpleDescription(
    `Your researcher comes up to you. "Studying here has been great so far!", she says. "But it certainly is somewhat lonely... it would be great to have company. We could get double the work done; imagine how fast we could discover new things!", she exclaims.
    
"But there's a problem. There's nowhere for them to stay in. I have an idea for a cabin, but... we'll need to build it, first. Oh well.. back to work," she mentions, as she turns away and continues focusing on her research.

You ponder her words for some time. Regardless of her feelings, you recognize that double the researchers could greatly speed things up. You think of possibly building a Researcher Cabin as she suggested, and hiring an extra researcher.

**You can now build Researcher Cabins!**`,
  )
  .build();

const event = createGameEvent(
  "researcher_cabin_unlocked",
  message,
  setFlag("researcher_cabin_unlocked"),
);

addContextListener("researcher_cabin_unlocked", (oldState, newState) => {
  if (
    !hasFlag(newState, "researcher_cabin_unlocked") &&
    hasFlag(newState, "research_unlocked") &&
    getTotalElementAndSchoolExp(newState) >= 1300
  ) {
    return triggerEvent(event);
  }
});

export default researcherCabin as ResearcherCabin;
