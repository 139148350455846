import Constants from "expo-constants";
import React, { useContext, useEffect, useMemo, useRef } from "react";
import {
  Animated,
  Platform,
  ScrollView,
  StyleSheet,
  TouchableNativeFeedback,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import { Button, Divider, ListItem } from "react-native-elements";
import { getEndingData } from "../../backend/endings/Endings";
import {
  clearFlag,
  hasFlag,
  hasGlobalFlag,
  setFlag,
} from "../../backend/Flags";
import { PlayerContext } from "../../backend/PlayerContext";
import { getResourceAmount, Resource } from "../../backend/Resources";
import { getTotalElementAndSchoolExp } from "../../backend/spells/ElementsAndSchools";
import { getTemporaryEffects } from "../../backend/timetick/TemporaryEffects";
import CategoryContainer from "../utility/CategoryContainer";
import { CombatStatsViewer } from "../utility/CombatStatsViewer";
import { SchoolViewer } from "../utility/ElementViewer";
import { ResourceViewer } from "../utility/ResourceViewer";
import { TemporaryEffectsViewer } from "../utility/TemporaryEffectsViewer";
import { Text } from "../utility/Text";

type Props = {
  onNavigationPress: (section: string) => void;
  onBackgroundPress: () => void;
  visible: boolean;
};

const MenuComponent = (
  props: {
    title: string;
    onPress: () => void;
    topDivider?: boolean;
    children: React.ReactNode;
  } & any,
) => {
  const { title, onPress, topDivider, ...others } = props;
  return (
    <View>
      {topDivider && <Divider />}
      <ListItem
        containerStyle={styles.navigationRowTitle}
        onPress={onPress}
        Component={Platform.select<any>({
          android: TouchableNativeFeedback,
          default: TouchableOpacity,
        })}
        {...others}
      >
        <ListItem.Content>
          <ListItem.Title>{title}</ListItem.Title>
        </ListItem.Content>
        <ListItem.Chevron />
      </ListItem>
      <Divider />
    </View>
  );
};

export function MenuContents(props: {
  visible: boolean;
  onNavigationPress: (section: string) => void;
}) {
  const playerContext = useContext(PlayerContext);
  const playerContextRef = useRef(playerContext);

  const showResearch = hasFlag(playerContext, "research_unlocked");
  const showAutocast = hasFlag(playerContext, "autocast_unlocked");
  const showInventory = hasFlag(playerContext, "inventory_unlocked");
  const showExploration = hasFlag(playerContext, "exploration_unlocked");
  const showRetirement = getTotalElementAndSchoolExp(playerContext) >= 10000;
  const showUpgrades = hasFlag(playerContext, "upgrades_unlocked");
  const showCreatures = hasFlag(playerContext, "creatures_unlocked");
  const showStrengthening =
    hasGlobalFlag(playerContext, "strengthening_unlocked") && showExploration;
  const showEnding = getEndingData(playerContext) != null;

  const navigation = useMemo(
    () => (
      <View style={styles.navigationBlock}>
        <MenuComponent
          title="Campus"
          topDivider
          onPress={() => props.onNavigationPress("Campus")}
        />
        {showResearch && (
          <MenuComponent
            title="Research"
            onPress={() => props.onNavigationPress("Research")}
          />
        )}
        {showAutocast && (
          <MenuComponent
            title="Apprentices"
            onPress={() => props.onNavigationPress("Apprentices")}
          />
        )}
        {showInventory && (
          <MenuComponent
            title="Inventory"
            onPress={() => props.onNavigationPress("Inventory")}
          />
        )}
        {showExploration && (
          <MenuComponent
            title="Exploration"
            onPress={() => props.onNavigationPress("Exploration")}
          />
        )}
        {showUpgrades && (
          <MenuComponent
            title="Upgrades"
            onPress={() => props.onNavigationPress("Upgrades")}
          />
        )}
        {showCreatures && (
          <MenuComponent
            title="Creatures"
            onPress={() => props.onNavigationPress("Creatures")}
          />
        )}
        {showStrengthening && (
          <MenuComponent
            title="Strengthening"
            onPress={() => props.onNavigationPress("Strengthening")}
          />
        )}
        {showEnding && (
          <MenuComponent
            title="Ending"
            onPress={() => props.onNavigationPress("Ending")}
          />
        )}
        {showRetirement && (
          <MenuComponent
            title="Retirement"
            onPress={() => props.onNavigationPress("Retirement")}
          />
        )}
        <MenuComponent
          title="Stats"
          onPress={() => props.onNavigationPress("Stats")}
        />
        <MenuComponent
          title="Help"
          onPress={() => props.onNavigationPress("Help")}
        />
        <MenuComponent
          title="Options"
          onPress={() => props.onNavigationPress("Options")}
        />
        <MenuComponent
          title="About"
          onPress={() => props.onNavigationPress("About")}
        />
        {Constants?.expoConfig?.extra?.showDebug && (
          <MenuComponent
            title="Debug"
            onPress={() => props.onNavigationPress("Debug")}
          />
        )}
      </View>
    ),
    [
      showResearch,
      showAutocast,
      showInventory,
      showExploration,
      showUpgrades,
      showCreatures,
      showEnding,
      showRetirement,
      props.onNavigationPress,
    ],
  );

  const hasTemporaryEffects = getTemporaryEffects(playerContext).length > 0;

  const isSpeedingUp = hasFlag(playerContext, "time_piece_speedup_enabled");
  const hasTimePieces =
    getResourceAmount(playerContext, Resource.TimePieces) > 0;

  return useMemo(
    () => (
      <ScrollView
        style={styles.scrollContainer}
        contentContainerStyle={styles.scrollContentContainer}
      >
        <View style={styles.titleContainer}>
          <Text h1>Magic Research</Text>
        </View>
        {navigation}
        {hasTimePieces && (
          <>
            {!isSpeedingUp && (
              <Button
                title="Speed up time (10 Time Pieces / sec)"
                onPress={() =>
                  playerContextRef.current.apply(
                    setFlag("time_piece_speedup_enabled"),
                  )
                }
                titleStyle={styles.timePieceButtonTitle}
                containerStyle={[styles.timePieceButtonContainer]}
              />
            )}
            {isSpeedingUp && (
              <Button
                title="Return time to normal"
                onPress={() =>
                  playerContextRef.current.apply(
                    clearFlag("time_piece_speedup_enabled"),
                  )
                }
                titleStyle={styles.timePieceButtonTitle}
                containerStyle={[styles.timePieceButtonContainer]}
              />
            )}
          </>
        )}
        <CategoryContainer title="Resources">
          <View style={styles.statViewer}>
            <ResourceViewer isVisible={props.visible} />
          </View>
        </CategoryContainer>
        <CategoryContainer title="Schools">
          <View style={styles.statViewer}>
            <SchoolViewer isVisible={props.visible} />
          </View>
        </CategoryContainer>
        {showExploration && (
          <CategoryContainer title="Combat">
            <View style={styles.statViewer}>
              <CombatStatsViewer isVisible={props.visible} />
            </View>
          </CategoryContainer>
        )}
        {hasTemporaryEffects && (
          <CategoryContainer title="Buffs">
            <TemporaryEffectsViewer isVisible={props.visible} />
          </CategoryContainer>
        )}
      </ScrollView>
    ),
    [
      navigation,
      showExploration,
      hasTemporaryEffects,
      hasTimePieces,
      isSpeedingUp,
      props.visible,
    ],
  );
}

export function Menu(props: Props) {
  const positionAnim = useRef(
    new Animated.Value(props.visible ? 0 : -300),
  ).current;

  const opacityAnim = useRef(
    new Animated.Value(props.visible ? 0.5 : 0),
  ).current;

  useEffect(() => {
    if (props.visible) {
      Animated.timing(positionAnim, {
        toValue: 0,
        duration: 150,
        useNativeDriver: true,
      }).start();
      Animated.timing(opacityAnim, {
        toValue: 0.5,
        duration: 150,
        useNativeDriver: true,
      }).start();
    } else {
      Animated.timing(positionAnim, {
        toValue: -300,
        duration: 150,
        useNativeDriver: true,
      }).start();
      Animated.timing(opacityAnim, {
        toValue: 0,
        duration: 150,
        useNativeDriver: true,
      }).start();
    }
  }, [props.visible]);

  return useMemo(
    () => (
      <>
        <TouchableWithoutFeedback onPress={props.onBackgroundPress}>
          <Animated.View
            style={[styles.backdrop, { opacity: opacityAnim }]}
            pointerEvents={props.visible ? "auto" : "none"}
          />
        </TouchableWithoutFeedback>
        <Animated.View
          style={[
            styles.container,
            { transform: [{ translateX: positionAnim }] },
          ]}
        >
          <MenuContents {...props} />
        </Animated.View>
      </>
    ),
    [positionAnim, opacityAnim, props.onBackgroundPress, props.visible],
  );
}

const styles = StyleSheet.create({
  backdrop: {
    position: "absolute",
    height: "100%",
    width: "100%",
    backgroundColor: "#000",
  },
  container: {
    justifyContent: "flex-start",
    alignItems: "stretch",
    position: "absolute",
    height: "100%",
    width: 300,
    left: 0,
    top: 0,
    backgroundColor: "#fff",
  },
  scrollContainer: {
    padding: 8,
  },
  scrollContentContainer: {
    paddingBottom: 16,
  },
  titleContainer: { marginBottom: 8 },
  navigationBlock: { marginTop: 16 },
  navigationRowTitle: { paddingVertical: 8 },
  timePieceButtonContainer: { marginTop: 16 },
  timePieceButtonTitle: { fontSize: 12 },
  statViewer: {
    marginTop: 4,
  },
});
