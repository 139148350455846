import React from "react";
import { StyleSheet, View } from "react-native";
import Demo from "../backend/Demo";
import { getCurrentVersionTimestamp } from "../utils/Versioning";
import { Markdown } from "./utility/Markdown";
import { Text } from "./utility/Text";

export default function About() {
  const versionText = Demo.isDemo() ? "**Demo Version**" : "**Full Version**";
  const versionNumber = getCurrentVersionTimestamp();
  const versionNumberText =
    versionNumber != null ? "Version " + versionNumber.toString() : "";
  const googlePlayText = Demo.isDemo()
    ? "Get the full version for Android in [the Google Play Store](https://play.google.com/store/apps/details?id=com.mcolotto.magicresearchfull)!"
    : "";
  const text = `## Magic Research

  
${versionText}

${versionNumberText}


A game by **@Maticolotto**


**Credits**

Assets from:
  * Pipoya RPG Monster Pack, by Pipoya (https://pipoya.itch.io/free-rpg-monster-pack)
  * Aekashics Librarium, by Aekashics (http://www.akashics.moe)
  * 1000+ Fantasy RPG Icons, by finalbossblues (https://finalbossblues.itch.io/icons)

Special thanks to **@jpascua93** for pre-launch feedback and development

[Privacy Policy](https://mcolotto.github.io/magic-research-demo/privacy-policy)

${googlePlayText}`;

  return (
    <View style={styles.container}>
      <Text h1>About</Text>
      <View style={styles.explanation}>
        <Markdown style={styles.explanationText}>{text}</Markdown>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "stretch",
    justifyContent: "flex-start",
    width: "100%",
  },
  explanation: {
    marginTop: 16,
    flex: 1,
    alignItems: "stretch",
    width: "100%",
  },
  explanationText: {
    width: "100%",
  },
});
