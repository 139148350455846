import { formatNumber } from "../../../utils/FormattingUtils";
import { ActionEffect } from "../../actions/Action";
import {
  registerTransformation,
  TransformationType,
} from "../../calculation/Calculation";
import { TransformationTags } from "../../calculation/TransformationTags";
import { AttackTarget } from "../../exploration/AttackTarget";
import { CombatStat } from "../../exploration/CombatStats";
import { hasFlag } from "../../Flags";
import { PlayerContextState } from "../../PlayerContext";
import {
  grantTemporaryEffect,
  hasTemporaryEffect,
} from "../../timetick/TemporaryEffects";
import { SpellActionBase } from "../Spell";

class Dazzle extends SpellActionBase {
  constructor() {
    super();
  }

  isVisible(state: PlayerContextState): boolean {
    return super.isVisible(state) && hasFlag(state, "exploration_unlocked");
  }

  getSpellName(): string {
    return "Dazzle";
  }

  getBaseCooldown(): number {
    return 8.0;
  }

  getArea(): string {
    return "Exploration";
  }

  getDisplayDescription(): string {
    return "Temporarily confuse an enemy's senses, reducing their :accuracy: for a short time.";
  }

  protected getBaseActionEffects(): Record<string, ActionEffect> {
    return {
      magnitude: {
        value: 1.5385,
        tags: [TransformationTags.TemporaryEffectMagnitude],
      },
      duration: {
        value: 5,
        tags: [TransformationTags.TemporaryEffectDuration],
      },
    };
  }

  doSpellAction(state: PlayerContextState): PlayerContextState {
    const effects = this.getActionEffects(state);
    return grantTemporaryEffect(
      this.getId(),
      this.getSpellName(),
      effects.duration,
      {
        isEnemyTemporaryEffect: true,
        combatOnly: true,
        isBeneficial: false,
      },
    )(state);
  }

  getDisplayEffect(state: PlayerContextState): string {
    const effects = this.getActionEffects(state);
    return `-${formatNumber(
      100 - (1.0 / effects.magnitude) * 100,
    )}%:accuracy: for ${formatNumber(effects.duration)} sec`;
  }

  getManaCost(): number {
    return 1100;
  }

  getSchoolLevelRequirements(): Record<string, number> {
    return {
      Illusion: 11,
    };
  }
}

const dazzle = new Dazzle();

registerTransformation(
  [[AttackTarget.Enemy, CombatStat.Accuracy]],
  "Dazzle",
  TransformationType.Multiplier,
  (state) =>
    hasTemporaryEffect(state, "Dazzle")
      ? 1.0 / dazzle.getActionEffects(state).magnitude
      : 1.0,
);

export default dazzle;
