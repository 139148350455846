import React, { useContext, useEffect, useMemo, useRef } from "react";
import { Animated, StyleSheet, View } from "react-native";
import ProgressBar from "react-native-progress/Bar";
import { AttackTarget } from "../../backend/exploration/AttackTarget";
import { CombatActionResult, PlayerContext } from "../../backend/PlayerContext";
import { getSecondsPlayed } from "../../backend/timetick/TotalTimePlayed";
import { formatNumber } from "../../utils/FormattingUtils";
import { GameIcon } from "../utility/icons/GameIcon";
import { Text } from "../utility/Text";

function CombatActionResultDisplay(props: {
  action: CombatActionResult;
  delay: number;
}) {
  let auxText = null,
    auxTextStyle = null;
  if (props.action != undefined) {
    if (!props.action.isHit) {
      auxText = "Miss!";
    } else {
      auxText =
        (props.action.damage > 0 ? "-" : "") +
        formatNumber(Math.abs(props.action.damage), {
          showDecimals: false,
        }) +
        (props.action.isCrit ? "!" : "");
      auxTextStyle = props.action.isCrit
        ? styles.auxTextCritical
        : props.action.damage > 0
        ? styles.auxTextDamage
        : styles.auxTextHealing;
    }
  }

  const damageAnim = useRef(new Animated.Value(0)).current;
  const opacityAnim = useRef(new Animated.Value(0)).current;
  useEffect(() => {
    damageAnim.setValue(0);
    Animated.sequence([
      Animated.delay(props.delay),
      Animated.spring(damageAnim, {
        toValue: -16,
        useNativeDriver: true,
        bounciness: 0,
      }),
    ]).start();
  }, [props.action?.id]);
  useEffect(() => {
    Animated.sequence([
      Animated.delay(props.delay),
      Animated.timing(opacityAnim, {
        toValue: 1,
        useNativeDriver: true,
        duration: 0,
      }),
      Animated.timing(opacityAnim, {
        toValue: 0,
        useNativeDriver: true,
        duration: 800,
      }),
    ]).start();
  }, [props.action?.id]);

  return auxText ? (
    <Animated.View
      style={[
        styles.auxTextContainer,
        { transform: [{ translateY: damageAnim }], opacity: opacityAnim },
      ]}
    >
      <Text style={[styles.auxText, auxTextStyle]}>{auxText}</Text>
    </Animated.View>
  ) : (
    <View />
  );
}

function HPBarNonMemo(props: {
  empty: boolean;
  current: number;
  max: number;
  actionTarget: AttackTarget;
}) {
  const playerContext = useContext(PlayerContext);
  const mountTime = useRef(getSecondsPlayed(playerContext));

  const actions = playerContext.exploration.actionResultQueue.filter(
    (result) =>
      result.target === props.actionTarget && result.time > mountTime.current,
  );
  const actionKeys = actions.map((action) => action.id).join();
  const actionResults = useMemo(() => {
    let lastTime = 0,
      delay = 0;
    const displays = [];
    for (let actionIdx in actions) {
      const action = actions[actionIdx];
      if (action.time * 1000 < lastTime + 300) {
        delay += lastTime + 300 - action.time * 1000;
      } else {
        delay = 0;
      }
      displays.push(
        <CombatActionResultDisplay
          key={action.id}
          action={action}
          delay={delay}
        />,
      );
      lastTime = action.time * 1000;
    }
    return displays;
  }, [actionKeys]);

  return (
    <View style={[styles.row, styles.container]}>
      <GameIcon icon="hp" style={styles.icon} />
      <View style={styles.progressBarContainer}>
        <ProgressBar
          progress={props.current / props.max}
          width={null}
          useNativeDriver={true}
          color="red"
          unfilledColor="#800"
          height={20}
        />
        {!props.empty && (
          <Text style={styles.overlaidText}>
            {formatNumber(Math.ceil(props.current))}/
            {formatNumber(Math.ceil(props.max))}
          </Text>
        )}
        {actionResults}
      </View>
    </View>
  );
}

const HPBar = React.memo(HPBarNonMemo);

export { HPBar };

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  icon: {
    marginTop: -4,
    marginRight: 4,
  },
  overlaidText: {
    color: "#fff",
    fontWeight: "bold",
    position: "absolute",
    width: "100%",
    textAlign: "center",
  },
  auxTextContainer: {
    left: 48,
    position: "absolute",
    width: "100%",
  },
  auxText: {
    color: "#000",
    width: "100%",
    textAlign: "center",
    fontWeight: "bold",
  },
  auxTextCritical: {
    color: "#dd0",
  },
  auxTextDamage: {
    color: "#f00",
  },
  auxTextHealing: {
    color: "#0f0",
  },
  progressBarContainer: {
    flex: 1,
  },
  container: {
    height: 32,
  },
});
