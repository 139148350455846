import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { applyTransformations } from "../../backend/calculation/Calculation";
import { TransformationTags } from "../../backend/calculation/TransformationTags";
import { cleanup } from "../../backend/Cleanup";
import { PlayerContext, PlayerContextState } from "../../backend/PlayerContext";
import {
  processOfflineTicks,
  processTimeTick,
} from "../../backend/timetick/TimeTick";

const TARGET_FPS = 20;

export default function GameTickProcessor(props: {
  setPlayerState: (state: PlayerContextState) => void;
}) {
  const playerContext = useContext(PlayerContext);

  // The use of ref is needed here because:
  // 1. We don't want to reset the interval if the context changes,
  //    because that will lead to delayed updates
  // 2. If we don't use playerContextRef and use playerContext instead,
  //    it will refer to an old context,
  //    and it will miss updates
  const playerContextRef = useRef(playerContext);
  playerContextRef.current = playerContext;

  const [lastTickTime, setLastTickTime] = useState(
    playerContext.lastProcessedTime,
  );

  const timeTickCallback = useCallback(async () => {
    const nowTime = Date.now();
    const elapsedSec =
      (nowTime - lastTickTime) / 1000 + playerContextRef.current.skipAheadTime;
    if (elapsedSec > 3) {
      // Trigger a loading update
      const secsTotal = Math.floor(Math.min(60 * 60 * 48, elapsedSec));
      let { apply, ...currentState } = playerContextRef.current;
      await processOfflineTicks(
        currentState,
        secsTotal,
        nowTime,
        props.setPlayerState,
      );
    } else {
      playerContextRef.current.apply((state) => {
        state = processTimeTick(elapsedSec)(state);
        state.lastProcessedTime = nowTime;
        return state;
      });
    }
    if (playerContextRef.current.skipAheadTime > 0) {
      playerContextRef.current.apply((state) => {
        state.skipAheadTime = 0;
        return state;
      });
    }
    setLastTickTime(nowTime);
  }, [lastTickTime]);

  const cleanupCallback = useCallback(async () => {
    playerContextRef.current.apply(cleanup);
  }, []);

  useEffect(() => {
    const gameSpeed = applyTransformations(
      [TransformationTags.GameSpeed],
      playerContextRef.current,
      1.0,
    );
    const interval = setTimeout(() => {
      timeTickCallback();
    }, 1000 / gameSpeed / (playerContext.global?.targetFps ?? TARGET_FPS));
    return () => clearTimeout(interval);
  }, [timeTickCallback]);

  useEffect(() => {
    const interval = setInterval(() => {
      cleanupCallback();
    }, 10000);
    return () => clearTimeout(interval);
  }, [cleanupCallback]);

  // Do not render anything
  return null;
}
