import {
  registerTransformation,
  TransformationType,
} from "../../../calculation/Calculation";
import { setFlag } from "../../../Flags";
import { PlayerContextState } from "../../../PlayerContext";
import {
  grantTemporaryEffect,
  hasTemporaryEffect,
} from "../../../timetick/TemporaryEffects";
import { AttackTarget } from "../../AttackTarget";
import { CombatStat } from "../../CombatStats";
import { setDungeonFloorCompleted } from "../../dungeons/Dungeons";
import { standardEnemyAttackEffect } from "../../Exploration";
import { BattlerAction, BattlerStats, Enemy } from "../Enemy";

class LittleDevil extends Enemy {
  getId(): string {
    return "littleDevil";
  }

  getName(): string {
    return "Little Devil";
  }

  getPicture() {
    return require("./littleDevil.png");
  }

  getBaseStats(): BattlerStats {
    return {
      maxHP: 900,
      defense: 14,
      attack: 26,
      attackDelay: 2.7,
      dodgeChance: 0,
      critChance: 0,
    };
  }

  getNextAction(state: PlayerContextState): BattlerAction {
    const fireBallChance = 0.2;
    if (Math.random() < fireBallChance) {
      return {
        name: "Fire Ball",
        delay: 5.0,
        transform: (state: PlayerContextState) =>
          standardEnemyAttackEffect(state, 1.0, 48),
      };
    }
    const weakenChance = 0.2;
    if (Math.random() < weakenChance) {
      return {
        name: "Weaken",
        delay: 5.0,
        transform: grantTemporaryEffect(
          "Weaken (Little Devil)",
          "Weaken (Little Devil)",
          12,
          {
            isBeneficial: false,
            mode: "add",
          },
        ),
      };
    }
    return {
      name: "Attack",
      delay: this.getAttackDelay(state),
      transform: (state: PlayerContextState) =>
        standardEnemyAttackEffect(state, 1.0, this.getAttack(state)),
    };
  }

  getCoinsAwarded(state: PlayerContextState): number {
    return 450;
  }

  getMonstiumAwarded(state: PlayerContextState): number {
    return 36;
  }

  getItemsAwarded() {
    return [{ itemId: "medicinalHerb", chance: 1.0, amount: 3 }];
  }

  isBoss(): boolean {
    return true;
  }

  onDeath(
    state: PlayerContextState,
    isFromCreatures: boolean,
  ): PlayerContextState {
    state = super.onDeath(state, isFromCreatures);
    state = setDungeonFloorCompleted(state, "schoolBasementB2");
    return setFlag("school_basement_b3_unlocked")(state);
  }
}

registerTransformation(
  [[AttackTarget.Player, CombatStat.Attack]],
  "LittleDevilWeaken",
  TransformationType.Addition,
  (state) => (hasTemporaryEffect(state, "Weaken (Little Devil)") ? -12 : 0),
);

export default new LittleDevil();
