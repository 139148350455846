import { PlayerContextState } from "../../../PlayerContext";
import { EquipmentSlot } from "../../Equipment";
import { ItemParams } from "../../Item";
import { EquippableItem } from "../EquippableItem";

class Sword extends EquippableItem {
  getId(): string {
    return "sword";
  }

  getPicture() {
    return require("./sword.png");
  }

  getBaseName(): string {
    return "Sword";
  }

  getDescription(): string | undefined {
    return "A sword: a mighty weapon in the hands of an expert.";
  }

  getAttackBonus(state: PlayerContextState, params: ItemParams): number {
    return 30;
  }

  getSlot(): EquipmentSlot {
    return EquipmentSlot.Hand;
  }

  getSalePrice(): number {
    return 1200;
  }

  getCraftingSchoolLevelRequirements() {
    return { Conjuration: 5 };
  }

  getCraftingMaterialsBase() {
    return {
      resources: {
        Iron: 120,
        Wood: 200,
        Temperature: 600,
      },
      items: {},
    };
  }
}

export default new Sword();
