import { ItemBase } from "../Item";

class RatTail extends ItemBase {
  getId(): string {
    return "ratTail";
  }

  getPicture() {
    return require("./ratTail.png");
  }

  getBaseName(): string {
    return "Rat Tail";
  }

  getTags() {
    return [{ tag: "Material" }];
  }

  getDescription(): string | undefined {
    return "The tail of a mouse that you have slayed. Useful in Alchemy.";
  }

  getEffect(): string | undefined {
    return undefined;
  }

  getSalePrice(): number {
    return 24;
  }

  getCraftingSchoolLevelRequirements() {
    return {};
  }

  getCraftingMaterialsBase() {
    return {
      resources: {},
      items: {},
    };
  }
}

export default new RatTail();
