import React from "react";
import { Image, ImageProps, ImageSourcePropType } from "react-native";
import { getItemByIdNullable } from "../../../backend/items/Items";

type IconProps = Omit<ImageProps, "source"> & {
  icon: string;
  small?: boolean;
};

const icons: Record<string, ImageSourcePropType> = {
  stone: require("./assets/stone.png"),
  wood: require("./assets/wood.png"),
  coins: require("./assets/coins.png"),
  mana: require("./assets/mana.png"),
  iron: require("./assets/iron.png"),
  water: require("./assets/water.png"),
  boost: require("./assets/boost.png"),
  temperature: require("./assets/temperature.png"),
  attack: require("./assets/attack.png"),
  attackDelay: require("./assets/attackDelay.png"),
  defense: require("./assets/defense.png"),
  hp: require("./assets/hp.png"),
  hpRegen: require("./assets/hpRegen.png"),
  monstium: require("./assets/monstium.png"),
  debuff: require("./assets/debuff.png"),
  generic: require("./assets/generic.png"),
  accuracy: require("./assets/accuracy.png"),
  dodge: require("./assets/dodge.png"),
  timepiece: require("./assets/timepiece.png"),
  critChance: require("./assets/critChance.png"),
  electricity: require("./assets/electricity.png"),
};

function GameIconNonMemo(props: IconProps) {
  const { icon, style, ...otherProps } = props;
  let imageSource = icons[icon];
  if (!imageSource) {
    // It could be an item
    const item = getItemByIdNullable(icon);
    if (item) {
      imageSource = item.getPicture();
    }
  }
  if (!imageSource) {
    return null;
  }
  const computedStyle = [
    { width: 32, height: 32 },
    style,
    props.small ? { width: 16, height: 16 } : {},
  ];
  return <Image source={imageSource} style={computedStyle} {...otherProps} />;
}

const GameIcon = React.memo(GameIconNonMemo);

export { GameIcon };
