import { PlayerContextState } from "../../PlayerContext";
import { Upgrade } from "../Upgrade";

class WoolFarm extends Upgrade {
  getName(): string {
    return "Wool Farm";
  }

  getMonstiumCost(): number {
    return 600;
  }

  getDisplayDescription(state: PlayerContextState): string {
    return "Adds a section of the school dedicated to raising sheep for wool.";
  }

  getDisplayEffect(state: PlayerContextState): string {
    return 'Unlocks "Wool Farm" exploration area';
  }

  getUpgradeRequirements() {
    return [];
  }
}

const woolFarm = new WoolFarm();

export default woolFarm;
