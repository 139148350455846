import { PlayerContextState } from "../../../PlayerContext";
import { Item } from "../../Item";
import AirSpeck from "./AirSpeck";
import DarknessSpeck from "./DarknessSpeck";
import EarthSpeck from "./EarthSpeck";
import FireSpeck from "./FireSpeck";
import LightningSpeck from "./LightningSpeck";
import NatureSpeck from "./NatureSpeck";
import WaterSpeck from "./WaterSpeck";

export function generateSpeckForState(state: PlayerContextState): Item {
  switch (state.primaryElement) {
    case "Fire":
      return FireSpeck;
    case "Earth":
      return EarthSpeck;
    case "Water":
      return WaterSpeck;
    case "Air":
      return AirSpeck;
    case "Nature":
      return NatureSpeck;
    case "Lightning":
      return LightningSpeck;
    case "Darkness":
      return DarknessSpeck;
    default:
      return FireSpeck;
  }
}
