import { formatNumber } from "../../../utils/FormattingUtils";
import { ActionEffect } from "../../actions/Action";
import {
  registerTransformation,
  TransformationType,
} from "../../calculation/Calculation";
import { TransformationTags } from "../../calculation/TransformationTags";
import { buildEventMessage } from "../../events/EventMessageBuilder";
import { triggerEvent } from "../../events/Events";
import { createGameEvent } from "../../events/SimpleGameEvent";
import { hasFlag, setFlag } from "../../Flags";
import { PlayerContextState } from "../../PlayerContext";
import { addContextListener } from "../../PlayerContextListeners";
import { getResourceAmount, recalculateCaps, Resource } from "../../Resources";
import { BuildingActionBase } from "../Building";
import { buildingAmountsChanged, getBuildingAmount } from "../Buildings";

class WaterTank extends BuildingActionBase {
  constructor() {
    super();
  }

  isVisible(state: PlayerContextState): boolean {
    return hasFlag(state, "water_tank_unlocked");
  }

  getBuildingName(): string {
    return "Water Tank";
  }

  getArea(): string {
    return "Campus";
  }

  getDisplayDescription(): string {
    return "Allows you to stockpile water.";
  }

  protected getBaseActionEffects(): Record<string, ActionEffect> {
    return {
      water: {
        value: 250,
        tags: [Resource.Water, TransformationTags.PerBuildingCap],
      },
    };
  }

  getDisplayEffect(state: PlayerContextState): string {
    const effects = this.getActionEffects(state);
    const resourceEffectMap: Partial<Record<Resource, string>> = {
      Water: `+${formatNumber(effects.water)} Max:water:`,
    };
    return (Object.keys(resourceEffectMap) as Array<Resource>)
      .filter((resource) => state.resources?.[resource] !== undefined)
      .map((resource) => resourceEffectMap[resource])
      .join(", ");
  }

  getBaseResourceCost(): Record<string, number> {
    return {
      Coins: 350,
      Wood: 300,
      Stone: 200,
    };
  }

  getBaseResourceScale(): Record<string, number> {
    return {
      Coins: 1.3,
      Wood: 1.3,
      Stone: 1.3,
    };
  }
}

function calculateWaterTankCap(state: PlayerContextState) {
  return waterTank.getActionEffects(state).water;
}

const waterTank = new WaterTank();

registerTransformation(
  [[TransformationTags.Cap, Resource.Water]],
  "WaterTankWaterCap",
  TransformationType.Addition,
  (state) => getBuildingAmount(state, waterTank) * calculateWaterTankCap(state),
);

addContextListener("water_tank_caps_recalculation", (oldState, newState) => {
  if (buildingAmountsChanged(oldState, newState, waterTank)) {
    return (state) => {
      return recalculateCaps(state);
    };
  }
});

const message = buildEventMessage("water_tank_unlocked", "Water Storage")
  .setSimpleDescription(
    `You have been struggling with water storage for a while. Creating it is easy, but you have nowhere to place it - it just drops to the ground!
    
You feel a surge of inspiration, as you realize you could build a container. Then you figure it's downright obvious.

You set out to build a water tank with stone and wood.

**You can now build Water Tanks!**`,
  )
  .build();

const event = createGameEvent(
  "water_tank_unlocked",
  message,
  setFlag("water_tank_unlocked"),
);

addContextListener("water_tank_unlocked", (oldState, newState) => {
  if (
    !hasFlag(newState, "water_tank_unlocked") &&
    hasFlag(newState, "gather_wood_unlocked") &&
    getResourceAmount(newState, Resource.Water) >= 10
  ) {
    return triggerEvent(event);
  }
});

export default waterTank as WaterTank;
