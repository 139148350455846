import { PlayerContextState } from "../../../PlayerContext";
import { EquipmentSlot } from "../../Equipment";
import { ItemParams } from "../../Item";
import { EquippableItem } from "../EquippableItem";

class IronMail extends EquippableItem {
  getId(): string {
    return "ironArmor";
  }

  getPicture() {
    return require("./ironMail.png");
  }

  getBaseName(): string {
    return "Iron Mail";
  }

  getDescription(): string | undefined {
    return "Heavy chest protection, to withstand tough blows.";
  }

  getDefenseBonus(state: PlayerContextState, params: ItemParams): number {
    return 16;
  }

  getSlot(): EquipmentSlot {
    return EquipmentSlot.Body;
  }

  getSalePrice(): number {
    return 2500;
  }

  getCraftingSchoolLevelRequirements() {
    return { Conjuration: 7 };
  }

  getCraftingMaterialsBase() {
    return {
      resources: {
        Iron: 300,
        Temperature: 1200,
      },
      items: {},
    };
  }
}

export default new IronMail();
