import { formatNumber } from "../../../utils/FormattingUtils";
import { ActionEffect } from "../../actions/Action";
import { TransformationTags } from "../../calculation/TransformationTags";
import { hasGlobalFlag } from "../../Flags";
import { PlayerContextState } from "../../PlayerContext";
import { CombatSpellBase } from "../CombatSpellBase";
import { SpellElement } from "../ElementsAndSchools";
import {
  getStandardAttackEffectText,
  getStandardFireAttackEffect,
} from "../Utils";

class ConeOfFire extends CombatSpellBase {
  getSpellName(): string {
    return "Cone of Fire";
  }

  getElement(): SpellElement | undefined {
    return SpellElement.Fire;
  }

  getDisplayDescription(): string {
    return "Hit an enemy with a cone-shaped burst of flames.";
  }

  protected getBaseActionEffects(): Record<string, ActionEffect> {
    return {
      average: {
        value: 240,
        tags: [TransformationTags.ActionMagnitude],
      },
      variance: {
        value: 10,
        tags: [TransformationTags.ActionVariance],
      },
      dotStrength: {
        value: 10,
        tags: [TransformationTags.TemporaryEffectMagnitude],
      },
      dotDuration: {
        value: 3.0,
        tags: [TransformationTags.TemporaryEffectDuration],
      },
    };
  }

  getDisplayEffect(state: PlayerContextState): string {
    const effects = this.getActionEffects(state);
    if (hasGlobalFlag(state, "advanced_evocation_unlocked")) {
      return (
        getStandardAttackEffectText(effects.average, effects.variance) +
        `, -${formatNumber(effects.dotStrength)}:hp:/sec for ${formatNumber(
          effects.dotDuration,
          {
            showDecimals: true,
          },
        )} sec`
      );
    }
    return getStandardAttackEffectText(effects.average, effects.variance);
  }

  doSpellAction(state: PlayerContextState): PlayerContextState {
    return getStandardFireAttackEffect(state, this);
  }

  getBaseCooldown(): number {
    return 3.5;
  }

  isVisible(state: PlayerContextState): boolean {
    return super.isVisible(state) && state.primaryElement == "Fire";
  }

  getManaCost(): number {
    return 1400;
  }

  getSchoolLevelRequirements(): Record<string, number> {
    return {
      Evocation: 8,
    };
  }
}

export default new ConeOfFire();
