import { ItemBase } from "../Item";

class Cloth extends ItemBase {
  getId(): string {
    return "cloth";
  }

  getPicture() {
    return require("./cloth.png");
  }

  getBaseName(): string {
    return "Cloth";
  }

  getTags() {
    return [{ tag: "Material" }];
  }

  getDescription(): string | undefined {
    return "A basic material for a lot of equipment, especially for spellcasters.";
  }

  getEffect(): string | undefined {
    return undefined;
  }

  getSalePrice(): number {
    return 200;
  }

  getCraftingSchoolLevelRequirements() {
    return { Conjuration: 1 };
  }

  getCraftingMaterialsBase() {
    return {
      resources: {},
      items: { wool: 4 },
    };
  }
}

export default new Cloth();
