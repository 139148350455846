import React, { useEffect, useMemo, useRef } from "react";
import { Animated, StyleSheet, TouchableWithoutFeedback } from "react-native";
import { EventList } from "./EventList";

type Props = {
  visible: boolean;
  onBackgroundPress: () => void;
};

export function EventMenu(props: Props) {
  const positionAnim = useRef(
    new Animated.Value(props.visible ? 0 : 300),
  ).current;

  const opacityAnim = useRef(
    new Animated.Value(props.visible ? 0.5 : 0),
  ).current;

  useEffect(() => {
    if (props.visible) {
      Animated.timing(positionAnim, {
        toValue: 0,
        duration: 150,
        useNativeDriver: true,
      }).start();
      Animated.timing(opacityAnim, {
        toValue: 0.5,
        duration: 150,
        useNativeDriver: true,
      }).start();
    } else {
      Animated.timing(positionAnim, {
        toValue: 300,
        duration: 150,
        useNativeDriver: true,
      }).start();
      Animated.timing(opacityAnim, {
        toValue: 0,
        duration: 150,
        useNativeDriver: true,
      }).start();
    }
  }, [props.visible]);

  return useMemo(
    () => (
      <>
        <TouchableWithoutFeedback onPress={props.onBackgroundPress}>
          <Animated.View
            style={[styles.backdrop, { opacity: opacityAnim }]}
            pointerEvents={props.visible ? "auto" : "none"}
          />
        </TouchableWithoutFeedback>
        <Animated.View
          style={[
            styles.container,
            { transform: [{ translateX: positionAnim }] },
          ]}
        >
          <EventList style={styles.eventList} />
        </Animated.View>
      </>
    ),
    [opacityAnim, positionAnim, props.visible],
  );
}

const styles = StyleSheet.create({
  backdrop: {
    position: "absolute",
    height: "100%",
    width: "100%",
    backgroundColor: "#000",
  },
  backdropTouchable: {
    position: "absolute",
    height: "100%",
    width: "100%",
  },
  container: {
    justifyContent: "flex-start",
    alignItems: "stretch",
    position: "absolute",
    height: "100%",
    width: 300,
    right: 0,
    top: 0,
    backgroundColor: "#fff",
  },
  eventList: {
    flex: 1,
  },
});
