import React, { useCallback, useContext, useMemo, useState } from "react";
import { StyleSheet, View } from "react-native";
import { Divider } from "react-native-elements";
import { getVisibleHelps } from "../backend/help/Helps";
import { PlayerContext } from "../backend/PlayerContext";
import { HelpContainer } from "./utility/HelpContainer";
import { Text } from "./utility/Text";

function HelpsContainer() {
  const [expandedHelp, setExpandedHelp] = useState("");
  const setHelpCallback = useCallback(
    (title: string) => {
      if (expandedHelp == title) {
        setExpandedHelp("");
      } else {
        setExpandedHelp(title);
      }
    },
    [expandedHelp],
  );

  const playerContext = useContext(PlayerContext);
  const allHelps = getVisibleHelps(playerContext);

  const helpNameString = allHelps.map((help) => help.title).join(",");

  const helpContainers = useMemo(
    () =>
      allHelps.map((help) => {
        return (
          <HelpContainer
            key={help.title}
            help={help}
            collapsed={expandedHelp != help.title}
            onPressExpand={setHelpCallback.bind(null, help.title)}
          />
        );
      }),
    [helpNameString, expandedHelp, setExpandedHelp],
  );

  return useMemo(() => {
    if (allHelps.length === 0) {
      return null;
    }
    return (
      <View>
        {helpContainers.map((helpContainer, idx) => {
          return (
            <View key={allHelps[idx].title}>
              <Divider />
              {helpContainer}
            </View>
          );
        })}
        <Divider />
      </View>
    );
  }, [helpNameString, helpContainers]);
}

export default function Help() {
  return (
    <View>
      <View style={styles.headerContainer}>
        <Text h1>Help</Text>
      </View>

      <Text>Learn how to play the game!</Text>
      <View style={styles.helpContainer}>
        <HelpsContainer />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "stretch",
    justifyContent: "flex-start",
    width: "100%",
  },
  headerContainer: {
    marginBottom: 16,
  },
  valueText: {
    minWidth: 60,
    textAlign: "right",
    marginLeft: 8,
  },
  valueTextLong: {
    minWidth: 112,
  },
  helpContainer: {
    marginTop: 16,
  },
  captionText: {
    flex: 1,
  },
});
