import React from "react";
import { StyleSheet, View } from "react-native";
import LibraryMarkdown, {
  getUniqueID,
  MarkdownIt,
} from "react-native-markdown-display";
import { Text } from "../utility/Text";
import { GameIcon } from "./icons/GameIcon";
import MarkdownIconPlugin from "./markdown/MarkdownIconPlugin";

const markdownItInstance = MarkdownIt({ typographer: false }).use(
  MarkdownIconPlugin,
  {
    containerClassName: "icon-embed",
  },
);

const markdownRules = {
  paragraph: (node: any, children: any, parent: any, markdownStyles: any) => {
    return (
      <View
        key={getUniqueID()}
        style={[node.index > 0 && styles.markdownParagraph]}
      >
        {children}
      </View>
    );
  },
  "regexp-0": (node: any, children: any, parent: any, markdownStyles: any) => {
    const icon = node.sourceMeta.match[1];
    // \u200b is "zero-width space". It needs to be added because
    // in devices like the Google Pixel phones and tablets,
    // if a line contains exclusively the icon with no text,
    // the icon gets cut off.
    return (
      <Text key={getUniqueID()}>
        <GameIcon
          key={getUniqueID()}
          style={{
            height: 16,
            width: 16,
            justifyContent: "flex-end",
            alignItems: "center",
            top: 2,
            marginLeft: 2,
            marginRight: icon === "attack" ? 0 : 2,
          }}
          icon={icon}
        />
        {"\u200b"}
      </Text>
    );
  },
};

function MarkdownImpl(props: any) {
  const style = props?.style || {};
  const children = props.children?.replace?.(
    /((\d(K|M|B|T)?)|(Max)) :/g,
    (_substring: string, args: string) => args + ":",
  );
  return (
    <LibraryMarkdown
      {...props}
      markdownit={markdownItInstance}
      rules={markdownRules}
      style={{
        body: [{ justifyContent: "flex-end", minHeight: 21 }, style],
        bullet_list_icon: { lineHeight: 16, marginTop: 4 },
        bullet_list: { width: "100%" },
        list_item: { alignItems: "center", justifyContent: "center" },
        heading2: {
          lineHeight: 24,
        },
      }}
    >
      {children}
    </LibraryMarkdown>
  );
}

const Markdown = React.memo(MarkdownImpl);

export { Markdown };

const styles = StyleSheet.create({
  markdownParagraph: {
    marginTop: 4,
  },
  markdownTextgroup: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    padding: 0,
    margin: 0,
  },
  text: {
    lineHeight: 16,
  },
});
