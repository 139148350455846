import { formatNumber } from "../../../utils/FormattingUtils";
import { applyTransformations } from "../../calculation/Calculation";
import { TransformationTags } from "../../calculation/TransformationTags";
import { Identifiable } from "../../generic/Identifiable";
import { addToInventory } from "../../items/Inventory";
import { getItemById } from "../../items/Items";
import {
  PlayerContextState,
  PlayerContextTransform,
} from "../../PlayerContext";
import { grantResource, Resource } from "../../Resources";
import { AttackTarget } from "../AttackTarget";
import { CombatStat } from "../CombatStats";
import { pushToCreatureMessageLog } from "../Creatures";
import { pushToMessageLog, standardEnemyAttackEffect } from "../Exploration";

export interface BattlerStats {
  maxHP: number;
  attack: number;
  attackDelay: number;
  defense: number;
  dodgeChance: number;
  critChance: number;
}

export interface BattlerAction {
  name: string;
  delay: number;
  transform: PlayerContextTransform;
}

export interface EnemyLoot {
  itemId: string;
  chance: number;
  amount: number;
  params?: any;
}

export abstract class Enemy implements Identifiable {
  abstract getId(): string;
  abstract getName(): string;
  abstract getBaseStats(): BattlerStats;
  abstract getPicture(state: PlayerContextState): any;

  getMaxHP(state: PlayerContextState): number {
    return applyTransformations(
      [AttackTarget.Enemy, CombatStat.MaxHP],
      state,
      this.getBaseStats().maxHP,
    );
  }
  getAttack(state: PlayerContextState): number {
    return applyTransformations(
      [AttackTarget.Enemy, CombatStat.Attack],
      state,
      this.getBaseStats().attack,
    );
  }
  getAttackDelay(state: PlayerContextState): number {
    return this.getBaseStats().attackDelay;
  }
  getCriticalChance(state: PlayerContextState): number {
    return applyTransformations(
      [AttackTarget.Enemy, CombatStat.CritChance],
      state,
      this.getBaseStats().critChance,
    );
  }
  getDefense(state: PlayerContextState): number {
    return applyTransformations(
      [AttackTarget.Enemy, CombatStat.Defense],
      state,
      this.getBaseStats().defense,
    );
  }
  getDodgeChance(state: PlayerContextState): number {
    return applyTransformations(
      [AttackTarget.Enemy, CombatStat.Dodge],
      state,
      this.getBaseStats().dodgeChance,
    );
  }

  getNextAction(state: PlayerContextState): BattlerAction {
    return {
      name: "Attack",
      delay: this.getAttackDelay(state),
      transform: (state) =>
        standardEnemyAttackEffect(
          state,
          1.0,
          this.getAttack(state),
          this.getCriticalChance(state),
        ),
    };
  }

  abstract getCoinsAwarded(state: PlayerContextState): number;

  abstract getMonstiumAwarded(state: PlayerContextState): number;

  getItemsAwarded(state: PlayerContextState): EnemyLoot[] {
    return [];
  }

  isBoss(): boolean {
    return false;
  }

  getBackdropColor(): string | undefined {
    return undefined;
  }

  onDeath(
    state: PlayerContextState,
    isFromCreatures: boolean,
  ): PlayerContextState {
    const baseCoinsAwarded = this.getCoinsAwarded(state);
    const baseMonstiumAwarded = this.getMonstiumAwarded(state);
    const coinsAwarded = applyTransformations(
      [
        TransformationTags.Loot,
        Resource.Coins,
        isFromCreatures
          ? TransformationTags.FromCreatures
          : TransformationTags.FromExploration,
      ],
      state,
      baseCoinsAwarded,
    );
    const monstiumAwarded = applyTransformations(
      [
        TransformationTags.Loot,
        Resource.Monstium,
        isFromCreatures
          ? TransformationTags.FromCreatures
          : TransformationTags.FromExploration,
      ],
      state,
      baseMonstiumAwarded,
    );

    state = grantResource(Resource.Coins, coinsAwarded)(state);
    state = grantResource(Resource.Monstium, monstiumAwarded)(state);
    if (!isFromCreatures) {
      state = pushToMessageLog(
        state,
        `You found ${formatNumber(coinsAwarded)} :coins: and ${formatNumber(
          monstiumAwarded,
        )} :monstium:!`,
      );
    } else {
      state = pushToCreatureMessageLog(
        state,
        `A creature found ${formatNumber(
          coinsAwarded,
        )} :coins: and ${formatNumber(monstiumAwarded)} :monstium:!`,
      );
    }
    const itemsAwarded = this.getItemsAwarded(state);
    itemsAwarded.forEach((enemyLoot) => {
      let chance = enemyLoot.chance;
      if (!isFromCreatures) {
        chance = applyTransformations(
          [TransformationTags.LootChance],
          state,
          chance,
        );
      }
      if (Math.random() < chance) {
        const item = getItemById(enemyLoot.itemId);
        const params = enemyLoot.params || {};
        state = addToInventory(
          { itemId: enemyLoot.itemId, params },
          enemyLoot.amount,
          state,
        );
        if (!isFromCreatures) {
          state = pushToMessageLog(
            state,
            `You found ${enemyLoot.amount} ${item.getName(params)}!`,
          );
        } else {
          state = pushToCreatureMessageLog(
            state,
            `A creature found ${enemyLoot.amount} ${item.getName(params)}!`,
          );
        }
      }
    });
    return state;
  }
}
