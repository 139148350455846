import React, { useMemo } from "react";
import { Item } from "../../backend/items/Item";
import {
  StyleSheet,
  View,
  TouchableHighlight,
  TouchableOpacity,
  ScrollView,
} from "react-native";
import { GameIcon } from "../utility/icons/GameIcon";
import { getItemById } from "../../backend/items/Items";
import { Badge } from "react-native-elements";
import { ItemTile } from "./ItemTile";

export interface ItemGridElement {
  itemId: string;
  amount: number;
}

export interface ItemGridProps {
  items: ItemGridElement[];
  onPressItem?: (item: Item, index: number) => void;
  dummySlotAmount?: number;
}

export function ItemGrid(props: ItemGridProps) {
  return useMemo(() => {
    let dummySlots = [];
    for (let i = 0; i < (props.dummySlotAmount || 0); i++) {
      dummySlots.push(<ItemTile key={`dummySlot${i}`} />);
    }
    return (
      <View style={styles.container}>
        <ScrollView style={styles.scrollView} nestedScrollEnabled={true}>
          <View style={styles.gridContainer}>
            {props.items.map((itemGridElement, index) => {
              const item = getItemById(itemGridElement.itemId);
              return (
                <ItemTile
                  key={index}
                  onPress={() =>
                    props.onPressItem && props.onPressItem(item, index)
                  }
                  icon={item.getId()}
                  amount={itemGridElement.amount}
                />
              );
            })}
            {dummySlots}
          </View>
        </ScrollView>
      </View>
    );
  }, [JSON.stringify(props.items), props.onPressItem, props.dummySlotAmount]);
}

const styles = StyleSheet.create({
  container: {
    borderColor: "#ddd",
    borderWidth: 2,
  },
  scrollView: {
    maxHeight: 220,
  },
  gridContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    backgroundColor: "#fff",
    justifyContent: "center",
    flex: 1,
  },
});
