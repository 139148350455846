import { PlayerContextState } from "../PlayerContext";

export type Hint = {
  description: string;
  isCompleted: (state: PlayerContextState) => boolean;

  // Returns a number between 0 and 1 representing a percentage
  progress?: (state: PlayerContextState) => number;
};

let hintsDatabase: Hint[] = [];

export function clearAllHints(): void {
  hintsDatabase = [];
}

export function pushHint(hint: Hint): void {
  hintsDatabase.push(hint);
}

export function getFirstHint(state: PlayerContextState): Hint | undefined {
  for (const idx in hintsDatabase) {
    const hint = hintsDatabase[idx];
    if (!hint.isCompleted(state)) {
      return hint;
    }
  }
}

export function getFirstHintIndex(
  state: PlayerContextState,
): string | undefined {
  for (const idx in hintsDatabase) {
    const hint = hintsDatabase[idx];
    if (!hint.isCompleted(state)) {
      return idx;
    }
  }
}
