import { formatNumber } from "../../../utils/FormattingUtils";
import { TransformationTags } from "../../calculation/TransformationTags";
import { PlayerContextState } from "../../PlayerContext";
import {
  getResourceAmount,
  getResourceCap,
  grantResource,
  Resource,
} from "../../Resources";
import { ActionBase, ActionEffect, DoActionArgs } from "../Action";

class GatherMana extends ActionBase {
  constructor() {
    super();
  }

  isVisible(): boolean {
    return true;
  }

  isEnabled(state: PlayerContextState): boolean {
    return (
      getResourceAmount(state, Resource.Mana) <
      getResourceCap(state, Resource.Mana)
    );
  }

  getTags(): string[] {
    return [
      ...super.getTags(),
      TransformationTags.ManualGathering,
      TransformationTags.Production,
      Resource.Mana,
    ];
  }

  getName(): string {
    return "Gather Mana";
  }

  getArea(): string {
    return "Campus";
  }

  getDisplayDescription(): string {
    return "Focus your thoughts and gather mana.";
  }

  getBaseActionEffects(): Record<string, ActionEffect> {
    return {
      mana: { value: 1 },
    };
  }

  getDisplayEffect(state: PlayerContextState): string {
    const effects = this.getActionEffects(state);
    return `+${formatNumber(effects.mana, {
      showDecimals: true,
    })}:mana:`;
  }

  getCost(state: PlayerContextState): {
    resources: Partial<Record<Resource, number>>;
    items: Record<string, number>;
  } {
    return { resources: {}, items: {} };
  }

  doAction(args: DoActionArgs, state: PlayerContextState): PlayerContextState {
    return grantResource(
      Resource.Mana,
      this.getActionEffects(state).mana,
    )(state);
  }
}

export default new GatherMana();
