import { PlayerContextState } from "../../../PlayerContext";
import { EquipmentSlot } from "../../Equipment";
import { ItemParams } from "../../Item";
import { EquippableItem } from "../EquippableItem";

class WoodenSandals extends EquippableItem {
  getId(): string {
    return "woodenSandals";
  }

  getPicture() {
    return require("./woodenSandals.png");
  }

  getBaseName(): string {
    return "Wooden Sandals";
  }

  getDescription(): string | undefined {
    return "A pair of uncomfortable, clunky sandals.";
  }

  getDefenseBonus(state: PlayerContextState, params: ItemParams): number {
    return 3;
  }

  getAttackDelayBonusPct(
    state: PlayerContextState,
    params: ItemParams,
  ): number {
    return 10;
  }

  getSlot(): EquipmentSlot {
    return EquipmentSlot.Accessory;
  }

  getSalePrice(): number {
    return 160;
  }

  getCraftingSchoolLevelRequirements() {
    return {
      Conjuration: 4,
    };
  }

  getCraftingMaterialsBase() {
    return {
      resources: { Wood: 750 },
      items: {},
    };
  }
}

export default new WoodenSandals();
