import React, { useContext, useMemo, useRef } from "react";
import { StyleSheet, View } from "react-native";
import { Card } from "react-native-elements";
import {
  allocateCreaturesToDungeonFloor,
  calculateCreatureCap,
  getFreeCreatures,
  isDungeonFloorValidForCreatures,
} from "../backend/exploration/Creatures";
import { getUnlockedDungeons } from "../backend/exploration/dungeons/Dungeons";
import { PlayerContext } from "../backend/PlayerContext";
import { formatNumber } from "../utils/FormattingUtils";
import { CreatureMessageLog } from "./creatures/CreatureMessageLog";
import ActionsContainer from "./utility/ActionsContainer";
import CategoryContainer from "./utility/CategoryContainer";
import { PlusMinusAllocator } from "./utility/PlusMinusAllocator";
import { Text } from "./utility/Text";

export function Creatures() {
  const playerContext = useContext(PlayerContext);
  const playerContextRef = useRef(playerContext);
  playerContextRef.current = playerContext;

  return useMemo(() => {
    const dungeons = getUnlockedDungeons(playerContext);
    const freeCreatures = getFreeCreatures(playerContext);
    const hasFreeCreatures = freeCreatures > 0;

    return (
      <View>
        <Card>
          <Card.Title>Dungeons</Card.Title>
          {dungeons.map((dungeon) => {
            const unlockedFloors = dungeon
              .getDungeonFloors()
              .filter((floor) =>
                isDungeonFloorValidForCreatures(playerContext, floor.getId()),
              );
            if (unlockedFloors.length === 0) {
              return null;
            }
            return (
              <CategoryContainer
                key={dungeon.getId()}
                title={dungeon.getName()}
              >
                {unlockedFloors.map((floor) => {
                  const current =
                    playerContext.creatures?.creatureAllocation?.[
                      floor.getId()
                    ] ?? 0;
                  return (
                    <View
                      key={floor.getId()}
                      style={[styles.row, styles.singleFloorContainer]}
                    >
                      <Text numberOfLines={2} style={styles.floorNameText}>
                        {floor.getFloorName()} (Lv.{floor.getBreedingLevel()})
                      </Text>
                      <View style={styles.row}>
                        <Text>{current}</Text>
                        <View style={styles.plusMinusAllocatorContainer}>
                          <PlusMinusAllocator
                            disableMinus={current === 0}
                            disablePlus={!hasFreeCreatures}
                            onPressMinus={() =>
                              playerContextRef.current.apply(
                                allocateCreaturesToDungeonFloor(
                                  floor.getId(),
                                  -1,
                                ),
                              )
                            }
                            onPressPlus={() =>
                              playerContextRef.current.apply(
                                allocateCreaturesToDungeonFloor(
                                  floor.getId(),
                                  1,
                                ),
                              )
                            }
                          />
                        </View>
                      </View>
                    </View>
                  );
                })}
              </CategoryContainer>
            );
          })}
        </Card>
        <View style={[styles.row, styles.totalCreaturesContainer]}>
          <Text>Creatures available: </Text>
          <Text>
            {formatNumber(freeCreatures)}/
            {formatNumber(playerContext.creatures.totalCreatures)}
          </Text>
        </View>
        <View style={[styles.row, styles.creatureCapacityContainer]}>
          <Text>Creature capacity: </Text>
          <Text>
            {formatNumber(playerContext.creatures.totalCreatures)}/
            {formatNumber(calculateCreatureCap(playerContext))}
          </Text>
        </View>
        <View>
          <ActionsContainer area="Creatures" />
        </View>
        <View style={styles.messageContainer}>
          <CreatureMessageLog />
        </View>
      </View>
    );
  }, [JSON.stringify(playerContext.creatures)]);
}

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  plusMinusAllocatorContainer: {
    marginLeft: 16,
  },
  totalCreaturesContainer: {
    marginTop: 16,
    paddingHorizontal: 16,
  },
  creatureCapacityContainer: {
    marginTop: 4,
    paddingHorizontal: 16,
  },
  singleFloorContainer: {
    marginVertical: 4,
  },
  messageContainer: {
    height: 300,
    padding: 8,
    borderColor: "#444",
    borderWidth: 1,
    marginTop: 8,
  },
  floorNameText: {
    flex: 1,
  },
});
