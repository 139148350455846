import { applyTransformations } from "../calculation/Calculation";
import { TransformationTags } from "../calculation/TransformationTags";
import { PlayerContextState, PlayerContextTransform } from "../PlayerContext";
import { meetsRequirements, School } from "../spells/ElementsAndSchools";
import { Spell } from "../spells/Spell";
import { getSpellByName } from "../spells/Spells";
import { registerTimeTickListener } from "../timetick/TimeTick";

function onTick(
  state: PlayerContextState,
  elapsedTimeSec: number,
): PlayerContextState {
  if (state.autocast.apprenticesPaused) {
    return state;
  }

  const apprenticeAllocation = getApprenticeAllocation(state);
  for (let spellName in apprenticeAllocation) {
    const allocatedApprentices = apprenticeAllocation[spellName] || 0;
    if (allocatedApprentices > 0) {
      // Cast once per minute per apprentice
      const baseThreshold = applyTransformations(
        [TransformationTags.AutocastDelay],
        state,
        60.0,
      );
      const toAdd = (elapsedTimeSec / baseThreshold) * allocatedApprentices;
      const currentProgress = getAutocastProgressForSpell(state, spellName);
      const totalProgress = toAdd + currentProgress;
      state.autocast.spellAutocastProgress[spellName] = totalProgress;
      if (totalProgress > 1.0) {
        // Time to cast! (if possible)
        const spell = getSpellByName(spellName);
        if (spell && spell.isEnabled(state)) {
          state.autocast.spellAutocastProgress[spellName] = 0.0;
          state = spell.doAction({ isAutomatic: true }, state);
        }
      }
    }
  }
  return state;
}

export function getApprenticeAllocation(
  state: PlayerContextState,
): Record<string, number> {
  return state?.autocast?.apprenticeAllocation || {};
}

function getAutocastProgressForSpell(
  state: PlayerContextState,
  spellName: string,
): number {
  return state.autocast.spellAutocastProgress?.[spellName] ?? 0.0;
}

export function getAutocastSchoolLevelRequirements(
  spell: Spell,
): Record<School, number> {
  const schoolRequirements = spell.getSchoolLevelRequirements();
  let autocastSchoolLevelRequirements: Record<School, number> = {
    Conjuration: 0,
    Enchantment: 0,
    Illusion: 0,
    Alchemy: 0,
    Evocation: 0,
    Protection: 0,
    Breeding: 0,
    Summoning: 0,
    Divination: 0,
  };
  for (let school in schoolRequirements) {
    autocastSchoolLevelRequirements[school as School] =
      (schoolRequirements[school as School] || -1) + 1;
  }
  return autocastSchoolLevelRequirements;
}

export function canAutocast(state: PlayerContextState, spell: Spell): boolean {
  return meetsRequirements(state, getAutocastSchoolLevelRequirements(spell));
}

export function calculateApprenticeTotal(state: PlayerContextState): number {
  return applyTransformations([TransformationTags.Apprentice], state, 0);
}

export function getFreeApprentices(state: PlayerContextState): number {
  const apprenticeAllocation = getApprenticeAllocation(state);
  let apprenticesAllocated = 0;
  for (let spellName in apprenticeAllocation) {
    const spell = getSpellByName(spellName);
    if (spell && spell.isVisible(state)) {
      apprenticesAllocated += apprenticeAllocation[spellName];
    }
  }
  return calculateApprenticeTotal(state) - apprenticesAllocated;
}

function allocateApprenticesToSpellImpl(
  spell: Spell,
  amount: number,
  state: PlayerContextState,
) {
  if (!canAutocast(state, spell)) {
    return state;
  }

  const spellName = spell.getSpellName();
  const currentAllocation = getApprenticeAllocation(state)?.[spellName] || 0;
  const actualAmountToAllocate =
    amount > 0
      ? Math.min(getFreeApprentices(state), amount)
      : Math.max(-currentAllocation, amount);
  state.autocast.apprenticeAllocation[spellName] =
    currentAllocation + actualAmountToAllocate;
  return state;
}

export function allocateApprenticesToSpell(
  this: any,
  spell: Spell,
  amount: number,
): PlayerContextTransform {
  // Do it this way to avoid creating extra functions and avoid rerenders
  return allocateApprenticesToSpellImpl.bind(this, spell, amount);
}

export function saveLoadoutToPosition(
  state: PlayerContextState,
  position: number,
): PlayerContextState {
  const allocation = JSON.parse(JSON.stringify(getApprenticeAllocation(state)));
  state.autocast.loadouts[position] = allocation;
  state.autocast.lastLoadoutUsed = position;
  return state;
}

export function loadLoadoutFromPosition(
  state: PlayerContextState,
  position: number,
): PlayerContextState {
  const allocation = JSON.parse(
    JSON.stringify(state?.autocast?.loadouts?.[position] || {}),
  );
  state.autocast.apprenticeAllocation = allocation;
  state.autocast.lastLoadoutUsed = position;
  return state;
}

export function loadAutocast(): void {
  registerTimeTickListener("Autocast", onTick);
}
