import { PlayerContextState } from "../../../PlayerContext";
import woolFarmExpansion from "../../../upgrades/other/WoolFarmExpansion";
import EnchantedSheep from "../../enemies/thundertower/EnchantedSheep";
import { DungeonFloor, encounterEnemy } from "../DungeonFloor";
import TheSpecialStockade from "./TheSpecialStockade";

class TheMagicalPen extends DungeonFloor {
  getId(): string {
    return "theMagicalPen";
  }

  getFloorName(): string {
    return "The Magical Pen";
  }

  getExplorationRequiredTimeSec(): number {
    return 10.0;
  }

  _getExplorationSuccessesForBoss(): number {
    return 0;
  }

  getBoss() {
    return undefined;
  }

  getBreedingLevel(): number {
    return 28;
  }

  isUnlocked(state: PlayerContextState): boolean {
    return woolFarmExpansion.isPurchased(state);
  }

  getExplorationPossibilities(state: PlayerContextState) {
    return [
      {
        weight: 100,
        transforms: encounterEnemy(EnchantedSheep),
      },
    ];
  }

  getNextFloor() {
    return TheSpecialStockade;
  }
}

export default new TheMagicalPen();
