import { buildEvent } from "../../../events/EventMessageBuilder";
import { triggerEvent } from "../../../events/Events";
import { hasFlag, setFlag } from "../../../Flags";
import { PlayerContextState } from "../../../PlayerContext";
import { setDungeonFloorCompleted } from "../../dungeons/Dungeons";
import { standardEnemyAttackEffect } from "../../Exploration";
import { BattlerAction, BattlerStats, Enemy } from "../Enemy";

class Skeleton extends Enemy {
  getId(): string {
    return "skeleton";
  }

  getName(): string {
    return "Skeleton";
  }

  getPicture() {
    return require("./skeleton.png");
  }

  getBaseStats(): BattlerStats {
    return {
      maxHP: 500,
      defense: 6,
      attack: 19,
      attackDelay: 3.0,
      dodgeChance: 0,
      critChance: 0,
    };
  }

  getNextAction(state: PlayerContextState): BattlerAction {
    const strongBlowChance = 0.5;
    if (Math.random() < strongBlowChance) {
      return {
        name: "Strong Blow",
        delay: this.getAttackDelay(state) * 2,
        transform: (state: PlayerContextState) =>
          standardEnemyAttackEffect(state, 1.0, this.getAttack(state) * 2),
      };
    }
    return {
      name: "Attack",
      delay: this.getAttackDelay(state),
      transform: (state: PlayerContextState) =>
        standardEnemyAttackEffect(state, 1.0, this.getAttack(state)),
    };
  }

  getCoinsAwarded(state: PlayerContextState): number {
    return 175;
  }

  getMonstiumAwarded(state: PlayerContextState): number {
    return 15;
  }

  getItemsAwarded() {
    return [{ itemId: "medicinalHerb", chance: 0.8, amount: 1 }];
  }

  isBoss(): boolean {
    return true;
  }

  onDeath(
    state: PlayerContextState,
    isFromCreatures: boolean,
  ): PlayerContextState {
    state = super.onDeath(state, isFromCreatures);
    state = setDungeonFloorCompleted(state, "schoolBasementB1");
    if (!hasFlag(state, "skeleton_defeated_event_triggered")) {
      state = triggerEvent(skeletonDefeatedEvent)(state);
    }
    return setFlag("school_basement_b2_unlocked")(state);
  }
}

const skeletonDefeatedEventBuilder = buildEvent(
  "skeleton_defeated_event",
  "The Frontier of Danger",
)
  .setOnTrigger(setFlag("skeleton_defeated_event_triggered"))
  .createMessage()
  .setSimpleDescription(`As you manage to defeat the powerful Skeleton, you notice what it was guarding all along: a staircase down to what seems to be... another floor? What could possibly be down here?
  
The mysteries abound, and you recall what your researcher told you before. The event called Tournament of Magic. You still feel very far away from that, but through this adventure, you have already managed to discover an entire school of magic. You realize that this might just be the best way forward to actually be able to participate in the event. You arm yourself with more courage before moving on into the unknown...

**You can now enter the School Basement B2! You can access this floor by pressing the "Next Floor" button in the Exploration screen.**`);

const skeletonDefeatedEvent = skeletonDefeatedEventBuilder.build();

export default new Skeleton();
