import { DataStore } from "../generic/DataStore";
import { PlayerContextState } from "../PlayerContext";

/**
 * AttackListeners are a generic way to add effects or actions when
 * an auto-attack gauge is full.
 */

export type AttackListener = (
  oldState: PlayerContextState,
  newState: PlayerContextState,
) => PlayerContextState;

const PlayerAttackListeners = new DataStore<AttackListener>();
const EnemyAttackListeners = new DataStore<AttackListener>();
const SummonAttackListeners = new DataStore<AttackListener>();

export { PlayerAttackListeners, EnemyAttackListeners, SummonAttackListeners };
