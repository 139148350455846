import { PlayerContextState } from "../../../PlayerContext";
import { standardEnemyAttackEffect } from "../../Exploration";
import { BattlerAction, BattlerStats, Enemy } from "../Enemy";

class AngrySheep extends Enemy {
  getId(): string {
    return "angrySheep";
  }

  getName(): string {
    return "Angry Sheep";
  }

  getPicture() {
    return require("./angrySheep.png");
  }

  getBaseStats(): BattlerStats {
    return {
      maxHP: 120,
      defense: 4,
      attack: 15,
      attackDelay: 4.0,
      dodgeChance: 0,
      critChance: 0,
    };
  }

  getNextAction(state: PlayerContextState): BattlerAction {
    const doNothingChance = 0.25;
    if (Math.random() < doNothingChance) {
      return {
        name: "Hop Around",
        delay: this.getAttackDelay(state),
        transform: (state: PlayerContextState) => {
          return state;
        },
      };
    }
    const chargeChance = 0.1;
    if (Math.random() < chargeChance) {
      return {
        name: "Charge!",
        delay: this.getAttackDelay(state) * 1.5,
        transform: (state: PlayerContextState) =>
          standardEnemyAttackEffect(state, 1.0, this.getAttack(state) * 3),
      };
    }
    return {
      name: "Attack",
      delay: this.getAttackDelay(state),
      transform: (state: PlayerContextState) =>
        standardEnemyAttackEffect(state, 1.0, this.getAttack(state)),
    };
  }

  getCoinsAwarded(state: PlayerContextState): number {
    return 110;
  }

  getMonstiumAwarded(state: PlayerContextState): number {
    return 8;
  }

  getItemsAwarded() {
    return [
      { itemId: "medicinalHerb", chance: 0.1, amount: 1 },
      { itemId: "wool", chance: 1.0, amount: 1 },
    ];
  }
}

export default new AngrySheep();
