import Demo from "../backend/Demo";
import { getCurrentEnemy } from "../backend/exploration/enemies/Enemies";
import { Enemy } from "../backend/exploration/enemies/Enemy";
import {
  getCurrentPlayerHP,
  getExplorationStatus,
} from "../backend/exploration/Exploration";
import { ExplorationStatus } from "../backend/exploration/ExplorationStatus";
import { getFirstHintIndex } from "../backend/hints/Hints";
import { EquipmentSlot, getEquippedItem } from "../backend/items/Equipment";
import { PlayerContextState } from "../backend/PlayerContext";
import { addContextListener } from "../backend/PlayerContextListeners";
import { registerRetirementListener } from "../backend/Retire";
import {
  getSchoolLevel,
  getUnlockedSchools,
} from "../backend/spells/ElementsAndSchools";
import { Storyline, Storylines } from "../backend/storylines/Storylines";
import {
  getSecondsPlayed,
  getTotalSecondsPlayed,
} from "../backend/timetick/TotalTimePlayed";
import { sendAnalyticsEventAsync } from "./Analytics";

function getBaseLogData(state: PlayerContextState): { [key: string]: any } {
  return {
    primaryElement: state.primaryElement,
    primarySchool: state.primarySchool,
    secondsPlayed: getSecondsPlayed(state),
    totalSecondsPlayed: getTotalSecondsPlayed(state),
    currentPrimaryLevel:
      state.primarySchool != null
        ? getSchoolLevel(state, state.primarySchool)
        : 0,
    totalRetirements: state.global.totalTimesResetted,
    currentHint: getFirstHintIndex(state),
    isDemo: Demo.isDemo(),
  };
}

export async function logAnalyticsEventGeneric(
  state: PlayerContextState,
  eventName: string,
  params: { [key: string]: any },
) {
  if (!state.global.allowAnalytics) {
    return;
  }
  const baseLogData = getBaseLogData(state);
  return await sendAnalyticsEventAsync(eventName, {
    ...baseLogData,
    ...params,
  });
}

export async function logStorylineCompleted(
  state: PlayerContextState,
  storyline: Storyline,
) {
  return await logAnalyticsEventGeneric(state, "storyline_completed", {
    storyline: storyline.getId(),
  });
}

export async function logRetirement(state: PlayerContextState) {
  return await logAnalyticsEventGeneric(state, "retirement", {});
}

export async function logNewHint(state: PlayerContextState, hintIdx: string) {
  return await logAnalyticsEventGeneric(state, "new_hint_reached", { hintIdx });
}

function getCombatDimensions(state: PlayerContextState) {
  return {
    ...Object.fromEntries(
      getUnlockedSchools(state).map((school) => [
        "school" + school + "Level",
        getSchoolLevel(state, school),
      ]),
    ),
    handEquipment: getEquippedItem(state, EquipmentSlot.Hand)?.itemId ?? "none",
    bodyEquipment: getEquippedItem(state, EquipmentSlot.Body)?.itemId ?? "none",
    accessoryEquipment:
      getEquippedItem(state, EquipmentSlot.Accessory)?.itemId ?? "none",
  };
}

export async function logExplorationVictory(
  state: PlayerContextState,
  enemy: Enemy,
) {
  return await logAnalyticsEventGeneric(state, "exploration_victory", {
    enemy: enemy.getId(),
    ...getCombatDimensions(state),
  });
}

export async function logExplorationDefeat(
  state: PlayerContextState,
  enemy: Enemy,
) {
  return await logAnalyticsEventGeneric(state, "exploration_defeat", {
    enemy: enemy.getId(),
    ...getCombatDimensions(state),
  });
}

export function loadAnalytics() {
  addContextListener("analytics_context_listener", (oldState, newState) => {
    const allStorylines = Storylines.getAll();
    allStorylines.forEach((storyline) => {
      if (!storyline.isCompleted(oldState) && storyline.isCompleted(newState)) {
        logStorylineCompleted(newState, storyline);
      }
    });

    if (
      Number.parseInt(getFirstHintIndex(oldState) ?? "0") <
      Number.parseInt(getFirstHintIndex(newState) ?? "0")
    ) {
      logNewHint(newState, getFirstHintIndex(newState) ?? "0");
    }

    if (
      getExplorationStatus(oldState) === ExplorationStatus.Combat &&
      getExplorationStatus(newState) !== ExplorationStatus.Combat
    ) {
      // We exitted combat
      const enemy = getCurrentEnemy(oldState);
      if (enemy.isBoss()) {
        if (getCurrentPlayerHP(newState) > 0) {
          logExplorationVictory(newState, enemy);
        } else {
          logExplorationDefeat(newState, enemy);
        }
      }
    }
  });
  registerRetirementListener("analytics_retirement", (state, isMock) => {
    if (!isMock) {
      logRetirement(state);
    }
    return state;
  });
}
