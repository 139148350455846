import { formatNumber } from "../../../utils/FormattingUtils";
import { ActionEffect } from "../../actions/Action";
import {
  registerTransformation,
  TransformationType,
} from "../../calculation/Calculation";
import { TransformationTags } from "../../calculation/TransformationTags";
import {
  buildEvent,
  buildEventMessage,
} from "../../events/EventMessageBuilder";
import { triggerEvent } from "../../events/Events";
import { createGameEvent } from "../../events/SimpleGameEvent";
import { hasFlag, setFlag } from "../../Flags";
import { PlayerContextState } from "../../PlayerContext";
import { addContextListener } from "../../PlayerContextListeners";
import { getTotalElementAndSchoolExp } from "../../spells/ElementsAndSchools";
import { BuildingActionBase } from "../Building";
import { getBuildingAmount } from "../Buildings";

class ApprenticeDorm extends BuildingActionBase {
  constructor() {
    super();
  }

  isVisible(state: PlayerContextState): boolean {
    return hasFlag(state, "apprentice_dorm_unlocked");
  }

  getBuildingName(): string {
    return "Apprentice Dorm";
  }

  getArea(): string {
    return "Campus";
  }

  getDisplayDescription(): string {
    return "Housing for apprentices, which can help cast spells.";
  }

  getBaseActionEffects(): Record<string, ActionEffect> {
    return {
      apprentices: { value: 1 },
    };
  }

  getDisplayEffect(state: PlayerContextState): string {
    return `+${formatNumber(
      this.getActionEffects(state).apprentices,
    )} Max Apprentices`;
  }

  getBaseResourceCost(): Record<string, number> {
    return {
      Coins: 300,
      Wood: 480,
      Stone: 240,
    };
  }

  getBaseResourceScale(): Record<string, number> {
    return {
      Coins: 1.5,
      Wood: 1.5,
      Stone: 1.5,
    };
  }
}

const apprenticeDorm = new ApprenticeDorm();

registerTransformation(
  [[TransformationTags.Apprentice]],
  "ApprenticeDormApprentice",
  TransformationType.Addition,
  (state) =>
    getBuildingAmount(state, apprenticeDorm) *
    apprenticeDorm.getActionEffects(state).apprentices,
);

const message = buildEventMessage(
  "apprentice_dorm_unlocked",
  "Apprentices Need Sleep",
)
  .setSimpleDescription(
    `Your researcher walks up to you. She is together with a shaky individual, who seems to be barely breaking into adulthood and appears scared at your sight.

"I have a great idea!", she says. "This is my cousin. He is a wizard-in-training... an apprentice wizard," she continues, while gazing at him, before looking at you once more.`,
  )
  .addOption(
    "Continue",
    buildEventMessage("apprentice_dorm_unlocked_1", "Apprentices Need Sleep")
      .setSimpleDescription(
        `"It is a honor to meet you, headmaster," exclaims the apprentice, while bowing at you somewhat too deeply.

The researcher continues. "It'd be great to have him stay here! He could help you cast spells and continue improving our campus. Besides, a magical institution makes no sense without people who'd like to learn. What do you think?", she asks you.

You recall thinking about how tedious it was to cast spells. This could be a big help!`,
      )
      .addOption(
        "Continue",
        buildEventMessage(
          "apprentice_dorm_unlocked_2",
          "Apprentices Need Sleep",
        )
          .setSimpleDescription(
            `"There's just a little problem," the researcher continues. "There is nowhere for him to sleep in! We'll need to build something."
            
You saw it coming. Still, you can't wait - you've had enough casting *Create Pebble* manually. You reassure the researcher and her cousin that you will think about it, and you walk away, starting to picture what you would need to construct suitable housing for your first apprentice.

**You can now build Apprentice Dorms!**`,
          )
          .build(),
        setFlag("apprentice_dorm_unlocked"),
      )
      .build(),
  )
  .build();

const event = createGameEvent(
  "apprentice_dorm_unlocked",
  message,
  setFlag("apprentice_dorm_event_triggered"),
);

addContextListener("apprentice_dorm_event_triggered", (oldState, newState) => {
  if (
    !hasFlag(newState, "apprentice_dorm_event_triggered") &&
    hasFlag(newState, "autocast_intro_triggered") &&
    getTotalElementAndSchoolExp(newState) >= 3000
  ) {
    return triggerEvent(event);
  }
});

const tournamentEventBuilder = buildEvent(
  "tournament_of_magic_intro",
  "The Tournament of Magic",
);

tournamentEventBuilder
  .setOnTrigger(setFlag("tournament_of_magic_intro_event_triggered"))
  .createMessage()
  .setSimpleDescription(`Your researcher approaches you and mentions something to you.
  
"Headmaster, I think you may already be aware, but - now that we have a proper, true magical institution, we should start thinking about the *Tournament of Magic*." You are not sure of what she means by that, but she continues: "The Tournament of Magic is a competition between magical institutions. It is a major event in regards to the research of magic, and those who manage to compete in it - let alone the winner - are sure to get great fame and prosperity."

"It could really take our school to new heights!", she exclaims.

You talk to her some more, and you start feeling like it is a great goal to have... though you know you are nowhere close to reaching it. At least for now...`);

const tournamentEvent = tournamentEventBuilder.build();

addContextListener(
  "tournament_of_magic_intro_event_triggered",
  (oldState, newState) => {
    if (
      !hasFlag(newState, "tournament_of_magic_intro_event_triggered") &&
      hasFlag(newState, "autocast_unlocked") &&
      getTotalElementAndSchoolExp(newState) >= 8000
    ) {
      return triggerEvent(tournamentEvent);
    }
  },
);

const messageBuilt = buildEventMessage(
  "autocast_unlocked",
  "The First Apprentice",
)
  .setSimpleDescription(
    `Construction for the first apprentice dorm is finished! You celebrate to a small audience. You notice the apprentice from before is still there. You approach him, though he still seems fearful and bows deeply.
    
"Is that... housing I could use?", he says, with a timid voice. You nod, and ask him if he'd like to become your first apprentice. He shakes, visibly and clearly nervous.

"That would be great... I-It's a honor, to be able to learn from one such as you, headmaster. I'll move in right away."

**You have unlocked Apprentices! Apprentices can automatically cast spells for you after a set amount of time. You may manage them in the Apprentices section, in the left-hand-side menu.**`,
  )
  .build();

const eventBuilt = createGameEvent(
  "autocast_unlocked",
  messageBuilt,
  setFlag("autocast_unlocked"),
);

addContextListener("autocast_unlocked", (oldState, newState) => {
  if (
    !hasFlag(newState, "autocast_unlocked") &&
    getBuildingAmount(newState, apprenticeDorm) > 0
  ) {
    return triggerEvent(eventBuilt);
  }
});

export default apprenticeDorm as ApprenticeDorm;
