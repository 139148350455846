import HireResearcher from "../../actions/campus/HireResearcher";
import {
  registerTransformation,
  TransformationType,
} from "../../calculation/Calculation";
import { TransformationTags } from "../../calculation/TransformationTags";
import { PlayerContextState } from "../../PlayerContext";
import { Upgrade } from "../Upgrade";
import researchFair from "./ResearchFair";

class ResearchPublication extends Upgrade {
  getName(): string {
    return "Research Publication";
  }

  getMonstiumCost(): number {
    return 4250;
  }

  getDisplayDescription(state: PlayerContextState): string {
    return "Publish the results of your research to increase the prestige of your institution.";
  }

  getDisplayEffect(state: PlayerContextState): string {
    return "Reduces the cost to hire researchers.";
  }

  getUpgradeRequirements() {
    return [researchFair];
  }
}

const researchPublication = new ResearchPublication();

registerTransformation(
  [[HireResearcher.getName(), TransformationTags.CostScale]],
  "ResearchAwardsCostScale",
  TransformationType.Multiplier,
  (state) => (researchPublication.isPurchased(state) ? 2.0 / 3.0 : 1.0),
);

export default researchPublication;
