import {
  applyTransformations,
  registerTransformation,
  TransformationType,
} from "../calculation/Calculation";
import { TransformationTags } from "../calculation/TransformationTags";
import { addContextListener } from "../PlayerContextListeners";
import {
  clearTemporaryEffect,
  grantTemporaryEffect,
  hasTemporaryEffect,
} from "../timetick/TemporaryEffects";
import { AttackTarget } from "./AttackTarget";
import { CombatStat } from "./CombatStats";
import { getExplorationStatus, resetEnemyNextAction } from "./Exploration";
import { ExplorationStatus } from "./ExplorationStatus";

export function loadStunning() {
  // While stunned, enemies and players both have a giant attack delay
  registerTransformation(
    [[AttackTarget.Player, CombatStat.AttackDelay]],
    "PlayerStunAttackDelay",
    TransformationType.Addition,
    (state) => (hasTemporaryEffect(state, "PlayerStun") ? +Infinity : 0),
  );
  registerTransformation(
    [[AttackTarget.Enemy, CombatStat.AttackDelay]],
    "EnemyStunAttackDelay",
    TransformationType.Addition,
    (state) => (hasTemporaryEffect(state, "EnemyStun") ? +Infinity : 0),
  );

  addContextListener("PlayerStunning", (oldState, newState) => {
    if (
      hasTemporaryEffect(newState, "PlayerStun") &&
      !hasTemporaryEffect(oldState, "PlayerStun")
    ) {
      // Player's been stunned, but does it have temporary immunity?
      if (hasTemporaryEffect(newState, "PlayerStunPrevention")) {
        return clearTemporaryEffect("PlayerStun");
      }

      // Player's stun has been set. Set attack timer start time to estimated stun end
      // so that on heal we don't immediately attack.
      return (state) => {
        state.exploration.playerActionProgress = 0.0;
        return state;
      };
    }
  });

  addContextListener("PlayerStunningRecovery", (oldState, newState) => {
    if (
      !hasTemporaryEffect(newState, "PlayerStun") &&
      hasTemporaryEffect(oldState, "PlayerStun") &&
      !hasTemporaryEffect(newState, "PlayerStunPrevention")
    ) {
      // Player's stun has run out, so restart the attack timer and give stun prevention
      return (state) => {
        state.exploration.playerActionProgress = 0.0;
        state = grantTemporaryEffect(
          "PlayerStunPrevention",
          "Stun Protection",
          applyTransformations(
            [
              AttackTarget.Player,
              TransformationTags.PostStunProtectionDuration,
            ],
            newState,
            6.0,
          ),
          { combatOnly: true },
        )(state);
        return state;
      };
    }
  });

  addContextListener("EnemyStunning", (oldState, newState) => {
    if (
      hasTemporaryEffect(newState, "EnemyStun") &&
      !hasTemporaryEffect(oldState, "EnemyStun")
    ) {
      // Enemy's been stunned, but does it have temporary immunity?
      if (hasTemporaryEffect(newState, "EnemyStunPrevention")) {
        return clearTemporaryEffect("EnemyStun");
      }

      // Enemy's stun has been set. Set attack timer start time to estimated stun end
      // so that on heal we don't immediately attack.
      return (state) => {
        state.exploration.enemyActionProgress = 0.0;
        return state;
      };
    }
  });

  addContextListener("EnemyStunningRecovery", (oldState, newState) => {
    if (
      !hasTemporaryEffect(newState, "EnemyStun") &&
      hasTemporaryEffect(oldState, "EnemyStun") &&
      !hasTemporaryEffect(newState, "EnemyStunPrevention")
    ) {
      // Enemy's stun has run out, so restart the attack timer
      return (state) => {
        resetEnemyNextAction();
        state.exploration.enemyActionProgress = 0.0;
        if (getExplorationStatus(state) === ExplorationStatus.Combat) {
          state = grantTemporaryEffect(
            "EnemyStunPrevention",
            "Stun Protection",
            applyTransformations(
              [
                AttackTarget.Enemy,
                TransformationTags.PostStunProtectionDuration,
              ],
              newState,
              6.0,
            ),
            { combatOnly: true, isEnemyTemporaryEffect: true },
          )(state);
        }
        return state;
      };
    }
  });
}
