import { PlayerContextState } from "../../../PlayerContext";
import { EquipmentSlot } from "../../Equipment";
import { ItemParams } from "../../Item";
import { EquippableItem } from "../EquippableItem";

class Sickle extends EquippableItem {
  getId(): string {
    return "sickle";
  }

  getPicture() {
    return require("./sickle.png");
  }

  getBaseName(): string {
    return "Sickle";
  }

  getDescription(): string | undefined {
    return "A weapon often used by farmers... or by reapers.";
  }

  getAttackBonus(state: PlayerContextState, params: ItemParams): number {
    return 45;
  }

  getSlot(): EquipmentSlot {
    return EquipmentSlot.Hand;
  }

  getSalePrice(): number {
    return 2400;
  }

  getCraftingSchoolLevelRequirements() {
    return { Conjuration: 9 };
  }

  getCraftingMaterialsBase() {
    return {
      resources: {
        Iron: 1100,
        Wood: 3000,
        Temperature: 1500,
      },
      items: {},
    };
  }
}

export default new Sickle();
