import React, { useContext, useMemo } from "react";
import { StyleSheet, View } from "react-native";
import ProgressBar from "react-native-progress/Bar";
import {
  getCurrentEnemyAction,
  getEnemyActionProgress,
} from "../../backend/exploration/Exploration";
import { PlayerContext } from "../../backend/PlayerContext";
import { GameIcon } from "../utility/icons/GameIcon";
import { Markdown } from "../utility/Markdown";
import { Text } from "../utility/Text";

export function EnemyActionDisplay() {
  const playerContext = useContext(PlayerContext);
  const currentEnemyAction = getCurrentEnemyAction(playerContext);
  const progress = getEnemyActionProgress(playerContext);
  return useMemo(() => {
    if (!currentEnemyAction) {
      return (
        <View>
          <Text h3>-</Text>
          <ProgressBar progress={0} useNativeDriver={true} />
        </View>
      );
    }
    return (
      <View style={styles.row}>
        <GameIcon icon="attack" style={styles.icon} />
        <View style={styles.progressBarContainer}>
          <Markdown>{currentEnemyAction.name}</Markdown>
          <ProgressBar
            progress={progress}
            useNativeDriver={true}
            width={null}
          />
        </View>
      </View>
    );
  }, [currentEnemyAction?.name, progress]);
}

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  icon: {
    marginRight: 4,
  },
  progressBarContainer: {
    flex: 1,
    height: 32,
  },
});
