import React from "react";
import { StyleSheet, View } from "react-native";
import ActionsContainer from "./utility/ActionsContainer";

export default function Campus() {
  return (
    <View style={styles.container}>
      <ActionsContainer area="Campus" />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "stretch",
    justifyContent: "flex-start",
    width: "100%",
  },
});
