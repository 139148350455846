import { formatNumber } from "../../../utils/FormattingUtils";
import { ActionEffect } from "../../actions/Action";
import {
  registerTransformation,
  TransformationType,
} from "../../calculation/Calculation";
import { TransformationTags } from "../../calculation/TransformationTags";
import { PlayerContextState } from "../../PlayerContext";
import { addContextListener } from "../../PlayerContextListeners";
import { recalculateCaps, Resource } from "../../Resources";
import manaCrystals from "../../upgrades/storage/ManaCrystals";
import { BuildingActionBase } from "../Building";
import { buildingAmountsChanged, getBuildingAmount } from "../Buildings";

class ManaCrystal extends BuildingActionBase {
  constructor() {
    super();
  }

  isVisible(state: PlayerContextState): boolean {
    return manaCrystals.isPurchased(state);
  }

  getBuildingName(): string {
    return "Mana Crystal";
  }

  getArea(): string {
    return "Campus";
  }

  getDisplayDescription(): string {
    return "A larger version of a Mana Shard. Increases the amount of Mana you can store.";
  }

  protected getBaseActionEffects(): Record<string, ActionEffect> {
    return {
      mana: {
        value: 200,
        tags: [Resource.Mana, TransformationTags.PerBuildingCap],
      },
    };
  }

  getDisplayEffect(state: PlayerContextState): string {
    const effects = this.getActionEffects(state);
    return `+${formatNumber(effects.mana)} Max:mana:`;
  }

  getBaseResourceCost(): Record<string, number> {
    return {
      Coins: 10000,
      Stone: 8000,
      Iron: 1200,
    };
  }

  getBaseResourceScale(): Record<string, number> {
    return {
      Coins: 1.3,
      Stone: 1.3,
      Iron: 1.3,
    };
  }
}

const manaCrystal = new ManaCrystal();

registerTransformation(
  [[Resource.Mana, TransformationTags.Cap]],
  "ManaCrystalCap",
  TransformationType.Addition,
  (state) =>
    getBuildingAmount(state, manaCrystal) *
    manaCrystal.getActionEffects(state).mana,
);

addContextListener("mana_crystal_cap_listener", (oldState, newState) => {
  if (buildingAmountsChanged(oldState, newState, manaCrystal)) {
    return (state) => {
      return recalculateCaps(state);
    };
  }
});

export default manaCrystal as ManaCrystal;
