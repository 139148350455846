import { Divider } from "@rneui/themed";
import moment from "moment";
import React, { useContext, useRef } from "react";
import { StyleSheet, View } from "react-native";
import { getEnding, getEndingData } from "../backend/endings/Endings";
import { PlayerContext } from "../backend/PlayerContext";
import { Storylines } from "../backend/storylines/Storylines";
import { formatNumber, formatTimeLong } from "../utils/FormattingUtils";
import { Markdown } from "./utility/Markdown";
import { Text } from "./utility/Text";

type RowProps = {
  caption: string;
  value: string;
};

function Row(props: RowProps) {
  return (
    <View style={styles.row}>
      <Text>{props.caption}</Text>
      <Text style={styles.valueText}>{props.value}</Text>
    </View>
  );
}

export default function Ending() {
  const playerContext = useContext(PlayerContext);
  const playerContextRef = useRef(playerContext);
  playerContextRef.current = playerContext;

  const endingData = getEndingData(playerContext);
  if (endingData == null) {
    return <View />;
  }

  const ending = getEnding(endingData.endingId);

  const totalDuration = moment.duration(endingData.totalTimePlayed, "seconds");
  const thisRunDuration = moment.duration(
    endingData.totalTimePlayedThisRun,
    "seconds",
  );

  const allStorylines = Storylines.getAll();

  return (
    <View style={styles.container}>
      <Text h1>{ending?.title}</Text>
      <View style={styles.explanation}>
        <Markdown style={styles.explanationText}>
          {ending?.description}
        </Markdown>
      </View>
      <View style={styles.dividerContainer}>
        <Divider />
      </View>
      <View style={styles.congratsContainer}>
        <Text h1>Congratulations!</Text>
        <Text h2>You have cleared Magic Research!</Text>
      </View>
      <View>
        <Row
          caption="Primary School and Element:"
          value={`${endingData.primarySchool} - ${endingData.primaryElement}`}
        />
        <Row
          caption="Total In-Game Time Played:"
          value={formatTimeLong(totalDuration)}
        />
        <Row
          caption="In-Game Time Played (This Run):"
          value={formatTimeLong(thisRunDuration)}
        />
        <Row
          caption="Maximum Primary Level Obtained:"
          value={endingData.mpl.toString()}
        />
        <Row
          caption="Primary School Level on Game Beaten:"
          value={endingData.primarySchoolLevelOnEnding.toString()}
        />
        <Row
          caption="Global Research Multiplier:"
          value={`${formatNumber(endingData.researchMultiplier, {
            showDecimals: true,
          })}x`}
        />
        <Row
          caption="Global Production Multiplier:"
          value={`${formatNumber(endingData.productionMultiplier, {
            showDecimals: true,
          })}x`}
        />
        <Row
          caption="Total Completed Storylines:"
          value={`${endingData.completedStorylines} / ${allStorylines.length}`}
        />
        <Row
          caption="Storyline Completion Percentage:"
          value={
            formatNumber(
              (100 * endingData.completedStorylines) / allStorylines.length,
            ) + "%"
          }
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "stretch",
    justifyContent: "flex-start",
    width: "100%",
  },
  explanation: {
    marginTop: 16,
    flex: 1,
    alignItems: "stretch",
    width: "100%",
  },
  explanationText: {
    width: "100%",
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 4,
  },
  valueText: {
    minWidth: 100,
    textAlign: "right",
    fontWeight: "bold",
    marginLeft: 8,
  },
  dividerContainer: {
    marginTop: 32,
    marginBottom: 24,
  },
  congratsContainer: {
    alignItems: "center",
    marginBottom: 16,
  },
});
