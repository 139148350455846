import { formatNumber } from "../../../utils/FormattingUtils";
import { ActionEffect } from "../../actions/Action";
import {
  registerTransformation,
  TransformationType,
} from "../../calculation/Calculation";
import { TransformationTags } from "../../calculation/TransformationTags";
import { buildEventMessage } from "../../events/EventMessageBuilder";
import { triggerEvent } from "../../events/Events";
import { createGameEvent } from "../../events/SimpleGameEvent";
import { hasFlag, setFlag } from "../../Flags";
import { PlayerContextState } from "../../PlayerContext";
import { addContextListener } from "../../PlayerContextListeners";
import { getResourceAmount, recalculateCaps, Resource } from "../../Resources";
import { BuildingActionBase } from "../Building";
import { buildingAmountsChanged, getBuildingAmount } from "../Buildings";
import Warehouse from "./Warehouse";

class Vault extends BuildingActionBase {
  constructor() {
    super();
  }

  isVisible(state: PlayerContextState): boolean {
    return hasFlag(state, "vault_unlocked");
  }

  getBuildingName(): string {
    return "Vault";
  }

  getArea(): string {
    return "Campus";
  }

  getDisplayDescription(): string {
    return "Allows you to store more coins safely.";
  }

  protected getBaseActionEffects(): Record<string, ActionEffect> {
    return {
      coins: {
        value: 20000,
        tags: [Resource.Coins, TransformationTags.PerBuildingCap],
      },
    };
  }

  getDisplayEffect(state: PlayerContextState): string {
    const effects = this.getActionEffects(state);
    const resourceEffectMap: Partial<Record<Resource, string>> = {
      Coins: `+${formatNumber(effects.coins)} Max:coins:`,
    };
    return (Object.keys(resourceEffectMap) as Array<Resource>)
      .filter((resource) => state.resources?.[resource] !== undefined)
      .map((resource) => resourceEffectMap[resource])
      .join(", ");
  }

  getBaseResourceCost(): Record<string, number> {
    return {
      Coins: 500,
      Stone: 600,
    };
  }

  getBaseResourceScale(): Record<string, number> {
    return {
      Coins: 1.3,
      Stone: 1.3,
    };
  }
}

const vault = new Vault();

function calculateVaultCap(state: PlayerContextState) {
  return vault.getActionEffects(state).coins;
}

registerTransformation(
  [[Resource.Coins, TransformationTags.Cap]],
  "VaultCoinsCap",
  TransformationType.Addition,
  (state) => getBuildingAmount(state, vault) * calculateVaultCap(state),
);

addContextListener("vault_coins_cap_recalculation", (oldState, newState) => {
  if (buildingAmountsChanged(oldState, newState, vault)) {
    return (state) => {
      return recalculateCaps(state);
    };
  }
});

const message = buildEventMessage("vault_unlocked", "Coin Storage")
  .setSimpleDescription(
    `You are managing to stockpile large amounts of coins! It would be wise to find a place to put them in.
    
Warehouses have been useful; stone has proven to be a good material for tons of things. You think about using it for a specialized building for money storage. You are concerned about bandits, but are convinced it'll be worth it in the long run.

**You can now build Vaults!**`,
  )
  .build();

const event = createGameEvent(
  "vault_unlocked",
  message,
  setFlag("vault_unlocked"),
);

addContextListener("vault_unlocked", (oldState, newState) => {
  if (
    !hasFlag(newState, "vault_unlocked") &&
    getResourceAmount(newState, Resource.Coins) >= 800 &&
    getBuildingAmount(newState, Warehouse) >= 1
  ) {
    return triggerEvent(event);
  }
});

export default vault as Vault;
