import React from "react";
import { StyleSheet, View } from "react-native";
import { Button } from "react-native-elements";

type PlusMinusAllocatorProps = {
  disableMinus: boolean;
  disablePlus: boolean;
  onPressMinus: () => void;
  onPressPlus: () => void;
};

export function PlusMinusAllocator(
  props: PlusMinusAllocatorProps = {
    disableMinus: false,
    disablePlus: false,
    onPressMinus: () => {},
    onPressPlus: () => {},
  },
) {
  return (
    <View style={styles.row}>
      <Button
        containerStyle={styles.plusMinusAllocatorButton}
        style={styles.plusMinusAllocatorButtonInner}
        iconContainerStyle={styles.plusMinusAllocatorButtonInner}
        buttonStyle={styles.plusMinusAllocatorButtonInner}
        title="-"
        onPress={props.onPressMinus}
        disabled={props.disableMinus}
      />
      <Button
        containerStyle={styles.plusMinusAllocatorButton}
        style={styles.plusMinusAllocatorButtonInner}
        iconContainerStyle={styles.plusMinusAllocatorButtonInner}
        buttonStyle={styles.plusMinusAllocatorButtonInner}
        title="+"
        onPress={props.onPressPlus}
        disabled={props.disablePlus}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  plusMinusAllocatorButton: {
    height: 32,
    width: 32,
    borderRadius: 16,
    marginHorizontal: 4,
  },
  plusMinusAllocatorButtonInner: {
    paddingHorizontal: 0,
    paddingVertical: 0,
    height: 32,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
});
