import { formatNumber } from "../../../utils/FormattingUtils";
import { ActionEffect } from "../../actions/Action";
import {
  registerTransformation,
  TransformationType,
} from "../../calculation/Calculation";
import { TransformationTags } from "../../calculation/TransformationTags";
import { buildEventMessage } from "../../events/EventMessageBuilder";
import { triggerEvent } from "../../events/Events";
import { createGameEvent } from "../../events/SimpleGameEvent";
import { hasFlag, setFlag } from "../../Flags";
import { PlayerContextState } from "../../PlayerContext";
import { addContextListener } from "../../PlayerContextListeners";
import { getResourceAmount, recalculateCaps, Resource } from "../../Resources";
import { BuildingActionBase } from "../Building";
import { buildingAmountsChanged, getBuildingAmount } from "../Buildings";

class Warehouse extends BuildingActionBase {
  constructor() {
    super();
  }

  isVisible(state: PlayerContextState): boolean {
    return hasFlag(state, "warehouse_unlocked");
  }

  getBuildingName(): string {
    return "Warehouse";
  }

  getArea(): string {
    return "Campus";
  }

  getDisplayDescription(): string {
    return "Increases the maximum resources you can store.";
  }

  protected getBaseActionEffects(): Record<string, ActionEffect> {
    return {
      stone: {
        value: 400,
        tags: [Resource.Stone, TransformationTags.PerBuildingCap],
      },
      wood: {
        value: 400,
        tags: [Resource.Wood, TransformationTags.PerBuildingCap],
      },
      iron: {
        value: 200,
        tags: [Resource.Iron, TransformationTags.PerBuildingCap],
      },
    };
  }

  getDisplayEffect(state: PlayerContextState): string {
    const effects = this.getActionEffects(state);
    const resourceEffectMap: Partial<Record<Resource, string>> = {
      Stone: `+${formatNumber(effects.stone)} Max :stone:`,
      Wood: `+${formatNumber(effects.wood)} Max :wood:`,
      Iron: `+${formatNumber(effects.iron)} Max :iron:`,
    };
    return (Object.keys(resourceEffectMap) as Array<Resource>)
      .filter((resource) => state.resources?.[resource] !== undefined)
      .map((resource) => resourceEffectMap[resource])
      .join(", ");
  }

  getBaseResourceCost(): Record<string, number> {
    return {
      Coins: 200,
      Stone: 30,
    };
  }

  getBaseResourceScale(): Record<string, number> {
    return {
      Coins: 1.3,
      Stone: 1.3,
    };
  }
}

const warehouse = new Warehouse();

function calculateStoneCapIncrease(state: PlayerContextState) {
  return warehouse.getActionEffects(state).stone;
}

function calculateWoodCapIncrease(state: PlayerContextState) {
  return warehouse.getActionEffects(state).wood;
}

function calculateIronCapIncrease(state: PlayerContextState) {
  return warehouse.getActionEffects(state).iron;
}

registerTransformation(
  [[TransformationTags.Cap, Resource.Stone]],
  "WarehouseStoneCap",
  TransformationType.Addition,
  (state) =>
    getBuildingAmount(state, warehouse) * calculateStoneCapIncrease(state),
);

registerTransformation(
  [[TransformationTags.Cap, Resource.Wood]],
  "WarehouseWoodCap",
  TransformationType.Addition,
  (state) =>
    getBuildingAmount(state, warehouse) * calculateWoodCapIncrease(state),
);

registerTransformation(
  [[TransformationTags.Cap, Resource.Iron]],
  "WarehouseIronCap",
  TransformationType.Addition,
  (state) =>
    getBuildingAmount(state, warehouse) * calculateIronCapIncrease(state),
);

addContextListener("warehouse_caps_recalculation", (oldState, newState) => {
  if (buildingAmountsChanged(oldState, newState, warehouse)) {
    return (state) => {
      return recalculateCaps(state);
    };
  }
});

const message = buildEventMessage("warehouse_unlocked", "Storage Ideas")
  .setSimpleDescription(
    `Accumulating stone is worthwhile. As you watch your reserves getting closer to full, you start thinking if there might be a way to improve the situation.
    
Maybe you can use the stone to create more room? It seems like a smart, straightforward idea... you could make a stone warehouse.

**You can now build Warehouses!**`,
  )
  .build();

const event = createGameEvent(
  "warehouse_unlocked",
  message,
  setFlag("warehouse_unlocked"),
);

addContextListener("warehouse_unlocked", (oldState, newState) => {
  if (
    !hasFlag(newState, "warehouse_unlocked") &&
    getResourceAmount(newState, Resource.Stone) >= 30
  ) {
    return triggerEvent(event);
  }
});

// Frozen Warehouse storyline
// Intentionally commented out because balance is unclear

/*
const frozenWarehouseEventBuilder = buildEvent(
  "frozen_warehouse",
  "(Storyline) The Frozen Warehouse",
);

frozenWarehouseEventBuilder
  .setOnTrigger(setFlag("frozen_warehouse_event_triggered"))
  .createMessage()
  .setSimpleDescription(
    `Your warehouses look like they're full, but you remember this was not the case at some point. Gathering all those resources took some time and effort.
  
Wouldn't it be wonderful if others would never have to do that again? You have an idea about spatially freezing one of your warehouses. You're not sure if you would get any benefit, but you think about giving it a try...`,
  )
  .addOption(
    "Freeze a Warehouse (Lose 1 Warehouse, 2400 Mana, 400 Stone, 400 Wood, 200 Iron; Requires Lv12 Enchantment)",
    {
      transform: (state) => {
        state = grantResource(Resource.Mana, -2400)(state);
        state = grantResource(Resource.Stone, -400)(state);
        state = grantResource(Resource.Wood, -400)(state);
        state = grantResource(Resource.Iron, -200)(state);
        const buildingData = state.buildings[
          warehouse.getName()
        ] as BuildingData;
        buildingData.current -= 1;
        state = setGlobalFlagForNextRun("frozen_warehouse_finished")(state);
        return state;
      },
      isEnabled: (state) => {
        return (
          getSchoolLevel(state, School.Enchantment) >= 12 &&
          getBuildingAmount(state, warehouse) >= 1 &&
          getResourceAmount(state, Resource.Mana) >= 2400 &&
          getResourceAmount(state, Resource.Stone) >= 400 &&
          getResourceAmount(state, Resource.Wood) >= 400 &&
          getResourceAmount(state, Resource.Iron) >= 200
        );
      },
    },
    frozenWarehouseEventBuilder.createMessage()
      .setSimpleDescription(`You focus and prepare to cast the spell! A light blue light envelopes the building's walls and becomes more and more solid. The warehouse looks like it is encased by a magical barrier - and with it, its contents.
      
You will never be able to access that again, but maybe someone eventually will...?

**You have completed the "The Frozen Warehouse" storyline! In future runs, you will begin with 1 Warehouse unlocked - as well as 400 Stone, 400 Wood, and 200 Iron!**`)
  )
  .addOption("Maybe later");

const frozenWarehouseEvent = frozenWarehouseEventBuilder.build();

*/

export default warehouse as Warehouse;
