import { formatNumber } from "../../../utils/FormattingUtils";
import { ActionEffect } from "../../actions/Action";
import { TransformationTags } from "../../calculation/TransformationTags";
import { buildEventMessage } from "../../events/EventMessageBuilder";
import { triggerEvent } from "../../events/Events";
import { createGameEvent } from "../../events/SimpleGameEvent";
import { hasFlag, setFlag } from "../../Flags";
import { PlayerContextState } from "../../PlayerContext";
import { addContextListener } from "../../PlayerContextListeners";
import { getResourceAmount, grantResource, Resource } from "../../Resources";
import { SpellElement } from "../../spells/ElementsAndSchools";
import { registerTimeTickListener } from "../../timetick/TimeTick";
import { BuildingActionBase } from "../Building";
import { getBuildingAmount } from "../Buildings";

class LumberYard extends BuildingActionBase {
  constructor() {
    super();
  }

  isVisible(state: PlayerContextState): boolean {
    return hasFlag(state, "lumber_yard_unlocked");
  }

  getBuildingName(): string {
    return "Lumber Yard";
  }

  getArea(): string {
    return "Campus";
  }

  getDisplayDescription(): string {
    return "Processes branches and trees, generating wood automatically.";
  }

  protected getBaseActionEffects(): Record<string, ActionEffect> {
    return {
      wood: { value: 1, tags: [Resource.Wood, TransformationTags.Production] },
    };
  }

  getDisplayEffect(state: PlayerContextState): string {
    const effects = this.getActionEffects(state);
    return `+${formatNumber(effects.wood, { showDecimals: true })}:wood:/sec`;
  }

  getBaseResourceCost(): Record<string, number> {
    return {
      Mana: 200,
      Wood: 25,
      Stone: 10,
    };
  }

  getBaseResourceScale(): Record<string, number> {
    return {
      Mana: 1.0,
      Wood: 1.3,
      Stone: 1.3,
    };
  }
}

const lumberYard = new LumberYard();

function onTick(
  state: PlayerContextState,
  elapsedTimeSec: number,
): PlayerContextState {
  const lumberYards = getBuildingAmount(state, lumberYard);
  if (lumberYards === 0) {
    return state;
  }
  const effects = lumberYard.getActionEffects(state);
  return grantResource(
    Resource.Wood,
    lumberYards * effects.wood * elapsedTimeSec,
  )(state);
}

registerTimeTickListener("LumberYard", onTick);

const message = buildEventMessage("lumber_yard_unlocked", "Wood Processing")
  .setSimpleDescription(
    `These trips to the forest are starting to get tedious. You are starting to wonder if there is a way you could make it easier on yourself.
    
Perhaps there is a way? Maybe we can reuse the wood we obtained to make something bigger? You get lost in thought as you walk back to campus.

By the time you arrive, you have figured it out. Lumber yards right at the edge of the forest. It can surely work!

**You can now build Lumber Yards!**`,
  )
  .build();

const event = createGameEvent(
  "lumber_yard_unlocked",
  message,
  setFlag("lumber_yard_unlocked"),
);

addContextListener("lumber_yard_unlocked", (oldState, newState) => {
  if (
    !hasFlag(newState, "lumber_yard_unlocked") &&
    newState.primaryElement === SpellElement.Nature
  ) {
    return setFlag("lumber_yard_unlocked");
  }

  if (
    !hasFlag(newState, "lumber_yard_unlocked") &&
    getResourceAmount(newState, Resource.Wood) >= 50
  ) {
    return triggerEvent(event);
  }
});

export default lumberYard as LumberYard;
