import { formatNumber } from "../../../utils/FormattingUtils";
import { ActionEffect } from "../../actions/Action";
import {
  registerTransformation,
  TransformationType,
} from "../../calculation/Calculation";
import { TransformationTags } from "../../calculation/TransformationTags";
import { hasFlag } from "../../Flags";
import { PlayerContextState } from "../../PlayerContext";
import {
  grantTemporaryEffect,
  hasTemporaryEffect,
} from "../../timetick/TemporaryEffects";
import { SpellActionBase } from "../Spell";

class TimeDilation extends SpellActionBase {
  constructor() {
    super();
  }

  isVisible(state: PlayerContextState): boolean {
    return super.isVisible(state) && hasFlag(state, "exploration_unlocked");
  }

  getSpellName(): string {
    return "Time Dilation";
  }

  getArea(): string {
    return "Exploration";
  }

  getDisplayDescription(): string {
    return "Slow down the passage of time.";
  }

  protected getBaseActionEffects(): Record<string, ActionEffect> {
    return {
      duration: {
        value: 30,
        tags: [TransformationTags.TemporaryEffectDuration],
      },
    };
  }

  doSpellAction(state: PlayerContextState): PlayerContextState {
    const effects = this.getActionEffects(state);
    return grantTemporaryEffect(
      this.getId(),
      this.getSpellName(),
      effects.duration,
      {},
    )(state);
  }

  getDisplayEffect(state: PlayerContextState): string {
    const effects = this.getActionEffects(state);
    return `Time moves at 0.5x speed for ${formatNumber(effects.duration)} sec`;
  }

  getManaCost(): number {
    return 510;
  }

  getSchoolLevelRequirements(): Record<string, number> {
    return {
      Illusion: 14,
    };
  }
}

const timeDilation = new TimeDilation();

registerTransformation(
  [[TransformationTags.GameSpeed]],
  "TimeDilationGameSpeed",
  TransformationType.Multiplier,
  (state) => (hasTemporaryEffect(state, "Time Dilation") ? 0.5 : 1),
);

export default timeDilation;
