import { EndingStats, PlayerContextState } from "../PlayerContext";
import {
  getMaxOfMaxPrimarySchoolLevels,
  getSchoolLevel,
  maximumSchoolLevelProductionMultiplierDx,
  maximumSchoolLevelResearchMultiplier,
  School,
  SpellElement,
} from "../spells/ElementsAndSchools";
import { Storylines } from "../storylines/Storylines";
import {
  getSecondsPlayed,
  getTotalSecondsPlayed,
} from "../timetick/TotalTimePlayed";
import { addUICommand } from "../UICommands";

export type Ending = {
  id: string;
  title: string;
  description: string;
};

let endingDatabase: Record<string, Ending> = {};

export function clearAllEndings(): void {
  endingDatabase = {};
}

export function addEnding(id: string, title: string, contents: string): void {
  endingDatabase[id] = { id, description: contents, title };
}

export function getEnding(id: string): Ending | undefined {
  return endingDatabase?.[id];
}

export function getEndingData(
  state: PlayerContextState,
): EndingStats | undefined {
  return state.thisRun.endingTriggered;
}

export function hasEverTriggeredEnding(
  state: PlayerContextState,
  id: string,
): boolean {
  return state.global.endingsTriggered.includes(id);
}

export function setGameEnding(
  state: PlayerContextState,
  id: string,
): PlayerContextState {
  if (
    state.thisRun.endingTriggered != null &&
    state.thisRun.endingTriggered.endingId === id
  ) {
    // Do nothing
    return state;
  }

  const allStorylines = Storylines.getAll();
  const completedStorylines = allStorylines.filter((storyline) => {
    return storyline.isCompleted(state);
  });
  state.thisRun.endingTriggered = {
    endingId: id,
    totalTimePlayed: getTotalSecondsPlayed(state),
    totalTimePlayedThisRun: getSecondsPlayed(state),
    primarySchool: state.primarySchool as School,
    primaryElement: state.primaryElement as SpellElement,
    primarySchoolLevelOnEnding: getSchoolLevel(
      state,
      state.primarySchool as School,
    ),
    mpl: getMaxOfMaxPrimarySchoolLevels(state),
    researchMultiplier: maximumSchoolLevelResearchMultiplier(state),
    productionMultiplier: maximumSchoolLevelProductionMultiplierDx(state),
    completedStorylines: completedStorylines.length,
  };
  const endingsTriggered = state.global.endingsTriggered;
  endingsTriggered.push(id);
  state.global.endingsTriggered = [...new Set(endingsTriggered)];
  state = addUICommand(state, "goToEnding");
  state = addUICommand(state, "closeAllMenus");
  state = addUICommand(state, "closeEventOverlay");
  return state;
}
