import { applyTransformations } from "../../../calculation/Calculation";
import { TransformationTags } from "../../../calculation/TransformationTags";
import { PlayerContextState } from "../../../PlayerContext";
import { Resource } from "../../../Resources";
import { EquipmentSlot } from "../../Equipment";
import { ItemParams } from "../../Item";
import { EquippableItem } from "../EquippableItem";

class Branch extends EquippableItem {
  getId(): string {
    return "branch";
  }

  getPicture() {
    return require("./branch.png");
  }

  getBaseName(): string {
    return "Branch";
  }

  getDescription(): string | undefined {
    return "A magically infused branch. Produces a small amount of :mana:.";
  }

  getAttackBonus(state: PlayerContextState, params: ItemParams): number {
    return 5;
  }

  getManaRegenBonus(state: PlayerContextState, params: ItemParams): number {
    return applyTransformations(
      [this.getId(), Resource.Mana, TransformationTags.Production],
      state,
      2.0,
    );
  }

  getSlot(): EquipmentSlot {
    return EquipmentSlot.Hand;
  }

  getSalePrice(): number {
    return 200;
  }

  getCraftingSchoolLevelRequirements() {
    return { Conjuration: 4, Enchantment: 4 };
  }

  getCraftingMaterialsBase() {
    return {
      resources: {
        Wood: 300,
        Mana: 1000,
      },
      items: {},
    };
  }
}

const branch = new Branch();

export default branch;
