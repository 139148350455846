import { registerTimeTickListener } from "./TimeTick";
import {
  GLOBAL_INITIAL_STATE,
  PlayerContextState,
  THIS_RUN_INITIAL_STATE,
} from "../PlayerContext";

export function loadTotalTimePlayed() {
  registerTimeTickListener("TotalTimePlayed", (state, timeElapsedSec) => {
    if (!state.global) {
      state.global = GLOBAL_INITIAL_STATE;
    }
    if (!state.thisRun) {
      state.thisRun = THIS_RUN_INITIAL_STATE;
    }
    state.global.secondsPlayed += timeElapsedSec;
    state.thisRun.secondsPlayed += timeElapsedSec;
    return state;
  });
}

export function getTotalSecondsPlayed(state: PlayerContextState) {
  return state?.global?.secondsPlayed || 0;
}

export function getSecondsPlayed(state: PlayerContextState) {
  return state?.thisRun?.secondsPlayed || 0;
}
