import { DataStore } from "../generic/DataStore";
import { PlayerContextState } from "../PlayerContext";

export class Storyline {
  name: string;
  hintDescription: (state: PlayerContextState) => string;
  getBonusDescription: (state: PlayerContextState) => string;
  isBonusActive: (state: PlayerContextState) => boolean;
  isCompleted: (state: PlayerContextState) => boolean;
  isHintUnlockable: (state: PlayerContextState) => boolean;

  constructor(
    name: string,
    isCompleted: (state: PlayerContextState) => boolean,
    isBonusActive: (state: PlayerContextState) => boolean,
    hintDescription: string | ((state: PlayerContextState) => string),
    getBonusDescription: string | ((state: PlayerContextState) => string),
    isHintUnlockable: (state: PlayerContextState) => boolean,
  ) {
    this.name = name;
    this.isCompleted = isCompleted;
    this.isBonusActive = isBonusActive;
    this.hintDescription =
      typeof hintDescription === "string"
        ? (state) => hintDescription
        : hintDescription;
    this.getBonusDescription =
      typeof getBonusDescription === "string"
        ? (state) => getBonusDescription
        : getBonusDescription;
    this.isHintUnlockable = isHintUnlockable;
  }

  getId(): string {
    return this.name;
  }
}

const Storylines = new DataStore<Storyline>();

export { Storylines };
