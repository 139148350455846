import { applyTransformations } from "../../../calculation/Calculation";
import { TransformationTags } from "../../../calculation/TransformationTags";
import { PlayerContextState } from "../../../PlayerContext";
import { Resource } from "../../../Resources";
import { EquipmentSlot } from "../../Equipment";
import { ItemParams } from "../../Item";
import { EquippableItem } from "../EquippableItem";

class FireWand extends EquippableItem {
  getId(): string {
    return "fireWand";
  }

  getPicture() {
    return require("./fireWand.png");
  }

  getBaseName(): string {
    return "Fire Wand";
  }

  getDescription(): string | undefined {
    return "A wizard's magical wand with the power of Fire. Can be an effective weapon!";
  }

  getAttackBonus(state: PlayerContextState, params: ItemParams): number {
    return 35;
  }

  getManaRegenBonus(state: PlayerContextState, params: ItemParams): number {
    return applyTransformations(
      [this.getId(), Resource.Mana, TransformationTags.Production],
      state,
      16.0,
    );
  }

  getMaxManaBonus(state: PlayerContextState, params: ItemParams): number {
    return 400;
  }

  getSlot(): EquipmentSlot {
    return EquipmentSlot.Hand;
  }

  isCraftable(state: PlayerContextState): boolean {
    return super.isCraftable(state) && state.primaryElement == "Fire";
  }

  getSalePrice(): number {
    return 1800;
  }

  getCraftingSchoolLevelRequirements() {
    return { Conjuration: 8, Enchantment: 8 };
  }

  getCraftingMaterialsBase() {
    return {
      resources: {
        Wood: 3000,
        Mana: 2500,
      },
      items: {
        fireSpeck: 1,
      },
    };
  }
}

const fireWand = new FireWand();

export default fireWand;
