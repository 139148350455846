import {
  registerTransformation,
  TransformationType,
} from "../../calculation/Calculation";
import { TransformationTags } from "../../calculation/TransformationTags";
import { clearFlag, hasFlag, setFlag } from "../../Flags";
import { getResourceAmount, grantResource, Resource } from "../../Resources";
import { getSchoolLevel, School } from "../../spells/ElementsAndSchools";
import { registerTimeTickListener } from "../../timetick/TimeTick";
import { registerRandomEventTrigger } from "../autorandom/RandomEventTrigger";
import { buildEventMessage } from "../EventMessageBuilder";
import { createGameEvent } from "../SimpleGameEvent";

export function loadRandomOneTimeCampusEvents() {
  const crystalBallMessage = buildEventMessage(
    "RandomOneTimeCampusEvents_1",
    "Divine my Future",
  )
    .setSimpleDescription(
      `A young woman, dressed as a wizarding apprentice shows up on campus with a crystal ball.
    
"Headmaster, your magical prowess is known far and wide", she says. "Crystal balls are important magical artifacts that can be used to know the future. I would like to know my own destiny, but my skill is not strong enough. Perhaps you can help me...?" she asks, looking at you.`,
    )
    .addOption(
      "Pretend to read the future (300 Mana)",
      buildEventMessage("RandomOneTimeCampusEvents_1_1", "Divine my Future")
        .setSimpleDescription(
          `You utilize your Illusion prowess to create a fuzzy image inside the crystal ball. You pretend to be focused in divining the future as the apprentice watches in awe.
          
As the illusion runs its course, you appear to exit your trance. You tell the woman that she is destined to learn magic and refine her powers at your institution. As much of a lie as it was, she falls for it.

"Wow... so that's really my destiny", she says, while lost in thought. "Then I should comply. ...I-I know that if you go against destiny, you will never be able to be successful... ...Can I stay here and study magic?", she asks. You nod.

**You have obtained an extra apprentice!**`,
        )
        .build(),
      (state) => {
        state = grantResource(Resource.Mana, -300)(state);
        state = setFlag("RandomOneTimeCampusEvents_1_ResearcherJoined")(state);
        return state;
      },
      (state) => getSchoolLevel(state, School.Illusion) >= 3,
      (state) => getResourceAmount(state, Resource.Mana) >= 300,
    )
    .addOption(
      "Decline",
      buildEventMessage("RandomOneTimeCampusEvents_1_2", "Divine my Future")
        .setSimpleDescription(
          "You decline her request. Sad, she thanks you, and leaves.",
        )
        .build(),
    )
    .addOption("Maybe later")
    .build();

  const crystalBallEvent = createGameEvent(
    "RandomOneTimeCampusEvents_1",
    crystalBallMessage,
    setFlag("RandomOneTimeCampusEvents_1_triggered"),
  );

  registerTransformation(
    [[TransformationTags.Apprentice]],
    "RandomOneTimeCampusEvents_1_ApprenticeJoined",
    TransformationType.Addition,
    (state) =>
      hasFlag(state, "RandomOneTimeCampusEvents_1_ResearcherJoined") ? 1 : 0,
  );

  registerRandomEventTrigger(
    crystalBallEvent,
    80,
    (state) =>
      !hasFlag(state, "RandomOneTimeCampusEvents_1_triggered") &&
      hasFlag(state, "autocast_unlocked"),
  );

  // ===============================

  const boughtMessage = buildEventMessage(
    "RandomOneTimeCampusEvents_1_x_1",
    "A traveling merchant appeared",
  )
    .setSimpleDescription(
      `"Great doing business with you!", he exclaims, taking your coins. Placing the pebble back inside the bag, he hands over the bag to you.
      
  This magical stone will come in handy!`,
    )
    .build();
  const rejectMessage = buildEventMessage(
    "RandomOneTimeCampusEvents_1_x_2",
    "A traveling merchant appeared",
  )
    .setSimpleDescription(
      `"That's too bad", he says, putting the stone back in the bag. "Have a good day!", he exclaims while turning back and leaving the campus.`,
    )
    .build();

  const travelingMerchantWaterStoneMessage = buildEventMessage(
    "RandomOneTimeCampusEvents_2",
    "A traveling merchant appeared",
  )
    .setSimpleDescription(
      `A traveling merchant appears at the campus gates and approaches you. "Look at this", he says, while getting a moist bag out from his pockets. He opens the bag, and shows you a small stone. You notice the stone seems to be emanating water.
      
"This pebble magically produces water", he says. "This might be useful to you. It can be yours for 1500 coins. Would you like to purchase it?"`,
    )
    .addOption(
      "Charm the merchant (400 Mana)",
      buildEventMessage(
        "RandomOneTimeCampusEvents_2_1",
        "A traveling merchant appeared",
      )
        .setSimpleDescription(
          `You use your Illusion prowess to charm the merchant into thinking positively about you. Without noticing, he says: "Actually... 1500 coins is quite a high price. I'd be happy to give you a *very* good deal. I can't go lower than 150 coins, though. Are you interested?`,
        )
        .addOption(
          "Buy the pebble",
          boughtMessage,
          (state) => {
            state = grantResource(Resource.Coins, -150)(state);
            state = setFlag("RandomOneTimeCampusEvents_2_bought")(state);
            return state;
          },
          undefined,
          (state) => getResourceAmount(state, Resource.Coins) >= 150,
        )
        .addOption(
          "Decline",
          rejectMessage,
          clearFlag("RandomOneTimeCampusEvents_2_bought"),
        )
        .addOption("Maybe later")
        .build(),
      grantResource(Resource.Mana, -400),
      (state) => getSchoolLevel(state, School.Illusion) >= 3,
      (state) => getResourceAmount(state, Resource.Mana) >= 400,
    )
    .addOption(
      "Buy the pebble",
      boughtMessage,
      (state) => {
        state = grantResource(Resource.Coins, -1500)(state);
        state = setFlag("RandomOneTimeCampusEvents_2_bought")(state);
        return state;
      },
      undefined,
      (state) => getResourceAmount(state, Resource.Coins) >= 1500,
    )
    .addOption(
      "Decline",
      rejectMessage,
      clearFlag("RandomOneTimeCampusEvents_2_bought"),
    )
    .addOption("Maybe later")
    .build();

  const travelingMerchantWaterStoneEvent = createGameEvent(
    "RandomOneTimeCampusEvents_2",
    travelingMerchantWaterStoneMessage,
    setFlag("RandomOneTimeCampusEvents_2_triggered"),
  );

  registerRandomEventTrigger(
    travelingMerchantWaterStoneEvent,
    80,
    (state) => !hasFlag(state, "RandomOneTimeCampusEvents_2_triggered"),
  );

  registerTimeTickListener(
    "RandomOneTimeCampusEvents_2_bought",
    (state, timeElapsedSec) => {
      if (hasFlag(state, "RandomOneTimeCampusEvents_2_bought")) {
        state = grantResource(Resource.Water, timeElapsedSec)(state);
      }
      return state;
    },
  );
}
