import { PlayerContextState } from "../../../PlayerContext";
import { EquipmentSlot } from "../../Equipment";
import { ItemParams } from "../../Item";
import { EquippableItem } from "../EquippableItem";

class Scimitar extends EquippableItem {
  getId(): string {
    return "scimitar";
  }

  getPicture() {
    return require("./scimitar.png");
  }

  getBaseName(): string {
    return "Scimitar";
  }

  getDescription(): string | undefined {
    return "A defensive sword with a curved, wider blade.";
  }

  getAttackBonus(state: PlayerContextState, params: ItemParams): number {
    return 43;
  }

  getDefenseBonus(state: PlayerContextState, params: ItemParams): number {
    return 10;
  }

  getEffect(): string | undefined {
    return "+43:attack:, +10:defense:";
  }

  getSlot(): EquipmentSlot {
    return EquipmentSlot.Hand;
  }

  getSalePrice(): number {
    return 4200;
  }

  getCraftingSchoolLevelRequirements() {
    return { Conjuration: 11 };
  }

  getCraftingMaterialsBase() {
    return {
      resources: {
        Iron: 1600,
        Wood: 2500,
        Temperature: 2100,
      },
      items: {},
    };
  }
}

const scimitar = new Scimitar();

export default scimitar;
