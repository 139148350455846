import React, { useContext, useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { Icon } from "react-native-elements";
import { PlayerContext } from "../../backend/PlayerContext";
import { Secret } from "../../backend/secrets/Secrets";
import { DescriptionViewer } from "./Miscellaneous";

export function SecretContainer(props: { secret: Secret }) {
  const playerContext = useContext(PlayerContext);
  const secret = props.secret;
  const isCompleted = props.secret.isCompleted(playerContext);
  const isUnlocked = props.secret.isUnlocked(playerContext);

  const descriptionContainer = useMemo(() => {
    const descriptionContents = isUnlocked
      ? secret.getDescription(playerContext)
      : "???";
    return (
      <View style={styles.descriptionTextContainer}>
        <DescriptionViewer style={{ flex: 1, justifyContent: "center" }}>
          {descriptionContents}
        </DescriptionViewer>
      </View>
    );
  }, [secret.getDescription(playerContext), isUnlocked]);

  const iconContainer = useMemo(() => {
    let iconName = "lock";

    if (isUnlocked) {
      iconName = "more-horiz";
    }

    if (isCompleted) {
      iconName = "check";
    }

    return <Icon name={iconName} size={20} color="#666" />;
  }, [isUnlocked, isCompleted]);

  return (
    <View style={styles.topContainer}>
      {iconContainer}
      {descriptionContainer}
    </View>
  );
}

const styles = StyleSheet.create({
  topContainer: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 8,
  },
  descriptionTextContainer: {
    marginLeft: 8,
    justifyContent: "center",
    flex: 1,
  },
});
