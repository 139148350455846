import { formatTime } from "../../../utils/FormattingUtils";
import { applyTransformations } from "../../calculation/Calculation";
import { TransformationTags } from "../../calculation/TransformationTags";
import { PlayerContextState } from "../../PlayerContext";
import { getResourceAmount, grantResource, Resource } from "../../Resources";
import { grantSchoolExp, School } from "../../spells/ElementsAndSchools";
import { ActionBase, ActionEffect, DoActionArgs } from "../Action";

class UseTimePieces extends ActionBase {
  constructor() {
    super();
  }

  isVisible(state: PlayerContextState): boolean {
    return true;
  }

  getName(): string {
    return "Use Time Pieces";
  }

  getArea(): string {
    return "Research";
  }

  getDisplayDescription(): string {
    return "Use all your time pieces to get a large amount of research.";
  }

  protected getBaseActionEffects(): Record<string, ActionEffect> {
    return {
      timePiecesPerResearchSecond: {
        value: 15,
        tags: [TransformationTags.TimePiecePerResearch],
      },
    };
  }

  getDisplayEffect(state: PlayerContextState): string {
    const timePiecesPerResearchSecond =
      this.getActionEffects(state).timePiecesPerResearchSecond;
    const secs = 2700 / timePiecesPerResearchSecond;
    const timeString = formatTime(secs, {
      shortForm: true,
      showDecimals: true,
    });
    return `Instantly obtain ${timeString} of research`;
  }

  canAfford(state: PlayerContextState): boolean {
    return (
      super.canAfford(state) &&
      getResourceAmount(state, Resource.TimePieces) > 2700
    );
  }

  getCost(state: PlayerContextState): {
    resources: Partial<Record<Resource, number>>;
    items: Record<string, number>;
  } {
    return {
      resources: {
        "Time Pieces": 2700,
      },
      items: {},
    };
  }

  doAction(args: DoActionArgs, state: PlayerContextState): PlayerContextState {
    const timePiecesPerResearchSecond =
      this.getActionEffects(state).timePiecesPerResearchSecond;
    const timePieces = 2700;
    const secs = timePieces / timePiecesPerResearchSecond;

    state = grantResource(
      Resource.TimePieces,
      -(this.getCost(state).resources["Time Pieces"] ?? 0),
    )(state);

    Object.keys(state.research.researcherAllocation.schools).forEach(
      (school) => {
        const researchersAllocated =
          state.research.researcherAllocation.schools[school as School] ?? 0;
        if (researchersAllocated > 0) {
          const expPerResearcherPerSec = applyTransformations(
            [school, TransformationTags.Research],
            state,
            1.0,
          );
          state = grantSchoolExp(
            school as School,
            researchersAllocated * expPerResearcherPerSec * secs,
          )(state);
        }
      },
    );

    return state;
  }
}

export default new UseTimePieces();
