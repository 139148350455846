import { Storylines } from "../storylines/Storylines";
import { Secret, SecretsByType, SecretType } from "./Secrets";

export function loadSecretsDemo() {
  // Storyline secrets
  Storylines.getAll().forEach((storyline) => {
    SecretsByType.Storyline.register(
      new Secret(
        storyline.name,
        SecretType.Storyline,
        storyline.hintDescription,
        storyline.isHintUnlockable,
        storyline.isCompleted,
      ),
    );
  });
}
