import React, { useContext, useMemo } from "react";
import { ScrollView } from "react-native";
import { getMessageLog } from "../../backend/exploration/Exploration";
import { PlayerContext } from "../../backend/PlayerContext";
import { Markdown } from "../utility/Markdown";

export function PlayerMessageLog() {
  const playerContext = useContext(PlayerContext);
  const messageLog = getMessageLog(playerContext);
  return useMemo(
    () => (
      <ScrollView>
        {messageLog.map((logItem) => (
          <Markdown key={logItem?.id ?? logItem}>
            {logItem?.message ?? logItem}
          </Markdown>
        ))}
      </ScrollView>
    ),
    [messageLog.map((logItem) => logItem?.id ?? logItem).join()],
  );
}
