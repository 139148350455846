import memoize from "fast-memoize";
import {
  applyTransformations,
  registerTransformation,
  TransformationType,
} from "../calculation/Calculation";
import { TransformationTags } from "../calculation/TransformationTags";
import {
  GLOBAL_INITIAL_STATE,
  PlayerContextState,
  PlayerContextTransform,
} from "../PlayerContext";
import { registerRetirementListener } from "../Retire";

export enum SpellElement {
  Fire = "Fire",
  Earth = "Earth",
  Air = "Air",
  Water = "Water",
  Nature = "Nature",
  Lightning = "Lightning",
  Darkness = "Darkness",
}

export enum School {
  Conjuration = "Conjuration",
  Enchantment = "Enchantment",
  Illusion = "Illusion",
  Alchemy = "Alchemy",
  Evocation = "Evocation",
  Protection = "Protection",
  Breeding = "Breeding",
  Summoning = "Summoning",
  Divination = "Divination",
}

export function getSchoolExp(
  state: PlayerContextState,
  school: School,
): number {
  return state.schoolExperience?.[school] || 0;
}

const getIncrementalExpRequiredForLevelAndExponent = memoize(
  (level: number, exponent: number): number => {
    if (level <= 1) {
      return 0;
    }
    return Math.floor(1000 * Math.pow(exponent, level - 2));
  },
);

const getTotalExpRequiredForLevelByExponent = memoize(
  (level: number, exponent: number): number => {
    if (level <= 1) {
      return 0;
    }
    return (
      getTotalExpRequiredForLevelByExponent(level - 1, exponent) +
      getIncrementalExpRequiredForLevelAndExponent(level, exponent)
    );
  },
);

const getLevelByExpAndExponent = (exp: number, exponent: number): number => {
  let level = 1;
  while (getTotalExpRequiredForLevelByExponent(level + 1, exponent) <= exp) {
    level += 1;
  }
  return level;
};

function getExponentForSchool(
  state: PlayerContextState,
  school: School,
): number {
  if (state?.schoolExponents?.[school] == null) {
    const primary = state.primarySchool === school;
    const exponent = applyTransformations(
      [TransformationTags.ExpRequirementScale, school],
      state,
      primary ? 1.4 : 1.5,
      { school: school },
    );
    state.schoolExponents[school] = exponent;
  }
  return state.schoolExponents[school] ?? 1.5;
}

export function getIncrementalExpRequiredForLevel(
  state: PlayerContextState,
  level: number,
  school: School,
): number {
  const exponent = getExponentForSchool(state, school);
  return getIncrementalExpRequiredForLevelAndExponent(level, exponent);
}

export function getTotalExpRequiredForLevel(
  state: PlayerContextState,
  level: number,
  school: School,
): number {
  const exponent = getExponentForSchool(state, school);
  return getTotalExpRequiredForLevelByExponent(level, exponent);
}

function getLevelByExp(
  state: PlayerContextState,
  exp: number,
  school: School,
): number {
  const exponent = getExponentForSchool(state, school);
  return getLevelByExpAndExponent(exp, exponent);
}

export function getSchoolLevel(
  state: PlayerContextState,
  school: School,
): number {
  return getLevelByExp(state, state.schoolExperience?.[school] || 0, school);
}

function grantSchoolExpImpl(
  school: School,
  amount: number,
  state: PlayerContextState,
) {
  let current = getSchoolExp(state, school);
  current += amount;
  current = Math.max(current, 0);
  state.schoolExperience[school] = current;
  return state;
}

export function grantSchoolExp(
  this: any,
  school: School,
  amount: number,
): PlayerContextTransform {
  // Do it this way to avoid creating extra functions and avoid rerenders
  return grantSchoolExpImpl.bind(this, school, amount);
}

export function getTotalElementAndSchoolExp(state: PlayerContextState): number {
  let totalExp = 0;
  for (let school in state.schoolExperience) {
    totalExp += getSchoolExp(state, school as School);
  }
  return totalExp;
}

function selectPrimaryElementImpl(element: string, state: PlayerContextState) {
  state.primaryElement = element;
  return state;
}

export function selectPrimaryElement(
  this: any,
  element: string,
): PlayerContextTransform {
  // Do it this way to avoid creating extra functions and avoid rerenders
  return selectPrimaryElementImpl.bind(this, element);
}

function selectPrimarySchoolImpl(school: School, state: PlayerContextState) {
  state.primarySchool = school;
  state.schoolExponents = {};

  // Selecting a primary school means we started the game
  state.thisRun.startTimestamp = Date.now();
  if (state.global.totalTimesResetted == 0) {
    state.global.startTimestamp = Date.now();
  }

  return state;
}

export function selectPrimarySchool(
  this: any,
  school: School,
): PlayerContextTransform {
  // Do it this way to avoid creating extra functions and avoid rerenders
  return selectPrimarySchoolImpl.bind(this, school);
}

export function getUnlockedElements(state: PlayerContextState): string[] {
  return Object.keys((state?.global || GLOBAL_INITIAL_STATE).unlockedElements);
}

function unlockElementImpl(element: string, state: PlayerContextState) {
  if (!state.global) {
    state.global = GLOBAL_INITIAL_STATE;
  }
  state.global.unlockedElements[element] = true;
  return state;
}

export function unlockElement(
  this: any,
  element: string,
): PlayerContextTransform {
  // Do it this way to avoid creating extra functions and avoid rerenders
  return unlockElementImpl.bind(this, element);
}

function unlockSchoolImpl(school: School, state: PlayerContextState) {
  if (!state.global) {
    state.global = GLOBAL_INITIAL_STATE;
  }
  state.global.unlockedSchools[school] = true;
  return state;
}

export function unlockSchool(
  this: any,
  school: School,
): PlayerContextTransform {
  // Do it this way to avoid creating extra functions and avoid rerenders
  return unlockSchoolImpl.bind(this, school);
}

export function getUnlockedSchools(state: PlayerContextState): School[] {
  return Object.keys(
    (state?.global || GLOBAL_INITIAL_STATE).unlockedSchools,
  ).filter(
    (school) =>
      (state?.global || GLOBAL_INITIAL_STATE).unlockedSchools[school as School],
  ) as School[];
}

for (let school in School) {
  registerTransformation(
    [[school, TransformationTags.Research]],
    school + "PrimarySchoolMultiplier",
    TransformationType.Multiplier,
    (state) => (state.primarySchool === school ? 2.0 : 1.0),
  );
}

export function getMaxPrimarySchoolLevel(
  state: PlayerContextState,
  school: School,
): number {
  return state?.global?.maxPrimarySchoolLevels?.[school] || 0;
}

export function getMaxOfMaxPrimarySchoolLevels(
  state: PlayerContextState,
): number {
  const maxSchoolLevels = state?.global?.maxPrimarySchoolLevels || {};
  let max = 0;
  for (let school in maxSchoolLevels) {
    max = Math.max(max, maxSchoolLevels[school as School] || 0);
  }
  return max;
}

export function getSumOfMaxPrimarySchoolLevels(
  state: PlayerContextState,
): number {
  const maxSchoolLevels = state?.global?.maxPrimarySchoolLevels || {};
  let total = 0;
  for (let school in maxSchoolLevels) {
    total += maxSchoolLevels[school as School] || 0;
  }
  return total;
}

export function maximumSchoolLevelResearchMultiplier(
  state: PlayerContextState,
) {
  const maxLevel = Math.max(getMaxOfMaxPrimarySchoolLevels(state), 1);
  const sumOfLevels = getSumOfMaxPrimarySchoolLevels(state);
  const researchMultiplier =
    (1 + (Math.pow(1.12, maxLevel - 1) - 1) / 1.8) *
    (Math.sqrt(16 + sumOfLevels) / 4);
  return researchMultiplier;
}

export function maximumSchoolLevelProductionMultiplier(
  state: PlayerContextState,
  multiplier: number,
) {
  return multiplier * maximumSchoolLevelProductionMultiplierDx(state);
}

export function maximumSchoolLevelProductionMultiplierDx(
  state: PlayerContextState,
) {
  const researchMultiplier = maximumSchoolLevelResearchMultiplier(state);
  return 1 + (Math.pow(researchMultiplier, 0.4) - 1) / 3.2;
}

registerTransformation(
  [[TransformationTags.Research]],
  "MSLResearch",
  TransformationType.Multiplier,
  maximumSchoolLevelResearchMultiplier,
);

registerTransformation(
  [[TransformationTags.Production]],
  "MSLProduction",
  TransformationType.Multiplier,
  maximumSchoolLevelProductionMultiplierDx,
);

registerTransformation(
  [[TransformationTags.Loot]],
  "MSLLoot",
  TransformationType.Multiplier,
  maximumSchoolLevelProductionMultiplierDx,
);

Object.keys(SpellElement).forEach((element) => {
  registerTransformation(
    [
      [
        TransformationTags.Cost,
        element,
        "Area:Campus",
        TransformationTags.Spell,
      ],
    ],
    element + "SpellCostReducer",
    TransformationType.Multiplier,
    (state) => (state.primaryElement == element ? 0.5 : 1),
  );
});

function retirementListener(state: PlayerContextState): PlayerContextState {
  const primarySchool = state?.primarySchool;
  if (!state.global) {
    state.global = GLOBAL_INITIAL_STATE;
  }
  if (primarySchool) {
    const level = getSchoolLevel(state, primarySchool);
    state.global.maxPrimarySchoolLevels[primarySchool] = Math.max(
      getMaxPrimarySchoolLevel(state, primarySchool),
      level,
    );
  }
  return state;
}

registerRetirementListener("mpl", retirementListener);

export function meetsRequirements(
  state: PlayerContextState,
  schoolRequirements: Partial<Record<School, number>>,
): boolean {
  if (
    Object.keys(schoolRequirements).some((schoolName) => {
      const levelRequirement = schoolRequirements[schoolName as School] || 0;
      if (levelRequirement <= 0) {
        return false;
      }
      return (
        getSchoolLevel(state, schoolName as School) <
          (schoolRequirements[schoolName as School] || 0) ||
        !getUnlockedSchools(state).includes(schoolName as School)
      );
    })
  ) {
    return false;
  }
  return true;
}
