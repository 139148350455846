import { applyTransformations } from "../../../calculation/Calculation";
import { TransformationTags } from "../../../calculation/TransformationTags";
import { PlayerContextState } from "../../../PlayerContext";
import { Resource } from "../../../Resources";
import { EquipmentSlot } from "../../Equipment";
import { ItemParams } from "../../Item";
import { EquippableItem } from "../EquippableItem";

class ElderWizardRobe extends EquippableItem {
  getId(): string {
    return "elderWizardRobe";
  }

  getPicture() {
    return require("./elderWizardRobe.png");
  }

  getBaseName(): string {
    return "Elder Wizard's Robe";
  }

  getDescription(): string | undefined {
    return "Strong magically infused clothing, to be worn by powerful spellcasters.";
  }

  getDefenseBonus(state: PlayerContextState, params: ItemParams): number {
    return 10;
  }

  getManaRegenBonus(state: PlayerContextState, params: ItemParams): number {
    return applyTransformations(
      [this.getId(), Resource.Mana, TransformationTags.Production],
      state,
      20.0,
    );
  }

  getMaxManaBonus(state: PlayerContextState, params: ItemParams): number {
    return 600;
  }

  getSlot(): EquipmentSlot {
    return EquipmentSlot.Body;
  }

  getSalePrice(): number {
    return 4000;
  }

  getCraftingSchoolLevelRequirements() {
    return {
      Conjuration: 7,
      Enchantment: 9,
    };
  }

  getCraftingMaterialsBase() {
    return {
      resources: { Mana: 2400 },
      items: { cloth: 16 },
    };
  }
}

const elderWizardRobe = new ElderWizardRobe();

export default elderWizardRobe;
