import AsyncStorage from "@react-native-async-storage/async-storage";
import { Buffer } from "buffer";
import deepmerge from "deepmerge";
import zlib from "react-zlib-js";
import { promisify } from "util";
import { PlayerContextState, PLAYER_INITIAL_STATE } from "./PlayerContext";
import { getTotalSecondsPlayed } from "./timetick/TotalTimePlayed";

const gzipCompress = promisify(zlib.gzip);
const gzipDecompress = promisify(zlib.gunzip);

export async function loadData(): Promise<PlayerContextState> {
  const jsonValue = await AsyncStorage.getItem("PlayerContextState");
  if (jsonValue !== null) {
    const state = JSON.parse(jsonValue);

    // Fix all the save compatibility changes
    return makeCompatible(state);
  }
  return PLAYER_INITIAL_STATE;
}

export async function saveData(state: PlayerContextState): Promise<void> {
  const lastSaveDataTime = Number.parseFloat(
    (await AsyncStorage.getItem("LastSaveSecondsPlayed")) || "0",
  );
  if (getTotalSecondsPlayed(state) > lastSaveDataTime) {
    await saveDataUnsafe(state);
  }
}

export async function saveDataUnsafe(state: PlayerContextState): Promise<void> {
  const jsonValue = JSON.stringify(state);
  await AsyncStorage.setItem(
    "LastSaveSecondsPlayed",
    getTotalSecondsPlayed(state).toString(),
  );
  await AsyncStorage.setItem("PlayerContextState", jsonValue);
}

function makeCompatible(obj: PlayerContextState): PlayerContextState {
  return deepmerge(PLAYER_INITIAL_STATE, obj);
}

export async function loadBase64Data(
  saveData: string,
): Promise<PlayerContextState | null> {
  const decodedBuffer = Buffer.from(
    Buffer.from(saveData, "base64").toString(),
    "base64",
  );
  const decodedJSON = await gzipDecompress(decodedBuffer);
  const decodedData = JSON.parse(decodedJSON.toString());
  if (decodedData !== null) {
    return makeCompatible(decodedData);
  }
  return null;
}

export async function serializeSaveData(
  state: PlayerContextState,
): Promise<string> {
  const compressedJSON = await gzipCompress(JSON.stringify(state));
  // Double converting to Base64 so it copies well on Android
  return Buffer.from(compressedJSON.toString("base64")).toString("base64");
}
