import { PlayerContextState } from "../PlayerContext";
import { EventMessage, EventMessageOption } from "./GameEvent";

class EmptyEventMessageImpl implements EventMessage {
  constructor() {}

  getId(): string {
    return "empty";
  }
  getTitle(params: any): string {
    return "[Deleted]";
  }
  getDescription(state: PlayerContextState, params: any): string {
    return "This is a deleted game event";
  }
  getAllowedOptions(
    state: PlayerContextState,
    params: any,
  ): EventMessageOption[] {
    return [];
  }
  isTerminal(): boolean {
    return true;
  }
  isActionable(state: PlayerContextState, params: any): boolean {
    return false;
  }
}

const EmptyEventMessage = new EmptyEventMessageImpl();

export { EmptyEventMessage };
