import { Picker } from "@react-native-picker/picker";
import * as Clipboard from "expo-clipboard";
import React, { useCallback, useContext, useRef } from "react";
import { StyleSheet, View } from "react-native";
import { Button } from "react-native-elements";
import { PlayerContext } from "../backend/PlayerContext";
import { serializeSaveData } from "../backend/SaveData";
import { AnalyticsContainer } from "./utility/AnalyticsContainer";
import CategoryContainer from "./utility/CategoryContainer";
import LoadGameContainer from "./utility/LoadGameContainer";
import { Text } from "./utility/Text";

export default function Options() {
  const playerContext = useContext(PlayerContext);
  const playerContextRef = useRef(playerContext);
  playerContextRef.current = playerContext;

  const copyToClipboard = useCallback(async () => {
    const serializedData = await serializeSaveData(playerContextRef.current);
    await Clipboard.setStringAsync(serializedData);
  }, []);

  const targetFpsChange = useCallback((value: number) => {
    playerContextRef.current.apply((oldState) => {
      oldState.global.targetFps = value;
      return oldState;
    });
  }, []);

  return (
    <View style={styles.container}>
      <View style={styles.headerContainer}>
        <Text h2>Options</Text>
      </View>
      <CategoryContainer title="Performance">
        <View style={styles.categoryContainer}>
          <Text>
            You may set your target FPS here. Higher FPS will make the game
            smoother but it could affect battery power and CPU usage.
          </Text>
          <View style={styles.targetFpsContainer}>
            <Text>Target FPS:</Text>
            <Picker
              onValueChange={targetFpsChange}
              selectedValue={playerContext.global.targetFps}
              style={styles.targetFpsPicker}
            >
              <Picker.Item label="10" value="10" key="10" />
              <Picker.Item label="12" value="12" key="12" />
              <Picker.Item label="15" value="15" key="15" />
              <Picker.Item label="20" value="20" key="20" />
              <Picker.Item label="24" value="24" key="24" />
              <Picker.Item label="30" value="30" key="30" />
              <Picker.Item label="40" value="40" key="40" />
              <Picker.Item label="50" value="50" key="50" />
              <Picker.Item label="60" value="60" key="60" />
            </Picker>
          </View>
        </View>
      </CategoryContainer>
      <CategoryContainer title="Data collection">
        <View style={styles.categoryContainer}>
          <Text>
            The game collects and sends small amounts of data related to your
            progress through the game in order to improve the game. If you
            prefer, you may opt out of this data collection.
          </Text>
          <AnalyticsContainer />
        </View>
      </CategoryContainer>
      <CategoryContainer title="Save File">
        <View style={styles.categoryContainer}>
          <Text>
            You may persist your save data by copying it to the clipboard and
            storing it anywhere you'd like.
          </Text>
          <Button
            onPress={copyToClipboard}
            title="Copy savefile to clipboard"
            containerStyle={styles.saveButton}
          />
        </View>
      </CategoryContainer>
      <CategoryContainer title="Load File">
        <View style={styles.categoryContainer}>
          <Text>You may import save data by pasting it here.</Text>
          <Text style={{ fontWeight: "bold", color: "red" }}>
            WARNING: This will erase your current save! Make sure you have
            backed up your data!
          </Text>
          <LoadGameContainer />
        </View>
      </CategoryContainer>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "stretch",
    justifyContent: "flex-start",
    width: "100%",
  },
  headerContainer: {
    marginBottom: 16,
  },
  categoryContainer: {
    marginTop: 8,
    paddingHorizontal: 8,
  },
  targetFpsContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  targetFpsPicker: {
    width: 100,
    marginLeft: 16,
    marginTop: 8,
  },
  sliderContainer: {
    width: 200,
    marginLeft: 16,
  },
  sliderTextContainer: {
    width: 120,
  },
  checkboxContainer: {
    marginLeft: 0,
    marginTop: 8,
    padding: 0,
  },
  checkboxText: {
    fontWeight: "normal",
  },
  saveButton: {
    marginTop: 8,
  },
  loadMessageContainer: {
    marginBottom: 8,
  },
});
