import { registerAction } from "../actions/Actions";
import { PlayerContextState, PlayerContextTransform } from "../PlayerContext";
import { Building, BuildingActionBase } from "./Building";

export type BuildingData = {
  current: number;
  turnedOn?: number;
};

const buildings: Record<string, Building> = {};

export function registerBuilding(building: BuildingActionBase) {
  buildings[building.getBuildingName()] = building;
  registerAction(building);
}

export function getBuildingByName(name: string): Building {
  return buildings?.[name] as Building;
}

export function getBuildingAmount(
  state: PlayerContextState,
  building: Building,
): number {
  return state.buildings?.[building.getBuildingName()]?.current || 0;
}

export function getBuildingAmountTurnedOn(
  state: PlayerContextState,
  building: Building,
): number {
  return state.buildings?.[building.getBuildingName()]?.turnedOn || 0;
}

function changeBuildingsTurnedOnImpl(
  building: Building,
  amount: number,
  state: PlayerContextState,
): PlayerContextState {
  const name = building.getBuildingName();

  if (state.buildings?.[name] == null) {
    state.buildings[name] = {
      current: 0,
      turnedOn: 0,
    };
  }

  const newAmount = getBuildingAmountTurnedOn(state, building) + amount;
  if (newAmount < 0 || newAmount > getBuildingAmount(state, building)) {
    return state;
  }
  state.buildings[name].turnedOn = newAmount;

  return state;
}

export function changeBuildingsTurnedOn(
  this: any,
  building: Building,
  amount: number,
): PlayerContextTransform {
  return changeBuildingsTurnedOnImpl.bind(this, building, amount);
}

export function buildingAmountsChanged(
  oldState: PlayerContextState,
  newState: PlayerContextState,
  building: Building,
): boolean {
  const oldBuildings = getBuildingAmount(oldState, building);
  const newBuildings = getBuildingAmount(newState, building);
  return oldBuildings != newBuildings;
}
