import { loadRandomEventTriggers } from "./autorandom/RandomEventTrigger";
import AutocastIntro from "./campus/AutocastIntro";
import CraftingIntro from "./campus/CraftingIntro";
import ExplorationIntro from "./campus/ExplorationIntro";
import { loadLevelUpEvents } from "./campus/LevelUp";
import { loadRandomOneTimeCampusEvents } from "./campus/RandomOneTimeCampusEvents";
import { loadRepeatableCampusEvents } from "./campus/RepeatableCampusEvents";
import Researcher from "./campus/Researcher";
import ShopIntro from "./campus/ShopIntro";
import { registerGameEvent } from "./Events";
import alchemyLabEvent from "./storyline/AlchemyLab";
import evocationEvent from "./storyline/EvocationIntro";
import HeadmastersStaff from "./storyline/HeadmastersStaff";
import magicLibraryEvent from "./storyline/MagicLibrary";
import statueEvent from "./storyline/Statue";
import timeRitualEvent from "./storyline/TimeRitual";

export function loadGameEventsDemo() {
  registerGameEvent(Researcher);
  registerGameEvent(AutocastIntro);
  registerGameEvent(CraftingIntro);
  registerGameEvent(ShopIntro);
  registerGameEvent(ExplorationIntro);
  registerGameEvent(HeadmastersStaff);
  registerGameEvent(alchemyLabEvent);
  registerGameEvent(statueEvent);
  registerGameEvent(evocationEvent);
  registerGameEvent(magicLibraryEvent);
  registerGameEvent(timeRitualEvent);
  loadLevelUpEvents();
  loadRandomEventTriggers();
  loadRepeatableCampusEvents();
  loadRandomOneTimeCampusEvents();
}
