import { PlayerContextState } from "../../../PlayerContext";
import { EquipmentSlot } from "../../Equipment";
import { ItemParams } from "../../Item";
import { EquippableItem } from "../EquippableItem";

class AssassinsDagger extends EquippableItem {
  getId(): string {
    return "assassinsDagger";
  }

  getPicture() {
    return require("./assassinsDagger.png");
  }

  getBaseName(): string {
    return "Assassin's Dagger";
  }

  getDescription(): string | undefined {
    return "A dagger that's particularly sharp and solid.";
  }

  getAttackBonus(state: PlayerContextState, params: ItemParams): number {
    return 30;
  }

  getAttackDelayBonusPct(
    state: PlayerContextState,
    params: ItemParams,
  ): number {
    return -55;
  }

  getSlot(): EquipmentSlot {
    return EquipmentSlot.Hand;
  }

  getSalePrice(): number {
    return 5400;
  }

  getCraftingSchoolLevelRequirements() {
    return { Conjuration: 12 };
  }

  getCraftingMaterialsBase() {
    return {
      resources: {
        Iron: 2500,
        Temperature: 3000,
      },
      items: {},
    };
  }
}

const assassinsDagger = new AssassinsDagger();

export default assassinsDagger;
