import { getAnalytics, logEvent } from "firebase/analytics";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDjH1kuc6_eCQyM91kGGXTVyW2uhLiztJc",
  authDomain: "magic-research.firebaseapp.com",
  projectId: "magic-research",
  storageBucket: "magic-research.appspot.com",
  messagingSenderId: "80276163359",
  appId: "1:80276163359:web:d68eca4ec6eb04db00be2a",
  measurementId: "G-E4V97V67HY",
};

const app = initializeApp(firebaseConfig);
const webAnalytics = getAnalytics(app);

export async function sendAnalyticsEventAsync(
  name: string,
  params?: { [key: string]: any },
) {
  await logEvent(webAnalytics, name, params);
}
