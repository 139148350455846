import vault from "../../buildings/campus/Vault";
import {
  registerTransformation,
  TransformationType,
} from "../../calculation/Calculation";
import { TransformationTags } from "../../calculation/TransformationTags";
import { PlayerContextState } from "../../PlayerContext";
import { addContextListener } from "../../PlayerContextListeners";
import { recalculateCaps, Resource } from "../../Resources";
import { Upgrade } from "../Upgrade";
import reinforcedWaterTanks from "./ReinforcedWaterTanks";

class ReinforcedVaults extends Upgrade {
  getName(): string {
    return "Reinforced Vaults";
  }

  getMonstiumCost(): number {
    return 2000;
  }

  getDisplayDescription(state: PlayerContextState): string {
    return "Add an extra layer of iron to your vaults, allowing you to store twice as much :coins:.";
  }

  getDisplayEffect(state: PlayerContextState): string {
    return "2x Vault storage";
  }

  getUpgradeRequirements() {
    return [reinforcedWaterTanks];
  }
}

const reinforcedVaults = new ReinforcedVaults();

registerTransformation(
  [[vault.getName(), TransformationTags.PerBuildingCap, Resource.Coins]],
  "Reinforced Vaults",
  TransformationType.Multiplier,
  (state) => (reinforcedVaults.isPurchased(state) ? 2.0 : 1.0),
);

addContextListener("ReinforcedVaultsListener", (oldState, newState) => {
  if (
    !reinforcedVaults.isPurchased(oldState) &&
    reinforcedVaults.isPurchased(newState)
  ) {
    return recalculateCaps;
  }
});

export default reinforcedVaults;
