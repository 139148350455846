import { ItemOccurrence, ItemAction } from "./Item";
import {
  PlayerContextState,
  EQUIPMENT_INITIAL_STATE,
  PlayerContextTransform,
} from "../PlayerContext";
import {
  getAmountOfItem,
  removeFromInventory,
  getInventory,
  calculateInventoryCap,
  addToInventory,
} from "./Inventory";

import bind from "memoize-bind";

export enum EquipmentSlot {
  Hand = "Hand",
  Body = "Body",
  Accessory = "Accessory",
}

export function canUnequipItem(
  state: PlayerContextState,
  slot: EquipmentSlot,
): boolean {
  const item = getEquippedItem(state, slot);
  if (!item) {
    return false;
  }

  if (
    getInventory(state).length >= calculateInventoryCap(state) &&
    getAmountOfItem(item, state) <= 0
  ) {
    return false;
  }

  return true;
}

export function canEquipItem(
  state: PlayerContextState,
  slot: EquipmentSlot,
  item: ItemOccurrence,
): boolean {
  if (getAmountOfItem(item, state) <= 0) {
    return false;
  }

  if (
    getAmountOfItem(item, state) > 1 &&
    getEquippedItem(state, slot) &&
    !canUnequipItem(state, slot)
  ) {
    return false;
  }

  return true;
}

export function equipToSlot(
  state: PlayerContextState,
  slot: EquipmentSlot,
  item: ItemOccurrence,
): PlayerContextState {
  if (!canEquipItem(state, slot, item)) {
    return state;
  }

  state = removeFromInventory(item, 1, state);
  state = unequipFromSlot(slot)(state);
  if (!state.equipment) {
    state.equipment = EQUIPMENT_INITIAL_STATE;
  }
  state.equipment[slot] = item;

  return state;
}

export function getEquippedItem(
  state: PlayerContextState,
  slot: EquipmentSlot,
): ItemOccurrence | undefined {
  return state.equipment?.[slot];
}

function unequipFromSlotImpl(
  slot: EquipmentSlot,
  state: PlayerContextState,
): PlayerContextState {
  if (!canUnequipItem(state, slot)) {
    return state;
  }

  const item = getEquippedItem(state, slot);
  if (!item) {
    return state;
  }

  state = addToInventory(item, 1, state);
  if (!state.equipment) {
    state.equipment = EQUIPMENT_INITIAL_STATE;
  }
  state.equipment[slot] = undefined;

  return state;
}

export function unequipFromSlot(
  this: any,
  slot: EquipmentSlot,
): PlayerContextTransform {
  // Do it this way to avoid creating extra functions and avoid rerenders
  return bind(unequipFromSlotImpl, this, slot);
}

export function getUnequipAction(
  state: PlayerContextState,
  slot: EquipmentSlot,
): ItemAction {
  return {
    description: "Unequip",
    isEnabled: canUnequipItem(state, slot),
    transform: unequipFromSlot(slot),
  };
}
