import { formatNumber } from "../../../utils/FormattingUtils";
import { ActionEffect } from "../../actions/Action";
import { TransformationTags } from "../../calculation/TransformationTags";
import { hasFlag } from "../../Flags";
import { PlayerContextState } from "../../PlayerContext";
import { grantTemporaryEffect } from "../../timetick/TemporaryEffects";
import { SpellActionBase } from "../Spell";
import quickness from "./Quickness";
import stoneskin from "./Stoneskin";
import strength from "./Strength";
import vigorOfHealth from "./VigorOfHealth";

class PhysicalAugment extends SpellActionBase {
  constructor() {
    super();
  }

  isVisible(state: PlayerContextState): boolean {
    return super.isVisible(state) && hasFlag(state, "exploration_unlocked");
  }

  getSpellName(): string {
    return "Physical Augment";
  }

  getArea(): string {
    return "Exploration";
  }

  getDisplayDescription(): string {
    return "Equivalent to Vigor of Health, Stoneskin, Quickness and Strength all at once.";
  }

  protected getBaseActionEffects(): Record<string, ActionEffect> {
    return {
      duration: {
        value: 40,
        tags: [TransformationTags.TemporaryEffectDuration],
      },
    };
  }

  getDisplayEffect(state: PlayerContextState): string {
    const hpEffects = vigorOfHealth.getActionEffects(state);
    const defEffects = stoneskin.getActionEffects(state);
    const atkDelayEffects = quickness.getActionEffects(state);
    const atkEffects = strength.getActionEffects(state);
    const effects = this.getActionEffects(state);
    return `+${formatNumber(hpEffects.magnitude)} Max:hp:, +${formatNumber(
      defEffects.magnitude,
    )}:defense:, -${formatNumber(
      atkDelayEffects.magnitude * 100,
    )}%:attackDelay: and +${formatNumber(
      atkEffects.magnitude,
    )}:attack: for ${formatNumber(effects.duration)} sec`;
  }

  getManaCost(): number {
    return 1820;
  }

  getSchoolLevelRequirements(): Record<string, number> {
    return {
      Enchantment: 14,
    };
  }

  doSpellAction(state: PlayerContextState): PlayerContextState {
    const duration = this.getActionEffects(state).duration;
    state = grantTemporaryEffect(
      "Vigor of Health",
      "Vigor of Health",
      duration,
      {},
    )(state);
    state = grantTemporaryEffect("Stoneskin", "Stoneskin", duration, {})(state);
    state = grantTemporaryEffect("Quickness", "Quickness", duration, {})(state);
    state = grantTemporaryEffect("Strength", "Strength", duration, {})(state);
    return state;
  }
}

export default new PhysicalAugment();
