import { PlayerContextState } from "../../../PlayerContext";
import woolFarm from "../../../upgrades/other/WoolFarm";
import { Dungeon } from "../Dungeon";
import { DungeonFloor } from "../DungeonFloor";
import TheCorral from "./TheCorral";
import TheMagicalPen from "./TheMagicalPen";
import TheSpecialStockade from "./TheSpecialStockade";

class WoolFarm extends Dungeon {
  getId(): string {
    return "woolFarm";
  }

  getName(): string {
    return "Wool Farm";
  }

  getDungeonFloors(): DungeonFloor[] {
    return [TheCorral, TheMagicalPen, TheSpecialStockade];
  }

  isUnlocked(state: PlayerContextState): boolean {
    return woolFarm.isPurchased(state);
  }
}

export default new WoolFarm();
