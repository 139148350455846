import { ActionEffect } from "../../actions/Action";
import { TransformationTags } from "../../calculation/TransformationTags";
import { PlayerContextState } from "../../PlayerContext";
import { CombatSpellBase } from "../CombatSpellBase";
import {
  getStandardAttackEffectText,
  getStandardSpellAttackEffect,
} from "../Utils";

class ManaSpike extends CombatSpellBase {
  getSpellName(): string {
    return "Mana Spike";
  }

  getDisplayDescription(): string {
    return "Condense :mana: into a sharp spike, then thrust it at an enemy.";
  }

  protected getBaseActionEffects(): Record<string, ActionEffect> {
    return {
      average: {
        value: 42.5,
        tags: [TransformationTags.ActionMagnitude],
      },
      variance: {
        value: 2.5,
        tags: [TransformationTags.ActionVariance],
      },
    };
  }

  getDisplayEffect(state: PlayerContextState): string {
    const effects = this.getActionEffects(state);
    return getStandardAttackEffectText(effects.average, effects.variance);
  }

  doSpellAction(state: PlayerContextState): PlayerContextState {
    return getStandardSpellAttackEffect(state, this);
  }

  getBaseCooldown(): number {
    return 1.0;
  }

  getManaCost(): number {
    return 250;
  }

  getSchoolLevelRequirements(): Record<string, number> {
    return {
      Evocation: 1,
    };
  }
}

export default new ManaSpike();
