import moment from "moment";
import React, { useCallback, useContext, useMemo, useRef } from "react";
import {
  FlatList,
  Platform,
  StyleSheet,
  TouchableNativeFeedback,
  TouchableOpacity,
  View,
} from "react-native";
import { Divider, Icon, ListItem } from "react-native-elements";
import ProgressBar from "react-native-progress/Bar";
import {
  addOccurrenceToQueue,
  EventOccurrenceData,
  getEventMessageById,
} from "../../backend/events/Events";
import { getFirstHint } from "../../backend/hints/Hints";
import {
  PlayerContext,
  PLAYER_INITIAL_STATE,
} from "../../backend/PlayerContext";
import CategoryContainer from "../utility/CategoryContainer";
import { Text } from "../utility/Text";

function EventListItem(props: {
  item: EventOccurrenceData;
  onPress: () => void;
}) {
  if (!props.item.currentMessageId) {
    return null;
  }
  const currentMessageData = getEventMessageById(props.item.currentMessageId);
  if (!currentMessageData) {
    return null;
  }
  const title = currentMessageData.getTitle(props.item?.params);
  const description =
    moment(props.item.timestamp).format("h:mm A") +
    " - " +
    currentMessageData.getDescription(PLAYER_INITIAL_STATE, props.item?.params);
  const playerContext = useContext(PlayerContext);
  return useMemo(() => {
    return (
      <View>
        <ListItem
          onPress={props.onPress}
          Component={Platform.select<any>({
            android: TouchableNativeFeedback,
            default: TouchableOpacity,
          })}
        >
          <ListItem.Content>
            <View style={styles.eventTitleContainer}>
              {currentMessageData.isActionable(
                playerContext,
                props.item?.params,
              ) && (
                <View style={styles.actionableIcon}>
                  <Icon name="exclamation" type="evilicon" size={24} />
                </View>
              )}
              <ListItem.Title style={styles.titleText}>{title}</ListItem.Title>
            </View>
            <Text numberOfLines={1}>{description}</Text>
          </ListItem.Content>
          <ListItem.Chevron
            name={currentMessageData.isTerminal() ? "check" : "chevron-right"}
          />
        </ListItem>
        <Divider />
      </View>
    );
  }, [
    title,
    description,
    currentMessageData.isTerminal(),
    currentMessageData.isActionable(playerContext, props.item?.params),
  ]);
}

const EventListItemMemoized = React.memo(
  EventListItem,
  (prevProps, nextProps) => {
    return prevProps.item.currentMessageId === nextProps.item.currentMessageId;
  },
);

export function EventList(props: { style?: any }) {
  const playerContext = useContext(PlayerContext);
  const playerContextRef = useRef(playerContext);
  playerContextRef.current = playerContext;

  const keyExtractor = useCallback(
    (item: EventOccurrenceData, index: number) => item.eventOccurrenceId,
    [],
  );

  const renderItem = useCallback((props: any) => {
    const onPress = () => {
      playerContextRef.current.apply(
        addOccurrenceToQueue(props.item.eventOccurrenceId),
      );
    };
    return <EventListItemMemoized {...props} onPress={onPress} />;
  }, []);

  const sortedEvents = Object.values(playerContext.eventOccurrences).sort(
    (a, b) => b.timestamp - a.timestamp,
  );

  const stringifiedEvents = useMemo(
    () => JSON.stringify(playerContext.eventOccurrences),
    [sortedEvents],
  );

  const terminalEvents = useMemo(
    () =>
      sortedEvents
        .filter((event) => {
          return (
            event.currentMessageId &&
            getEventMessageById(event.currentMessageId).isTerminal()
          );
        })
        .slice(0, 20),
    [stringifiedEvents],
  );
  const ongoingEvents = useMemo(
    () =>
      sortedEvents.filter((event) => {
        return (
          event.currentMessageId &&
          !getEventMessageById(event.currentMessageId).isTerminal()
        );
      }),
    [stringifiedEvents],
  );

  const eventCategories = useMemo(() => {
    const eventCategories = [];
    if (ongoingEvents.length > 0) {
      eventCategories.push({ title: "Ongoing", data: ongoingEvents });
    }
    if (terminalEvents.length > 0) {
      eventCategories.push({ title: "Finished", data: terminalEvents });
    }
    return eventCategories;
  }, [ongoingEvents, terminalEvents]);

  const currentHint = getFirstHint(playerContext);

  const categoryKeyExtractor = useCallback(
    (item: { title: string; data: EventOccurrenceData[] }, index: number) =>
      item.title,
    [],
  );

  const categoryRenderItem = useCallback(
    (props: any) => {
      return (
        <CategoryContainer
          titleStyle={styles.categoryTitle}
          title={props.item.title}
        >
          <FlatList
            keyExtractor={keyExtractor}
            data={props.item.data}
            renderItem={renderItem}
          />
        </CategoryContainer>
      );
    },
    [keyExtractor, renderItem],
  );

  return (
    <View style={props.style}>
      <View style={styles.emptyTitleContainer}>
        <Icon name="notifications" containerStyle={styles.titleIcon} />
        <Text h2>Events</Text>
      </View>
      <View style={styles.hintContainer}>
        <CategoryContainer
          titleStyle={styles.categoryTitle}
          title="Current Goal"
        >
          <View style={styles.hintContents}>
            <Text style={styles.hintContentsText}>
              {currentHint?.description}!
            </Text>
            {currentHint?.progress && (
              <ProgressBar
                progress={currentHint?.progress(playerContext)}
                width={null}
                useNativeDriver={true}
                style={styles.hintProgress}
              />
            )}
          </View>
        </CategoryContainer>
      </View>
      <FlatList
        keyExtractor={categoryKeyExtractor}
        data={eventCategories}
        renderItem={categoryRenderItem}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  categoryTitle: {
    marginLeft: 8,
  },
  emptyTitleContainer: {
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 8,
  },
  titleIcon: {
    paddingRight: 8,
    height: 28,
  },
  hintContents: {
    marginLeft: 16,
    marginTop: 8,
    paddingRight: 32,
  },
  hintContentsText: {
    fontStyle: "italic",
  },
  hintProgress: {
    marginTop: 4,
  },
  hintContainer: {
    marginBottom: 8,
  },
  eventTitleContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  titleText: {
    flex: 1,
  },
  actionableIcon: {
    marginRight: 4,
  },
});
