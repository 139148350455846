import { PlayerContextState } from "../../../PlayerContext";
import { standardEnemyAttackEffect } from "../../Exploration";
import { BattlerAction, BattlerStats, Enemy } from "../Enemy";

class EnchantedSheep extends Enemy {
  getId(): string {
    return "enchantedSheep";
  }

  getName(): string {
    return "Enchanted Sheep";
  }

  getPicture() {
    return require("./enchantedSheep.png");
  }

  getBaseStats(): BattlerStats {
    return {
      maxHP: 1600,
      defense: 48,
      attack: 73,
      attackDelay: 1.5,
      dodgeChance: 0.1,
      critChance: 0.02,
    };
  }

  getNextAction(state: PlayerContextState): BattlerAction {
    const doNothingChance = 0.01;
    if (Math.random() < doNothingChance) {
      return {
        name: "Hop Around",
        delay: this.getAttackDelay(state),
        transform: (state: PlayerContextState) => {
          return state;
        },
      };
    }
    const chargeChance = 0.1;
    if (Math.random() < chargeChance) {
      return {
        name: "Charge!",
        delay: this.getAttackDelay(state) * 2,
        transform: (state: PlayerContextState) =>
          standardEnemyAttackEffect(state, 1.0, this.getAttack(state) * 2),
      };
    }
    return {
      name: "Attack",
      delay: this.getAttackDelay(state),
      transform: (state: PlayerContextState) =>
        standardEnemyAttackEffect(state, 1.0, this.getAttack(state)),
    };
  }

  getCoinsAwarded(state: PlayerContextState): number {
    return 1660;
  }

  getMonstiumAwarded(state: PlayerContextState): number {
    return 136;
  }

  getItemsAwarded(state: PlayerContextState) {
    return [
      {
        itemId: "manaRegenerationPotion",
        chance: 0.02,
        amount: 1,
      },
      {
        itemId: "magicWool",
        chance: 1.0,
        amount: 1,
      },
    ];
  }
}

export default new EnchantedSheep();
