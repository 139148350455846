import { Action } from "./Action";

const actions: Record<string, Action> = {};

export function registerAction(action: Action) {
  actions[action.getName()] = action;
}

export function getActionByName(name: string): Action {
  return actions?.[name] as Action;
}

export function getAllActions(): Record<string, Action> {
  return actions;
}

export function getActionsForAreaByCategory(
  area: string,
): Record<string, Record<string, Action>> {
  let filteredActions: Record<string, Record<string, Action>> = {};
  Object.keys(actions)
    .filter((actionName) => actions[actionName].getArea() === area)
    .forEach((actionName: string) => {
      const category = actions[actionName].getCategory();
      if (!filteredActions?.[category]) {
        filteredActions[category] = {};
      }
      filteredActions[category][actionName] = actions[actionName];
    });
  return filteredActions;
}
