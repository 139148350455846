import { hasFlag } from "../Flags";
import { getTotalElementAndSchoolExp } from "../spells/ElementsAndSchools";
import { Helps } from "./Helps";

export function loadHelpsDemo() {
  Helps.register({
    title: "Introduction",
    isVisible: (state) => true,
    order: 0,
    description: `**Welcome to Magic Research!**
      
In Magic Research, you are the headmaster of a magical institution. Cast spells, accumulate resources, create buildings, and much more to gain prestige and build the best magical school in the world!

In the top left corner, you will find **the menu button** which shows you a breakdown of your current resources and let you access various areas of the game.

In the top right corner, you will find two buttons. **The notification bell button** takes you to the list of events that have happened recently and allows you to see more information about them or interact with them. In this menu, you can also see **your current Goal** - if you are not sure of what to do next, you can follow what it says.

The other button in the top right corner is **the magic wand button**. This will take you to the magic menu, where you can cast any spell you may have learned.`,
  });
  Helps.register({
    title: "Events",
    isVisible: (state) => true,
    order: 10,
    description: `Events are occurrences that happen either randomly with the passage of time or when something specific happens. You will know an event has happened because a dialog will show up and cover the screen.

You can see a list of events by going to **the event list menu**, by pressing the notification bell button at the top right of the screen. Pressing on any event will let you see the last message of the event, and you can check out the previous messages by using the arrows at the top of the dialog.    

Events are **very important** in the game, as most progress ends up happening via events that trigger when certain actions are done.

Events can be either *Completed* or *Ongoing*. Completed events require no further action on your part. Ongoing events, on the other hand, mean that you haven't reached the end of the event for some reason. If possible, you should strive to complete all ongoing events. If you have ongoing events, the notification bell icon will show a number in red indicating this fact.

There is a special kind of event called **Storyline**. These events are particularly important; keep an eye for them and aim to complete as many as possible.`,
  });
  Helps.register({
    title: "Spells",
    isVisible: (state) => true,
    order: 20,
    description: `Spells are your primary way of interacting with the game. You can cast them usually from **the cast spell menu** and they cost a certain amount of :mana:. Because of this reason, :mana: is the most important resource in the game. You can obtain Mana by gathering it manually in your Campus, or via buildings like the Mana Spouts.
    
Spells are divided into **schools of magic**. These roughly group spells by a theme. There are three schools of magic: **Conjuration**, which mostly allows you to create resources such as :stone: and :water:; **Enchantment** that allows you to improve the performance of your buildings; and **Illusion** which allows you to obtain more :coins:. (Or are there really just these three...?)

Using spells from a certain school will give you experience on that school. Eventually, the school level will increase, which will usually unlock new spells.

At the beginning of the game, you selected a *primary school of magic*. It will be easier to level this primary school, which means you will likely unlock more advanced spells for this school quicker than for the other schools.`,
  });
  Helps.register({
    title: "Campus",
    isVisible: (state) => true,
    order: 100,
    description: `In the **Campus** screen, you can do a limited set of actions such as **Gather Mana**, or use your resources to **construct Buildings**.`,
  });
  Helps.register({
    title: "Research",
    isVisible: (state) => hasFlag(state, "research_unlocked"),
    order: 110,
    description: `In the **Research** screen, you can assign the researchers that you have hired to do research on schools of magic. Each researcher generates a set amount of school experience per second.
    
Research is a much more effective way of raising school levels than casting spells, and it becomes even more effective the more you progress in the game.`,
  });
  Helps.register({
    title: "Apprentices",
    isVisible: (state) => hasFlag(state, "autocast_unlocked"),
    order: 120,
    description: `In the **Apprentices** screen, you can order the apprentices from the school to cast spells automatically for you. This is very useful for **automating** several of the actions you typically do. One example: ordering apprentices to cast *Enchant Mana Spouts* will let you get the vastly improved :mana: production without having to make a clear effort to cast the enchantment over and over.
    
In order for an apprentice to be able to cast a spell, your school level for that spell needs to be one level higher than the level required to unlock it. For example, for *Enchant Mana Spouts*, you need Enchantment Lv1 to cast it - but you need Enchantment Lv2 for apprentices to be able to cast it for you.

**Apprentices cast each spell once every 60 seconds.** If you want apprentices to cast a spell faster, you can **assign multiple apprentices to the same spell** which will increase the frequency of the spell proportionally. For example, **2 apprentices would cast the spell twice per minute (once every 30 seconds)**, 3 apprentices would cast it three times per minute (once every 20 seconds), 4 apprentices would be 4 times per minute (once every 15 seconds), and so on.

If a spell cannot be cast when an apprentice attempts to cast it, usually because there is not enough :mana:Mana, the apprentice will cast the spell as soon as it is able to.

Sometimes, it can be useful to have apprentices stop casting spells temporarily. You can pause apprentices with the **Pause Apprentices** button at the bottom of this screen.

In addition, as you play the game you will face different situations which means you will want to have different spells being cast automatically. You can use **Loadouts** for this, at the bottom of the Apprentices screen. Saving to a loadout will record your current apprentice assignment to the specified slot. You can then load it later to go back to that assignment.`,
  });
  Helps.register({
    title: "Inventory and Crafting",
    isVisible: (state) => hasFlag(state, "inventory_unlocked"),
    order: 130,
    description: `In the **Inventory** screen, you are able to see the *items* you have accumulated and crafted. Items are objects that have various effects. Some of them can be *equipped* on yourself, which will provide various bonuses. Others can be used directly from this screen. You may also throw away (or later, sell) unwanted items.
    
On the top right of this screen is a **Craft Items** button, that opens the crafting interface. From that interface, you can select an item and, if you have the materials, you can create the item. The materials are either resources or other items.

You can unlock new items to craft by increasing your school levels. Conjuration and Enchantment typically unlock new items to craft, but other schools may also unlock new items. In some cases, some items may require a certain level from multiple schools at once. For example, the item "Branch" requires Lv4 Conjuration and Lv4 Enchantment.

**Note: If you are unable to craft an item even though you have the materials, your inventory might be full! You need at least one empty slot in your inventory to craft, even if you are creating a second item of the same type.**`,
  });
  Helps.register({
    title: "Exploration",
    isVisible: (state) => hasFlag(state, "exploration_unlocked"),
    order: 140,
    description: `In the **Exploration** screen, you can let your headmaster travel to various areas and brave dangers - and find untold knowledge. Maybe something you find while exploring will decisively bring prestige to the school!
    
Select a destination or dungeon to explore, and the headmaster will begin exploring. Once the exploration finishes, the headmaster will begin combat against an enemy. 

If combat is successful, you will earn :coins:, :monstium: and possibly some items. The stronger the enemy, the bigger the rewards typically are. These rewards are shown on the Exploration screen as they are obtained.

If combat is unsuccessful, the headmaster will be defeated and will wait until its health is fully recovered before exploring again.

After succeeding in combat a certain number of times, a *Boss* is unlocked for the area, which is exceptionally strong. Defeating the Boss will let you move on to the next floor of the destination you are in. You can do this by pressing the "Next Floor" button.`,
  });
  Helps.register({
    title: "Combat",
    isVisible: (state) => hasFlag(state, "exploration_unlocked"),
    order: 150,
    description: `During **Combat**, your headmaster fights an enemy head-on. The goal is to reduce the enemy's :hp: (health, or HP), to zero, before letting your own :hp: drop to zero.
    
Your headmaster will automatically attack using the hand item it is equipped with, while the enemy can act in different ways, often attacking back. You can see the progress of the current combat in the **Exploration** screen.

You can also manually use items or cast spells from the bottom of the **Combat** screen, which can help give you an edge. You can add often-used items or spells to the **Quickbar**, which is a separate screen that conveniently allows you to do so without having to search for each item or spell. In the Quickbar you can also see your current temporary effects (positive and negative).`,
  });
  Helps.register({
    title: "Upgrades",
    isVisible: (state) => hasFlag(state, "upgrades_unlocked"),
    order: 160,
    description: `In the **Upgrades** screen, you can spend your accumulated :monstium: into various improvements to several areas of the game, from buildings in your campus, inventory size, combat attributes, among others.
    
Upgrades do not persist through retirement and must be purchased again every time.`,
  });

  Helps.register({
    title: "Retirement",
    isVisible: (state) => getTotalElementAndSchoolExp(state) >= 10000,
    order: 500,
    description: `**Retirement** is one of the most powerful features in the game. In essence, retiring allows you to restart the game from the beginning, including choosing a new primary school and element. However, there are strong benefits to retirement which are detailed below.
    
When you retire, the level your primary school is currently at is permanently recorded. This level is called the **Maximum Primary Level**, or **MPL**. After you retire, you will get a **Global Research Multiplier** depending on the highest **MPL** you have achieved for every school. This multiplier is very important, as it can vastly improve the effectiveness of research, letting you unlock stronger spells much, much quicker.

In addition, there is a similar **Global Production Multiplier** which will affect all resource production you have from buildings and other features in the game. This includes Mana, which means after retirement it should be much easier to cast stronger spells or cast spells more often. This also affects loot.

One last benefit from retirement is related to Storylines. Any **benefits acquired from Storylines will persist through retirements**, meaning progress will become faster. In addition, some Storylines only provide their benefit after you retire once - retiring is encouraged in order to get the full benefit in those cases.

Deciding when to retire is very important. One suggestion is to retire if progress has gotten slower or achieving the next Goal (see: **Introduction** help section) appears impossible. Another is to retire if the research and production multipliers will substantially increase, or if you complete a Storyline with powerful effects that only appear after retirement.`,
  });
  Helps.register({
    title: "Storylines",
    isVisible: (state) => true,
    order: 510,
    description: `**Storylines** are special events. Like other events, they can happen randomly or once a certain action happens, but they are often rarer and harder to come by. 
    
Storylines provide powerful permanent benefits when completed. If you happen to come across a Storyline event, you should complete it if possible, as the benefits can often significantly change how the game plays.

Storylines represent progress through the game, and as a player one of the goals of the game is to complete every Storyline. You can see the progress in completing all Storylines in the Stats menu.`,
  });
  Helps.register({
    title: "Stats",
    isVisible: (state) => true,
    order: 900,
    description: `In the **Stats** screen, you can see a set of statistics about your game, such as the total amount of played time, the Maximum Primary Levels for every school, or the amount of completed Storylines.`,
  });
  Helps.register({
    title: "Options / Save Data",
    isVisible: (state) => true,
    order: 910,
    description: `In the **Options** screen, you can access a few configuration options for the game and you can export or import your save data. You can use this feature to transfer your progress from the demo of Magic Research to the full game.
    
To export your save data, press the **Copy save data to clipboard** button. Once you have done this, paste this in some text document. This text is your current save data. You can share this with other people or store it somewhere safe as a backup.

To import new save data, you can paste the text that was exported on the previous step into the text field. If the import is successful, the interface will instantly change and reflect the new data. **Note: you will lose your old save data once this happens, so make sure to export and store it somewhere first!**`,
  });
}
