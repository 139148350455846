import React, { useContext } from "react";
import { PlayerContext } from "../../backend/PlayerContext";
import { getActionsForAreaByCategory } from "../../backend/actions/Actions";
import { ActionContainer } from "./ActionContainer";
import { StyleSheet, View } from "react-native";
import { Divider } from "react-native-elements";
import { Text } from "./Text";

type CategoryContainerProps = {
  title: string;
  titleStyle?: any;
  children: any;
};

export default function CategoryContainer(props: CategoryContainerProps) {
  return (
    <View style={styles.category}>
      <Text style={[styles.categoryTitle, props.titleStyle]} h4>
        {props.title}
      </Text>
      <Divider style={styles.categoryDivider} />
      {props.children}
    </View>
  );
}

const styles = StyleSheet.create({
  category: { marginTop: 16 },
  categoryTitle: { marginBottom: 4, fontWeight: "bold" },
  categoryDivider: { height: StyleSheet.hairlineWidth * 2 },
});
