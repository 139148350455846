import { PlayerContextState } from "../../../PlayerContext";
import { EquipmentSlot } from "../../Equipment";
import { ItemParams } from "../../Item";
import { EquippableItem } from "../EquippableItem";

class SpikedClub extends EquippableItem {
  getId(): string {
    return "spikedClub";
  }

  getPicture() {
    return require("./spikedClub.png");
  }

  getBaseName(): string {
    return "Spiked Club";
  }

  getDescription(): string | undefined {
    return "A club with a wooden base, but reinforced with spikes for a more destructive punch.";
  }

  getAttackBonus(state: PlayerContextState, params: ItemParams): number {
    return 55;
  }

  getAttackDelayBonusPct(
    state: PlayerContextState,
    params: ItemParams,
  ): number {
    return 20;
  }

  getSlot(): EquipmentSlot {
    return EquipmentSlot.Hand;
  }

  getSalePrice(): number {
    return 3600;
  }

  getCraftingSchoolLevelRequirements() {
    return { Conjuration: 10 };
  }

  getCraftingMaterialsBase() {
    return {
      resources: {
        Wood: 6500,
        Iron: 600,
        Temperature: 1800,
      },
      items: {},
    };
  }
}

const spikedClub = new SpikedClub();

export default spikedClub;
