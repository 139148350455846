import React, { useContext, useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { ItemStack } from "../../backend/items/Item";
import { getItemById } from "../../backend/items/Items";
import { PlayerContext } from "../../backend/PlayerContext";
import { DescriptionViewer, EffectViewer } from "../utility/Miscellaneous";
import { Text } from "../utility/Text";
import { ItemTags } from "./ItemTags";
import { ItemTile } from "./ItemTile";

export interface ItemInfoProps {
  itemStack: ItemStack;
}

export function ItemInfo(props: ItemInfoProps) {
  const playerContext = useContext(PlayerContext);

  const { itemId, params } = props.itemStack.itemOccurrence;
  const item = getItemById(itemId);
  const name = item.getName(params);
  const description = item.getDescription(playerContext, params);
  const effect = item.getEffect(playerContext, params);
  const tags = item.getTags(playerContext, params);

  return useMemo(
    () => (
      <View style={styles.itemDetailsContainer}>
        <View style={styles.main}>
          <Text h3>
            {name}
            {props.itemStack.amount > 1 && ` (${props.itemStack.amount})`}
          </Text>
          <View style={styles.tagRow}>
            <ItemTags tags={tags} />
          </View>
          {description && <DescriptionViewer>{description}</DescriptionViewer>}
          {effect && <EffectViewer>{effect}</EffectViewer>}
        </View>
        <ItemTile icon={item.getId()} />
      </View>
    ),
    [
      name,
      props.itemStack.amount,
      JSON.stringify(tags),
      description,
      effect,
      item.getId(),
    ],
  );
}

const styles = StyleSheet.create({
  itemDetailsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  main: {
    flex: 1,
  },
  tagRow: {
    marginVertical: 4,
  },
});
