import CreateBoulder from "./conjuration/CreateBoulder";
import CreateIronBar from "./conjuration/CreateIronBar";
import CreateIronOre from "./conjuration/CreateIronOre";
import CreateRock from "./conjuration/CreateRock";
import CreateStone from "./conjuration/CreateStone";
import CreateWater from "./conjuration/CreateWater";
import CreateWaterII from "./conjuration/CreateWaterII";
import CreateWaterIII from "./conjuration/CreateWaterIII";
import EnchantFurnaces from "./enchantment/EnchantFurnaces";
import EnchantLumberYards from "./enchantment/EnchantLumberYards";
import EnchantManaGeysers from "./enchantment/EnchantManaGeysers";
import EnchantManaSpouts from "./enchantment/EnchantManaSpouts";
import EnchantMines from "./enchantment/EnchantMines";
import Levitation from "./enchantment/Levitation";
import PhysicalAugment from "./enchantment/PhysicalAugment";
import Quickness from "./enchantment/Quickness";
import Stoneskin from "./enchantment/Stoneskin";
import Strength from "./enchantment/Strength";
import VigorOfHealth from "./enchantment/VigorOfHealth";
import AirCutter from "./evocation/AirCutter";
import Blizzard from "./evocation/Blizzard";
import ConeOfFire from "./evocation/ConeOfFire";
import FireBall from "./evocation/FireBall";
import Gale from "./evocation/Gale";
import IceNeedle from "./evocation/IceNeedle";
import InfernalBlaze from "./evocation/InfernalBlaze";
import Landslide from "./evocation/Landslide";
import ManaSpike from "./evocation/ManaSpike";
import RockTomb from "./evocation/RockTomb";
import Stalagmite from "./evocation/Stalagmite";
import Twister from "./evocation/Twister";
import WaterBomb from "./evocation/WaterBomb";
import Blur from "./illusion/Blur";
import BubbleShow from "./illusion/BubbleShow";
import Dazzle from "./illusion/Dazzle";
import FlameShow from "./illusion/FlameShow";
import Getaway from "./illusion/Getaway";
import MagicShow from "./illusion/MagicShow";
import RainbowShow from "./illusion/RainbowShow";
import Slow from "./illusion/Slow";
import TimeDilation from "./illusion/TimeDilation";
import WindShow from "./illusion/WindShow";
import { registerSpell } from "./Spells";

export function loadSpellsDemo() {
  // ======== Conjuration

  registerSpell(CreateStone);
  registerSpell(CreateRock);
  registerSpell(CreateBoulder);
  registerSpell(CreateWater);
  registerSpell(CreateWaterII);
  registerSpell(CreateWaterIII);
  registerSpell(CreateIronOre);
  registerSpell(CreateIronBar);

  // ======== Enchantment

  registerSpell(EnchantManaSpouts);
  registerSpell(EnchantLumberYards);
  registerSpell(EnchantFurnaces);
  registerSpell(EnchantManaGeysers);
  registerSpell(EnchantMines);
  registerSpell(VigorOfHealth);
  registerSpell(Stoneskin);
  registerSpell(Quickness);
  registerSpell(Strength);
  registerSpell(Levitation);
  registerSpell(PhysicalAugment);

  // ======== Illusion

  registerSpell(MagicShow);
  registerSpell(WindShow);
  registerSpell(BubbleShow);
  registerSpell(FlameShow);
  registerSpell(Getaway);
  registerSpell(Blur);
  registerSpell(RainbowShow);
  registerSpell(Dazzle);
  registerSpell(Slow);
  registerSpell(TimeDilation);

  // ======== Evocation

  registerSpell(ManaSpike);
  registerSpell(FireBall);
  registerSpell(ConeOfFire);
  registerSpell(InfernalBlaze);
  registerSpell(Stalagmite);
  registerSpell(RockTomb);
  registerSpell(Landslide);
  registerSpell(IceNeedle);
  registerSpell(WaterBomb);
  registerSpell(Blizzard);
  registerSpell(AirCutter);
  registerSpell(Gale);
  registerSpell(Twister);
}
