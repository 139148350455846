import { PlayerContextState } from "../../../PlayerContext";
import { EquipmentSlot } from "../../Equipment";
import { ItemParams } from "../../Item";
import { EquippableItem } from "../EquippableItem";

class Club extends EquippableItem {
  getId(): string {
    return "club";
  }

  getPicture() {
    return require("./club.png");
  }

  getBaseName(): string {
    return "Club";
  }

  getDescription(): string | undefined {
    return "A wooden club. Slightly heavy.";
  }

  getAttackBonus(state: PlayerContextState, params: ItemParams): number {
    return 20;
  }

  getAttackDelayBonusPct(
    state: PlayerContextState,
    params: ItemParams,
  ): number {
    return 20;
  }

  getEffect(): string | undefined {
    return "+20:attack:, +20%:attackDelay:";
  }

  getSlot(): EquipmentSlot {
    return EquipmentSlot.Hand;
  }

  getSalePrice(): number {
    return 200;
  }

  getCraftingSchoolLevelRequirements() {
    return { Conjuration: 4 };
  }

  getCraftingMaterialsBase() {
    return {
      resources: {
        Wood: 900,
      },
      items: {},
    };
  }
}

const club = new Club();

export default club;
