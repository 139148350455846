import { formatNumber } from "../../../utils/FormattingUtils";
import { ActionEffect } from "../../actions/Action";
import { getBuildingAmount } from "../../buildings/Buildings";
import ManaGeyser from "../../buildings/campus/ManaGeyser";
import {
  registerTransformation,
  TransformationType,
} from "../../calculation/Calculation";
import { TransformationTags } from "../../calculation/TransformationTags";
import { PlayerContextState } from "../../PlayerContext";
import {
  grantTemporaryEffect,
  hasTemporaryEffect,
} from "../../timetick/TemporaryEffects";
import { SpellActionBase } from "../Spell";

class EnchantManaGeysers extends SpellActionBase {
  constructor() {
    super();
  }

  isVisible(state: PlayerContextState): boolean {
    return super.isVisible(state) && getBuildingAmount(state, ManaGeyser) > 0;
  }

  getSpellName(): string {
    return "Enchant Mana Geysers";
  }

  getArea(): string {
    return "Campus";
  }

  getDisplayDescription(): string {
    return "Magically alter your Mana Geysers to produce more mana for a limited time.";
  }

  protected getBaseActionEffects(): Record<string, ActionEffect> {
    return {
      magnitude: {
        value: 2.0,
        tags: [TransformationTags.TemporaryEffectMagnitude],
      },
      duration: {
        value: 20,
        tags: [TransformationTags.TemporaryEffectDuration],
      },
    };
  }

  doSpellAction(state: PlayerContextState): PlayerContextState {
    const effects = this.getActionEffects(state);
    return grantTemporaryEffect(
      this.getId(),
      this.getSpellName(),
      effects.duration,
      {},
    )(state);
  }

  getDisplayEffect(state: PlayerContextState): string {
    const effects = this.getActionEffects(state);
    return `${formatNumber(effects.magnitude, {
      showDecimals: true,
    })}x:mana: produced by Mana Geysers for ${formatNumber(
      effects.duration,
    )} sec.`;
  }

  getManaCost(): number {
    return 160;
  }

  getSchoolLevelRequirements(): Record<string, number> {
    return {
      Enchantment: 9,
    };
  }
}

const enchantManaGeysers = new EnchantManaGeysers();

registerTransformation(
  [[ManaGeyser.getName(), TransformationTags.Production]],
  "EnchantManaGeysers",
  TransformationType.Multiplier,
  (state) =>
    hasTemporaryEffect(state, "Enchant Mana Geysers")
      ? enchantManaGeysers.getActionEffects(state).magnitude
      : 1,
);

export default new EnchantManaGeysers();
