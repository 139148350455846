import { ActionEffect } from "../../actions/Action";
import { TransformationTags } from "../../calculation/TransformationTags";
import { PlayerContextState } from "../../PlayerContext";
import { CombatSpellBase } from "../CombatSpellBase";
import { SpellElement } from "../ElementsAndSchools";
import {
  getStandardAttackEffectText,
  getStandardSpellAttackEffect,
} from "../Utils";

class Landslide extends CombatSpellBase {
  getSpellName(): string {
    return "Landslide";
  }

  getElement(): SpellElement | undefined {
    return SpellElement.Earth;
  }

  getDisplayDescription(): string {
    return "Crush an enemy with the power of a landslide.";
  }

  protected getBaseActionEffects(): Record<string, ActionEffect> {
    return {
      average: {
        value: 280,
        tags: [TransformationTags.ActionMagnitude],
      },
      variance: {
        value: 80,
        tags: [TransformationTags.ActionVariance],
      },
    };
  }

  getDisplayEffect(state: PlayerContextState): string {
    const effects = this.getActionEffects(state);
    return getStandardAttackEffectText(effects.average, effects.variance);
  }

  doSpellAction(state: PlayerContextState): PlayerContextState {
    return getStandardSpellAttackEffect(state, this);
  }

  getBaseCooldown(): number {
    return 4.5;
  }

  isVisible(state: PlayerContextState): boolean {
    return super.isVisible(state) && state.primaryElement == "Earth";
  }

  getManaCost(): number {
    return 1500;
  }

  getSchoolLevelRequirements(): Record<string, number> {
    return {
      Evocation: 13,
    };
  }
}

export default new Landslide();
