import {
  registerTransformation,
  TransformationType,
} from "../../calculation/Calculation";
import { TransformationTags } from "../../calculation/TransformationTags";
import { PlayerContextState } from "../../PlayerContext";
import { Upgrade } from "../Upgrade";
import healthBoost from "./HealthBoost";

class ExplorationTraining extends Upgrade {
  getName(): string {
    return "Exploration Training";
  }

  getMonstiumCost(): number {
    return 1200;
  }

  getDisplayDescription(state: PlayerContextState): string {
    return "Train yourself on techniques that can make exploration more effective.";
  }

  getDisplayEffect(state: PlayerContextState): string {
    return "-20% Exploration time";
  }

  getUpgradeRequirements() {
    return [healthBoost];
  }
}

const explorationTraining = new ExplorationTraining();

registerTransformation(
  [[TransformationTags.ExplorationDelay]],
  "ExplorationTraining",
  TransformationType.Multiplier,
  (state) => (explorationTraining.isPurchased(state) ? 0.8 : 1.0),
);

export default explorationTraining;
